import React, { ReactElement } from 'react';
import {
  TextField,
  DateField,
  useResourceContext,
  useStore,
} from 'react-admin';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import ListWithTitle from '../common/ListWithTitle';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import { getSideFiltersKey } from '../common/helpers/storeKeys';
import PunchPriorityField from '../common/PunchPriorityField';
import PunchCategoryField from '../common/PunchCategoryField';
import DisciplineField from '../common/DisciplineField';
import ComponentField from '../common/ComponentField';
import LocationField from '../common/LocationField';
import ColumnActions from '../common/ColumnActions';
import ListActions from '../common/ListActions';
import SelectField from '../common/SelectField';
import SubsystemField from '../common/SubsystemField';
import { originators } from '../submitPunch/constants';
import { useDictionaryLabelsWithResource } from '../../utils/UtilityFunctions';
import { statuses } from './constants';
import PendingPunchShow from './PendingPunchShow';
import PendingPunchEdit from './PendingPunchEdit';
import BulkActionButtons from './components/BulkActionButtons';
import PendingPunchSidebarFilters from './components/PendingPunchSidebarFilters';

const PendingPunchList: React.FC = (): ReactElement => {
  const resource: string = useResourceContext();
  const sideFilter = getSideFiltersKey(resource);
  const [showSideFilter] = useStore(sideFilter, false);
  const defaultFilter = useDefaultFilter('project', {
    status: ['SUBMITTED', 'APPROVED'],
    masterPunchId: 'NULL',
  });
  const { labels } = useDictionaryLabelsWithResource();

  return (
    <ListWithTitle
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={
        <ListActions
          sideFilter={sideFilter}
          defaultFilter={defaultFilter}
          showAction={{
            import: false,
            export: false,
            effective: false,
            create: false,
            select: true,
          }}
        />
      }
      sort={{ field: 'id', order: 'ASC' }}
      aside={
        showSideFilter ? (
          <PendingPunchSidebarFilters defaultFilter={defaultFilter} />
        ) : null
      }
    >
      <DatagridConfigurableRbac
        ShowComponent={<PendingPunchShow withoutActions />}
        EditComponent={<PendingPunchEdit syncWithLocation={false} />}
        bulkActionButtons={<BulkActionButtons />}
        defaultVisibleColumns={[
          'id',
          'description',
          'subsystemId',
          'component.id',
          'priorityId',
          'punchCatId',
          'status',
        ]}
      >
        <TextField source="id" label="ID" />
        <TextField source="description" label="Description" />
        <SubsystemField label="SubSystem" />
        <ComponentField
          source="componentId"
          textSource="component.tag"
          sortBy="`component.tag`"
          label="Tag"
        />
        <PunchPriorityField label="Priority" />
        <PunchCategoryField source="punchCatId" label={labels['punchCatId']} />
        <DisciplineField />
        <LocationField />
        <TextField source="raisedBy" label="Raised By" />
        <DateField source="raisedDate" label="Raised Date" />
        <SelectField source="status" label="Status" choices={statuses} />
        <SelectField
          source="originator"
          label="Originator"
          choices={originators}
        />
        <TextField source="comments" label="Review Comments" />
        {renderMetaDataFields()}
        <ColumnActions label="Actions" />
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default PendingPunchList;
