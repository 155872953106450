import React, { ReactElement } from 'react';
import { maxLength, SimpleForm } from 'react-admin';
import Box from '@mui/material/Box';
import commonStyles from '../common/commonStyles';
import FooterToolbar from '../common/FooterToolbar';
import { getContext, UserContext } from '../../provider/userContext';
import CreateWithTitle from '../common/CreateWithTitle';
import TextInput from '../common/TextInput';
import { required } from '../../utils/UtilityFunctions';
import { TestType } from './types';

const TestTypeCreate: React.FC = (): ReactElement => {
  const ctx: UserContext = getContext();

  const transform = (data: TestType): TestType => ({
    ...data,
    divisionId: ctx?.defaultDivisionId,
  });

  return (
    <CreateWithTitle transform={transform} redirect="list">
      <SimpleForm toolbar={<FooterToolbar />}>
        <Box sx={commonStyles.createBoxHalfWidth}>
          <TextInput
            source="testType"
            label="Test Type"
            validate={[required(), maxLength(30)]}
            sx={commonStyles.flexBoxItem}
          />
        </Box>
        <Box sx={commonStyles.createBoxHalfWidth}>
          <TextInput
            source="description"
            label="Description"
            validate={maxLength(120)}
            sx={commonStyles.flexBoxItem}
            fullWidth
          />
        </Box>
      </SimpleForm>
    </CreateWithTitle>
  );
};

export default TestTypeCreate;
