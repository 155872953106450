import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import ColumnActions from '../common/ColumnActions';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import ListActions from '../common/ListActions';
import ListWithTitle from '../common/ListWithTitle';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import ActivityTypeEdit from './ActivityTypeEdit';
import ActivityTypeShow from './ActivityTypeShow';

const ActivityTypeList: React.FC = (): ReactElement => {
  const defaultFilter = useDefaultFilter('project');

  return (
    <ListWithTitle
      filters={<FreeTextSearchFilter />}
      actions={<ListActions />}
      filterDefaultValues={defaultFilter}
      sort={{ field: 'activityType', order: 'ASC' }}
    >
      <DatagridConfigurableRbac
        ShowComponent={<ActivityTypeShow withoutActions />}
        EditComponent={<ActivityTypeEdit syncWithLocation={false} />}
        defaultVisibleColumns={['id', 'activityType']}
      >
        <TextField source="id" label="ID" />
        <TextField source="activityType" label="Activity Type" />
        {renderMetaDataFields()}
        <ColumnActions label="Actions" />
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default ActivityTypeList;
