import React, { ReactElement } from 'react';
import { Link, TextField, useRecordContext } from 'react-admin';
import get from 'lodash/get';
import { RESOURCE_PRESERVATION_SERIES } from '../constants';

interface PreservationSeriesFieldProps {
  label?: string;
  source?: string;
}

const PreservationSeriesField: React.FC<PreservationSeriesFieldProps> = ({
  label,
  source,
}): ReactElement => {
  const record = useRecordContext();
  const field = get(record, source);
  const link = `/${RESOURCE_PRESERVATION_SERIES}/${field}/show`;

  return (
    <Link to={link}>
      <TextField label={label} source="preservationSeriesId" />
    </Link>
  );
};

PreservationSeriesField.defaultProps = {
  label: 'Preservation Series',
  source: 'preservationSeriesId',
};

export default PreservationSeriesField;
