import React, { ReactElement, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { SxProps, Theme } from '@mui/material/styles';
import { assetOptionRenderer } from '../../structureAssetGroup/helper';
import { getContext, UserContext } from '../../../provider/userContext';
import { RESOURCE_STRUCTURE_ASSET } from '../../constants';
import AutocompleteReferenceInput from '../../common/AutocompleteReferenceInput';
import { required } from '../../../utils/UtilityFunctions';

interface AssetInputProps {
  isRequired?: boolean;
  alwaysOn?: boolean;
  multiple?: boolean;
  sx: SxProps<Theme>;
  source?: string;
}

const AssetInput: React.FC<AssetInputProps> = ({
  isRequired,
  multiple,
  sx,
  ...rest
}): ReactElement => {
  const ctx: UserContext = getContext();
  const regionId = useWatch({ name: 'regionId' });

  const requestParams = useMemo(() => {
    return {
      filter: {
        division_id: ctx?.defaultDivisionId,
        ...(regionId && { regionId }),
      },
      sort: { field: 'asset', order: 'ASC' as const },
    };
  }, [regionId, ctx]);

  return (
    <AutocompleteReferenceInput
      {...rest}
      resource={RESOURCE_STRUCTURE_ASSET}
      requestParams={requestParams}
      label="Asset"
      name="asset_inc"
      optionText={assetOptionRenderer}
      multiple={multiple}
      validate={isRequired ? [required()] : []}
      sx={sx}
      fullWidth
      includeFields={['id', 'asset', 'region.region']}
    />
  );
};

AssetInput.defaultProps = { source: 'assetId' };

export default AssetInput;
