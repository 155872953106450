import React, { ReactElement } from 'react';
import {
  DatagridConfigurable,
  ReferenceManyField,
  TextField,
  DateField,
} from 'react-admin';
import { RESOURCE_MATERIAL_PURCHASE_ORDER_MATERIAL } from '../../../constants';
import PurchaseOrderField from '../../../common/PurchaseOrderField';

const PurchaseOrderTabList: React.FC = (): ReactElement => {
  return (
    <ReferenceManyField
      reference={RESOURCE_MATERIAL_PURCHASE_ORDER_MATERIAL}
      target="materialId"
      perPage={100}
      fullWidth={true}
    >
      <DatagridConfigurable bulkActionButtons={false} rowClick={false}>
        <DateField
          source="materialPurchaseOrder.generateDate"
          label="Date Generated"
        />
        <PurchaseOrderField
          source="materialPurchaseOrderId"
          textSource="materialPurchaseOrder.orderNo"
        />
        <TextField source="qtyOrdered" label="Qty Ordered" />
        <DateField
          source="materialPurchaseOrder.receivedDate"
          label="Date Received"
        />
        <TextField source="qtyReceived" label="Qty Received" />
        <TextField source="materialPurchaseOrder.status" label="Status" />
      </DatagridConfigurable>
    </ReferenceManyField>
  );
};

export default PurchaseOrderTabList;
