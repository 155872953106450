import React, { ReactElement } from 'react';
import { useStore } from 'react-admin';
import { RED, WHITE, YELLOW } from '../../theme/colors';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import { getSideFiltersKey } from '../common/helpers/storeKeys';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import LegendButton from '../common/LegendButton';
import ListActions from '../common/ListActions';
import ListWithTitle from '../common/ListWithTitle';
import { RESOURCE_TEST_EQUIPMENT } from '../constants';
import TestEquipmentListDatagrid from './components/TestEquipmentListDatagrid';
import TestEquipmentsSidebarFilters from './components/TestEquipmentSidebarFilters';

const legends = [
  {
    label: 'Calibration Expiration Date: more than a month',
    color: WHITE,
  },
  {
    label: 'Calibration Expiration Date: less than a month',
    color: YELLOW,
  },
  {
    label: 'Calibration Expiration Date: expired',
    color: RED,
  },
];

const TestEquipmentList: React.FC = (): ReactElement => {
  const sideFilter = getSideFiltersKey(RESOURCE_TEST_EQUIPMENT);
  const [showSideFilter] = useStore(sideFilter, false);
  const defaultFilter = useDefaultFilter('project');

  return (
    <ListWithTitle
      resource={RESOURCE_TEST_EQUIPMENT}
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={
        <ListActions
          sideFilter={sideFilter}
          defaultFilter={defaultFilter}
          actions={<LegendButton legends={legends} />}
        />
      }
      sort={{ field: 'id', order: 'ASC' }}
      aside={
        showSideFilter && (
          <TestEquipmentsSidebarFilters defaultFilter={defaultFilter} />
        )
      }
    >
      <TestEquipmentListDatagrid />
    </ListWithTitle>
  );
};

export default TestEquipmentList;
