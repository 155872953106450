import React, { useEffect, ReactElement } from 'react';
import { BooleanInput } from 'react-admin';
import { useWatch, useFormContext } from 'react-hook-form';
import { SxProps, Theme } from '@mui/material/styles';

interface DependentBooleanInputProps {
  source: string;
  label: string;
  dependentSource: string;
  defaultValue?: boolean;
  sx?: SxProps<Theme>;
}

const DependentBooleanInput: React.FC<DependentBooleanInputProps> = ({
  source,
  label,
  dependentSource,
  ...rest
}): ReactElement => {
  const { setValue } = useFormContext();
  const dependentInputOn = useWatch({
    name: dependentSource,
  });

  useEffect(() => {
    if (dependentInputOn) {
      setValue(source, false);
    }
  }, [dependentInputOn, setValue, source]);

  return <BooleanInput {...rest} source={source} label={label} />;
};

export default DependentBooleanInput;
