import React, { ReactElement, useMemo } from 'react';
import { AutocompleteInput } from 'react-admin';
import { getContext, UserContext } from '../../provider/userContext';
import { RESOURCE_ACTIVITY_TYPE } from '../constants';
import { required } from '../../utils/UtilityFunctions';
import AutocompleteReferenceInput from './AutocompleteReferenceInput';

interface ActivityTypeInputProps
  extends React.ComponentProps<typeof AutocompleteInput> {
  isRequired?: boolean;
}

const ActivityTypeInput: React.FC<ActivityTypeInputProps> = ({
  isRequired,
  ...rest
}): ReactElement => {
  const ctx: UserContext = getContext();
  const requestParams = useMemo(() => {
    return {
      filter: {
        projectId: ctx?.projectId,
      },
      sort: { field: 'activityType', order: 'ASC' as const },
    };
  }, [ctx?.projectId]);

  return (
    <AutocompleteReferenceInput
      {...rest}
      resource={RESOURCE_ACTIVITY_TYPE}
      requestParams={requestParams}
      label="Type"
      name="activityType_inc"
      optionText="activityType"
      validate={isRequired ? required() : undefined}
      includeFields={['id', 'activityType']}
    />
  );
};

ActivityTypeInput.defaultProps = {
  source: 'activityTypeId',
};

export default ActivityTypeInput;
