import {
  DateField,
  Labeled,
  NumberField,
  TabbedShowLayout,
  TextField,
} from 'react-admin';
import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import renderShowMetaDataFields from '../common/helpers/renderShowMetaDataFields';
import { useDictionaryLabelsWithResource } from '../../utils/UtilityFunctions';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import CustomBooleanField from '../common/CustomBooleanField';
import AssignedField from '../common/Assigned/AssignedField';
import ComponentField from '../common/ComponentField';
import LocationField from '../common/LocationField';
import commonStyles from '../common/commonStyles';
import { commentType } from '../common/constants';
import CommentList from '../comment/CommentList';
import AuditListTab from '../audit/AuditListTab';
import { RESOURCE_COMMENT } from '../constants';
import TestField from '../common/TestField';
import { PreservationSeriesListTab } from './components/PreservationSeriesListTab';

const PreservationItemShow: React.FC<ShowComponentProps> = ({
  syncWithLocation,
  ...rest
}): ReactElement => {
  const { labels } = useDictionaryLabelsWithResource();

  return (
    <ShowComponent {...rest}>
      <TabbedShowLayout
        sx={commonStyles.bottomMargin}
        syncWithLocation={syncWithLocation}
      >
        <TabbedShowLayout.Tab label="Preservation Item">
          <Box>
            <Labeled
              label={labels['componentId']}
              sx={commonStyles.flexBoxItem}
            >
              <ComponentField />
            </Labeled>
            <Labeled label={labels['testId']} sx={commonStyles.flexBoxItem}>
              <TestField textSource="test.testFormNo" />
            </Labeled>
            <Labeled label={labels['sequence']} sx={commonStyles.flexBoxItem}>
              <TextField source="sequence" />
            </Labeled>
          </Box>
          <Box>
            <Labeled label={labels['locationId']} sx={commonStyles.flexBoxItem}>
              <LocationField />
            </Labeled>
            <Labeled label="Responsible" sx={commonStyles.flexBoxItem}>
              <AssignedField />
            </Labeled>
          </Box>
          <Box>
            <Labeled label={labels['frequency']} sx={commonStyles.flexBoxItem}>
              <TextField source="frequency" />
            </Labeled>
            <Labeled
              label={labels['estimatedManHours']}
              sx={commonStyles.flexBoxItem}
            >
              <NumberField source="estimatedManHours" />
            </Labeled>
            <Labeled label="Mobile">
              <CustomBooleanField
                source="isMobile"
                sx={commonStyles.flexBoxItem}
              />
            </Labeled>
          </Box>
          <Box>
            <Labeled label={labels['startDate']} sx={commonStyles.flexBoxItem}>
              <DateField source="startDate" />
            </Labeled>
            <Labeled label={labels['endDate']} sx={commonStyles.flexBoxItem}>
              <DateField source="endDate" />
            </Labeled>
          </Box>
          <Box>
            <Labeled label={labels['onSiteDate']} sx={commonStyles.flexBoxItem}>
              <DateField source="onSiteDate" />
            </Labeled>
          </Box>
          <Box>
            <Labeled label={labels['plusDate']} sx={commonStyles.flexBoxItem}>
              <TextField source="plusDate" />
            </Labeled>
          </Box>
          <Box>
            <Labeled
              label={labels['storagePreservation']}
              sx={commonStyles.flexBoxItem}
            >
              <TextField source="storagePreservation" />
            </Labeled>
          </Box>
          <Box>{renderShowMetaDataFields()}</Box>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="series" path="series">
          <PreservationSeriesListTab />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="comments" path="comments">
          <CommentList
            resource={RESOURCE_COMMENT}
            targetId="preservation_item_id"
            commentType={commentType.preservation_item}
          />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="History">
          <AuditListTab tableName="tbl_preservation_item" />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </ShowComponent>
  );
};

export default PreservationItemShow;
