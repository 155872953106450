import React, { useCallback, Fragment, ReactElement } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { TabbedShowLayout } from 'react-admin';
import AttachmentRevisionCreateInDialog from '../../attachmentRevision/AttachmentRevisionCreateInDialog';
import AttachmentRevisionList from '../../attachmentRevision/AttachmentRevisionList';
import { RESOURCE_ATTACHMENT_REVISION } from '../../constants';
import commonStyles from '../../common/commonStyles';

interface AttachmentRevisionProps {
  onlyShow?: boolean;
}

const AttachmentRevision: React.FC<AttachmentRevisionProps> = ({
  onlyShow,
}): ReactElement => {
  const queryClient = useQueryClient();

  const onUpload = useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: [RESOURCE_ATTACHMENT_REVISION],
    });
  }, [queryClient]);

  return (
    <TabbedShowLayout syncWithLocation={false}>
      <TabbedShowLayout.Tab
        label="Revisions"
        id="revisions_tab"
        sx={commonStyles.formTabHeader}
        syncWithLocation={false}
      >
        <Fragment>
          <AttachmentRevisionList onUpload={onUpload} onlyShow={onlyShow} />
          {!onlyShow && (
            <AttachmentRevisionCreateInDialog onUpload={onUpload} />
          )}
        </Fragment>
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  );
};

export default AttachmentRevision;
