import { useEffect, useMemo, useRef, useState } from 'react';
import { useGetList, useGetOne, useNotify } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import { getContext, UserContext } from '../../provider/userContext';
import { RESOURCE_COMPONENT, RESOURCE_PUNCH_PRIORITY } from '../constants';

const useAutoSetHighPunchPriorityForRegulatoryItemComponent = () => {
  const ctx: UserContext = getContext();
  const { setValue } = useFormContext();
  const notify = useNotify();
  const componentId = useWatch({ name: 'componentId' });
  const priorityId = useWatch({ name: 'priorityId' });
  const priorityIdRef = useRef(priorityId);
  const [initialComponentId] = useState(componentId);
  const { data: component } = useGetOne(RESOURCE_COMPONENT, {
    id: componentId,
  });
  const { data: punchPriorities } = useGetList(RESOURCE_PUNCH_PRIORITY, {
    filter: {
      projectId: ctx.projectId,
    },
  });

  const highestPunchPriority = useMemo(() => {
    return punchPriorities?.find(
      (punchPriority) =>
        punchPriority.priority === '1' || punchPriority.priority === 'A'
    );
  }, [punchPriorities]);

  useEffect(() => {
    priorityIdRef.current = priorityId;
  }, [priorityId]);

  useEffect(() => {
    if (
      component?.isRegulatoryItem &&
      componentId !== initialComponentId &&
      highestPunchPriority &&
      priorityIdRef.current !== highestPunchPriority.id
    ) {
      setValue('priorityId', highestPunchPriority.id, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
      notify(
        'Your Punch item Priority was increased automatically to the highest level since the selected component is a regulatory item. You can edit `Punch Priority` field if necessary',
        {
          type: 'warning',
          autoHideDuration: 10000,
        }
      );
    }
  }, [
    componentId,
    component,
    highestPunchPriority,
    initialComponentId,
    setValue,
    notify,
  ]);
};

export default useAutoSetHighPunchPriorityForRegulatoryItemComponent;
