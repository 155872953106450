import React, { ReactElement } from 'react';
import {
  DateField,
  ListProps,
  NumberField,
  TextField,
  useStore,
} from 'react-admin';
import { useDictionaryLabelsWithResource } from '../../utils/UtilityFunctions';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import { getSideFiltersKey } from '../common/helpers/storeKeys';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import AssignedField from '../common/Assigned/AssignedField';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import CustomBooleanField from '../common/CustomBooleanField';
import ComponentField from '../common/ComponentField';
import ColumnActions from '../common/ColumnActions';
import ListWithTitle from '../common/ListWithTitle';
import LocationField from '../common/LocationField';
import ListActions from '../common/ListActions';
import TestField from '../common/TestField';
import { RESOURCE_PRESERVATION_ITEM } from '../constants';
import BulkActionButtons from './components/BulkActionButtons';
import PreservationItemSidebarFilters from './PreservationItemSidebarFilters';
import PreservationItemEdit from './PreservationItemEdit';
import PreservationItemShow from './PreservationItemShow';

const PreservationItemList: React.FC<ListProps> = (): ReactElement => {
  const { labels, preferenceKey } = useDictionaryLabelsWithResource();
  const sideFilter = getSideFiltersKey(RESOURCE_PRESERVATION_ITEM);
  const [showSideFilter] = useStore(sideFilter, false);
  const defaultFilter = useDefaultFilter('project');

  return (
    <ListWithTitle
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={
        <ListActions
          sideFilter={sideFilter}
          preferenceKey={preferenceKey}
          defaultFilter={defaultFilter}
        />
      }
      sort={{ field: 'id', order: 'ASC' }}
      aside={
        showSideFilter ? (
          <PreservationItemSidebarFilters defaultFilter={defaultFilter} />
        ) : null
      }
    >
      <DatagridConfigurableRbac
        ShowComponent={<PreservationItemShow withoutActions />}
        EditComponent={<PreservationItemEdit syncWithLocation={false} />}
        bulkActionButtons={<BulkActionButtons />}
        defaultVisibleColumns={[
          'id',
          'componentId',
          'testId',
          'locationId',
          'frequency',
          'sequence',
          'startDate',
          'endDate',
          'storagePreservation',
        ]}
        preferenceKey={preferenceKey}
      >
        <TextField source="id" label="ID" />
        <ComponentField label={labels['componentId']} />
        <TestField label={labels['testId']} textSource="test.testFormNo" />
        <LocationField label={labels['locationId']} />
        <AssignedField
          label={labels['assigned'] || 'Responsible'}
          sortable={false}
        />
        <CustomBooleanField
          source="isMobile"
          label={labels['isMobile'] || 'Mobile'}
        />
        <TextField
          source="frequency"
          label={labels['frequency'] || 'Frequency'}
        />
        <NumberField
          source="estimatedManHours"
          label={labels['estimatedManHours'] || 'Estimated Man Hours'}
        />
        <TextField source="sequence" label={labels['sequence'] || 'Sequence'} />
        <DateField
          source="startDate"
          label={labels['startDate'] || 'Start Date'}
        />
        <DateField source="endDate" label={labels['endDate'] || 'End Date'} />
        <TextField
          source="storagePreservation"
          label={labels['storagePreservation'] || 'Storage Preservation'}
        />
        <DateField
          source="onSiteDate"
          label={labels['onSiteDate'] || 'On Site Date'}
        />
        <TextField
          source="plusDate"
          label={labels['plusDate'] || 'Plus Date'}
        />
        {renderMetaDataFields()}
        <ColumnActions label="Actions" />
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default PreservationItemList;
