import React, { useCallback, useState, Fragment, ReactElement } from 'react';
import { useRecordContext } from 'react-admin';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as ViewFormIcon } from '../../../assets/icons/functionalIcons/ViewForm.svg';
import EmptyIconButtonPlaceholder from '../EmptyIconButtonPlaceholder';
import JSONForm from './JSONForm';

type JSONFormShowButtonType = {
  title?: string;
  showEmptyIcon?: boolean;
};

export const JSONFormShowButton: React.FC<JSONFormShowButtonType> = ({
  title = 'Revision',
  showEmptyIcon = true,
}): ReactElement => {
  const record = useRecordContext();
  const [showDialog, setShowDialog] = useState(false);
  const openDialog = useCallback(() => setShowDialog(true), []);
  const closeDialog = useCallback(() => setShowDialog(false), []);

  if (record?.details?.formSchema && record?.details?.formUiSchema) {
    return (
      <Fragment>
        <IconButton
          size="small"
          color="primary"
          title="Preview form"
          onClick={openDialog}
        >
          <ViewFormIcon />
        </IconButton>
        <Dialog
          open={showDialog}
          onClose={closeDialog}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle>
            {title} #{record?.id}
          </DialogTitle>
          <IconButton
            onClick={closeDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <JSONForm
              formSchema={record.details.formSchema}
              formUiSchema={record.details.formUiSchema}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>Close</Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }

  return showEmptyIcon ? <EmptyIconButtonPlaceholder /> : null;
};

export default JSONFormShowButton;
