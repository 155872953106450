import React, { useMemo, ReactElement } from 'react';
import { useInput, DateInputProps, Validator } from 'react-admin';
import { SxProps, Theme } from '@mui/material/styles';
import moment from 'moment/moment';
import dayjs, { Dayjs } from 'dayjs';
import { getLocaleDateFormat, required } from '../../utils/UtilityFunctions';
import { validateDate } from './helpers/dateFilter';
import CustomDatePicker from './CustomDatePicker';
import { mergeValidate } from './validators';

type DateValue = Dayjs | null;

type CustomDateInputProps = DateInputProps & {
  source: string;
  label: string;
  maxDate?: DateValue;
  minDate?: DateValue;
  sx?: SxProps<Theme>;
  validate?: Validator | Validator[];
  readOnly?: boolean;
  disabled?: boolean;
};

const CustomDateInput: React.FC<CustomDateInputProps> = ({
  source,
  label,
  sx,
  maxDate,
  minDate,
  validate: validateFromProps,
  defaultValue,
  readOnly,
  disabled,
  isRequired,
  ...rest
}): ReactElement => {
  const localeDateFormat = useMemo(getLocaleDateFormat, []);
  let validate = validateFromProps;
  validate = validate ?? [];
  validate = Array.isArray(validate) ? validate : [validate];
  if (isRequired) {
    validate.push(required());
  }

  const {
    field,
    fieldState: { isTouched, invalid, error },
    formState: { isSubmitted },
  } = useInput({
    source,
    isRequired,
    parse: (value) => {
      return validateDate(value)
        ? moment(value.format(localeDateFormat), localeDateFormat).format(
            'YYYY-MM-DD'
          )
        : null;
    },
    validate: mergeValidate(validate, maxDate, minDate),
    defaultValue,
  });

  return (
    <CustomDatePicker
      {...rest}
      isRequired={isRequired}
      label={label}
      value={field.value || null}
      onChange={field.onChange}
      sx={sx}
      error={(isTouched || isSubmitted) && invalid}
      helperText={(isTouched || isSubmitted) && invalid ? error?.message : ''}
      minDate={minDate}
      maxDate={maxDate}
      disabled={readOnly || disabled}
    />
  );
};

CustomDateInput.defaultProps = {
  // Max Date limit for Date input component for today() + 10Years
  maxDate: dayjs().add(10, 'y'),
  minDate: dayjs('1900-01-01'),
};

export default CustomDateInput;
