const styles = {
  flexBoxItem: {
    width: '245px',
    marginRight: '10px',
    marginTop: '8px',
  },
  flexBoxLongItem: {
    width: '515px',
    marginRight: '10px',
    marginTop: '8px',
  },
};

export default styles;
