import React, { ReactElement, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import PriceInput, { PriceInputProps } from '../../common/PriceInput';
import { MaterialType } from '../../materialPage/types';

export interface POMaterialsPriceInputProps extends Partial<PriceInputProps> {
  material?: MaterialType;
}

const POMaterialsPriceInput: React.FC<POMaterialsPriceInputProps> = ({
  material,
  ...rest
}): ReactElement => {
  const { setValue } = useFormContext();

  useEffect(() => {
    setValue('price', material?.price || 0);
  }, [material, setValue]);

  return <PriceInput {...rest} source="price" isRequired />;
};

export default POMaterialsPriceInput;
