import React, { ReactElement, useMemo } from 'react';
import { AutocompleteInput } from 'react-admin';
import { UserContext, getContext } from '../../provider/userContext';
import { required } from '../../utils/UtilityFunctions';
import { RESOURCE_ATTACHMENT_TYPE } from '../constants';
import AutocompleteReferenceInput from './AutocompleteReferenceInput';

interface AttachmentTypeInputProps
  extends React.ComponentProps<typeof AutocompleteInput> {
  isRequired?: boolean;
  alwaysOn?: boolean;
  multiple?: boolean;
  fullWidth?: boolean;
  sx?: object;
  source?: string;
}

const AttachmentTypeInput: React.FC<AttachmentTypeInputProps> = ({
  isRequired,
  ...rest
}): ReactElement => {
  const ctx: UserContext = getContext();
  const requestParams = useMemo(() => {
    return {
      filter: {
        projectId: ctx?.projectId,
      },
      sort: { field: 'subtype', order: 'ASC' as const },
    };
  }, [ctx?.projectId]);

  return (
    <AutocompleteReferenceInput
      {...rest}
      resource={RESOURCE_ATTACHMENT_TYPE}
      requestParams={requestParams}
      label="Book Section"
      name="subtype_inc"
      optionText="subtype"
      validate={isRequired ? [required()] : []}
      includeFields={['id', 'subtype']}
    />
  );
};

AttachmentTypeInput.defaultProps = { source: 'subtypeId' };

export default AttachmentTypeInput;
