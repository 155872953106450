import React, { ReactElement, useCallback } from 'react';
import {
  TabbedForm,
  useGetOne,
  useNotify,
  useRedirect,
  useUpdate,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import HistoryTab from '../common/HistoryTab';
import { EditPropsWithLocation } from '../common/types';
import FooterToolbar from '../common/FooterToolbar';
import { getErrorMessage } from '../../utils/UtilityFunctions';
import { RESOURCE_CONFIGURATION } from '../constants';
import useUpdateUpload from '../common/hooks/useUpdateUpload';
import useInvalidateResourceQueryCache from '../common/hooks/useInvalidateResourceQueryCache';
import useResourceTitle from '../common/hooks/useResourceTitle';
import { setPageTitle } from '../common/helpers/setPageTitle';
import { FormDataWithFiles } from '../attachment/types';
import ConfigurationEditForm from './components/ConfigurationEditForm';
import { CONFIGURATION_TYPE } from './constants';
import { Configuration } from './types';

const ConfigurationEdit: React.FC<EditPropsWithLocation<Configuration>> = (
  props
): ReactElement => {
  const invalidateQueries = useInvalidateResourceQueryCache(
    RESOURCE_CONFIGURATION
  );
  const { id } = useParams();
  const redirect = useRedirect();
  const notify = useNotify();
  const [update] = useUpdate();
  const { data: record } = useGetOne(RESOURCE_CONFIGURATION, {
    id: props?.id ?? id,
  });
  const resourceTitle = useResourceTitle();
  setPageTitle(record ? `${resourceTitle} #${record.id}` : resourceTitle);

  const { upload } = useUpdateUpload({
    onSuccess: async () => {
      redirect('list', RESOURCE_CONFIGURATION);
      await invalidateQueries();
    },
  });

  const updateRecord = useCallback(
    async (id: number, data) => {
      const newData = {
        cfgValue: data.cfgValue,
      };

      await update(
        RESOURCE_CONFIGURATION,
        { id, data: newData },
        {
          onSuccess: () => redirect('list', RESOURCE_CONFIGURATION),
          onError: (error) => {
            notify(getErrorMessage(error), {
              type: 'error',
              undoable: false,
            });
          },
        }
      );
    },
    [notify, redirect, update]
  );

  const updateWithFile = useCallback(
    async ({ file, ...data }) => {
      try {
        const newData: FormDataWithFiles = {
          ...data,
        };
        if (file) {
          newData.files = [file.rawFile];
        }
        await upload(RESOURCE_CONFIGURATION, newData);
      } catch (error) {
        notify(getErrorMessage(error), {
          type: 'error',
          undoable: false,
        });
      }
    },
    [notify, upload]
  );

  const handleSubmit = useCallback(
    async (data) => {
      if (data.cfgValueType === CONFIGURATION_TYPE.FILE) {
        await updateWithFile(data);
      } else {
        await updateRecord(data.id, data);
      }
    },
    [updateWithFile, updateRecord]
  );

  return (
    <TabbedForm
      id="edit_configuration_form"
      toolbar={<FooterToolbar />}
      onSubmit={handleSubmit}
      syncWithLocation={false}
      record={record}
    >
      <TabbedForm.Tab label="Configuration">
        <ConfigurationEditForm />
      </TabbedForm.Tab>
      <HistoryTab tableName={'tbl_configuration'} />
    </TabbedForm>
  );
};

export default ConfigurationEdit;
