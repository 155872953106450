import { Box } from '@mui/material';
import dayjs from 'dayjs';
import React, { ReactElement } from 'react';
import commonStyles from '../../../../common/commonStyles';
import CompletedByInput from '../../../../componentTest/components/CompletedByInput';
import CompletedDateTimeInput from '../../../../componentTest/components/CompletedDateTimeInput';
import VerifiedInput from '../../../../componentTest/components/VerifiedInput';

const ItrBulkEditForm: React.FC = (): ReactElement => (
  <Box sx={commonStyles.flexBox}>
    <CompletedByInput sx={commonStyles.rowFormInput} />
    <CompletedDateTimeInput
      sx={commonStyles.rowFormInput}
      defaultDate={dayjs()}
    />
    <VerifiedInput sx={commonStyles.rowFormShortInput} />
  </Box>
);

export default ItrBulkEditForm;
