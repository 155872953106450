import React, { ReactElement } from 'react';
import AttachmentFormTab from '../../common/AttachmentFormTab';
import useCanAccess from '../../common/hooks/useCanAccess';
import { EditPropsWithLocation } from '../../common/types';
import TabbedEditForm from '../../common/TabbedEditForm';
import MetaDataInputs from '../../common/MetaDataInputs';
import commonStyles from '../../common/commonStyles';
import LazyFormTab from '../../common/LazyFormTab';
import { RESOURCE_MATERIAL, RESOURCE_STORAGE } from '../../constants';
import HistoryTab from '../../common/HistoryTab';
import VendorTab from './components/vendorTab/VendorTab';
import StorageTab from './components/storageTab/StorageTab';
import ComponentTab from './components/componentTab/ComponentTab';
import PurchaseOrderTabList from './components/PurchaseOrderTabList';
import MaterialForm from './MaterialForm';
import { MaterialType } from './types';

const MaterialEdit: React.FC<EditPropsWithLocation<MaterialType>> = (
  props
): ReactElement => {
  const { edit: canEditStorage } = useCanAccess(RESOURCE_STORAGE);

  return (
    <TabbedEditForm {...props} autoSave={false}>
      <LazyFormTab
        label="Material"
        id="material_tab"
        sx={commonStyles.formTabHeader}
      >
        <MaterialForm />
        <MetaDataInputs />
      </LazyFormTab>
      <LazyFormTab
        label="Storage"
        id="storage_tab"
        sx={commonStyles.formTabHeader}
        path="tab-storage"
      >
        <StorageTab readonly={!canEditStorage} />
      </LazyFormTab>
      <LazyFormTab
        label="PO"
        id="po_tab"
        sx={commonStyles.formTabHeader}
        path="tab-po"
      >
        <PurchaseOrderTabList />
      </LazyFormTab>
      <LazyFormTab
        label="Attachment"
        id="attachments_tab"
        sx={commonStyles.formTabHeader}
        path="tab-attachment"
      >
        <AttachmentFormTab
          targetId="materialId"
          resourceName={RESOURCE_MATERIAL}
        />
      </LazyFormTab>
      <LazyFormTab
        label="Tag"
        id="tag_tab"
        sx={commonStyles.formTabHeader}
        path="tab-tag"
      >
        <ComponentTab />
      </LazyFormTab>
      <LazyFormTab
        label="Vendors"
        id="vendors_tab"
        sx={commonStyles.formTabHeader}
        path="tab-vendor"
      >
        <VendorTab />
      </LazyFormTab>
      <HistoryTab tableName="tbl_material" path="tab-history" />
    </TabbedEditForm>
  );
};

export default MaterialEdit;
