import React, { ReactElement, useMemo } from 'react';
import { required } from '../../../utils/UtilityFunctions';
import { RESOURCE_CURRENCY } from '../../constants';
import AutocompleteReferenceInput from '../../common/AutocompleteReferenceInput';
import commonStyles from '../../common/commonStyles';

interface CurrencyInputProps {
  label?: string;
  source?: string;
  isRequired?: boolean;
}

const CurrencyInput: React.FC<CurrencyInputProps> = ({
  isRequired,
  label,
  ...rest
}): ReactElement => {
  const requestParams = useMemo(() => {
    return {
      sort: { field: 'code', order: 'ASC' as const },
    };
  }, []);

  return (
    <AutocompleteReferenceInput
      {...rest}
      perPage={200}
      resource={RESOURCE_CURRENCY}
      requestParams={requestParams}
      label={label}
      name="code_inc"
      optionText="code"
      validate={isRequired ? required() : undefined}
      sx={commonStyles.flexBoxItem}
    />
  );
};

CurrencyInput.defaultProps = { source: 'currencyId', label: 'Currency' };

export default CurrencyInput;
