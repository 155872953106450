import React, { ReactElement } from 'react';
import { ReferenceManyField, TextField, useRecordContext } from 'react-admin';
import { EditableDatagrid } from '@react-admin/ra-editable-datagrid';
import DeleteWithConfirmIconButton from '../DeleteWithConfirmIconButton';
import ContactField from '../ContactField';
import commonStyles from '../commonStyles';
import ContactRowForm from './ContactRowForm';

type ContactFormTabProps = {
  target: string;
  reference: string;
  field: string;
};

const ContactFormTab: React.FC<ContactFormTabProps> = ({
  target,
  reference,
  field,
}): ReactElement => {
  const record = useRecordContext();

  return (
    <ReferenceManyField target={target} reference={reference}>
      <EditableDatagrid
        disableAutofocus
        bulkActionButtons={false}
        mutationMode="pessimistic"
        createForm={<ContactRowForm recordId={record.id} field={field} />}
        editForm={<ContactRowForm recordId={record.id} field={field} />}
        sx={commonStyles.formTabDatagrid}
        actions={<DeleteWithConfirmIconButton />}
      >
        <ContactField />
        <TextField
          label="First name"
          source="contact.firstName"
          sortBy="contact.first_name"
        />
        <TextField
          label="Last name"
          source="contact.lastName"
          sortBy="contact.last_name"
        />
        <TextField label="Phone" source="contact.phone" />
      </EditableDatagrid>
    </ReferenceManyField>
  );
};

export default ContactFormTab;
