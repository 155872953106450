import React, { useCallback, useEffect, useState } from 'react';
import { TextInputProps, useInput, useListContext } from 'react-admin';
import { Box, TextField, IconButton, InputAdornment } from '@mui/material';
import { ReactComponent as SearchIcon } from '../../assets/icons/functionalIcons/Search.svg';
import { ReactComponent as CancelIcon } from '../../assets/icons/functionalIcons/CancelRound.svg';
import { isEmpty } from '../../utils/UtilityFunctions';
import { RESOURCE_USER } from '../constants';
import AdditionalSearchFilterButton from './additionalSearchFilter/AdditionalSearchFilterButton';
import { useAdditionalSearchFilter } from './additionalSearchFilter/useAdditionalSearchFilter';
import useDebounce from './hooks/useDebounce';

const SearchInput = (prop) => {
  const { source, label, handle, resource } = prop;
  const notUserResource = resource !== RESOURCE_USER;
  const { field } = useInput({ source });
  const [value, setValue] = useState(field.value || '');
  const filterAnchorsRegexp = /^\^|\$$/gi;
  const { filterValues } = useListContext();
  const debounce = useDebounce(1500);
  const {
    menuOpen,
    isInputFocused,
    filterType,
    addFilterAnchor,
    handleChangeFilterType,
    handleInputFocus,
    handleInputBlur,
    handleMenuOpen,
    handleMenuClose,
    menuRef,
  } = useAdditionalSearchFilter();

  useEffect(() => {
    if (isEmpty(filterValues['q'])) {
      setValue('');
    }
  }, [filterValues]);

  const clearHandler = useCallback(async () => {
    field.onChange('');
    setValue('');
  }, [field]);

  const handleSearch = useCallback(async () => {
    field.onChange(
      notUserResource ? addFilterAnchor(value.trim()) : value.trim()
    );
  }, [addFilterAnchor, field, notUserResource, value]);

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterType]);

  const handleChange = useCallback(
    (ev) => {
      ev.persist();
      setValue(ev.target.value);
      debounce(() => {
        if (!ev.target.value) {
          field.onChange('');
        }
      });
    },
    [debounce, field]
  );

  const handleEnterClick = useCallback(
    (ev) => {
      ev.persist();
      if (ev.key === 'Enter') {
        handleSearch();
      } else {
        return null;
      }
    },
    [handleSearch]
  );

  return (
    <span style={{ marginBottom: 5 }}>
      <div>
        <TextField
          variant="outlined"
          label={label}
          size="small"
          className="freeTextSearchInput"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {notUserResource && (
                  <AdditionalSearchFilterButton
                    handleMenuOpen={handleMenuOpen}
                    ref={menuRef}
                    index="search"
                    isInputFocused={isInputFocused}
                    menuOpen={menuOpen}
                    handleMenuClose={handleMenuClose}
                    filterType={filterType}
                    handleChangeFilterType={handleChangeFilterType}
                  />
                )}
                <IconButton onClick={clearHandler}>
                  <CancelIcon />
                </IconButton>
                <IconButton onClick={handleSearch}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={value.replace(filterAnchorsRegexp, '')}
          onChange={handleChange}
          onClick={handle}
          onKeyPress={handleEnterClick}
          {...(notUserResource && {
            onMouseDown: (event) => {
              if (
                menuRef.current &&
                menuRef.current.contains(event.target as HTMLElement)
              ) {
                event.preventDefault();
              }
            },
            onFocus: handleInputFocus,
            onBlur: handleInputBlur,
          })}
        />
      </div>
    </span>
  );
};

const FreeTextSearchInput: React.FC<TextInputProps> = (props) => {
  return (
    <Box minWidth={'180px'} display={'flex'} alignItems={'center'}>
      <SearchInput {...props} />
    </Box>
  );
};

export default FreeTextSearchInput;
