import React, { ReactElement } from 'react';
import { DateField, TextField } from 'react-admin';
import ColumnActions from '../common/ColumnActions';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import ListActions from '../common/ListActions';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import CustomBooleanField from '../common/CustomBooleanField';
import ListWithTitle from '../common/ListWithTitle';
import DivisionShow from './DivisionShow';
import DivisionEdit from './DivisionEdit';

const DivisionList: React.FC = (): ReactElement => (
  <ListWithTitle
    filters={<FreeTextSearchFilter />}
    actions={<ListActions />}
    sort={{ field: 'divisionNumber', order: 'ASC' }}
  >
    <DatagridConfigurableRbac
      ShowComponent={<DivisionShow withoutActions />}
      EditComponent={<DivisionEdit syncWithLocation={false} />}
      defaultVisibleColumns={[
        'id',
        'divisionNumber',
        'divisionName',
        'startDate',
        'endDate',
        'isActive',
      ]}
    >
      <TextField source="id" label="ID" />
      <TextField source="divisionNumber" label="Division Number" />
      <TextField source="divisionName" label="Division Name" />
      <DateField source="startDate" label="Start Date" />
      <DateField source="stopDate" label="Stop Date" />
      <CustomBooleanField source="isActive" label="Active" />
      {renderMetaDataFields()}
      <ColumnActions label="Actions" />
    </DatagridConfigurableRbac>
  </ListWithTitle>
);

export default DivisionList;
