import React, { ReactElement } from 'react';
import { useRedirect } from 'react-admin';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { getAuthProvider } from '../provider/authProvider/authProvider';

const EmptyLogin: React.FC = (): ReactElement => {
  const redirect = useRedirect();

  getAuthProvider()
    .checkAuth(undefined)
    .then(() => redirect('/'))
    // eslint-disable-next-line no-console
    .catch((e) => console.log(e))
    .then();

  return (
    <Box sx={{ width: '100%', marginTop: '150px' }}>
      <Typography component="h4" color="textSecondary" align="center">
        Redirecting to the login page...
      </Typography>
    </Box>
  );
};

export default EmptyLogin;
