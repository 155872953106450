import React, { ReactElement, useCallback, useMemo } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { TextInput, useInput, Validator } from 'react-admin';
import { SxProps, Theme } from '@mui/material/styles';
import useResourceChoices from '../common/hooks/useResourceChoices';
import { GroupHeader, GroupItems } from '../common/Assigned/styles';
import { customRouteResources, fieldChoices } from './constants';
import { compareResources } from './helpers';

interface ResourceInputProps {
  validate?: Validator[];
  sx: SxProps<Theme>;
  source?: string;
  label?: string;
  alwaysOn?: boolean;
}

const ResourceInput: React.FC<ResourceInputProps> = ({
  validate,
  sx,
  source,
  label,
}): ReactElement => {
  const resourceChoices = useResourceChoices();
  const input = useInput({ source });
  const allResourcesChoices = [...resourceChoices, ...customRouteResources];
  const groupedResourceChoices = allResourcesChoices
    .map((resourceChoice) => ({
      group: 'Resources',
      ...resourceChoice,
    }))
    .sort(compareResources);
  const groupedFieldChoices = fieldChoices
    .map((fieldChoice) => ({
      group: 'Fields',
      ...fieldChoice,
    }))
    .sort((a, b) => a.name.localeCompare(b.name));

  const groupedChoices = [...groupedResourceChoices, ...groupedFieldChoices];

  const handleChange = useCallback(
    (event) => {
      const { textContent } = event.target as HTMLInputElement;
      const value =
        groupedChoices.find((item) => item.name === textContent)?.id ??
        textContent;
      input.field.onChange(value || '');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [input.field]
  );

  const inputText = useMemo(() => {
    const value =
      groupedChoices.find((item) => item.id === input.field.value)?.name ??
      input.field.value;
    return value || '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input.field.value]);

  return (
    <Autocomplete
      id={label}
      freeSolo
      options={groupedChoices}
      onChange={handleChange}
      value={inputText}
      sx={sx}
      groupBy={(option) => option.group}
      getOptionLabel={(option) => {
        return option?.name || option || '';
      }}
      renderGroup={(params) => {
        return (
          <li key={params.key}>
            <GroupHeader>{params.group}</GroupHeader>
            <GroupItems>{params.children}</GroupItems>
          </li>
        );
      }}
      renderInput={(params) => (
        <TextInput
          {...params}
          source={source}
          label={label}
          validate={validate}
        />
      )}
    />
  );
};

ResourceInput.defaultProps = {
  source: 'resourceName',
  label: 'Resource/Field',
};

export default ResourceInput;
