import React, { Fragment, ReactElement, useEffect } from 'react';
import { useRedirect } from 'react-admin';
import { lucidDiagramAuthProvider as authProvider } from '../../../provider/authProvider/providers/lucidDiagramAuthProvider';

const LucidAuthCallback: React.FC = (): ReactElement => {
  const redirect = useRedirect();

  useEffect(() => {
    authProvider
      .provideAuthTokenAndGetRedirect()
      .then((originator) => redirect(originator));
  }, [redirect]);
  return <Fragment />;
};

export default LucidAuthCallback;
