import { Button, useDataProvider, useNotify } from 'react-admin';
import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { getContext, UserContext } from '../../provider/userContext';
import { RESOURCE_USER } from '../constants';
import { getErrorMessage } from '../../utils/UtilityFunctions';
import useInvalidateUserEffectivePermission from './hooks/useInvalidateUserEffectivePermission';
import commonStyles from './commonStyles';

interface UpdatePermissionProps {
  userId?: string;
}

const UpdateEffectivePermissionButton: React.FC<UpdatePermissionProps> = ({
  userId,
}) => {
  const dataProvider = useDataProvider();
  const ctx: UserContext = getContext();
  const notify = useNotify();

  const { mutate, isPending } = useMutation({
    mutationFn: () =>
      dataProvider.updateUserEffectivePermission(RESOURCE_USER, [userId]),
    onSuccess: async () => {
      await invalidateUserEffectivePermission();
    },
    onError: (error) => {
      notify(getErrorMessage(error, 'Update Permissions failed!'), {
        type: 'error',
        undoable: false,
        multiLine: true,
      });
    },
  });
  const invalidateUserEffectivePermission =
    useInvalidateUserEffectivePermission(ctx?.projectId, userId);

  return (
    <Button
      id="update_user_permission"
      label={
        isPending ? 'Updating...' : `Update User Permission${userId ? '' : 's'}`
      }
      disabled={isPending}
      onClick={async () => {
        await mutate();
      }}
      sx={commonStyles.smResponsiveButton}
    >
      <span>
        {isPending
          ? 'Updating...'
          : `Update User Permission${userId ? '' : 's'}`}
      </span>
    </Button>
  );
};

export default UpdateEffectivePermissionButton;
