import React, { ReactElement } from 'react';
import { DateField, TextField, usePermissions, useStore } from 'react-admin';
import { canAccess } from '@react-admin/ra-rbac';
import { useDictionaryLabelsWithResource } from '../../utils/UtilityFunctions';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import { getSideFiltersKey } from '../common/helpers/storeKeys';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import EquipmentTypeField from '../common/EquipmentTypeField';
import PunchCategoryField from '../common/PunchCategoryField';
import PunchPriorityField from '../common/PunchPriorityField';
import DisciplineField from '../common/DisciplineField';
import ComponentField from '../common/ComponentField';
import SubsystemField from '../common/SubsystemField';
import ListWithTitle from '../common/ListWithTitle';
import LocationField from '../common/LocationField';
import ColumnActions from '../common/ColumnActions';
import ListActions from '../common/ListActions';
import VendorField from '../common/VendorField';
import SystemField from '../common/SystemField';
import LegendButton from '../common/LegendButton';
import { RESOURCE_PUNCH } from '../constants';
import { FieldPermissionActions } from '../common/constants';
import BulkActionButtons from './components/BulkActionButtons';
import StatusDotColor from './components/StatusDotColor';
import PunchSideFilters from './PunchSideFilters';
import PunchEdit from './PunchEdit';
import PunchShow from './PunchShow';
import { legend } from './constants';

const PunchList: React.FC = (): ReactElement => {
  const { labels, preferenceKey } = useDictionaryLabelsWithResource();
  const sideFilter = getSideFiltersKey(RESOURCE_PUNCH);
  const [showSideFilter] = useStore(sideFilter, false);
  const { permissions } = usePermissions();
  const defaultFilter = useDefaultFilter('project');
  const combinedFieldPermissionActions = [
    FieldPermissionActions.show,
    FieldPermissionActions.edit,
  ];

  return (
    <ListWithTitle
      filterDefaultValues={defaultFilter}
      filters={<FreeTextSearchFilter />}
      actions={
        <ListActions
          sideFilter={sideFilter}
          defaultFilter={defaultFilter}
          preferenceKey={preferenceKey}
          actions={<LegendButton legends={legend} />}
        />
      }
      aside={
        showSideFilter ? (
          <PunchSideFilters defaultFilter={defaultFilter} />
        ) : null
      }
      sort={{ field: 'punchNo', order: 'ASC' }}
    >
      <DatagridConfigurableRbac
        ShowComponent={<PunchShow withoutActions />}
        EditComponent={<PunchEdit syncWithLocation={false} />}
        defaultVisibleColumns={[
          'id',
          'punchNo',
          'description',
          'status',
          'componentId',
          'subsystemId',
          'priorityId',
          'raisedBy',
          'raisedDate',
          'punchCatId',
        ]}
        preferenceKey={preferenceKey}
        bulkActionButtons={<BulkActionButtons />}
        checkFieldsAccess
      >
        <TextField source="id" label="ID" />
        <TextField source="punchNo" label={labels['punchNo'] || 'Punch No'} />
        <TextField
          source="description"
          label={labels['description'] || 'Description'}
        />
        <StatusDotColor label={labels['status']} />
        <ComponentField label={labels['componentId']} />
        <SubsystemField label={labels['subsystemId']} />
        <TextField
          source="subsystem.description"
          label="SubSystem Description"
          sortBy="`subsystem.description`"
        />
        <TextField
          source="subsystem.phase"
          label="SubSystem Phase"
          sortBy="`subsystem.phase`"
        />
        <SystemField
          label="System"
          source="subsystem.systemId"
          textSource="subsystem.system.systemName"
          sortBy="`subsystem.system.systemName`"
        />
        <TextField
          source="subsystem.system.description"
          label="System Description"
          sortBy="`subsystem.system.description`"
        />
        <PunchPriorityField label={labels['priorityId']} />
        <TextField
          source="raisedBy"
          label={labels['raisedBy'] || 'Raised By'}
        />
        <DateField
          source="raisedDate"
          label={labels['raisedDate'] || 'Raised Date'}
        />
        {combinedFieldPermissionActions.some((action) =>
          canAccess({
            permissions,
            action,
            resource: `${RESOURCE_PUNCH}.clearedBy`,
          })
        ) && (
          <TextField
            source="clearedBy"
            label={labels['clearedBy'] || 'Cleared By'}
          />
        )}
        {combinedFieldPermissionActions.some((action) =>
          canAccess({
            permissions,
            action,
            resource: `${RESOURCE_PUNCH}.clearedDate`,
          })
        ) && (
          <DateField
            source="clearedDate"
            label={labels['clearedDate'] || 'Cleared Date'}
          />
        )}
        {combinedFieldPermissionActions.some((action) =>
          canAccess({
            permissions,
            action,
            resource: `${RESOURCE_PUNCH}.acceptedBy`,
          })
        ) && (
          <TextField
            source="acceptedBy"
            label={labels['acceptedBy'] || 'Accepted By'}
          />
        )}
        {combinedFieldPermissionActions.some((action) =>
          canAccess({
            permissions,
            action,
            resource: `${RESOURCE_PUNCH}.acceptedDate`,
          })
        ) && (
          <DateField
            source="acceptedDate"
            label={labels['acceptedDate'] || 'Accepted Date'}
          />
        )}
        <TextField
          source="responsible"
          label={labels['responsible'] || 'Responsible'}
        />
        <PunchCategoryField label={labels['punchCatId']} />
        <LocationField label={labels['locationId']} />
        <DateField
          source="requiredDate"
          label={labels['requiredDate'] || 'Required Date'}
        />
        <DateField
          source="estimatedClearDate"
          label={labels['estimatedClearDate'] || 'Estimated Clear Date'}
        />
        <DisciplineField label={labels['disciplineId'] || 'Discipline'} />
        <TextField source="actionee" label={labels['actionee'] || 'Actionee'} />
        <TextField source="poRmaNo" label={labels['poRmaNo'] || 'Po Rma No'} />
        <VendorField label={labels['vendorId'] || 'Responsible Vendor'} />
        <TextField
          source="workpackOverride"
          label={labels['workpackOverride'] || 'Workpack Override'}
        />
        <EquipmentTypeField
          source="component.equipTypeId"
          textSource="component.equipType.equipType"
          sortBy="`component.equipType.equipType`"
        />
        <TextField
          source="correctiveAction"
          label={labels['correctiveAction'] || 'Corrective Action'}
        />
        {labels['cfText01'] ? (
          <TextField source="cfText01" label={labels['cfText01']} />
        ) : null}
        {labels['cfText02'] ? (
          <TextField source="cfText02" label={labels['cfText02']} />
        ) : null}
        {labels['cfText03'] ? (
          <TextField source="cfText03" label={labels['cfText03']} />
        ) : null}
        {labels['cfText04'] ? (
          <TextField source="cfText04" label={labels['cfText04']} />
        ) : null}
        {labels['cfText05'] ? (
          <TextField source="cfText05" label={labels['cfText05']} />
        ) : null}
        {renderMetaDataFields()}
        <ColumnActions label="Actions" showActions={{ delete: false }} />
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default PunchList;
