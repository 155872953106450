import React, { ReactElement, useMemo } from 'react';
import { getContext, UserContext } from '../../provider/userContext';
import { RESOURCE_POSITION } from '../constants';
import commonStyles from '../common/commonStyles';
import { required } from '../../utils/UtilityFunctions';
import AutocompleteReferenceInput from './AutocompleteReferenceInput';

interface PositionInputProps {
  isRequired?: boolean;
  alwaysOn?: boolean;
  multiple?: boolean;
  source?: string;
  label?: string;
  projectId?: number;
  readOnly?: boolean;
}
const PositionInput: React.FC<PositionInputProps> = ({
  multiple,
  source,
  label,
  isRequired,
  projectId,
  readOnly,
}): ReactElement => {
  const ctx: UserContext = getContext();
  const requestParams = useMemo(() => {
    return {
      filter: {
        projectId: projectId || ctx?.projectId,
        isDeleted: false,
      },
      sort: { field: 'position', order: 'ASC' as const },
    };
  }, [ctx?.projectId, projectId]);

  return (
    <AutocompleteReferenceInput
      resource={RESOURCE_POSITION}
      requestParams={requestParams}
      label={label}
      name="position_inc"
      optionText="position"
      sx={commonStyles.flexBoxItem}
      multiple={multiple}
      validate={isRequired ? [required()] : []}
      source={source}
      includeFields={['id', 'position']}
      readOnly={readOnly}
    />
  );
};

PositionInput.defaultProps = { source: 'positionId', label: 'Position Name' };

export default PositionInput;
