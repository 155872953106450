import React, { ReactElement } from 'react';
import { maxLength, useRecordContext } from 'react-admin';
import Box from '@mui/material/Box';
import ActivityDurationUnitInput from '../../common/ActivityDurationUnitInput';
import ActivityConstraintInput from '../../common/ActivityConstraintInput';
import ActivityPhaseInput from '../../common/ActivityPhaseInput';
import ActivityTypeInput from '../../common/ActivityTypeInput';
import CertTemplateInput from '../../common/CertTemplateInput';
import CustomNumberInput from '../../common/CustomNumberInput';
import CustomDateInput from '../../common/CustomDateInput';
import DisciplineInput from '../../common/DisciplineInput';
import { activityCategory } from '../../common/constants';
import ComponentInput from '../../common/ComponentInput';
import TextInput from '../../common/TextInput';
import commonStyles from '../../common/commonStyles';

interface ComponentActivityFormProps {
  bulkEditMode?: boolean;
}

const ComponentActivityForm: React.FC<ComponentActivityFormProps> = ({
  bulkEditMode,
}): ReactElement => {
  const isRequired = !bulkEditMode;
  const record = useRecordContext();
  return (
    <Box sx={commonStyles.columnFlexBox}>
      <Box sx={commonStyles.flexBoxWithWrap}>
        <TextInput
          source="componentActivity"
          label="Activity"
          sx={commonStyles.flexBoxItem}
          validate={maxLength(75)}
          disabled={bulkEditMode}
        />
        <ComponentInput sx={commonStyles.flexBoxItem} isRequired={isRequired} />
        <TextInput
          source="sequence"
          label="Sequence"
          sx={commonStyles.flexBoxItem}
          validate={maxLength(20)}
          disabled={bulkEditMode}
        />
        {record && (
          <TextInput
            source="component.subsystem.subsystem"
            label="Subsystem"
            sx={commonStyles.flexBoxItem}
            disabled
          />
        )}
      </Box>
      <Box sx={commonStyles.flexBoxWithWrap}>
        <ActivityPhaseInput
          sx={commonStyles.flexBoxItem}
          isRequired={isRequired}
        />
        <DisciplineInput
          sx={commonStyles.flexBoxItem}
          withAllOption
          isRequired={isRequired}
        />
        <ActivityTypeInput
          sx={commonStyles.flexBoxItem}
          isRequired={isRequired}
        />
      </Box>
      <Box sx={commonStyles.flexBoxWithWrap}>
        <TextInput
          source="description"
          label="Description"
          sx={commonStyles.flexBoxLongItem}
          validate={maxLength(255)}
          multiline
        />
        <ActivityConstraintInput sx={commonStyles.flexBoxItem} />
      </Box>
      <Box sx={commonStyles.flexBoxWithWrap}>
        <CustomNumberInput
          source="duration"
          label="Duration"
          sx={commonStyles.flexBoxItem}
          isPositive
        />
        <CustomNumberInput
          source="actualDuration"
          label="Actual Duration"
          sx={commonStyles.flexBoxItem}
          isPositive
        />
        <ActivityDurationUnitInput sx={commonStyles.flexBoxItem} />
      </Box>
      <Box sx={commonStyles.flexBoxWithWrap}>
        <CustomDateInput
          source="plannedDate"
          label="Planned Date"
          sx={commonStyles.flexBoxItem}
        />
        <CustomDateInput
          source="actualDate"
          label="Actual Date"
          sx={commonStyles.flexBoxItem}
        />
        <CertTemplateInput
          category={activityCategory.component}
          sx={commonStyles.flexBoxItem}
        />
      </Box>
    </Box>
  );
};

export default ComponentActivityForm;
