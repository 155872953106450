import React, { ReactElement } from 'react';
import { ChipField, ReferenceArrayField } from 'react-admin';
import { RESOURCE_USER_GROUP } from '../constants';
import SmartSingleFieldList from './SmartSingleFieldList';

type PropsType = Omit<
  React.ComponentProps<typeof ReferenceArrayField>,
  'reference'
>;

const UserProjectUserGroupField: React.FC<PropsType> = ({
  label,
  ...rest
}): ReactElement => {
  return (
    <ReferenceArrayField
      label={label ?? ''}
      reference={RESOURCE_USER_GROUP}
      {...rest}
    >
      <SmartSingleFieldList>
        <ChipField source="userGroup" />
      </SmartSingleFieldList>
    </ReferenceArrayField>
  );
};

export default UserProjectUserGroupField;
