import React, { ReactElement } from 'react';
import CertDetailsEditTab from '../certDetails/CertDetailsEditTab';
import commonStyles from '../common/commonStyles';
import HistoryTab from '../common/HistoryTab';
import MetaDataInputs from '../common/MetaDataInputs';
import TabbedEditForm from '../common/TabbedEditForm';
import LazyFormTab from '../common/LazyFormTab';
import { EditPropsWithLocation } from '../common/types';
import { RESOURCE_SUBSYSTEM_ACTIVITY } from '../constants';
import SubSystemActivityForm from './components/SubSystemActivityForm';

const SubSystemActivityEdit: React.FC<EditPropsWithLocation> = (
  props
): ReactElement => {
  return (
    <TabbedEditForm {...props}>
      <LazyFormTab
        label="SubSystem Activity"
        id="subsystem_activity_tab"
        sx={commonStyles.formTabHeader}
      >
        <SubSystemActivityForm />
        <MetaDataInputs />
      </LazyFormTab>
      <LazyFormTab
        label="Certificates"
        id="certificates_tab"
        sx={commonStyles.formTabHeader}
      >
        <CertDetailsEditTab
          target="subsystemActivityId"
          targetReference={RESOURCE_SUBSYSTEM_ACTIVITY}
        />
      </LazyFormTab>
      <HistoryTab tableName="tbl_subsystem_activity" />
    </TabbedEditForm>
  );
};

export default SubSystemActivityEdit;
