import React, { ReactElement, useCallback } from 'react';
import {
  DatagridConfigurable,
  FunctionField,
  ReferenceManyField,
  TextField,
} from 'react-admin';
import Box from '@mui/material/Box';
import useInvalidateResourceQueryCache from '../../../../common/hooks/useInvalidateResourceQueryCache';
import DeleteWithConfirmIconButton from '../../../../common/DeleteWithConfirmIconButton';
import commonStyles from '../../../../common/commonStyles';
import StorageField from '../../../../common/StorageField';
import {
  RESOURCE_MATERIAL,
  RESOURCE_MATERIAL_STORAGE,
} from '../../../../constants';
import StorageTabEdit from './StorageTabEdit';

const renderActions =
  (onDelete) =>
  (record: Record<string, string>): ReactElement => {
    return (
      <Box sx={commonStyles.columnActionsBox}>
        <StorageTabEdit record={record} />
        <DeleteWithConfirmIconButton
          className="deleteButton"
          onDelete={onDelete}
        />
      </Box>
    );
  };

interface StorageTabListProps {
  showActions?: boolean;
}

const StorageTabList: React.FC<StorageTabListProps> = ({
  showActions = true,
}): ReactElement => {
  const invalidateQueries = useInvalidateResourceQueryCache(RESOURCE_MATERIAL);
  const clearCache = useCallback(async () => {
    await invalidateQueries();
  }, [invalidateQueries]);
  return (
    <ReferenceManyField
      reference={RESOURCE_MATERIAL_STORAGE}
      target="materialId"
      perPage={100}
      fullWidth={true}
    >
      <DatagridConfigurable bulkActionButtons={false} rowClick={false}>
        <TextField source="id" label="ID" />
        <TextField source="qtyOnHand" label="Qty On Hand" />
        <StorageField />
        <TextField
          source="storage.storageLocation"
          label="Storage Location"
          sortable={false}
        />
        {showActions && (
          <FunctionField render={renderActions(clearCache)} label="Actions" />
        )}
      </DatagridConfigurable>
    </ReferenceManyField>
  );
};

export default StorageTabList;
