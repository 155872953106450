import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import ColumnActions from '../common/ColumnActions';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import ListActions from '../common/ListActions';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import ListWithTitle from '../common/ListWithTitle';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import AttachmentTypeShow from './AttachmentTypeShow';
import AttachmentTypeEdit from './AttachmentTypeEdit';

const AttachmentTypeList: React.FC = (): ReactElement => {
  const defaultFilter = useDefaultFilter('project');

  return (
    <ListWithTitle
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={<ListActions />}
      sort={{ field: 'subtype', order: 'ASC' }}
    >
      <DatagridConfigurableRbac
        ShowComponent={<AttachmentTypeShow withoutActions />}
        EditComponent={<AttachmentTypeEdit syncWithLocation={false} />}
        defaultVisibleColumns={['id', 'subtype']}
      >
        <TextField source="id" label="ID" />
        <TextField source="subtype" label="Book Section" />
        {renderMetaDataFields()}
        <ColumnActions label="Actions" />
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default AttachmentTypeList;
