import React, { ReactElement } from 'react';
import { maxLength } from 'react-admin';
import Box from '@mui/material/Box';
import HistoryTab from '../common/HistoryTab';
import LazyFormTab from '../common/LazyFormTab';
import MetaDataInputs from '../common/MetaDataInputs';
import TabbedEditForm from '../common/TabbedEditForm';
import commonStyles from '../common/commonStyles';
import { EditPropsWithLocation } from '../common/types';
import TextInput from '../common/TextInput';
import { required } from '../../utils/UtilityFunctions';
import { PunchPriority } from './types';
import { priorityValidator } from './constants';

const PunchPriorityEdit: React.FC<EditPropsWithLocation<PunchPriority>> = (
  props
): ReactElement => (
  <TabbedEditForm {...props}>
    <LazyFormTab
      label="Punch Priority"
      id="punch_priority_tab"
      sx={commonStyles.formTabHeader}
    >
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="priority"
          label="Priority"
          validate={[required(), maxLength(50), priorityValidator]}
          sx={commonStyles.flexBoxItem}
        />
      </Box>
      <Box sx={commonStyles.createBoxHalfWidth}>
        <TextInput
          source="definition"
          label="Definition"
          validate={[maxLength(255)]}
          sx={commonStyles.flexBoxItem}
          fullWidth
          multiline
        />
      </Box>
      <Box sx={commonStyles.flexBox}></Box>
      <MetaDataInputs />
    </LazyFormTab>
    <HistoryTab tableName="tbl_punch_priority" />
  </TabbedEditForm>
);

export default PunchPriorityEdit;
