import React, { ReactElement } from 'react';
import { SelectField } from 'react-admin';
import { categoryChoices } from '../certTemplate/constants';

interface CertTemplateCategoryFieldProps {
  label?: string;
  source?: string;
  sortBy?: string;
}

const CertTemplateCategoryField: React.FC<CertTemplateCategoryFieldProps> = ({
  label,
  source,
  sortBy,
}): ReactElement => (
  <SelectField
    source={source}
    choices={categoryChoices}
    sortBy={sortBy}
    label={label}
  />
);

CertTemplateCategoryField.defaultProps = {
  label: 'Category',
  sortBy: 'category',
  source: 'category',
};

export default CertTemplateCategoryField;
