import { DateField, Labeled, TabbedShowLayout, TextField } from 'react-admin';
import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import commonStyles from '../common/commonStyles';
import renderShowMetaDataFields from '../common/helpers/renderShowMetaDataFields';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import AttachmentList from '../attachment/AttachmentList';
import AuditListTab from '../audit/AuditListTab';
import { RESOURCE_MOC } from '../constants';
import ParentMocField from './components/ParentMocField';
import MocTypeField from './components/MocTypeField';
import RelatedComponentsField from './components/relatedFields/RelatedComponentsField';
import RelatedSubSystemField from './components/relatedFields/RelatedSubsystemsField';
import RelatedSystemsField from './components/relatedFields/RelatedSystemsField';

const MocShow: React.FC<ShowComponentProps> = ({
  syncWithLocation,
  ...rest
}): ReactElement => (
  <ShowComponent {...rest}>
    <TabbedShowLayout
      sx={commonStyles.bottomMargin}
      syncWithLocation={syncWithLocation}
    >
      <TabbedShowLayout.Tab label="MoC">
        <Box>
          <Labeled>
            <TextField
              source="mocNo"
              label="MoC"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled>
            <TextField
              source="title"
              label="Title"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled>
            <TextField
              source="description"
              label="Description"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled>
            <TextField
              source="details"
              label="Details"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
        </Box>
        <Box>
          <Labeled label="MoC Type" sx={commonStyles.flexBoxItem}>
            <MocTypeField />
          </Labeled>
          <Labeled label="Parent MoC" sx={commonStyles.flexBoxItem}>
            <ParentMocField />
          </Labeled>
        </Box>
        <Box>
          <Labeled
            key="raisedBy"
            label="Raised By"
            sx={commonStyles.flexBoxItem}
          >
            <TextField source="raisedBy" />
          </Labeled>
          <Labeled
            key="raisedDate"
            label="Raised Date"
            sx={commonStyles.flexBoxItem}
          >
            <DateField source="raisedDate" />
          </Labeled>
        </Box>
        <Box>
          <Labeled
            key="asweredBy"
            label="Answered By"
            sx={commonStyles.flexBoxItem}
          >
            <TextField source="asweredBy" />
          </Labeled>
          <Labeled
            key="answeredDate"
            label="Answered Date"
            sx={commonStyles.flexBoxItem}
          >
            <DateField source="answeredDate" />
          </Labeled>
        </Box>
        <Box>
          <Labeled
            key="closedBy"
            label="Closed By"
            sx={commonStyles.flexBoxItem}
          >
            <TextField source="closedBy" />
          </Labeled>
          <Labeled
            key="closedDate"
            label="Closed Date"
            sx={commonStyles.flexBoxItem}
          >
            <DateField source="closedDate" />
          </Labeled>
        </Box>
        <Box>{renderShowMetaDataFields()}</Box>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Related" path="related">
        <Box>
          <Labeled sx={commonStyles.flexBoxItem}>
            <RelatedSystemsField />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <RelatedSubSystemField />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <RelatedComponentsField />
          </Labeled>
        </Box>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Attachment" path="attachment">
        <AttachmentList
          targetId="moc_id"
          resourceName={RESOURCE_MOC}
          onlyShow={true}
        />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="History">
        <AuditListTab tableName="tbl_moc" />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </ShowComponent>
);

export default MocShow;
