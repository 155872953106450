import React, { useCallback } from 'react';
import { Button, SaveButton, Toolbar } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import commonStyles from '../../commonStyles';

interface ImportToolbarProps {
  onClose: () => void;
  label?: string;
}

const ImportToolbar: React.FC<ImportToolbarProps> = ({ onClose, label }) => {
  const { resetField } = useFormContext();
  const resetForm = useCallback(() => {
    setTimeout(() => resetField('file'), 0); // ugly hack because mutationOptions don't work with type="submit"
  }, [resetField]);

  return (
    <Toolbar style={commonStyles.footerActionsBox}>
      <SaveButton label={label || 'Import'} onClick={resetForm} />
      <Button label="Close" onClick={onClose} type="reset" variant="text" />
    </Toolbar>
  );
};

export default ImportToolbar;
