import React, { ReactElement } from 'react';
import { maxLength } from 'react-admin';
import Box from '@mui/material/Box';
import commonStyles from '../common/commonStyles';
import HistoryTab from '../common/HistoryTab';
import LazyFormTab from '../common/LazyFormTab';
import MetaDataInputs from '../common/MetaDataInputs';
import TabbedEditForm from '../common/TabbedEditForm';
import { EditPropsWithLocation } from '../common/types';
import TextInput from '../common/TextInput';
import { required } from '../../utils/UtilityFunctions';
import { StructureAssetGroup } from './types';
import AssetInput from './AssetInput';

const StructureAssetGroupEdit: React.FC<
  EditPropsWithLocation<StructureAssetGroup>
> = (props): ReactElement => (
  <TabbedEditForm {...props}>
    <LazyFormTab
      label="Asset Group"
      id="structure_asset_group_tab"
      sx={commonStyles.formTabHeader}
    >
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="assetGroup"
          label="Asset Group"
          validate={[required(), maxLength(150)]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="description"
          label="Description"
          validate={maxLength(255)}
          sx={commonStyles.flexBoxItem}
          fullWidth
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <AssetInput isRequired />
      </Box>
      <MetaDataInputs />
    </LazyFormTab>
    <HistoryTab tableName="tbl_structure_asset_group" />
  </TabbedEditForm>
);

export default StructureAssetGroupEdit;
