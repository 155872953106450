import axios from 'axios';
import { lucidDocumentViewUrl } from '../../../components/lucidDiagram/constants';

const lucidDiagramDataProvider = {
  isDocumentAvailable: async (documentId: string) => {
    const url = lucidDocumentViewUrl(documentId);
    try {
      const { status } = await axios.head(url);
      return status === 200;
    } catch (e) {
      return false;
    }
  },
};

export default lucidDiagramDataProvider;
