import axios from 'axios';
import { downloadFile } from '../../../utils/UtilityFunctions';
import { ContentType, CRUD_API_URL, getRequestConfig } from '../../api';

export const getAttachmentRevisionDataProvider = () => ({
  importJsonForRevisionDetails: (
    resource: string,
    data: Record<string, never>
  ) => {
    return axios.post(
      `${CRUD_API_URL}/${resource}/import-revision-details`,
      data,
      getRequestConfig(ContentType.formData)
    );
  },
  updateRevisionFile: (resource: string, data: Record<string, never>) => {
    return axios.post(
      `${CRUD_API_URL}/${resource}/update-revision-file`,
      data,
      getRequestConfig()
    );
  },
  pullAlphaForm: (resource: string, data: Record<string, never>) => {
    return axios.post(
      `${CRUD_API_URL}/${resource}/pull-alpha-form`,
      data,
      getRequestConfig()
    );
  },
  pullRevisionFileFromMasterTemplate: (
    resource: string,
    data: Record<string, never>
  ) => {
    return axios.post(
      `${CRUD_API_URL}/${resource}/pull-revision-file`,
      data,
      getRequestConfig()
    );
  },
  pushRevisionFile: (resource: string, data: Record<string, never>) => {
    return axios.post(
      `${CRUD_API_URL}/${resource}/push-revision-file`,
      data,
      getRequestConfig()
    );
  },
  pullJSONFormFromMasterTemplate: (
    resource: string,
    data: Record<string, never>
  ) => {
    return axios.post(
      `${CRUD_API_URL}/${resource}/pull-json-form`,
      data,
      getRequestConfig()
    );
  },
  pushJSONForm: (resource: string, data: Record<string, never>) => {
    return axios.post(
      `${CRUD_API_URL}/${resource}/push-json-form`,
      data,
      getRequestConfig()
    );
  },
  getMasterTemplateDownloadLink: (
    resource: string,
    data: Record<string, never>
  ) => {
    return axios.post(
      `${CRUD_API_URL}/${resource}/master-template-link`,
      data,
      getRequestConfig()
    );
  },
  generateTestPdf: (resource: string, params: { id: number; type: string }) => {
    const { id, type } = params;

    return axios
      .get(
        `${CRUD_API_URL}/${resource}/${id}/generate-test-pdf${type ? '?type=' + type : ''}`,
        {
          ...getRequestConfig(),
          responseType: 'blob',
        }
      )
      .then((req) => {
        if (req['status'] === 200) {
          downloadFile(resource, req.data, req.headers['content-type'], 'pdf');
        }

        return req;
      });
  },
});
