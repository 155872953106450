import React, {
  ReactElement,
  useState,
  useCallback,
  Fragment,
  useMemo,
} from 'react';
import { useNotify, useRecordContext } from 'react-admin';
import { Box, Tabs, Tab } from '@mui/material';
import { AxiosResponse } from 'axios';
import useInvalidateResourceQueryCache from '../../common/hooks/useInvalidateResourceQueryCache';
import PendingPunchFormTab from '../../pendingPunch/components/PendingPunchFormTab';
import AttachmentFormTab from '../../common/AttachmentFormTab';
import useCanAccess from '../../common/hooks/useCanAccess';
import { ATTACHMENT_TYPE_ENUMS } from '../../common/enums';
import CustomTabPanel from '../../common/CustomTabPanel';
import { Originator } from '../../submitPunch/constants';
import { RESOURCE_SMART_TAG_ITR } from '../../constants';
import commonStyles from '../../common/commonStyles';
import PreviousAttachmentTab from './PreviousAttachmentTab';
import SmartTagITRForm from './SmartTagITRForm';
import MultiTagITRForm from './MultiTagITRForm';

interface SmartTagITREditTabsProps {
  onCancel?: () => void;
  setPendingPunchTitle: (val: string | null) => void;
  setPendingPunchCreateDialogCloseDisabled: (val: boolean) => void;
}

const SmartTagITREditTabs: React.FC<SmartTagITREditTabsProps> = (
  props
): ReactElement => {
  const {
    onCancel,
    setPendingPunchTitle,
    setPendingPunchCreateDialogCloseDisabled,
  } = props;
  const record = useRecordContext();
  const [value, setValue] = useState(0);
  const canAccessList = useCanAccess();
  const notify = useNotify();
  const invalidateQueries = useInvalidateResourceQueryCache(
    RESOURCE_SMART_TAG_ITR,
    true
  );
  const isMultiTag = useMemo(
    () =>
      record?.test?.multiTag &&
      record?.multiTagTemplateDetails?.formSchema &&
      record?.multiTagTemplateDetails?.formUiSchema,
    [record]
  );

  const clearCache = useCallback(async () => {
    await invalidateQueries();
  }, [invalidateQueries]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleUpload = (result: AxiosResponse | AxiosResponse[]) => {
    clearCache();
    if (result?.['status'] === 204) {
      notify(
        `File you uploaded is big and it will take some time to convert it to pdf. 
        Once it's converted you will be notified and the pdf file will appear in the list of attachments.`,
        { type: 'success' }
      );
    }
  };

  const tabs = useMemo(
    () =>
      [
        {
          label: 'Form',
          id: 'form_tab',
        },
        ...[
          isMultiTag && {
            label: 'Multi Tag Form',
            id: 'multi_tag_form_tab',
          },
        ],
        {
          label: 'Punch List',
          id: 'pending_punch_common_tab',
        },
        {
          label: 'Attachment',
          id: 'attachment_tab',
        },
        {
          label: 'Previous Attachment',
          id: 'previous_attachment_tab',
        },
      ].filter((item) => item),
    [isMultiTag]
  );

  return (
    <Fragment>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          {tabs.map(({ label, id }) => (
            <Tab
              label={label}
              id={id}
              key={id}
              sx={{ ...commonStyles.formTabHeader, overflowX: 'auto' }}
            />
          ))}
        </Tabs>
      </Box>

      {tabs.map(({ id }, i) => (
        <CustomTabPanel value={value} index={i} id={id} key={'panel' + id}>
          {id === 'form_tab' && (
            <SmartTagITRForm
              onCancel={onCancel}
              setPendingPunchTitle={setPendingPunchTitle}
              setPendingPunchCreateDialogCloseDisabled={
                setPendingPunchCreateDialogCloseDisabled
              }
            />
          )}
          {id === 'multi_tag_form_tab' && (
            <MultiTagITRForm
              onCancel={onCancel}
              setPendingPunchTitle={setPendingPunchTitle}
              setPendingPunchCreateDialogCloseDisabled={
                setPendingPunchCreateDialogCloseDisabled
              }
            />
          )}
          {id === 'pending_punch_common_tab' && (
            <PendingPunchFormTab
              targetId="componentTestId"
              readonly={!canAccessList.edit}
              outerResource={RESOURCE_SMART_TAG_ITR}
              originator={Originator.ITR}
            />
          )}
          {id === 'attachment_tab' && (
            <AttachmentFormTab
              targetId="componentTestId"
              attachmentType={[ATTACHMENT_TYPE_ENUMS.SMART_ITR]}
              resourceName={RESOURCE_SMART_TAG_ITR}
              simpleView
              onUpload={handleUpload}
              onDelete={clearCache}
            />
          )}
          {id === 'previous_attachment_tab' && <PreviousAttachmentTab />}
        </CustomTabPanel>
      ))}
    </Fragment>
  );
};

export default SmartTagITREditTabs;
