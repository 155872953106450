import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import ColumnActions from '../common/ColumnActions';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import ListActions from '../common/ListActions';
import ListWithTitle from '../common/ListWithTitle';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import ActivityPhaseEdit from './ActivityPhaseEdit';
import ActivityPhaseShow from './ActivityPhaseShow';

const ActivityPhaseList: React.FC = (): ReactElement => {
  const defaultFilter = useDefaultFilter('project');

  return (
    <ListWithTitle
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={<ListActions />}
      sort={{ field: 'phase', order: 'ASC' }}
    >
      <DatagridConfigurableRbac
        ShowComponent={<ActivityPhaseShow withoutActions />}
        EditComponent={<ActivityPhaseEdit syncWithLocation={false} />}
        defaultVisibleColumns={['id', 'phase', 'sortOrder']}
      >
        <TextField source="id" label="ID" />
        <TextField source="phase" label="Phase" />
        <TextField source="sortOrder" label="Sort Order" />
        {renderMetaDataFields()}
        <ColumnActions label="Actions" />
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default ActivityPhaseList;
