import React, { ReactElement } from 'react';
import { BooleanInput, Button, maxLength } from 'react-admin';
import Box from '@mui/material/Box';
import { EditPropsWithLocation } from '../common/types';
import CustomDateInput from '../common/CustomDateInput';
import TabbedEditForm from '../common/TabbedEditForm';
import commonStyles from '../common/commonStyles';
import LazyFormTab from '../common/LazyFormTab';
import TextInput from '../common/TextInput';
import { required } from '../../utils/UtilityFunctions';

const AssignITREdit: React.FC<EditPropsWithLocation> = (
  props
): ReactElement => (
  <TabbedEditForm
    {...props}
    redirect={false}
    footerToolbarProps={{ CancelButtonComponent: Button }}
  >
    <LazyFormTab
      label="SubSystem"
      id="subsystem_tab"
      sx={commonStyles.formTabHeader}
    >
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="subsystem"
          validate={[maxLength(85)]}
          name="subsystem"
          sx={commonStyles.flexBoxItem}
          disabled
        />
        <TextInput
          source="description"
          name="description"
          validate={[maxLength(150), required()]}
          sx={commonStyles.flexBoxLongItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <BooleanInput source="isAssignItr" label="Populate" />
        <CustomDateInput
          source="assignItrDateTime"
          label="Date Populated"
          sx={commonStyles.flexBoxItem}
          disabled
        />
        <TextInput
          source="itrAssigner"
          label="Populated By"
          sx={commonStyles.flexBoxItem}
          disabled
        />
      </Box>
    </LazyFormTab>
  </TabbedEditForm>
);

export default AssignITREdit;
