import { Labeled, TabbedShowLayout, TextField } from 'react-admin';
import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import commonStyles from '../common/commonStyles';
import renderShowMetaDataFields from '../common/helpers/renderShowMetaDataFields';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import AuditListTab from '../audit/AuditListTab';

const TestTypeShow: React.FC<ShowComponentProps> = ({
  syncWithLocation,
  ...rest
}): ReactElement => (
  <ShowComponent {...rest}>
    <TabbedShowLayout
      sx={commonStyles.bottomMargin}
      syncWithLocation={syncWithLocation}
    >
      <TabbedShowLayout.Tab label="Test Type">
        <Labeled>
          <TextField
            source="testType"
            label="Test Type"
            sx={commonStyles.flexBoxItem}
          />
        </Labeled>
        <Labeled>
          <TextField
            source="description"
            label="Description"
            sx={commonStyles.flexBoxItem}
          />
        </Labeled>
        <Box>{renderShowMetaDataFields()}</Box>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="History">
        <AuditListTab tableName="tbl_test_type" />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </ShowComponent>
);

export default TestTypeShow;
