import React, { ReactElement, useCallback } from 'react';
import CommentFormTab from '../common/CommentFormTab';
import HistoryTab from '../common/HistoryTab';
import LazyFormTab from '../common/LazyFormTab';
import MetaDataInputs from '../common/MetaDataInputs';
import TabbedEditForm from '../common/TabbedEditForm';
import commonStyles from '../common/commonStyles';
import { EditPropsWithLocation } from '../common/types';
import { commentType } from '../common/constants';
import AttachmentRevisionFormTab from '../attachmentRevision/AttachmentRevisionFormTab';
import { Drawing } from './types';
import DrawingForm from './DrawingForm';
import SubsystemFormTab from './components/SubsystemFormTab/SubsystemFormTab';
import ComponentFormTab from './components/ComponentFormTab/ComponentFormTab';

const DrawingEdit: React.FC<EditPropsWithLocation<Drawing>> = (
  props
): ReactElement => {
  const commentTabTransformFunc = useCallback(
    (data, recordId) => ({
      ...data,
      drawingId: recordId,
      commentType: commentType.drawing,
    }),
    []
  );

  return (
    <TabbedEditForm {...props}>
      <LazyFormTab
        label="Drawing"
        id="drawing_tab"
        sx={commonStyles.formTabHeader}
      >
        <DrawingForm />
        <MetaDataInputs />
      </LazyFormTab>
      <LazyFormTab
        label="Subsystems"
        id="subsystems_tab"
        sx={commonStyles.formTabHeader}
      >
        <SubsystemFormTab />
      </LazyFormTab>
      <LazyFormTab
        label="Components"
        id="components_tab"
        sx={commonStyles.formTabHeader}
      >
        <ComponentFormTab />
      </LazyFormTab>
      <LazyFormTab
        label="Comment"
        id="comments_tab"
        sx={commonStyles.formTabHeader}
      >
        <CommentFormTab
          targetId="drawing_id"
          commentType={commentType.drawing}
          transformFunc={commentTabTransformFunc}
        />
      </LazyFormTab>
      <LazyFormTab
        label="Revisions"
        id="revisions_tab"
        sx={commonStyles.formTabHeader}
      >
        <AttachmentRevisionFormTab targetId="drawingId" />
      </LazyFormTab>
      <HistoryTab tableName="tbl_drawing" />
    </TabbedEditForm>
  );
};

export default DrawingEdit;
