import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  List,
  Paper,
  CardContent,
  Typography,
  ListItemText,
  ListItemIcon,
  ListItem,
} from '@mui/material';
import { useGetIdentity } from 'ra-core';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import IconClose from '@mui/icons-material/Close';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { Identifier, useDataProvider, useNotify } from 'react-admin';
import { useMutation } from '@tanstack/react-query';
import CircularProgress from '../../common/CircularProgress';
import { getErrorMessage } from '../../../utils/UtilityFunctions';
import { useTimeout } from '../../common/hooks/useTimeout';
import { CUSTOM_ROUTE_BOOK_GENERATION } from '../../constants';
import { getBookJobProgressIcon, getSubsystemProgressIcon } from '../helpers';
import { styles } from '../styles';
import {
  BookGenType,
  BookQueueDialogProps,
  SubsystemBookGenType,
} from '../types';
import DownloadButton from './DownloadButton';

export const BookQueueDialog: React.FC<BookQueueDialogProps> = ({
  isOpen,
  onClose,
}): ReactElement => {
  const dataProvider = useDataProvider();
  const { identity } = useGetIdentity();
  const notify = useNotify();
  const [statusCheckingDelay, setStatusCheckingDelay] = useState(null);
  const [jobIds, setJobIds] = useState<Identifier[]>([]);
  const [books, setBooks] = useState([]);
  const { isPending, mutate: getInitialStatus } = useMutation({
    mutationFn: () =>
      dataProvider.getInitialStatus(CUSTOM_ROUTE_BOOK_GENERATION),
    onSuccess: ({ data }) => {
      setBooks(data);
      setJobIds(data.map((item) => item.id));
      setStatusCheckingDelay(3000);
    },
    onError: (error) => {
      notify(getErrorMessage(error), {
        type: 'error',
        undoable: false,
      });
    },
  });

  const { mutate: getStatus } = useMutation({
    mutationFn: (jobIds: Identifier[]) => {
      return dataProvider.getStatus(CUSTOM_ROUTE_BOOK_GENERATION, jobIds);
    },
    onSuccess: ({ data }) => {
      setBooks(data);
    },
    onError: (error) => {
      notify(getErrorMessage(error), {
        type: 'error',
        undoable: false,
      });
    },
  });

  useEffect(() => {
    isOpen && identity?.email && getInitialStatus();
  }, [identity?.email, isOpen, getInitialStatus]);

  const stopStatusChecking = useTimeout(async () => {
    if (jobIds?.length > 0) {
      await getStatus(jobIds);
    }
  }, statusCheckingDelay);

  useEffect(() => {
    if (books.length > 0) {
      const completedBookGen = books.filter(
        (item) =>
          item?.status === 'FINISHED_FAILED' ||
          item?.status === 'FINISHED_SUCCESS'
      );
      if (completedBookGen.length === books.length) {
        stopStatusChecking();
      }
    }
  }, [books, stopStatusChecking]);

  const handleClose = useCallback(() => {
    stopStatusChecking();
    setStatusCheckingDelay(null);
    onClose();
  }, [onClose, stopStatusChecking]);

  const renderSubsystem = (subsystem: SubsystemBookGenType) => {
    return (
      <Box
        key={subsystem.id}
        sx={{
          width: '100%',
        }}
      >
        <Paper elevation={0} sx={{ marginTop: '10px' }} variant={'outlined'}>
          <CardContent>
            <Box sx={styles.printQueueSubsystemList}>
              <Typography sx={{ fontSize: 16 }}>{subsystem.name}</Typography>
              <Box sx={styles.statusIcon}>
                {getSubsystemProgressIcon(subsystem.status)}
              </Box>
            </Box>

            {subsystem.statusMessage && (
              <Typography sx={{ fontSize: 14 }} gutterBottom>
                {subsystem.statusMessage}
              </Typography>
            )}

            {subsystem.errorMessages.length > 0 &&
              subsystem.errorMessages.map((error, index) => (
                <Typography
                  key={`${subsystem.id}-error-${index}`}
                  sx={styles.errorText}
                  color="text.secondary"
                >
                  {error}
                </Typography>
              ))}
          </CardContent>
          <Box sx={styles.outputFiles}>
            {subsystem.outputFiles.length > 0 &&
              subsystem.outputFiles.map((item) => (
                <DownloadButton
                  key={item.id}
                  subsystem={item?.subsystem}
                  downloadUrl={item?.downloadUrl}
                />
              ))}
          </Box>
        </Paper>
      </Box>
    );
  };

  const renderBook = (book: BookGenType) => {
    return (
      <ListItem key={book.id} sx={styles.bookListItem}>
        <Box sx={styles.bookListItemHeader}>
          <ListItemIcon>
            <LibraryBooksIcon />
          </ListItemIcon>
          <ListItemText key={0} primary={book.details.statusMessage} />
          <Box sx={styles.statusIcon}>
            {getBookJobProgressIcon(book.status)}
          </Box>
        </Box>
        {book.details.subsystems.map((subsystem) => renderSubsystem(subsystem))}
      </ListItem>
    );
  };

  return (
    <Dialog maxWidth="lg" onClose={handleClose} open={isOpen}>
      <DialogTitle></DialogTitle>
      <DialogContent>
        <Box flex={1}>
          <Box marginBottom={'20px'} minWidth={'400px'}>
            <List sx={{ width: '100%' }}>
              {isPending ? (
                <CircularProgress />
              ) : books.length === 0 ? (
                <Box>Queue is empty</Box>
              ) : (
                books.map((book) => renderBook(book))
              )}
            </List>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" startIcon={<IconClose />}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
