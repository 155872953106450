import React, { ReactElement } from 'react';
import {
  useRecordContext,
  useList,
  ListContextProvider,
  Datagrid,
  TextField,
  BooleanField,
  DateField,
  FunctionField,
  useGetList,
  Identifier,
} from 'react-admin';
import { Box, SvgIcon } from '@mui/material';
import useTemplateUpdateCanAccess from '../common/hooks/useTemplateUpdateCanAccess';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import JSONFormShowButton from '../common/JSONForm/JSONFormShowButton';
import DownloadButton from '../common/DownloadButton';
import PreviewButton from '../common/PreviewButton';
import ColumnActions from '../common/ColumnActions';
import commonStyles from '../common/commonStyles';
import { RESOURCE_ATTACHMENT_REVISION } from '../constants';
import AttachmentRevisionSetActive from './AttachmentRevisionSetActive';
import AttachmentRevisionEdit from './AttachmentRevisionEdit';
import AttachmentRevisionTest from './AttachmentRevisionTest';

type AttachmentRevisionListProps = {
  onUpload: () => void;
  onlyShow?: boolean;
  outerResource?: string;
  outerResourceId?: Identifier;
  externalFormId?: string;
};

const AttachmentRevisionList: React.FC<AttachmentRevisionListProps> = ({
  onUpload,
  onlyShow,
  outerResource,
  outerResourceId,
  externalFormId,
}): ReactElement => {
  const record = useRecordContext();
  const templateUpdateEnabled = useTemplateUpdateCanAccess(
    record?.attachment?.resourceName
  );

  const { data, isLoading } = useGetList(RESOURCE_ATTACHMENT_REVISION, {
    filter: { attachmentId: record.id },
    sort: { field: 'isActive', order: 'DESC' },
  });

  const listContext = useList({ data, isLoading });

  return (
    <ListContextProvider value={listContext}>
      <Datagrid
        bulkActionButtons={false}
        rowClick={false}
        sx={{ width: '100%' }}
      >
        <TextField source="id" />
        <FunctionField
          label="File Name"
          render={(record) => `${record.s3FileKey.split('/').at(-1)}`}
        />
        <TextField source="revision" />
        <DateField source="revisionDate" label="Revision Date" />
        <BooleanField source="isActive" label="Active" FalseIcon={SvgIcon} />
        {renderMetaDataFields()}
        <ColumnActions
          label="Actions"
          showActions={{ show: false, edit: false, delete: false }}
        >
          <Box sx={{ ...commonStyles.columnActionsBox, minWidth: 230 }}>
            <JSONFormShowButton />
            <PreviewButton />
            {!onlyShow && (
              <AttachmentRevisionEdit
                outerResource={outerResource}
                outerResourceId={outerResourceId}
                externalFormId={externalFormId}
              />
            )}
            <DownloadButton />
            {!onlyShow && templateUpdateEnabled && outerResource && (
              <AttachmentRevisionTest outerResource={outerResource} />
            )}
            {!onlyShow && <AttachmentRevisionSetActive onUpload={onUpload} />}
          </Box>
        </ColumnActions>
      </Datagrid>
    </ListContextProvider>
  );
};

export default AttachmentRevisionList;
