import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import ColumnActions from '../common/ColumnActions';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import ListActions from '../common/ListActions';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import ListWithTitle from '../common/ListWithTitle';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import VendorEdit from './VendorEdit';
import VendorShow from './VendorShow';

const VendorList: React.FC = (): ReactElement => {
  const defaultFilter = useDefaultFilter('project');

  return (
    <ListWithTitle
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={<ListActions />}
      sort={{ field: 'vendor', order: 'ASC' }}
    >
      <DatagridConfigurableRbac
        ShowComponent={<VendorShow withoutActions />}
        EditComponent={<VendorEdit syncWithLocation={false} />}
        defaultVisibleColumns={[
          'id',
          'vendor',
          'description',
          'responsibility',
        ]}
      >
        <TextField source="id" label="ID" />
        <TextField source="vendor" label="Vendor" />
        <TextField source="description" label="Description" />
        <TextField source="responsibility" label="Responsibility" />
        {renderMetaDataFields()}
        <ColumnActions label="Actions" />
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default VendorList;
