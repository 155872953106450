import React, { Fragment, ReactElement } from 'react';
import {
  BooleanField,
  Labeled,
  SelectField,
  useRecordContext,
} from 'react-admin';
import { Box } from '@mui/material';
import { SectionType, drawingReportTypeChoices } from '../constants';
import commonStyles from '../../common/commonStyles';
import { BookSection } from '../type';
import CustomBooleanField from '../../common/CustomBooleanField';
import DrawingTypesField from '../../common/DrawingTypesField';
import { BookSectionCertListShow } from '../bookSectionCertList/BookSectionCertListShow';
import { BookSectionEngCodeListShow } from '../bookSectionEngCodeList/BookSectionEngCodeListShow';
import BookSectionTestListShow from '../bookSectionTestList/BookSectionTestListShow';
import AttachmentTypeField from './AttachmentTypeField';

export const BookSectionTypeComponentShow: React.FC = (): ReactElement => {
  const { sectionType } = useRecordContext<BookSection>();
  switch (sectionType) {
    case SectionType.PRESERVATION: {
      return (
        <Labeled label="Include attachments">
          <BooleanField source="includeAttachments" />
        </Labeled>
      );
    }
    case SectionType.DRAWING: {
      return (
        <Fragment>
          <Box sx={commonStyles.flexBox}>
            <Labeled label="Include drawings" sx={commonStyles.flexBoxItem}>
              <CustomBooleanField source="includeDrawings" />
            </Labeled>
            <Labeled label="Drawing Report Type" sx={commonStyles.flexBoxItem}>
              <SelectField
                source="drawingReportType"
                choices={drawingReportTypeChoices}
              />
            </Labeled>
          </Box>
          <Box sx={commonStyles.flexBox}>
            <Labeled label="Drawing Types" sx={commonStyles.flexBoxLongItem}>
              <DrawingTypesField />
            </Labeled>
          </Box>
        </Fragment>
      );
    }
    case SectionType.ATTACHMENT: {
      return (
        <Labeled label="Book Section">
          <AttachmentTypeField source="attachmentType" />
        </Labeled>
      );
    }
    case SectionType.CERT: {
      return <BookSectionCertListShow />;
    }
    case SectionType.TEST: {
      return <BookSectionTestListShow />;
    }
    case SectionType.ENG_LIST: {
      return <BookSectionEngCodeListShow />;
    }
    default: {
      return null;
    }
  }
};
