import React, { Fragment, ReactElement } from 'react';
import { AxiosResponse } from 'axios';
import AttachmentCreateInDialog from '../attachment/AttachmentCreateInDialog';
import AttachmentList from '../attachment/AttachmentList';
import { ATTACHMENT_TYPE_ENUMS } from './enums';

type AttachmentFormTabType = {
  targetId: string;
  resourceName: string | string[];
  onUpload?: (result: AxiosResponse | AxiosResponse[]) => void;
  onDelete?: (record) => void;
  attachmentType?: ATTACHMENT_TYPE_ENUMS[];
  simpleView?: boolean;
  isImage?: boolean;
};

const AttachmentFormTab: React.FC<AttachmentFormTabType> = ({
  targetId,
  onUpload,
  onDelete,
  attachmentType = [
    ATTACHMENT_TYPE_ENUMS.GENERAL,
    ATTACHMENT_TYPE_ENUMS.SMART_ITR,
  ],
  simpleView,
  isImage,
  resourceName,
}): ReactElement => {
  return (
    <Fragment>
      <AttachmentList
        targetId={targetId}
        onDelete={onDelete}
        attachmentType={attachmentType}
        simpleView={simpleView}
        resourceName={resourceName}
      />
      <AttachmentCreateInDialog
        isImage={isImage}
        targetId={targetId}
        onUpload={onUpload}
        attachmentType={attachmentType?.[0]}
      />
    </Fragment>
  );
};

export default AttachmentFormTab;
