import React, { ReactElement, Fragment } from 'react';
import { maxLength, BooleanInput, useRecordContext } from 'react-admin';
import Box from '@mui/material/Box';
import AssignedWithCreateInput from '../common/Assigned/AssignedWithCreateInput';
import { ContactCategories } from '../common/contactInput/constants';
import DisciplineTestInput from '../common/DisciplineTestInput';
import DependentTextInput from '../common/DependentTextInput';
import VendorInput from '../common/VendorInput/VendorInput';
import AutocompleteInput from '../common/AutocompleteInput';
import TestFormNoInput from '../common/TestFormNoInput';
import DisciplineInput from '../common/DisciplineInput';
import CustomDateInput from '../common/CustomDateInput';
import { required } from '../../utils/UtilityFunctions';
import ComponentInput from '../common/ComponentInput';
import LocationInput from '../common/LocationInput';
import commonStyles from '../common/commonStyles';
import TextInput from '../common/TextInput';
import VerifiedInput from './components/VerifiedInput';
import { statuses, mobileTestStatuses } from './constants';
import CompletedByInput from './components/CompletedByInput';
import EquipmentTypeInput from './components/EquipmentTypeInput';
import MobileTestStatusInput from './components/MobileTestStatusInput';
import CompletedDateTimeInput from './components/CompletedDateTimeInput';

const ComponentTestForm: React.FC = (): ReactElement => {
  const record = useRecordContext();

  return (
    <Box sx={commonStyles.columnFlexBox}>
      <Box sx={commonStyles.flexBox}>
        <ComponentInput
          isRequired={!record}
          sx={commonStyles.flexBoxItem}
          disabled={!!record}
        />
        <TestFormNoInput
          source="testId"
          isRequired={!record}
          sx={commonStyles.flexBoxItem}
          disabled={!!record}
        />
        <AutocompleteInput
          disabled
          source="status"
          label="Status"
          sx={commonStyles.flexBoxItem}
          choices={statuses}
        />
        <LocationInput
          isRequired={!record}
          sx={commonStyles.flexBoxItem}
          disabled={!!record}
        />
        <EquipmentTypeInput />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <VerifiedInput sx={commonStyles.flexBoxItem} />
        <CompletedDateTimeInput sx={commonStyles.flexBoxItem} />
        <CompletedByInput sx={commonStyles.flexBoxLongItem} />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <BooleanInput
          source="retest"
          label="Retest"
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="retestReason"
          label="Retest Reason"
          validate={[maxLength(255)]}
          sx={{ ...commonStyles.flexBoxLongItem, flexGrow: 1 }}
          fullWidth
          multiline
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="reviewComments"
          label="Review Comments"
          sx={{ ...commonStyles.flexBoxLongItem, flexGrow: 1 }}
          fullWidth
          multiline
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <BooleanInput
          source="isMobile"
          label="Mobile"
          sx={commonStyles.flexBoxItem}
        />
        <MobileTestStatusInput
          source="mobileTestStatus"
          label="Mobile Status"
          validate={[required()]}
          sx={commonStyles.flexBoxItem}
          choices={mobileTestStatuses}
        />
        <AssignedWithCreateInput
          label="Responsible"
          sx={commonStyles.flexBoxLongItem}
          category={ContactCategories.itrEmpWorkGrp}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="itrTotalSignatures"
          label="ITR Total Signatures"
          disabled
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="itrSigned"
          label="ITR Signed"
          disabled
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="itrSignedBy"
          label="ITR Signed By"
          disabled
          sx={commonStyles.flexBoxItem}
        />
        <VendorInput source="responsibleVendorId" />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <BooleanInput
          source="isPrinted"
          label="Printed"
          sx={commonStyles.flexBoxItem}
          disabled
        />
        <DependentTextInput
          source="printedBy"
          label="Printed By"
          validate={[maxLength(75)]}
          mainField="isPrinted"
          sx={commonStyles.flexBoxLongItem}
          fullWidth
          disabled
        />
        <CustomDateInput
          source="printedDate"
          label="Printed Date"
          sx={commonStyles.flexBoxItem}
          disabled
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        {record && (
          <Fragment>
            <DisciplineTestInput
              label="Test Discipline"
              source="test.disciplineTestId"
              sx={commonStyles.flexBoxItem}
              disabled
            />
            <DisciplineInput
              label="Tag Discipline"
              source="component.disciplineId"
              sx={commonStyles.flexBoxItem}
              disabled
            />
            <TextInput
              source="component.subsystem.subsystem"
              sx={commonStyles.flexBoxItem}
              label="SubSystem"
              disabled
            />
            <TextInput
              source="component.subsystem.system.systemName"
              sx={commonStyles.flexBoxItem}
              label="System"
              disabled
            />
          </Fragment>
        )}
      </Box>
    </Box>
  );
};

export default ComponentTestForm;
