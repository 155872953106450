import React, { Fragment, ReactElement } from 'react';
import { Labeled, TabbedShowLayout, TextField } from 'react-admin';
import { BooleanField } from 'ra-ui-materialui/dist';
import Box from '@mui/material/Box';
import { useDictionaryLabelsWithResource } from '../../../utils/UtilityFunctions';
import ShowComponent, { ShowComponentProps } from '../../common/ShowComponent';
import MaterialTypeField from '../../common/MaterialTypeField';
import AttachmentList from '../../attachment/AttachmentList';
import useCanAccess from '../../common/hooks/useCanAccess';
import commonStyles from '../../common/commonStyles';
import AuditListTab from '../../audit/AuditListTab';
import { RESOURCE_MATERIAL, RESOURCE_STORAGE } from '../../constants';
import PriceField from '../common/PriceField';
import StorageTab from './components/storageTab/StorageTab';
import VendorTabList from './components/vendorTab/VendorTabList';
import PurchaseOrderTabList from './components/PurchaseOrderTabList';
import ComponentTabList from './components/componentTab/ComponentTabList';

const MaterialShow: React.FC<ShowComponentProps> = ({
  syncWithLocation,
  ...rest
}): ReactElement => {
  const { labels } = useDictionaryLabelsWithResource();
  const { edit: canEditStorage } = useCanAccess(RESOURCE_STORAGE);

  return (
    <ShowComponent {...rest}>
      <TabbedShowLayout
        sx={commonStyles.bottomMargin}
        syncWithLocation={syncWithLocation}
      >
        <TabbedShowLayout.Tab label="Material">
          <Fragment>
            <Box sx={commonStyles.flexBox}>
              <Labeled sx={commonStyles.flexBoxLongItem}>
                <TextField
                  source="material"
                  label={labels['material'] || 'Material'}
                />
              </Labeled>
              <Labeled sx={commonStyles.flexBoxLongItem}>
                <TextField
                  source="description"
                  label={labels['description'] || 'Description'}
                />
              </Labeled>
              <Labeled sx={commonStyles.flexBoxItem}>
                <MaterialTypeField />
              </Labeled>
            </Box>
            <Box sx={commonStyles.flexBox}>
              <Labeled sx={commonStyles.flexBoxLongItem}>
                <PriceField label={labels['price'] || 'Price'} />
              </Labeled>
              <Labeled sx={commonStyles.flexBoxLongItem}>
                <BooleanField
                  source="spare"
                  label={labels['spare'] || 'Spare'}
                />
              </Labeled>
            </Box>
            <Box sx={commonStyles.flexBox}>
              <Labeled sx={commonStyles.flexBoxLongItem}>
                <TextField
                  source="orderPoint"
                  label={labels['orderPoint'] || 'Order Point'}
                />
              </Labeled>
              <Labeled sx={commonStyles.flexBoxLongItem}>
                <TextField
                  source="defReorderQty"
                  label={labels['defReorderQty'] || 'Def Reorder Qty'}
                />
              </Labeled>
            </Box>
            <Box sx={commonStyles.flexBox}>
              <Labeled sx={commonStyles.flexBoxLongItem}>
                <TextField
                  source="minLevel"
                  label={labels['minLevel'] || 'Min Level'}
                />
              </Labeled>
              <Labeled sx={commonStyles.flexBoxLongItem}>
                <TextField
                  source="maxLevel"
                  label={labels['maxLevel'] || 'Max Level'}
                />
              </Labeled>
            </Box>
          </Fragment>
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab label="Storage">
          <StorageTab readonly={!canEditStorage} />
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab label="PO">
          <PurchaseOrderTabList />
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab label="Attachment">
          <AttachmentList
            targetId="materialId"
            resourceName={RESOURCE_MATERIAL}
            onlyShow
          />
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab label="Tag">
          <ComponentTabList showAction={false} />
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab label="Vendor">
          <VendorTabList showActions={false} />
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab label="History">
          <AuditListTab tableName="tbl_material" />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </ShowComponent>
  );
};

export default MaterialShow;
