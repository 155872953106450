import React, { ReactElement } from 'react';
import { TextInputProps, Validator } from 'react-admin';
import { useWatch } from 'react-hook-form';
import { required } from '../../utils/UtilityFunctions';
import TextInput from './TextInput';

interface DependentInputProps extends TextInputProps {
  mainField: string;
  validate?: Validator[];
}

const DependentTextInput: React.FC<DependentInputProps> = ({
  mainField,
  validate,
  ...rest
}): ReactElement => {
  const isRequired = useWatch({ name: mainField });

  return (
    <TextInput
      validate={
        isRequired && !rest.disabled ? [required(), ...validate] : validate
      }
      {...rest}
    />
  );
};

export default DependentTextInput;
