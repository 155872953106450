import Box from '@mui/material/Box';
import React, { ReactElement } from 'react';
import { Labeled, TabbedShowLayout, TextField } from 'react-admin';
import AuditListTab from '../audit/AuditListTab';
import commonStyles from '../common/commonStyles';
import renderShowMetaDataFields from '../common/helpers/renderShowMetaDataFields';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import styles from './styles';

const TestEquipmentTypeShow: React.FC<ShowComponentProps> = ({
  syncWithLocation,
  ...rest
}): ReactElement => (
  <ShowComponent {...rest}>
    <TabbedShowLayout
      sx={commonStyles.bottomMargin}
      syncWithLocation={syncWithLocation}
    >
      <TabbedShowLayout.Tab label="Test Equipment Type">
        <Box>
          <Labeled label="Test Equipment Type">
            <TextField source="type" sx={commonStyles.flexBoxItem} />
          </Labeled>
          <Labeled label="Description">
            <TextField
              source="description"
              sx={styles.flexBoxDescriptionItem}
            />
          </Labeled>
        </Box>
        <Box>{renderShowMetaDataFields()}</Box>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="History">
        <AuditListTab tableName="tbl_test_equipment_type" />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </ShowComponent>
);

export default TestEquipmentTypeShow;
