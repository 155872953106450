import React, { ReactElement, useMemo } from 'react';
import { AutocompleteInput } from 'react-admin';
import { getContext, UserContext } from '../../provider/userContext';
import { RESOURCE_PUNCH_PRIORITY } from '../constants';
import { required } from '../../utils/UtilityFunctions';
import AutocompleteReferenceInput from './AutocompleteReferenceInput';

interface PunchPriorityInputProps
  extends React.ComponentProps<typeof AutocompleteInput> {
  isRequired?: boolean;
  alwaysOn?: boolean;
  fullWidth?: boolean;
  multiple?: boolean;
  sx?: object;
  source?: string;
  label?: string;
}

const PunchPriorityInput: React.FC<PunchPriorityInputProps> = ({
  isRequired,
  label,
  multiple,
  fullWidth,
  sx,
  ...rest
}): ReactElement => {
  const ctx: UserContext = getContext();
  const requestParams = useMemo(() => {
    return {
      filter: {
        projectId: ctx?.projectId,
      },
      sort: { field: 'priority', order: 'ASC' as const },
    };
  }, [ctx?.projectId]);

  return (
    <AutocompleteReferenceInput
      {...rest}
      resource={RESOURCE_PUNCH_PRIORITY}
      requestParams={requestParams}
      label={label}
      name="priority_inc"
      optionText="priority"
      multiple={multiple}
      validate={isRequired ? [required()] : []}
      sx={sx}
      fullWidth={fullWidth}
      includeFields={['id', 'priority']}
    />
  );
};

PunchPriorityInput.defaultProps = {
  source: 'priorityId',
  label: 'Priority',
};

export default PunchPriorityInput;
