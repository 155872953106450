import React, { ReactElement } from 'react';
import { TabbedShowLayout } from 'react-admin';
import ShowComponent from '../common/ShowComponent';
import commonStyles from '../common/commonStyles';
import ItrFormTab from './components/dataFormTabs/itrFormTab/ItrFormTab';
import PreservationFormTab from './components/dataFormTabs/preservationFormRow/PreservationFormTab';
import PunchFormTab from './components/dataFormTabs/punchFormTab/PunchFormTab';
import CertFormTab from './components/dataFormTabs/certFormTab/CertFormTab';
import { ScannerFormProps } from './types';

const QrScannerForm: ScannerFormProps = ({
  editMode,
  ...rest
}): ReactElement => {
  return (
    <ShowComponent {...rest}>
      <TabbedShowLayout sx={commonStyles.bottomMargin} syncWithLocation={false}>
        <TabbedShowLayout.Tab label="ITR">
          <ItrFormTab editMode={editMode} />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Preservation">
          <PreservationFormTab editMode={editMode} />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Certificate">
          <CertFormTab editMode={editMode} />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Punch">
          <PunchFormTab editMode={editMode} />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </ShowComponent>
  );
};

export default QrScannerForm;
