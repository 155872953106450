import React, { Fragment, ReactElement, useCallback, useState } from 'react';
import {
  Button,
  useDataProvider,
  useNotify,
  useRecordContext,
} from 'react-admin';
import { useMutation } from '@tanstack/react-query';
import { getErrorMessage } from '../../../utils/UtilityFunctions';
import { RESOURCE_ATTACHMENT_REVISION } from '../../constants';
import { PullAndPushButtonProps } from '../types';
import SelectOtherCompaniesDialog from './SelectOtherCompaniesDialog';

const PushRevisionFileToOtherCompaniesButton: React.FC<
  PullAndPushButtonProps
> = ({ resource, resourceId }): ReactElement => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [isOpen, setIsOpen] = useState(false);
  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);

  const {
    mutate: pushRevisionFileToOtherCompanies,
    isPending: pushInProgress,
  } = useMutation({
    mutationFn: async (data) => {
      return await dataProvider.pushRevisionFile(RESOURCE_ATTACHMENT_REVISION, {
        id: record?.id,
        resource,
        resourceId,
        companies: data,
      });
    },
    onSuccess: async () => {
      notify('Successfully Pushed Revision File.', {
        type: 'success',
        undoable: false,
      });
    },
    onError: (error) => {
      notify('Push Revision File Error: ' + getErrorMessage(error), {
        type: 'error',
        undoable: false,
      });
    },
  });

  const submit = async (data) => {
    pushRevisionFileToOtherCompanies(data);
    closeModal();
  };

  return (
    <Fragment>
      <Button
        label={pushInProgress ? 'Pushing...' : 'Push To Other Companies'}
        onClick={openModal}
        size="medium"
        variant="contained"
        sx={{ marginTop: '10px' }}
      />
      <SelectOtherCompaniesDialog
        isOpen={isOpen}
        openModal={openModal}
        closeModal={closeModal}
        submit={submit}
      />
    </Fragment>
  );
};

export default PushRevisionFileToOtherCompaniesButton;
