import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import { RESOURCE_TEST_EQUIPMENT_TYPE } from '../constants';
import SmartLinkField from './SmartLinkField';

interface VendorFieldProps {
  label?: string;
  source?: string;
  textSource?: string;
  sortBy?: string;
  sortable?: boolean;
}

const TestEquipmentTypeField: React.FC<VendorFieldProps> = ({
  label,
  source,
  textSource,
}): ReactElement => (
  <SmartLinkField
    label={label}
    source={source}
    resource={RESOURCE_TEST_EQUIPMENT_TYPE}
  >
    <TextField source={textSource} />
  </SmartLinkField>
);

TestEquipmentTypeField.defaultProps = {
  label: 'Test Equipment Type',
  textSource: 'testEquipmentType.type',
  source: 'testEquipmentTypeId',
  sortBy: '`testEquipmentType.type`',
};

export default TestEquipmentTypeField;
