import React, { ReactElement, useMemo } from 'react';
import { RESOURCE_MODULE } from '../constants';
import { getContext, UserContext } from '../../provider/userContext';
import { required } from '../../utils/UtilityFunctions';
import AutocompleteReferenceInput from './AutocompleteReferenceInput';

interface ModuleInputProps {
  isRequired?: boolean;
  alwaysOn?: boolean;
  multiple?: boolean;
  sx?: object;
  source?: string;
}

const ModuleInput: React.FC<ModuleInputProps> = ({
  isRequired,
  multiple,
  sx,
  ...rest
}): ReactElement => {
  const ctx: UserContext = getContext();
  const requestParams = useMemo(() => {
    return {
      filter: { projectId: ctx?.projectId },
      sort: { field: 'moduleName', order: 'ASC' as const },
    };
  }, [ctx?.projectId]);

  return (
    <AutocompleteReferenceInput
      {...rest}
      resource={RESOURCE_MODULE}
      requestParams={requestParams}
      label="Module"
      name="moduleName_inc"
      optionText="moduleName"
      multiple={multiple}
      validate={isRequired ? [required()] : []}
      sx={sx}
      includeFields={['id', 'moduleName']}
    />
  );
};

ModuleInput.defaultProps = { source: 'moduleId' };

export default ModuleInput;
