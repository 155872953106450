import React, { ReactElement } from 'react';
import { SelectInput, SelectInputProps } from 'react-admin';
import { required } from '../../utils/UtilityFunctions';
import { ACTIVITY_DURATION_UNIT_CHOICES } from './constants';

interface ActivityDurationUnitInputProps extends SelectInputProps {
  isRequired?: boolean;
}

const ActivityDurationUnitInput: React.FC<ActivityDurationUnitInputProps> = ({
  label,
  source,
  isRequired,
  ...rest
}): ReactElement => (
  <SelectInput
    {...rest}
    label={label}
    source={source}
    choices={ACTIVITY_DURATION_UNIT_CHOICES}
    validate={isRequired ? required() : undefined}
  />
);

ActivityDurationUnitInput.defaultProps = {
  label: 'Duration Unit',
  source: 'durationUnit',
};

export default ActivityDurationUnitInput;
