import React, { ReactElement } from 'react';
import { FieldProps, TextField, useRecordContext } from 'react-admin';
import { SxProps, Theme } from '@mui/material/styles';
import SmartLinkField from '../../common/SmartLinkField';

interface ActivityTextFieldProps extends FieldProps {
  label: string;
  source: string;
  resource?: string;
  sortBy?: string;
  sx?: SxProps<Theme>;
}

const ActivityTextField: React.FC<ActivityTextFieldProps> = ({
  label,
  source,
  resource,
  sortBy,
  sx,
}): ReactElement => {
  const record = useRecordContext();
  const activity = record?.systemActivityId
    ? 'systemActivity'
    : record?.subsystemActivityId
      ? 'subsystemActivity'
      : record?.componentActivityId
        ? 'componentActivity'
        : record?.activityId
          ? 'activity'
          : null;

  if (resource) {
    return (
      <SmartLinkField
        label={label}
        source={`${activity}.activityType.${source}`}
        resource={resource}
        sortBy={`${activity}.${sortBy}`}
      >
        <TextField source={`${activity}.activityType.${sortBy}`} sx={sx} />
      </SmartLinkField>
    );
  }

  return <TextField source={`${activity}.${source}`} label={label} sx={sx} />;
};

export default ActivityTextField;
