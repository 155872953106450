import React, { ReactElement, useEffect } from 'react';
import { Card, CardContent } from '@mui/material';
import {
  Button,
  Filter,
  AutocompleteInput,
  useUnselectAll,
  useResourceContext,
} from 'react-admin';
import isEmpty from 'lodash/isEmpty';
import { SideFilter } from '../../common/types';
import { ACTION_CHOICES } from '../constants';
import { RESOURCE_AUDIT } from '../../constants';
import useSidebarFilters from '../../common/hooks/useSidebarFilters';
import DateRangeFilter from '../../common/DateRangeFilter';
import TextInput from '../../common/TextInput';
import sideFilterStyles from '../../common/sideFilterStyles';
import AuditTableNameInput from './AuditTableNameInput';

const AuditFiltersSidebar: React.FC<SideFilter> = ({
  defaultFilter,
}): ReactElement => {
  const {
    data,
    isLoading,
    filterValues,
    dateFrom,
    dateTo,
    handleDateFromChange,
    handleDateToChange,
    handleClearFilters,
  } = useSidebarFilters(RESOURCE_AUDIT, defaultFilter);

  const unselectAll = useUnselectAll(useResourceContext());

  useEffect(() => {
    unselectAll();
  }, [filterValues, unselectAll]);

  if (!data || (!data.length && isEmpty(filterValues)) || isLoading) {
    return null;
  }

  return (
    <Card sx={sideFilterStyles.sideFiltersCard}>
      <CardContent sx={sideFilterStyles.sideFiltersCardContainer}>
        <Filter>
          <AuditTableNameInput source="tableName" alwaysOn />
          <TextInput
            label="Record Id"
            source="recordId"
            fullWidth
            alwaysOn
            name="recordId"
          />
          <AutocompleteInput
            source="action"
            label="Action"
            optionValue="action"
            multiple
            choices={ACTION_CHOICES}
            sx={sideFilterStyles.sideFiltersSelectInput}
            alwaysOn
          />
          <TextInput
            label="Group Action"
            source="groupAction"
            name="groupAction_inc"
            fullWidth
            alwaysOn
          />
          <DateRangeFilter
            label="Updated"
            source="updatedAt"
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
          />
          <TextInput
            label="Updated By"
            source="updatedBy"
            name="updatedBy_inc"
            fullWidth
            alwaysOn
          />
          <TextInput
            label="Updated By Name"
            source="updatedByName"
            name="updatedByName_inc"
            fullWidth
            alwaysOn
          />
        </Filter>
        <Button label="Clear filters" onClick={handleClearFilters} />
      </CardContent>
    </Card>
  );
};

export default AuditFiltersSidebar;
