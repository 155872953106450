import React, { ReactElement } from 'react';
import { Labeled, TabbedShowLayout, TextField } from 'react-admin';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import CustomBooleanField from '../common/CustomBooleanField';
import commonStyles from '../common/commonStyles';
import renderShowMetaDataFields from '../common/helpers/renderShowMetaDataFields';
import { RESOURCE_BOOK, RESOURCE_BOOK_SECTION } from '../constants';
import AuditListTab from '../audit/AuditListTab';
import SectionTypeField from './components/SectionTypeField';
import { BookSectionTypeComponentShow } from './components/BookSectionTypeComponentShow';

const BookSectionShow: React.FC<ShowComponentProps> = ({
  syncWithLocation,
  ...rest
}): ReactElement => {
  const { bookId } = useParams();

  return (
    <ShowComponent
      {...rest}
      resource={RESOURCE_BOOK_SECTION}
      showActionsProps={{
        to: `/${RESOURCE_BOOK}/${bookId}/${RESOURCE_BOOK_SECTION}`,
      }}
    >
      <TabbedShowLayout
        sx={commonStyles.bottomMargin}
        syncWithLocation={syncWithLocation}
      >
        <TabbedShowLayout.Tab label="Book Section">
          <Box sx={commonStyles.flexBox}>
            <Labeled label="Section" sx={commonStyles.flexBoxItem}>
              <TextField source="section" />
            </Labeled>
            <Labeled label="Section Type" sx={commonStyles.flexBoxItem}>
              <SectionTypeField />
            </Labeled>
            <Labeled label="Title" sx={commonStyles.flexBoxItem}>
              <TextField source="title" />
            </Labeled>
          </Box>
          <Box sx={commonStyles.flexBox}>
            <Labeled label="Description" sx={commonStyles.flexBoxLongItem}>
              <TextField source="description" />
            </Labeled>
          </Box>
          <Box sx={commonStyles.flexBox}>
            <Labeled
              label="Show section summary"
              sx={commonStyles.flexBoxSelectItem}
            >
              <CustomBooleanField source="showSectionSummary" />
            </Labeled>
          </Box>
          <Box>{renderShowMetaDataFields()}</Box>
          <Box>
            <BookSectionTypeComponentShow />
          </Box>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="History">
          <AuditListTab tableName="tbl_book_section" />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </ShowComponent>
  );
};

export default BookSectionShow;
