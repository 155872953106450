import React, {
  useState,
  useRef,
  ReactElement,
  Fragment,
  useCallback,
  useMemo,
} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useRecordContext } from 'react-admin';
import SaveButton from '../../common/SaveButton';
import { PreservationItemType } from '../types';

const SaveWithConfirmButton: React.FC = (): ReactElement => {
  const record: PreservationItemType = useRecordContext();
  const { getValues } = useFormContext();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const eventRef = useRef(null);
  const previousFrequency = record.frequency;
  const previousStartDate = record.startDate;
  const submitEvent = useMemo(() => new Event('submit', { bubbles: true }), []);

  const frequencyText =
    previousFrequency && !getValues('frequency') ? 'Frequency' : '';
  const startDateText =
    previousStartDate && !getValues('startDate') ? 'Start Date' : '';

  const confirmationMessage = `Are you sure you want to delete the ${frequencyText}${frequencyText && startDateText ? ' and ' : ''}${startDateText}? This may result in the removal of series and activities.`;

  const handleClick = useCallback(
    (event) => {
      if (
        (previousFrequency && !getValues('frequency')) ||
        (previousStartDate && !getValues('startDate'))
      ) {
        event.preventDefault();
        eventRef.current = event.nativeEvent;
        setIsDialogOpen(true);
      }
    },
    [getValues, previousFrequency, previousStartDate]
  );

  const handleCancel = useCallback(() => {
    eventRef.current = null;
    setIsDialogOpen(false);
  }, []);

  const handleConfirm = useCallback(() => {
    eventRef.current?.target.form?.dispatchEvent(submitEvent);
    eventRef.current = null;
    setIsDialogOpen(false);
  }, [submitEvent]);

  return (
    <Fragment>
      <SaveButton onClick={handleClick} />
      <Dialog open={isDialogOpen}>
        <DialogContent>
          <Typography variant="body1">{confirmationMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm}>Save</Button>
          <Button onClick={handleCancel}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default SaveWithConfirmButton;
