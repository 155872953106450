import React, { ReactElement } from 'react';
import { AutocompleteInput } from 'react-admin';
import { required } from '../../utils/UtilityFunctions';
import { ACTIVITY_CATEGORY_OPTIONS } from './constants';
import { SelectInputMultipleProps } from './types';

const ActivityCategoryInput: React.FC<SelectInputMultipleProps> = ({
  source,
  label,
  isRequired,
  ...rest
}): ReactElement => (
  <AutocompleteInput
    {...rest}
    source={source}
    label={label}
    choices={ACTIVITY_CATEGORY_OPTIONS}
    validate={isRequired ? required() : undefined}
  />
);

ActivityCategoryInput.defaultProps = {
  label: 'Category',
  source: 'category',
};

export default ActivityCategoryInput;
