import React, { ReactElement } from 'react';
import { useWatch } from 'react-hook-form';
import { useRecordContext } from 'react-admin';
import MetaDataInputs from '../../common/MetaDataInputs';
import LazyFormTab from '../../common/LazyFormTab';
import commonStyles from '../../common/commonStyles';
import PipingForm from './PipingForm';

const PipingFormTab: React.FC = (props): ReactElement => {
  const record = useRecordContext();
  const hasPiping = useWatch({ name: 'hasPiping' });

  return (
    <LazyFormTab
      {...props}
      label="Piping"
      id="piping_tab"
      sx={hasPiping ? commonStyles.formTabHeader : commonStyles.displayNone}
    >
      <PipingForm />
      {record && <MetaDataInputs source="piping" />}
    </LazyFormTab>
  );
};

export default PipingFormTab;
