import React, { ReactElement } from 'react';
import { Datagrid, ReferenceManyField, TextField } from 'react-admin';

interface ContactShowTabProps {
  target: string;
  reference: string;
}

const ContactShowTab: React.FC<ContactShowTabProps> = ({
  target,
  reference,
}): ReactElement => {
  return (
    <ReferenceManyField target={target} reference={reference}>
      <Datagrid bulkActionButtons={false} rowClick={false}>
        <TextField source="contact.nickname" label="Nickname" />
        <TextField source="contact.firstName" label="First Name" />
        <TextField source="contact.lastName" label="Last Name" />
        <TextField source="contact.email" label="Email" />
        <TextField source="contact.phone" label="Phone" />
      </Datagrid>
    </ReferenceManyField>
  );
};

export default ContactShowTab;
