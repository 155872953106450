import { AutocompleteInputChangeReason } from '@mui/base/useAutocomplete/useAutocomplete';
import React, {
  ChangeEvent,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AutocompleteInput, Validator } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import { isImage } from '../../../utils/UtilityFunctions';
import useDebounce from '../hooks/useDebounce';
import ContactCreateDialog from './ContactCreateDialog';
import { useContactList } from './useContactList';

interface ContactInputProps {
  category: string;
  alwaysOn?: boolean;
  sx?: object;
  source: string;
  label?: string;
  validate?: Validator | Validator[];
  optionValue?: string;
  disabled?: boolean;
  canCreate?: boolean;
  readOnly?: boolean;
}

export type ContactType = {
  id: string;
  nickname: string;
};

const optionKey = 'nickname';

const ContactInput: React.FC<ContactInputProps> = ({
  category,
  canCreate = true,
  source,
  ...rest
}): ReactElement => {
  const [, setFilters] = useState({ q: '' });
  const { data, isLoading, isFetched } = useContactList(category);
  const plainTextInput = useRef('');
  const isDefaultPlainTextUsed = useRef(false);
  const { formState, setValue: setFormValue } = useFormContext();
  const { [source]: value } = useWatch();
  const [plainText, setPlainText] = useState('');

  const contacts = useMemo(() => {
    const contacts = [];

    if (plainText && !isImage(plainText)) {
      contacts.push({
        [optionKey]: plainText,
      });
    }
    return contacts
      .concat(data || [])
      .sort((contact1, contact2) =>
        contact1.nickname.localeCompare(contact2.nickname)
      );
  }, [data, plainText]);

  useEffect(() => {
    if (
      isDefaultPlainTextUsed.current ||
      !isFetched ||
      !formState.defaultValues
    ) {
      return;
    }
    isDefaultPlainTextUsed.current = true;

    const defaultValue = formState.defaultValues[source];
    if (
      defaultValue &&
      !contacts.some((contact) => contact[optionKey] === value)
    ) {
      setPlainText(defaultValue);
    }
  }, [contacts, formState.defaultValues, isFetched, source, value]);

  const handleInput = (event) => {
    plainTextInput.current = event.target.value;
  };

  const handleBlur = () => {
    if (plainTextInput.current) {
      setFormValue(source, plainTextInput.current, {
        shouldDirty: true,
        shouldValidate: true,
        shouldTouch: true,
      });
      setPlainText(plainTextInput.current);
    }
  };

  const handleChange = (value) => {
    plainTextInput.current = '';
    if (!value) {
      setPlainText('');
    }
  };

  const debounce = useDebounce();
  const handleDebouncedInputChange = (
    event: ChangeEvent<HTMLInputElement>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    debounce(() => {
      if (event && reason === 'input') {
        setFilters({ q: event?.target?.value });
      }
    });
  };

  return (
    <AutocompleteInput
      {...rest}
      source={source}
      choices={contacts}
      isLoading={isLoading}
      create={
        canCreate && (
          <ContactCreateDialog
            listOfContacts={data || []}
            category={category}
            initValue={plainTextInput?.current}
          />
        )
      }
      onBlur={handleBlur}
      onInput={handleInput}
      onInputChange={handleDebouncedInputChange}
      onChange={handleChange}
      optionText={optionKey}
      optionValue={optionKey}
    />
  );
};

export default ContactInput;
