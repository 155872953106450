import React, { ReactElement } from 'react';
import {
  DateField,
  ListProps,
  NumberField,
  TextField,
  useStore,
  SelectField,
  usePermissions,
} from 'react-admin';
import { canAccess } from '@react-admin/ra-rbac';
import { useDictionaryLabelsWithResource } from '../../utils/UtilityFunctions';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import useCanAccess from '../common/hooks/useCanAccess';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import PreservationSeriesField from '../common/PreservationSeriesField';
import PreservationItemField from '../common/PreservationItemField';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import CustomBooleanField from '../common/CustomBooleanField';
import SignatureTextField from '../common/SignatureTextField';
import AssignedField from '../common/Assigned/AssignedField';
import ComponentField from '../common/ComponentField';
import ColumnActions from '../common/ColumnActions';
import ListWithTitle from '../common/ListWithTitle';
import LocationField from '../common/LocationField';
import ListActions from '../common/ListActions';
import TestField from '../common/TestField';
import { RESOURCE_PRESERVATION_ITEM_ACTIVITY } from '../constants';
import { FieldPermissionActions } from '../common/constants';
import BulkActionButtons from './components/BulkActionButtons';
import PreservationItemActivityForceDeleteButton from './components/PreservationItemActivityForceDeleteButton';
import PreservationItemActivitySidebarFilters from './PreservationItemActivitySidebarFilters';
import PreservationItemActivityShow from './PreservationItemActivityShow';
import PreservationItemActivityEdit from './PreservationItemActivityEdit';
import { completedChoices } from './constants';

const PreservationItemActivityList: React.FC<ListProps> = (): ReactElement => {
  const { labels, preferenceKey } = useDictionaryLabelsWithResource();
  const sideFilter = 'preservationItemActivity.sideFilters';
  const [showSideFilter] = useStore(sideFilter, false);
  const defaultFilter = useDefaultFilter('project', {
    isDeleted: false,
  });
  const canAccessList = useCanAccess();
  const { permissions } = usePermissions();
  const combinedFieldPermissionActions = [
    FieldPermissionActions.show,
    FieldPermissionActions.edit,
  ];

  return (
    <ListWithTitle
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={
        <ListActions
          sideFilter={sideFilter}
          showAction={{ create: false, import: false }}
          preferenceKey={preferenceKey}
          defaultFilter={{ ...defaultFilter, isClosed: false }}
        />
      }
      sort={{ field: 'id', order: 'ASC' }}
      aside={
        showSideFilter ? (
          <PreservationItemActivitySidebarFilters
            defaultFilter={defaultFilter}
          />
        ) : null
      }
      queryOptions={{ meta: { excludeFields: ['formData'] } }}
    >
      <DatagridConfigurableRbac
        ShowComponent={<PreservationItemActivityShow withoutActions />}
        EditComponent={
          <PreservationItemActivityEdit syncWithLocation={false} />
        }
        defaultVisibleColumns={[
          'id',
          'componentId',
          'testId',
          'locationId',
          'frequency',
          'dueDate',
          'lateDate',
          'sequence',
          'storagePreservation',
        ]}
        preferenceKey={preferenceKey}
        bulkActionButtons={<BulkActionButtons />}
        checkFieldsAccess
      >
        <TextField source="id" label="ID" />
        <PreservationItemField label={labels['preservationItemId']} />
        <PreservationSeriesField label={labels['preservationSeriesId']} />
        <ComponentField label={labels['componentId']} />
        <TestField
          label={labels['testId']}
          source="testId"
          textSource="test.testFormNo"
          sortBy="`test.testFormNo`"
        />

        <LocationField label={labels['locationId']} />
        <NumberField
          source="estimatedManHours"
          label={labels['estimatedManHours'] || 'Estimated Man Hours'}
        />
        <NumberField
          source="actualManHours"
          label={labels['actualManHours'] || 'Actual Man Hours'}
        />
        <NumberField
          source="adminManHours"
          label={labels['adminManHours'] || 'Admin Man Hours'}
        />
        <DateField
          source="onSiteDate"
          label={labels['onSiteDate'] || 'On Site Date'}
        />
        <AssignedField
          label={labels['assigned'] || 'Responsible'}
          sortable={false}
        />
        {combinedFieldPermissionActions.some((action) =>
          canAccess({
            permissions,
            action,
            resource: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.inspector`,
          })
        ) && (
          <SignatureTextField
            source="inspector"
            label={labels['inspector'] || 'Inspector'}
          />
        )}
        {combinedFieldPermissionActions.some((action) =>
          canAccess({
            permissions,
            action,
            resource: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.signOffDate`,
          })
        ) && (
          <DateField
            source="signOffDate"
            label={labels['signOffDate'] || 'Sign Off Date'}
          />
        )}
        {combinedFieldPermissionActions.some((action) =>
          canAccess({
            permissions,
            action,
            resource: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.completed`,
          })
        ) && (
          <SelectField
            source="completed"
            label={labels['completed'] || 'Status'}
            choices={completedChoices}
          />
        )}
        {combinedFieldPermissionActions.some((action) =>
          canAccess({
            permissions,
            action,
            resource: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.verifiedBy`,
          })
        ) && (
          <SignatureTextField
            source="verifiedBy"
            label={labels['verifiedBy'] || 'Verified By'}
          />
        )}
        {combinedFieldPermissionActions.some((action) =>
          canAccess({
            permissions,
            action,
            resource: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.verifiedDate`,
          })
        ) && (
          <DateField
            source="verifiedDate"
            label={labels['verifiedDate'] || 'Verified Date'}
          />
        )}
        <NumberField
          source="frequency"
          label={labels['frequency'] || 'Frequency'}
        />
        <DateField source="dueDate" label={labels['dueDate'] || 'Due Date'} />
        <DateField
          source="lateDate"
          label={labels['lateDate'] || 'Late Date'}
        />
        <TextField source="sequence" label={labels['sequence'] || 'Sequence'} />
        <TextField
          source="storagePreservation"
          label={labels['storagePreservation'] || 'Storage Preservation'}
        />
        <CustomBooleanField
          source="isMobile"
          label={labels['isMobile'] || 'Mobile'}
        />
        {combinedFieldPermissionActions.some((action) =>
          canAccess({
            permissions,
            action,
            resource: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.isClosed`,
          })
        ) && (
          <CustomBooleanField
            source="isClosed"
            label={labels['isClosed'] || 'Closed'}
          />
        )}
        <CustomBooleanField source="hasAttachment" label="Has Attachment" />
        <NumberField
          source="plusDate"
          label={labels['plusDate'] || 'Plus Date'}
        />
        <TextField
          source="printedBy"
          label={labels['printedBy'] || 'Printed By'}
        />
        <DateField
          source="printDate"
          label={labels['printDate'] || 'Printed Date'}
        />
        {renderMetaDataFields()}
        <ColumnActions label="Actions" renderChildrenOnRightSide>
          {canAccessList.forceDelete && (
            <PreservationItemActivityForceDeleteButton />
          )}
        </ColumnActions>
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default PreservationItemActivityList;
