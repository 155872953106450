import Box from '@mui/material/Box';
import React, { ReactElement } from 'react';
import AttachmentFormTab from '../common/AttachmentFormTab';
import commonStyles from '../common/commonStyles';
import { ATTACHMENT_TYPE_ENUMS } from '../common/enums';
import HistoryTab from '../common/HistoryTab';
import LazyFormTab from '../common/LazyFormTab';
import MetaDataInputs from '../common/MetaDataInputs';
import TabbedEditForm from '../common/TabbedEditForm';
import { EditPropsWithLocation } from '../common/types';
import { RESOURCE_TEST_EQUIPMENT } from '../constants';
import styles from './styles';
import TestEquipmentForm from './TestEquipmentForm';
import { TestEquipment } from './types';

const TestEquipmentEdit: React.FC<EditPropsWithLocation<TestEquipment>> = (
  props
): ReactElement => (
  <TabbedEditForm {...props}>
    <LazyFormTab
      label="Test Equipment"
      id="test_equipment_tab"
      sx={commonStyles.formTabHeader}
    >
      <Box>
        <TestEquipmentForm />
        <MetaDataInputs itemSx={styles.flexBoxItem} />
      </Box>
    </LazyFormTab>
    <LazyFormTab
      label="Attachment"
      id="attachment_tab"
      sx={commonStyles.formTabHeader}
    >
      <AttachmentFormTab
        targetId="testEquipmentId"
        attachmentType={[ATTACHMENT_TYPE_ENUMS.GENERAL]}
        resourceName={RESOURCE_TEST_EQUIPMENT}
        simpleView
      />
    </LazyFormTab>
    <HistoryTab tableName="tbl_test_equipment" />
  </TabbedEditForm>
);

export default TestEquipmentEdit;
