import React, { ReactElement, useEffect } from 'react';
import { maxLength, useRecordContext } from 'react-admin';
import Box from '@mui/material/Box';
import { useFormContext } from 'react-hook-form';
import ActivityConstraintInput from '../../common/ActivityConstraintInput';
import ActivityDurationUnitInput from '../../common/ActivityDurationUnitInput';
import ActivityPhaseInput from '../../common/ActivityPhaseInput';
import ActivityTypeInput from '../../common/ActivityTypeInput';
import CertTemplateInput from '../../common/CertTemplateInput';
import commonStyles from '../../common/commonStyles';
import { activityCategory } from '../../common/constants';
import CustomDateInput from '../../common/CustomDateInput';
import CustomNumberInput from '../../common/CustomNumberInput';
import DisciplineInput from '../../common/DisciplineInput';
import SystemInput from '../../common/SystemInput';
import TextInput from '../../common/TextInput';
import ActivityCategoryInput from '../../common/ActivityCertificate';
import SubsystemInput from '../../common/SubsystemInput';
import ComponentInput from '../../common/ComponentInput';

interface SystemActivityFormProps {
  bulkEditMode?: boolean;
}

const renderEntityInput = (category: string, isRequired: boolean) => {
  switch (category) {
    case activityCategory.system:
      return (
        <SystemInput sx={commonStyles.flexBoxItem} isRequired={isRequired} />
      );
    case activityCategory.subSystem:
      return (
        <SubsystemInput sx={commonStyles.flexBoxItem} isRequired={isRequired} />
      );
    case activityCategory.component:
      return (
        <ComponentInput sx={commonStyles.flexBoxItem} isRequired={isRequired} />
      );
    default:
      return (
        <TextInput
          name="entity"
          source="entity"
          label="Entity"
          disabled
          sx={commonStyles.flexBoxItem}
        />
      );
  }
};

const ActivityForm: React.FC<SystemActivityFormProps> = ({
  bulkEditMode,
}): ReactElement => {
  const isRequired = !bulkEditMode;
  const { setValue, watch } = useFormContext();
  const category = watch('category');
  const record = useRecordContext();

  useEffect(() => {
    setValue('certTemplateId', null);
    setValue('systemId', null);
    setValue('subsystemId', null);
    setValue('componentId', null);
  }, [setValue, category]);

  return (
    <Box sx={commonStyles.columnFlexBox}>
      <Box sx={commonStyles.flexBoxWithWrap}>
        <TextInput
          name="activity"
          source="activity"
          label="Activity"
          sx={commonStyles.flexBoxItem}
          validate={maxLength(75)}
          disabled={bulkEditMode}
        />
        <ActivityCategoryInput
          sx={commonStyles.flexBoxItem}
          readOnly={Boolean(record?.id) || bulkEditMode}
          isRequired={isRequired}
        />
        {renderEntityInput(category, isRequired)}
        <TextInput
          name="sequence"
          source="sequence"
          label="Sequence"
          sx={commonStyles.flexBoxItem}
          validate={maxLength(20)}
          disabled={bulkEditMode}
        />
      </Box>
      <Box sx={commonStyles.flexBoxWithWrap}>
        <ActivityPhaseInput
          sx={commonStyles.flexBoxItem}
          isRequired={isRequired}
        />
        <DisciplineInput
          sx={commonStyles.flexBoxItem}
          withAllOption
          isRequired={isRequired}
        />
        <ActivityTypeInput
          sx={commonStyles.flexBoxItem}
          isRequired={isRequired}
        />
      </Box>
      <Box sx={commonStyles.flexBoxWithWrap}>
        <TextInput
          name="description"
          source="description"
          label="Description"
          sx={commonStyles.flexBoxLongItem}
          validate={maxLength(150)}
          multiline
        />
        <ActivityConstraintInput sx={commonStyles.flexBoxItem} />
      </Box>
      <Box sx={commonStyles.flexBoxWithWrap}>
        <CustomNumberInput
          name="duration"
          source="duration"
          label="Duration"
          sx={commonStyles.flexBoxItem}
          isPositive
        />
        <CustomNumberInput
          name="actualDuration"
          source="actualDuration"
          label="Actual Duration"
          sx={commonStyles.flexBoxItem}
          isPositive
        />
        <ActivityDurationUnitInput sx={commonStyles.flexBoxItem} />
      </Box>
      <Box sx={commonStyles.flexBoxWithWrap}>
        <CustomDateInput
          name="plannedDate"
          source="plannedDate"
          label="Planned Date"
          sx={commonStyles.flexBoxItem}
        />
        <CustomDateInput
          name="actualDate"
          source="actualDate"
          label="Actual Date"
          sx={commonStyles.flexBoxItem}
        />
        <CertTemplateInput
          category={category}
          disabled={!category}
          sx={commonStyles.flexBoxItem}
        />
      </Box>
    </Box>
  );
};

export default ActivityForm;
