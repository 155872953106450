import React, { ReactElement } from 'react';
import { Identifier } from 'react-admin';
import CloseIcon from '@mui/icons-material/Close';
import { Card, CardContent, IconButton, MenuItem } from '@mui/material';
import notificationStyles from '../notificationStyles';
import { Notification } from '../type';
import NotificationTitle from './NotificationTitle';

interface NotificationItemCardProps {
  notification: Notification;
  readNotification: (id: Identifier) => void;
  handleOpenNotification?: () => void;
}

const NotificationItemCard: React.FC<NotificationItemCardProps> = ({
  notification,
  readNotification,
  handleOpenNotification,
}): ReactElement => {
  const handleReadNotification = async () => {
    await readNotification(notification.id);
  };

  return (
    <MenuItem
      key={`notification_${notification.id}`}
      sx={notificationStyles.notificationMenuItem}
    >
      <Card sx={notificationStyles.notificationCard}>
        <CardContent
          sx={notificationStyles.notificationCardContent}
          onClick={handleOpenNotification}
        >
          <NotificationTitle notification={notification} />
        </CardContent>
        <IconButton
          size="small"
          sx={notificationStyles.notificationDismiss}
          onClick={handleReadNotification}
        >
          <CloseIcon />
        </IconButton>
      </Card>
    </MenuItem>
  );
};

export default NotificationItemCard;
