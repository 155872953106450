import React, { ReactElement } from 'react';
import { useRecordContext } from 'react-admin';
import SystemField from '../../common/SystemField';
import { getCertDetailsSystemSource } from './helpers';

interface CertDetailsSystemFieldProps {
  label?: string;
  source?: string;
}

const CertDetailsSystemField: React.FC<CertDetailsSystemFieldProps> = ({
  label,
}): ReactElement => {
  const record = useRecordContext();
  const { source, textSource } = getCertDetailsSystemSource(record);

  return <SystemField source={source} textSource={textSource} label={label} />;
};

CertDetailsSystemField.defaultProps = {
  label: 'System',
  source: 'certDetailsSystem',
};

export default CertDetailsSystemField;
