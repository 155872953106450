import React, { ReactElement } from 'react';
import { BooleanInput, maxLength } from 'react-admin';
import Box from '@mui/material/Box';
import SelectInputWithFreeText from '../common/SelectInputWithFreeText';
import DisciplineInput from '../common/DisciplineInput';
import { required } from '../../utils/UtilityFunctions';
import commonStyles from '../common/commonStyles';
import TextInput from '../common/TextInput';
import { equipmentTypeChoices } from './constants';

interface EquipmentTypeFormProps {
  bulkEditMode?: boolean;
}

const EquipmentTypeForm: React.FC<EquipmentTypeFormProps> = ({
  bulkEditMode,
}): ReactElement => {
  const requiredValidation = bulkEditMode ? [] : [required()];

  return (
    <Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="equipType"
          label="Type"
          validate={[maxLength(75), ...requiredValidation]}
          sx={commonStyles.flexBoxItem}
          disabled={bulkEditMode}
        />
        <TextInput
          source="description"
          label="Description"
          validate={[maxLength(125)]}
          sx={commonStyles.flexBoxItem}
          fullWidth
          multiline
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <SelectInputWithFreeText
          label="Hard/Soft"
          source="hardSoft"
          options={equipmentTypeChoices}
          validate={[maxLength(15)]}
          sx={commonStyles.flexBoxItem}
          fullWidth
        />
        <TextInput
          source="grouping"
          label="Grouping"
          validate={[maxLength(75)]}
          sx={commonStyles.flexBoxItem}
        />
        <DisciplineInput />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <BooleanInput
          source="metricsCount"
          label="Metrics Count"
          defaultValue={false}
        />
      </Box>
    </Box>
  );
};

export default EquipmentTypeForm;
