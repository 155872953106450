export const UPDATE_REVISION_FILE_MENU_ITEMS = [
  { key: 'update_revision_file', label: 'Update Revision File' },
  { key: 'download_master_copy', label: 'Download Master Copy' },
  { key: 'pull_from_master_copy', label: 'Pull From Master Copy' },
  { key: 'push_to_master_copy', label: 'Push To Master Copy' },
  { key: 'push_to_other_companies', label: 'Push To Other Companies' },
];

export const UPDATE_JSON_FORM_MENU_ITEMS = [
  { key: 'pull_alpha_for', label: 'Pull Alpha Form' },
  { key: 'download_master_copy', label: 'Download Master Copy' },
  { key: 'pull_from_master_copy', label: 'Pull From Master Copy' },
  { key: 'push_to_master_copy', label: 'Push To Master Copy' },
  { key: 'push_to_other_companies', label: 'Push To Other Companies' },
];
