import React, { ReactElement, useMemo } from 'react';
import { AutocompleteInput } from 'react-admin';
import { RESOURCE_GROUP } from '../constants';
import { getContext, UserContext } from '../../provider/userContext';
import { required } from '../../utils/UtilityFunctions';
import AutocompleteReferenceInput from './AutocompleteReferenceInput';

interface GroupInputProps
  extends React.ComponentProps<typeof AutocompleteInput> {
  label?: string;
  category?: string[];
  source?: string;
}

const GroupInput: React.FC<GroupInputProps> = ({
  category,
  label,
  isRequired,
  ...rest
}): ReactElement => {
  const ctx: UserContext = getContext();
  const requestParams = useMemo(() => {
    return {
      filter: {
        projectId: ctx?.projectId,
        category,
      },
      sort: { field: 'groupName', order: 'ASC' as const },
    };
  }, [category, ctx?.projectId]);

  return (
    <AutocompleteReferenceInput
      {...rest}
      resource={RESOURCE_GROUP}
      requestParams={requestParams}
      label={label}
      name="groupName_inc"
      optionText="groupName"
      validate={isRequired ? [required()] : []}
      includeFields={['id', 'groupName']}
    />
  );
};

GroupInput.defaultProps = { source: 'groupId', label: 'Group' };

export default GroupInput;
