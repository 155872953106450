import React, { ReactElement } from 'react';
import {
  DateField,
  Labeled,
  TabbedShowLayout,
  TextField,
  usePermissions,
} from 'react-admin';
import Box from '@mui/material/Box';
import { canAccess } from '@react-admin/ra-rbac';
import commonStyles from '../common/commonStyles';
import renderShowMetaDataFields from '../common/helpers/renderShowMetaDataFields';
import { useDictionaryLabelsWithResource } from '../../utils/UtilityFunctions';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import PunchPriorityField from '../common/PunchPriorityField';
import PunchCategoryField from '../common/PunchCategoryField';
import EquipmentTypeField from '../common/EquipmentTypeField';
import AttachmentList from '../attachment/AttachmentList';
import { ATTACHMENT_TYPE_ENUMS } from '../common/enums';
import DisciplineField from '../common/DisciplineField';
import ImageGallery from '../imageGallery/ImageGallery';
import ComponentField from '../common/ComponentField';
import SubsystemField from '../common/SubsystemField';
import LocationField from '../common/LocationField';
import { commentType, FieldPermissionActions } from '../common/constants';
import CommentList from '../comment/CommentList';
import AuditListTab from '../audit/AuditListTab';
import { RESOURCE_COMMENT, RESOURCE_PUNCH } from '../constants';
import VendorField from '../common/VendorField';
import SystemField from '../common/SystemField';

const PunchShow: React.FC<ShowComponentProps> = ({
  syncWithLocation,
  ...rest
}): ReactElement => {
  const { labels } = useDictionaryLabelsWithResource();
  const { permissions } = usePermissions();

  return (
    <ShowComponent {...rest}>
      <TabbedShowLayout
        sx={commonStyles.bottomMargin}
        syncWithLocation={syncWithLocation}
      >
        <TabbedShowLayout.Tab label="Punch">
          <Box>
            <Labeled sx={commonStyles.flexBoxItem}>
              <TextField label={labels['status'] || 'Status'} source="status" />
            </Labeled>
            <Labeled sx={commonStyles.flexBoxItem}>
              <TextField
                label={labels['punchNo'] || 'Punch No'}
                source="punchNo"
              />
            </Labeled>
          </Box>
          <Box>
            <Labeled sx={commonStyles.flexBoxDescriptionItem}>
              <TextField
                source="description"
                label={labels['description'] || 'Description'}
              />
            </Labeled>
          </Box>
          <Box>
            <Labeled sx={commonStyles.flexBoxItem}>
              <ComponentField label={labels['componentId']} />
            </Labeled>

            <Labeled sx={commonStyles.flexBoxItem}>
              <PunchPriorityField label={labels['priorityId']} />
            </Labeled>
            <Labeled sx={commonStyles.flexBoxItem}>
              <EquipmentTypeField
                source="component.equipTypeId"
                textSource="component.equipType.equipType"
                label={labels['equipTypeId'] || 'Equipment Type'}
              />
            </Labeled>
          </Box>
          <Box>
            <Labeled sx={commonStyles.flexBoxItem}>
              <SubsystemField label={labels['subsystemId']} />
            </Labeled>
            <Labeled sx={commonStyles.flexBoxItem}>
              <TextField source="subsystem.phase" label="Subsystem Phase" />
            </Labeled>
            <Labeled sx={commonStyles.flexBoxLongItem}>
              <TextField
                source="subsystem.description"
                label="Subsystem Description"
              />
            </Labeled>
          </Box>
          <Box>
            <Labeled sx={commonStyles.flexBoxItem}>
              <SystemField
                source="subsystem.systemId"
                textSource="subsystem.system.systemName"
              />
            </Labeled>
            <Labeled sx={commonStyles.flexBoxLongItem}>
              <TextField
                source="subsystem.system.description"
                label="System Description"
              />
            </Labeled>
          </Box>
          <Box>
            <Labeled sx={commonStyles.flexBoxItem}>
              <TextField
                source="raisedBy"
                label={labels['raisedBy'] || 'Raised By'}
              />
            </Labeled>
            <Labeled sx={commonStyles.flexBoxItem}>
              <DateField
                source="raisedDate"
                label={labels['raisedDate'] || 'Raised Date'}
              />
            </Labeled>
            {canAccess({
              permissions,
              action: FieldPermissionActions.show,
              resource: `${RESOURCE_PUNCH}.clearedBy`,
            }) && (
              <Labeled sx={commonStyles.flexBoxItem}>
                <TextField
                  source="clearedBy"
                  label={labels['clearedBy'] || 'Cleared By'}
                />
              </Labeled>
            )}
            {canAccess({
              permissions,
              action: FieldPermissionActions.show,
              resource: `${RESOURCE_PUNCH}.clearedDate`,
            }) && (
              <Labeled sx={commonStyles.flexBoxItem}>
                <DateField
                  source="clearedDate"
                  label={labels['clearedDate'] || 'Cleared Date'}
                />
              </Labeled>
            )}
          </Box>
          <Box>
            {canAccess({
              permissions,
              action: FieldPermissionActions.show,
              resource: `${RESOURCE_PUNCH}.acceptedBy`,
            }) && (
              <Labeled sx={commonStyles.flexBoxItem}>
                <TextField
                  source="acceptedBy"
                  label={labels['acceptedBy'] || 'Accepted By'}
                />
              </Labeled>
            )}
            {canAccess({
              permissions,
              action: FieldPermissionActions.show,
              resource: `${RESOURCE_PUNCH}.acceptedDate`,
            }) && (
              <Labeled sx={commonStyles.flexBoxItem}>
                <DateField
                  source="acceptedDate"
                  label={labels['acceptedDate'] || 'Accepted Date'}
                />
              </Labeled>
            )}
            <Labeled sx={commonStyles.flexBoxItem}>
              <DateField
                source="requiredDate"
                label={labels['requiredDate'] || 'Required Date'}
              />
            </Labeled>
            <Labeled sx={commonStyles.flexBoxItem}>
              <DateField
                source="estimatedClearDate"
                label={labels['estimatedClearDate'] || 'Estimated Clear Date'}
              />
            </Labeled>
          </Box>
          <Box>
            <Labeled sx={commonStyles.flexBoxItem}>
              <TextField
                source="responsible"
                label={labels['responsible'] || 'Responsible'}
              />
            </Labeled>
            <Labeled sx={commonStyles.flexBoxItem}>
              <PunchCategoryField label={labels['punchCatId']} />
            </Labeled>
            <Labeled sx={commonStyles.flexBoxItem}>
              <LocationField label={labels['locationId']} />
            </Labeled>
            <Labeled sx={commonStyles.flexBoxItem}>
              <DisciplineField label={labels['disciplineId']} />
            </Labeled>
          </Box>
          <Box>
            <Labeled sx={commonStyles.flexBoxItem}>
              <TextField
                source="actionee"
                label={labels['actionee'] || 'Actionee'}
              />
            </Labeled>
            <Labeled sx={commonStyles.flexBoxItem}>
              <TextField
                source="poRmaNo"
                label={labels['poRmaNo'] || 'Po Rma No'}
              />
            </Labeled>
            <Labeled sx={commonStyles.flexBoxItem}>
              <VendorField
                source="vendorId"
                label={labels['vendorId'] || 'Vendor'}
              />
            </Labeled>
            <Labeled sx={commonStyles.flexBoxItem}>
              <TextField
                source="workpackOverride"
                label={labels['workpackOverride'] || 'Workpack Override'}
              />
            </Labeled>
          </Box>
          <Box>
            <Labeled sx={commonStyles.flexBoxLongItem}>
              <TextField
                source="correctiveAction"
                label={labels['correctiveAction'] || 'Corrective Action'}
              />
            </Labeled>
            {labels['cfText01'] ? (
              <Labeled sx={commonStyles.flexBoxLongItem}>
                <TextField source="cfText01" label={labels['cfText01']} />
              </Labeled>
            ) : null}
          </Box>
          <Box>
            {labels['cfText02'] ? (
              <Labeled sx={commonStyles.flexBoxLongItem}>
                <TextField source="cfText02" label={labels['cfText02']} />
              </Labeled>
            ) : null}
            {labels['cfText03'] ? (
              <Labeled sx={commonStyles.flexBoxLongItem}>
                <TextField source="cfText03" label={labels['cfText03']} />
              </Labeled>
            ) : null}
          </Box>
          <Box>
            {labels['cfText04'] ? (
              <Labeled sx={commonStyles.flexBoxLongItem}>
                <TextField source="cfText04" label={labels['cfText04']} />
              </Labeled>
            ) : null}
            {labels['cfText05'] ? (
              <Labeled sx={commonStyles.flexBoxLongItem}>
                <TextField source="cfText05" label={labels['cfText05']} />
              </Labeled>
            ) : null}
          </Box>
          <Box>{renderShowMetaDataFields()}</Box>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab
          label="comments"
          path="comments"
          id="comments_tab"
        >
          <CommentList
            resource={RESOURCE_COMMENT}
            targetId="punch_id"
            commentType={commentType.punch}
          />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab
          label="Attachment"
          id="attachment_tab"
          sx={commonStyles.formTabHeader}
        >
          <AttachmentList
            targetId="punchId"
            resourceName={RESOURCE_PUNCH}
            attachmentType={[
              ATTACHMENT_TYPE_ENUMS.GENERAL,
              ATTACHMENT_TYPE_ENUMS.IMAGE,
            ]}
            onlyShow
          />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab
          label="Image Gallery"
          id="gallery_tab"
          sx={commonStyles.formTabHeader}
        >
          <ImageGallery targetId="punchId" onlyShow />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="History">
          <AuditListTab tableName="tbl_punch" />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </ShowComponent>
  );
};

export default PunchShow;
