import React, { ReactElement } from 'react';
import { ReferenceField, ChipField } from 'react-admin';
import { RESOURCE_POSITION } from '../constants';

interface Props {
  label?: string;
  source?: string;
  sortable?: boolean;
}

const UserProjectPositionField: React.FC<Props> = ({
  label,
  source,
  sortable,
}): ReactElement => (
  <ReferenceField
    label={label}
    source={source}
    reference={RESOURCE_POSITION}
    link={false}
    sortable={sortable}
  >
    <ChipField source="position" />
  </ReferenceField>
);

UserProjectPositionField.defaultProps = {
  label: 'Position',
  source: 'positionId',
};

export default UserProjectPositionField;
