import React, { ReactNode, useContext } from 'react';
import { useResourceContext } from 'react-admin';
import { CardContent } from '@mui/material';
import { DataProviderErrorsContext } from './DataProviderErrorsContext';
import { SubscriptionError } from './types';
import Error402 from './errors/Error402';
import Error400 from './errors/Error400';
import Error500 from './errors/Error500';

type Props = {
  children: ReactNode;
  action: string;
  resource?: string;
  ignoreCodes?: number[];
};

const getErrorComponent = (error: SubscriptionError) => {
  switch (true) {
    case error.status === 402:
      return <Error402 />;
    case error.status >= 500:
      return <Error500 />;
    case error.status >= 400:
      return <Error400 />;
  }
};

const DataProviderErrorsWrapper: React.FC<Props> = ({
  children,
  action,
  resource,
  ignoreCodes = [],
}) => {
  const resourceContext = useResourceContext({ resource });
  const { errorState } = useContext(DataProviderErrorsContext);

  if (
    errorState[`${resourceContext}.${action}`]?.message &&
    !ignoreCodes.includes(errorState[`${resourceContext}.${action}`]?.status)
  ) {
    return (
      <CardContent>
        {getErrorComponent(errorState[`${resourceContext}.${action}`])}
      </CardContent>
    );
  }

  return children;
};

export default DataProviderErrorsWrapper;
