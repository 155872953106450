import React, { ReactElement, useCallback } from 'react';
import { TabbedForm, useRedirect } from 'react-admin';
import { getContext, UserContext } from '../../provider/userContext';
import commonStyles from '../common/commonStyles';
import LazyFormTab from '../common/LazyFormTab';
import CreateWithTitle from '../common/CreateWithTitle';
import FooterToolbar from '../common/FooterToolbar';
import { RESOURCE_COMPONENT } from '../constants';
import ComponentForm from './components/ComponentForm';
import ElectricalFormTab from './components/ElectricalFormTab';
import PipingFormTab from './components/PipingFormTab';
import MechanicalFormTab from './components/MechanicalFormTab';
import InstrumentationFormTab from './components/InstrumentationFormTab';

const ComponentCreate: React.FC = (): ReactElement => {
  const ctx: UserContext = getContext();
  const redirect = useRedirect();

  const transform = (data) => ({
    ...data,
    projectId: ctx?.projectId,
  });

  const onSuccess = useCallback(async () => {
    redirect('list', RESOURCE_COMPONENT);
  }, [redirect]);

  return (
    <CreateWithTitle transform={transform} mutationOptions={{ onSuccess }}>
      <TabbedForm
        toolbar={<FooterToolbar />}
        sx={commonStyles.tabbedFormBoldHeader}
      >
        <LazyFormTab
          label="Component"
          id="component_tab"
          sx={commonStyles.formTabHeader}
        >
          <ComponentForm />
        </LazyFormTab>
        <ElectricalFormTab />
        <PipingFormTab />
        <MechanicalFormTab />
        <InstrumentationFormTab />
      </TabbedForm>
    </CreateWithTitle>
  );
};

export default ComponentCreate;
