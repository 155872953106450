import { checkJson, getJsonValue } from '../common/helpers/json';
import { actionChoices } from './constants';

export const convertRoleAction = (data) => {
  return !data ||
    data.length === 0 ||
    data.includes(actionChoices[0].id) ||
    (data.length === actionChoices.length - 1 &&
      !data.includes(actionChoices[0].id))
    ? '*'
    : JSON.stringify(data);
};

export const formatRoleAction = (data) => {
  if (!data) {
    return [];
  }

  if (Array.isArray(data)) {
    return data;
  }

  const jsonValue = getJsonValue(data);

  if (jsonValue === '*') {
    return actionChoices
      .filter((choice) => choice.id !== '*')
      .map((choice) => choice.id);
  }

  return jsonValue;
};

export const formatRoleRecord = (data) => {
  return checkJson(data) ? JSON.parse(data) : data;
};

export const compareResources = (a, b) => {
  if (a.id === '*') {
    return -1;
  }
  if (b.id === '*') {
    return 1;
  }
  return a.name.localeCompare(b.name);
};
