import { RowForm } from '@react-admin/ra-editable-datagrid';
import React, { ReactElement } from 'react';
import { Identifier, TextField } from 'react-admin';
import SubsystemInput from '../../../common/SubsystemInput';
import commonStyles from '../../../common/commonStyles';

interface SubsystemRowFormProps {
  recordId: Identifier;
}

const SubsystemRowForm: React.FC<SubsystemRowFormProps> = ({
  recordId,
}): ReactElement => (
  <RowForm
    transform={(data) => ({
      ...data,
      drawingId: recordId,
    })}
  >
    <SubsystemInput sx={commonStyles.rowFormInput} />
    <TextField source="" sx={commonStyles.flexBoxLongItem} />
  </RowForm>
);

export default SubsystemRowForm;
