import { RowForm } from '@react-admin/ra-editable-datagrid';
import React, { ReactElement } from 'react';
import { Identifier, TextField } from 'react-admin';
import commonStyles from '../commonStyles';
import ContactInput from './ContactInput';

interface ContactRowFormProps {
  recordId: Identifier;
  field: string;
}

const ContactRowForm: React.FC<ContactRowFormProps> = ({
  recordId,
  field,
}): ReactElement => (
  <RowForm
    transform={(data) => ({
      ...data,
      [field]: recordId,
    })}
  >
    <ContactInput sx={commonStyles.rowFormInput} />
    <TextField source="" sx={commonStyles.flexBoxLongItem} />
    <TextField source="" sx={commonStyles.flexBoxLongItem} />
    <TextField source="" sx={commonStyles.flexBoxLongItem} />
  </RowForm>
);

export default ContactRowForm;
