import React, { ReactElement } from 'react';
import {
  Datagrid,
  TextField,
  ReferenceManyField,
  useRecordContext,
} from 'react-admin';
import { getContext, UserContext } from '../../../provider/userContext';
import { CustomPagination } from '../../common/CustomPagination';
import SmartReferenceField from '../../common/SmartReferenceField';
import UserProjectGroupField from '../../common/UserProjectGroupField';
import UserProjectPositionField from '../../common/UserProjectPositionField';
import UserProjectUserGroupField from '../../common/UserProjectUserGroupField';
import UserProjectRoleField from '../../common/UserProjectRoleField';
import { RESOURCE_DIVISION, RESOURCE_USER } from '../../constants';

const ProjectUsersTab: React.FC = (): ReactElement => {
  const ctx: UserContext = getContext();
  const record = useRecordContext();
  const companySource = `companies.${ctx?.company}`;
  const projectSource = `${companySource}.projects.${record.id}`;

  return (
    <ReferenceManyField
      reference={RESOURCE_USER}
      target="projectId"
      sort={{ field: 'id', order: 'ASC' }}
      perPage={10}
      pagination={<CustomPagination sx={{ width: '100%' }} />}
    >
      <Datagrid
        bulkActionButtons={false}
        rowClick={false}
        sx={{ width: '100%' }}
      >
        <SmartReferenceField
          label="Email"
          source="id"
          reference={RESOURCE_USER}
          textSource="id"
        />
        <TextField source="firstName" label="First Name" />
        <TextField source="lastName" label="Last Name" />
        <SmartReferenceField
          label="Division"
          source={`${companySource}.defaultDivisionId`}
          reference={RESOURCE_DIVISION}
          sortable={false}
          textSource="divisionName"
        />
        <UserProjectRoleField
          label="Roles"
          source={`${projectSource}.roles`}
          sortable={false}
        />
        <UserProjectGroupField
          label="Groups"
          source={`${projectSource}.groups`}
          sortable={false}
        />
        <UserProjectUserGroupField
          label="User Groups"
          source={`${projectSource}.userGroups`}
          sortable={false}
        />
        <UserProjectPositionField
          source={`${projectSource}.positionId`}
          sortable={false}
        />
      </Datagrid>
    </ReferenceManyField>
  );
};

export default ProjectUsersTab;
