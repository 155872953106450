import Box from '@mui/material/Box';
import React, { ReactElement } from 'react';
import { BooleanInput, maxLength } from 'react-admin';
import { required } from '../../utils/UtilityFunctions';
import commonStyles from '../common/commonStyles';
import CustomDateInput from '../common/CustomDateInput';
import CustomNumberInput from '../common/CustomNumberInput';
import MetaDataInputs from '../common/MetaDataInputs';
import TestEquipmentTypeInput from '../common/TestEquipmentTypeInput';
import TextInput from '../common/TextInput';
import VendorInput from '../common/VendorInput/VendorInput';
import styles from './styles';

interface TestEquipmentFormProps {
  bulkEditMode?: boolean;
  editMode?: boolean;
}

const TestEquipmentForm: React.FC<TestEquipmentFormProps> = ({
  bulkEditMode,
  editMode,
}): ReactElement => {
  const requiredValidation = bulkEditMode ? [] : [required()];
  const isRequired = !bulkEditMode;

  return (
    <Box sx={commonStyles.columnFlexBox}>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="testEquipment"
          label="Test Equipment name"
          sx={styles.flexBoxItem}
          validate={[...requiredValidation, maxLength(100)]}
        />
        <TextInput
          source="manufacturer"
          label="Manufacturer"
          sx={styles.flexBoxLongItem}
          validate={[...requiredValidation, maxLength(100)]}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="model"
          label="Model"
          sx={styles.flexBoxItem}
          validate={[...requiredValidation, maxLength(50)]}
        />
        <TextInput
          source="serialNumber"
          label="Serial Number"
          sx={styles.flexBoxItem}
          validate={[...requiredValidation, maxLength(50)]}
        />
        <BooleanInput source="rent" label="Rent" sx={styles.flexBoxItem} />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <CustomDateInput
          source="calibrationDate"
          label="Calibration Date"
          sx={styles.flexBoxItem}
          validate={requiredValidation}
          isRequired={isRequired}
        />
        <CustomDateInput
          source="calibrationExpirationDate"
          label="Calibration Expiration Date"
          sx={styles.flexBoxItem}
          validate={requiredValidation}
          isRequired={isRequired}
        />
        <TestEquipmentTypeInput
          sx={styles.flexBoxItem}
          source="testEquipmentTypeId"
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <CustomNumberInput
          source="cost"
          label="Cost"
          sx={styles.flexBoxItem}
          isPositive
        />
        <VendorInput label="Vendor" sx={styles.flexBoxItem} />
        {/* TODO uncomment when "Test Equipment Checkout Page" is ready */}
        {/*<BooleanInput*/}
        {/*  source="available"*/}
        {/*  label="Available"*/}
        {/*  sx={styles.flexBoxItem}*/}
        {/*  disabled*/}
        {/*/>*/}
      </Box>
      {editMode && <MetaDataInputs itemSx={styles.flexBoxItem} />}
    </Box>
  );
};

export default TestEquipmentForm;
