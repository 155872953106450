import { TextField, Labeled, TabbedShowLayout } from 'react-admin';
import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import renderShowMetaDataFields from '../common/helpers/renderShowMetaDataFields';
import commonStyles from '../common/commonStyles';
import AuditListTab from '../audit/AuditListTab';

const BookShow: React.FC<ShowComponentProps> = ({
  syncWithLocation,
  ...rest
}): ReactElement => {
  return (
    <ShowComponent {...rest}>
      <TabbedShowLayout
        sx={commonStyles.bottomMargin}
        syncWithLocation={syncWithLocation}
      >
        <TabbedShowLayout.Tab label="Book">
          <Box>
            <Labeled sx={commonStyles.flexBoxItem}>
              <TextField label="Title" source="title" />
            </Labeled>
            <Labeled sx={commonStyles.flexBoxLongItem}>
              <TextField label="Description" source="description" />
            </Labeled>
          </Box>
          <Box>{renderShowMetaDataFields()}</Box>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="History">
          <AuditListTab tableName="tbl_book" />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </ShowComponent>
  );
};

export default BookShow;
