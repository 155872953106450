import React, {
  Fragment,
  ReactElement,
  useCallback,
  useRef,
  useState,
} from 'react';
import { Identifier, useRecordContext } from 'react-admin';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { UpdateMenuItem } from '../types';
import PullAlphaFormButton from './PullAlphaFormButton';
import DownloadMasterCopyButton from './DownloadMasterCopyButton';
import PullJSONFormFromMasterCopyButton from './PullJSONFormFromMasterCopyButton';
import PushJSONFormToMasterCopyButton from './PushJSONFormToMasterCopyButton';
import PushJSONFormToOtherCompaniesButton from './PushJSONFormToOtherCompaniesButton';

interface UpdateJSONFormMenuProps {
  externalFormId?: string;
  outerResource?: string;
  outerResourceId?: Identifier;
  menuItems?: UpdateMenuItem[];
}

export const UpdateJSONFormMenu: React.FC<UpdateJSONFormMenuProps> = ({
  menuItems,
  externalFormId,
  outerResource,
  outerResourceId,
}): ReactElement => {
  const offset = externalFormId ? 0 : 1;
  const record = useRecordContext();
  const [menuOpen, setMenuOpen] = useState(false);
  const menuAnchorRef = useRef<HTMLDivElement>(null);
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);

  const handleMenuItemClick = useCallback(
    (_: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
      setSelectedMenuIndex(index);
      setMenuOpen(false);
    },
    []
  );

  const handleMenuToggle = useCallback(() => {
    setMenuOpen((prevOpen) => !prevOpen);
  }, []);

  const handleMenuClose = useCallback((event: Event) => {
    if (
      menuAnchorRef.current &&
      menuAnchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setMenuOpen(false);
  }, []);

  return (
    <Fragment>
      <ButtonGroup
        variant="outlined"
        ref={menuAnchorRef}
        aria-label="update revision buttons"
      >
        {selectedMenuIndex === 0 && externalFormId && (
          <PullAlphaFormButton
            id={record?.id}
            externalFormId={externalFormId}
          />
        )}
        {selectedMenuIndex === 1 - offset && (
          <DownloadMasterCopyButton
            resource={outerResource}
            resourceId={outerResourceId}
            type="json"
          />
        )}
        {selectedMenuIndex === 2 - offset && (
          <PullJSONFormFromMasterCopyButton
            resource={outerResource}
            resourceId={outerResourceId}
          />
        )}
        {selectedMenuIndex === 3 - offset && (
          <PushJSONFormToMasterCopyButton
            resource={outerResource}
            resourceId={outerResourceId}
          />
        )}
        {selectedMenuIndex === 4 - offset && (
          <PushJSONFormToOtherCompaniesButton
            resource={outerResource}
            resourceId={outerResourceId}
          />
        )}
        <Button
          style={{ borderWidth: 2, marginLeft: -2 }}
          size="small"
          aria-controls={menuOpen ? 'update-revision-menu' : undefined}
          aria-expanded={menuOpen ? 'true' : undefined}
          aria-label="select update revision method"
          aria-haspopup="menu"
          onClick={handleMenuToggle}
          variant="contained"
          sx={{ marginTop: '10px' }}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1000,
        }}
        open={menuOpen}
        anchorEl={menuAnchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleMenuClose}>
                <MenuList id="update-revision-menu" autoFocusItem>
                  {menuItems.map((option, index) => (
                    <MenuItem
                      key={option.key}
                      selected={index === selectedMenuIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

export default UpdateJSONFormMenu;
