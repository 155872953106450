import { useRecordContext } from 'react-admin';
import React, { ReactElement, useMemo } from 'react';
import commonStyles from '../../../../common/commonStyles';
import { RESOURCE_CERT_GATING_PUNCH } from '../../../../constants';
import { CertGating } from '../../../types';
import GatingFormRow from '../GatingFormRow';
import GatingForm from '../GatingForm';
import { getContext, UserContext } from '../../../../../provider/userContext';
import PunchPriorityField from './PunchPriorityField';
import PunchPriorityInput from './PunchPriorityInput';

const PunchPrioritiesForm: React.FC = (): ReactElement => {
  const record = useRecordContext<CertGating>();
  const ctx: UserContext = getContext();

  const additionalFilters = useMemo(() => {
    const selectedIds = record.tblCertGatingPunches.map(
      (punchPriority) => punchPriority.punchPriority.id
    );
    if (selectedIds.length > 0) return { id_neq_any: selectedIds };
  }, [record.tblCertGatingPunches]);

  const formRow = (
    <GatingFormRow
      recordId={record?.id}
      projectId={ctx?.projectId}
      reference={RESOURCE_CERT_GATING_PUNCH}
    >
      <PunchPriorityInput
        sx={commonStyles.rowFormInput}
        isRequired
        additionalFilters={additionalFilters}
      />
    </GatingFormRow>
  );

  return (
    <GatingForm formRow={formRow} reference={RESOURCE_CERT_GATING_PUNCH}>
      <PunchPriorityField sx={commonStyles.rowFormField} />
    </GatingForm>
  );
};

export default PunchPrioritiesForm;
