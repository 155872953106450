import React, { Fragment, useCallback, useState, ReactElement } from 'react';
import { Button, useNotify, useUpdate } from 'react-admin';
import { EditDialog } from '@react-admin/ra-form-layout';
import { ReactComponent as Edit } from '../../../../../assets/icons/functionalIcons/Edit.svg';
import {
  RESOURCE_MATERIAL,
  RESOURCE_MATERIAL_VENDOR,
} from '../../../../constants';
import { getErrorMessage } from '../../../../../utils/UtilityFunctions';
import useInvalidateRelatedResourceQueries from '../../../../common/hooks/useInvalidateRelatedResourceQueries';
import VendorTabForm from './VendorTabForm';

interface VendorTabEditProps {
  record?: Record<string, string>;
}

const VendorTabEdit: React.FC<VendorTabEditProps> = ({
  record,
}): ReactElement => {
  const [open, setOpen] = useState(false);
  const invalidateQueries =
    useInvalidateRelatedResourceQueries(RESOURCE_MATERIAL);
  const [update] = useUpdate(
    RESOURCE_MATERIAL_VENDOR,
    {},
    {
      onSuccess: () => {
        invalidateQueries().then();
      },
    }
  );
  const notify = useNotify();
  const handleOpen = useCallback(() => setOpen(true), [setOpen]);
  const handleClose = useCallback(() => setOpen(false), [setOpen]);
  const onSubmit = useCallback(
    (data) => {
      update(
        RESOURCE_MATERIAL_VENDOR,
        {
          data,
          id: record.id,
        },
        {
          onError: (error) => {
            notify('Error: ' + getErrorMessage(error), {
              type: 'error',
              undoable: false,
            });
            handleClose();
          },
          onSuccess: () => {
            notify('Vendor record was updated', {
              type: 'success',
              undoable: false,
            });
            handleClose();
          },
        }
      );
    },
    [update, notify, record.id, handleClose]
  );

  return (
    <Fragment>
      <Button label=" " onClick={handleOpen}>
        <Edit />
      </Button>
      <EditDialog
        title={<span>Edit Vendor #{record.id}</span>}
        fullWidth
        maxWidth="md"
        isOpen={open}
        open={handleOpen}
        close={handleClose}
      >
        <VendorTabForm onSubmit={onSubmit} record={record} />
      </EditDialog>
    </Fragment>
  );
};

export default VendorTabEdit;
