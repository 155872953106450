import { ValidationErrorMessage, Validator } from 'react-admin';
import dayjs from 'dayjs';
import { StatusFieldType } from './types';

export const datePickerErrors = ({
  error,
  maxDate,
  minDate,
}): ValidationErrorMessage => {
  const errors = {
    invalidDate: 'Invalid Date',
    maxDate: !!maxDate
      ? `The date must not exceed ${maxDate.format('YYYY-MM-DD')}.`
      : undefined,
    minDate: !!minDate
      ? `The date must be greater ${minDate.format('YYYY-MM-DD')}.`
      : undefined,
  };

  return errors[error];
};

export const validateMaxDate =
  (maxDate): Validator =>
  (value): ValidationErrorMessage => {
    if (dayjs(value)?.isAfter(maxDate)) {
      return datePickerErrors({ error: 'maxDate', maxDate, minDate: null });
    }
    return '';
  };

export const validateMinDate =
  (minDate): Validator =>
  (value): ValidationErrorMessage => {
    if (dayjs(value)?.isBefore(dayjs(minDate).subtract(1, 'd'))) {
      return datePickerErrors({ error: 'minDate', maxDate: null, minDate });
    }
    return '';
  };

export const mergeValidate = (validate, maxDate, minDate) => {
  const defaultValidate = [validateMaxDate(maxDate), validateMinDate(minDate)];

  if (Array.isArray(validate)) {
    defaultValidate.push(...validate);
  } else if (validate) {
    defaultValidate.push(validate);
  }

  return defaultValidate;
};

export const validateStatusFields = (fieldType: StatusFieldType): Validator => {
  return (_value, allValues): ValidationErrorMessage => {
    const {
      clearedBy,
      clearedDate,
      acceptedBy,
      acceptedDate,
      raisedBy,
      raisedDate,
      answeredBy,
      answeredDate,
      closedBy,
      closedDate,
      isVerified,
      verifiedBy,
      verifiedDate,
      verified,
    } = allValues;

    switch (fieldType) {
      case 'cleared': {
        if ((clearedBy && !clearedDate) || (!clearedBy && clearedDate)) {
          return 'Cleared By and Cleared Date fields must be filled in or empty at the same time';
        }
        return '';
      }
      case 'accepted': {
        if (isVerified && (!acceptedBy || !acceptedDate)) {
          return 'Accepted By and Accepted Date fields must be filled in if Verified is turned on';
        }
        if ((acceptedBy && !acceptedDate) || (!acceptedBy && acceptedDate)) {
          return 'Accepted By and Accepted Date fields must be filled in or empty at the same time';
        }
        return '';
      }
      case 'raised': {
        if ((raisedBy && !raisedDate) || (!raisedBy && raisedDate)) {
          return 'Raised By and Raised Date fields must be filled in or empty at the same time';
        }
        return '';
      }
      case 'answered': {
        if ((answeredBy && !answeredDate) || (!answeredBy && answeredDate)) {
          return 'Answered By and Answered Date fields must be filled in or empty at the same time';
        }
        return '';
      }
      case 'closed': {
        if ((closedBy && !closedDate) || (!closedBy && closedDate)) {
          return 'Closed By and Closed Date fields must be filled in or empty at the same time';
        }
        return '';
      }
      case 'verified': {
        if (verified && (!verifiedBy || !verifiedDate)) {
          return 'Verified By and Verified Date fields must be filled in if Verified is turned on';
        }
        if ((verifiedBy && !verifiedDate) || (!verifiedBy && verifiedDate)) {
          return 'Verified By and Verified Date fields must be filled in or empty at the same time';
        }
        return '';
      }
    }
  };
};

export const validateLessThanValue =
  (maxValue): Validator =>
  (value): ValidationErrorMessage => {
    if (value && value >= maxValue) {
      return `Must be less than ${maxValue}`;
    }
    return '';
  };
