import { RED, GREY } from '../../theme/colors';

export const legend = [
  {
    label: 'Status: None',
    color: GREY,
  },
  {
    label: 'Status: Rejected',
    color: RED,
  },
];

export const getRowStyle = (record) => {
  return {
    borderLeftColor: getRowBorderColor(record),
    borderLeftWidth: 8,
    borderLeftStyle: 'solid',
  };
};

export const getRowBorderColor = (record) => {
  switch (record?.status) {
    case 'NONE':
      return GREY;
    case 'REJECTED':
      return RED;
    default:
      return 'transparent';
  }
};
