import React, { ReactElement } from 'react';
import { SelectInput } from 'react-admin';
import { SxProps, Theme } from '@mui/material/styles';
import { required } from '../../utils/UtilityFunctions';
import { YES_NO_CHOICES } from './constants';
import { SelectInputProps } from './types';

interface YesNoInputProps extends SelectInputProps {
  source: string;
  label: string;
  isRequired?: boolean;
  defaultValue?: string;
  sx: SxProps<Theme>;
  choices?: { id: string | boolean; name: string }[];
  name?: string;
}

const YesNoInput: React.FC<YesNoInputProps> = ({
  source,
  label,
  isRequired,
  defaultValue,
  sx,
  choices,
  ...rest
}): ReactElement => (
  <SelectInput
    source={source}
    label={label}
    sx={sx}
    choices={choices}
    validate={isRequired ? [required()] : []}
    defaultValue={defaultValue}
    {...rest}
  />
);

YesNoInput.defaultProps = {
  choices: YES_NO_CHOICES,
};

export default YesNoInput;
