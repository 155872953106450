import React, {
  Fragment,
  useCallback,
  useState,
  ReactElement,
  useRef,
} from 'react';
import { Button, CreateProps } from 'react-admin';
import { CreateDialog } from '@react-admin/ra-form-layout';
import useInvalidateResourceQueryCache from '../../../common/hooks/useInvalidateResourceQueryCache';
import QrUploadForm from './QrUploadForm';

const QrUploadButton: React.FC<CreateProps> = (props): ReactElement => {
  const [open, setOpen] = useState(false);
  const abortController = useRef<AbortController>();
  const invalidator = useInvalidateResourceQueryCache();

  const handleOpen = useCallback(() => {
    abortController.current = new AbortController();
    setOpen(true);
  }, []);

  const handleClose = useCallback(async () => {
    setOpen(false);
    abortController.current.abort();
    await invalidator();
  }, [invalidator]);

  return (
    <Fragment>
      <Button label="Upload" onClick={handleOpen} />
      <CreateDialog
        {...props}
        title={<span>QR Upload</span>}
        fullWidth
        maxWidth="md"
        isOpen={open}
        open={handleOpen}
        close={handleClose}
      >
        <QrUploadForm abortController={abortController} />
      </CreateDialog>
    </Fragment>
  );
};

export default QrUploadButton;
