import React, { Fragment, ReactElement, useMemo } from 'react';
import { Badge } from '@mui/material';
import { Box } from '@mui/system';
import { Validator } from 'react-admin';
import { ensureArray } from '../../../utils/UtilityFunctions';
import Tooltip from '../../common/Tooltip';
import AutocompleteReferenceInput from '../../common/AutocompleteReferenceInput';
import commonStyles from '../../common/commonStyles';
import { RESOURCE_SECURITY_ROLE } from '../../constants';
import { getRoleGroupValidator } from '../helpers';

interface RoleInputProps {
  label: string;
  source: string;
  readOnly?: boolean;
  validate?: Validator | Validator[];
}

const RoleInput: React.FC<RoleInputProps> = ({
  label,
  source,
  readOnly,
  validate,
}): ReactElement => {
  const requestParams = useMemo(() => {
    return {
      sort: { field: 'role', order: 'ASC' as const },
      filter: { isDeleted: false },
    };
  }, []);

  const validators = useMemo(() => {
    return [getRoleGroupValidator(), ...ensureArray(validate)];
  }, [validate]);

  return (
    <Fragment>
      <AutocompleteReferenceInput
        resource={RESOURCE_SECURITY_ROLE}
        requestParams={requestParams}
        source={source}
        label={label}
        name="role_inc"
        optionText="role"
        multiple
        validate={validators}
        sx={{ ...commonStyles.flexBoxSelectItem, marginRight: 0 }}
        readOnly={readOnly}
        includeFields={['id', 'role']}
      />
      <Tooltip title="For granular access control, select one or more Roles. If you select Group(s), Roles are optional.">
        <Box sx={{ marginTop: '20px', marginRight: '20px', fontSize: 12 }}>
          <Badge
            badgeContent="i"
            color="primary"
            sx={{ paddingLeft: '12px', paddingBottom: '5px' }}
          />
        </Box>
      </Tooltip>
    </Fragment>
  );
};

export default RoleInput;
