import React, { ReactElement } from 'react';
import {
  Button,
  useDataProvider,
  useNotify,
  useRecordContext,
} from 'react-admin';
import { useMutation } from '@tanstack/react-query';
import { getErrorMessage } from '../../../utils/UtilityFunctions';
import { RESOURCE_ATTACHMENT_REVISION } from '../../constants';
import { PullAndPushButtonProps } from '../types';

const PushRevisionFileToMasterCopyButton: React.FC<PullAndPushButtonProps> = ({
  resource,
  resourceId,
}): ReactElement => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const {
    mutate: pushRevisionFileToMasterTemplate,
    isPending: pushInProgress,
  } = useMutation({
    mutationFn: async () => {
      return await dataProvider.pushRevisionFile(RESOURCE_ATTACHMENT_REVISION, {
        id: record?.id,
        resource,
        resourceId,
        isUpdateMasterCopy: true,
      });
    },
    onSuccess: async () => {
      notify('Successfully Pushed Revision File.', {
        type: 'success',
        undoable: false,
      });
    },
    onError: (error) => {
      notify('Push Revision File Error: ' + getErrorMessage(error), {
        type: 'error',
        undoable: false,
      });
    },
  });

  return (
    <Button
      label={pushInProgress ? 'Pushing...' : 'Push To Master Copy'}
      onClick={() => {
        pushRevisionFileToMasterTemplate();
      }}
      size="medium"
      variant="contained"
      sx={{ marginTop: '10px' }}
    />
  );
};

export default PushRevisionFileToMasterCopyButton;
