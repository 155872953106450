import React, { ReactElement } from 'react';
import { Box } from '@mui/material';
import { SimpleForm, ListButton, TextField, Labeled } from 'react-admin';
import CertDetailsSubsystemField from '../../certDetails/components/CertDetailsSubsystemField';
import CertDetailsSystemField from '../../certDetails/components/CertDetailsSystemField';
import { ReactComponent as CancelIcon } from '../../../assets/icons/functionalIcons/Cancel.svg';
import ActivityTextField from '../../certDetails/components/ActivityTextField';
import ActivityField from '../../certDetails/components/ActivityField';
import ActivityPhaseField from '../../common/ActivityPhaseField';
import CertTemplateField from '../../common/CertTemplateField';
import DisciplineField from '../../common/DisciplineField';
import ComponentField from '../../common/ComponentField';
import { RESOURCE_ACTIVITY_TYPE } from '../../constants';
import FooterToolbar from '../../common/FooterToolbar';
import commonStyles from '../../common/commonStyles';
import SmartTabWidthButtons from '../../common/SmartTabWidthButtons';
import JSONFormTab from './JSONFormTab';

interface SmartCertDetailsFormProps {
  onCancel?: () => void;
}

const SmartCertDetailsForm: React.FC<SmartCertDetailsFormProps> = ({
  onCancel,
}): ReactElement => (
  <SimpleForm
    toolbar={
      <FooterToolbar autoSave={true} onCancel={onCancel}>
        <ListButton label="Cancel" icon={<CancelIcon />} onClick={onCancel} />
      </FooterToolbar>
    }
  >
    <Box sx={{ ...commonStyles.columnFlexBox, padding: '0 16px 30px' }}>
      <SmartTabWidthButtons />
      <Labeled label="Certificate Template">
        <CertTemplateField />
      </Labeled>
      <Labeled label="Certificate Description">
        <TextField source="certTemplate.description" />
      </Labeled>
      <Labeled label="Activity">
        <ActivityField source="activity" label="" />
      </Labeled>
      <Labeled label="Activity Description">
        <ActivityTextField source="description" label="" />
      </Labeled>
      <Labeled label="Certificate Category">
        <TextField source="certTemplate.category" />
      </Labeled>
      <Labeled label="Tag Name">
        <ComponentField />
      </Labeled>
      <Labeled label="System Name">
        <CertDetailsSystemField />
      </Labeled>
      <Labeled label="SubSystem Name">
        <CertDetailsSubsystemField />
      </Labeled>
      <Labeled label="Discipline Name">
        <DisciplineField />
      </Labeled>
      <Labeled label="Phase">
        <ActivityPhaseField />
      </Labeled>
      <Labeled label="Activity Type">
        <ActivityTextField
          resource={RESOURCE_ACTIVITY_TYPE}
          source="activityTypeId"
          sortBy="activityType.activityType"
          label=""
        />
      </Labeled>
    </Box>
    <JSONFormTab />
  </SimpleForm>
);

export default SmartCertDetailsForm;
