import {
  RESOURCE_EQUIPMENT_TYPE_WITH_TEST,
  RESOURCE_EQUIPMENT_TYPE_WITH_TEST_EQUIPMENT_TYPE,
} from '../constants';

export const equipmentTypeChoices = ['Hard', 'Soft'];

export const EQUIPMENT_TYPE_EXPORT_BUTTONS = [
  {
    label: 'Export',
  },
  {
    label: 'Export All',
    includeAllColumns: true,
  },
  {
    label: 'Export Tests',
    fileName: 'equipment_type_tests_and_locations',
    resource: RESOURCE_EQUIPMENT_TYPE_WITH_TEST,
    columns: [
      { source: 'id', label: 'ID' },
      { source: 'equipType', label: 'Type' },
      { source: 'test', label: 'Test' },
      { source: 'location', label: 'Location' },
    ],
  },
  {
    label: 'Export Testing Equipments',
    fileName: 'equipment_type_test_equipment_types',
    resource: RESOURCE_EQUIPMENT_TYPE_WITH_TEST_EQUIPMENT_TYPE,
    columns: [
      { source: 'id', label: 'ID' },
      { source: 'equipType', label: 'Type' },
      { source: 'testEquipType', label: 'Test Equipment Type' },
    ],
  },
];

export const EQUIPMENT_TYPE_IMPORT_BUTTONS = [
  {
    label: 'Import',
  },
  {
    source: RESOURCE_EQUIPMENT_TYPE_WITH_TEST,
    label: 'Import Tests',
  },
  {
    source: RESOURCE_EQUIPMENT_TYPE_WITH_TEST_EQUIPMENT_TYPE,
    label: 'Import Testing Equipments',
  },
];
