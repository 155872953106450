import React, {
  Fragment,
  ReactElement,
  useCallback,
  useState,
  useEffect,
} from 'react';
import {
  ResourceContextProvider,
  useNotify,
  SimpleForm,
  useGetList,
  useUpdateMany,
  useListContext,
  useUnselectAll,
  Button,
  TabbedForm,
} from 'react-admin';
import { useQueryClient } from '@tanstack/react-query';
import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ContentCreate from '@mui/icons-material/Create';
import cloneDeep from 'lodash/cloneDeep';
import {
  compareObjects,
  getErrorMessage,
  removeEmptyObjects,
  removeNullObjectValues,
  truncateLongMessage,
} from '../../utils/UtilityFunctions';
import useResourceTitle from './hooks/useResourceTitle';
import useCanAccess from './hooks/useCanAccess';
import SaveOnlyToolbar from './SaveOnlyToolbar';
import FormSpy from './FormSpy';
import useInvalidateResourceQueryCache from './hooks/useInvalidateResourceQueryCache';

interface BulkEditButtonProps {
  form?: ReactElement | ReactElement[];
  resource: string;
  label?: string;
  icon?: ReactElement;
  tabs?: ReactElement[] | ReactElement;
}

const BulkEditButton: React.FC<BulkEditButtonProps> = ({
  form,
  label = 'Bulk Edit',
  resource,
  tabs,
  icon = <ContentCreate />,
}): ReactElement => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  const [updateMany] = useUpdateMany();
  const canAccessList = useCanAccess();
  const { selectedIds } = useListContext();
  const unselectAll = useUnselectAll(resource);
  const [record, setRecord] = useState({ id: null });
  const title = useResourceTitle(true);
  const [isOpen, setIsOpen] = useState(false);
  const [touchedFields, setTouchedFields] = useState({});
  const invalidateQueries = useInvalidateResourceQueryCache(resource, true);

  const handleChange = useCallback(
    (fields: object) => {
      setTouchedFields({ ...fields });
    },
    [setTouchedFields]
  );

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const deleteUnchangedValues = useCallback(
    (changedValues: object, values: object) => {
      Object.keys(values || {}).map((key) => {
        if (!Object.keys(changedValues).includes(key)) {
          delete values[key];
          return;
        }
        if (typeof values[key] === 'object')
          deleteUnchangedValues(changedValues[key], values[key]);
      });
    },
    []
  );

  const handleSubmit = useCallback(
    async (data) => {
      // We should send only touched fields
      deleteUnchangedValues(touchedFields, data);
      await updateMany(
        resource,
        {
          ids: selectedIds,
          data,
        },
        {
          onSuccess: async () => {
            await queryClient.removeQueries({
              queryKey: [resource],
              exact: false,
            });

            await invalidateQueries();

            notify(`${selectedIds.length} elements updated`, {
              type: 'success',
            });
            unselectAll();
            setIsOpen(false);
          },
          onError: (error) => {
            notify(getErrorMessage(error), {
              type: 'error',
              undoable: false,
            });
          },
        }
      );
    },
    [
      deleteUnchangedValues,
      touchedFields,
      updateMany,
      resource,
      selectedIds,
      queryClient,
      invalidateQueries,
      notify,
      unselectAll,
    ]
  );

  const { data: records = [] } = useGetList(resource, {
    filter: { id: selectedIds },
  });

  useEffect(() => {
    // Get one common record only with the same values for all records
    if (records.length) {
      const result = records.reduce((acc, curr) => {
        return compareObjects(cloneDeep(acc), cloneDeep(curr));
      }, {});
      removeNullObjectValues(result);
      removeEmptyObjects(result);
      setRecord(result);
    }
  }, [records]);

  const formProps = {
    id: 'bulk_edit_form',
    onSubmit: handleSubmit,
    record: record,
    defaultValues: record,
    toolbar: <SaveOnlyToolbar />,
    noValidate: true,
  };

  if (!canAccessList.edit) return;

  return (
    <ResourceContextProvider value={resource}>
      <Fragment>
        <Button
          disabled={selectedIds.length < 2}
          label={label}
          onClick={handleOpen}
        >
          {icon}
        </Button>
        {/* Check isOpen before rendering Dialog component to avoid infinite re-renders. */}
        {isOpen && (
          <Dialog fullWidth maxWidth="xl" open={isOpen} onClose={handleClose}>
            <DialogTitle>
              {`Bulk Edit ${selectedIds.length} ${title} (ids: ${truncateLongMessage(selectedIds.sort((a, b) => a - b).join(', '), 53)})`}
            </DialogTitle>
            <IconButton
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>

            <DialogContent>
              {tabs && (
                <TabbedForm {...formProps} syncWithLocation={false}>
                  {tabs}
                  <FormSpy handleChange={handleChange} />
                </TabbedForm>
              )}
              {form && (
                <SimpleForm {...formProps}>
                  {form}
                  <FormSpy handleChange={handleChange} />
                </SimpleForm>
              )}
            </DialogContent>
          </Dialog>
        )}
      </Fragment>
    </ResourceContextProvider>
  );
};

export default BulkEditButton;
