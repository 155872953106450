import React, { Fragment, ReactElement } from 'react';
import useResourceTitle from './hooks/useResourceTitle';
import { setPageTitle } from './helpers/setPageTitle';

interface CreateTitleProps {
  title?: string;
}

const CreateTitle: React.FC<CreateTitleProps> = ({ title }): ReactElement => {
  const resourceTitle = useResourceTitle();
  const pageTitle = title || `${resourceTitle} Create`;
  setPageTitle(pageTitle);

  return <Fragment>{pageTitle}</Fragment>;
};

export default CreateTitle;
