import Box from '@mui/material/Box';
import { maxLength } from 'react-admin';
import React, { Fragment, ReactElement } from 'react';
import commonStyles from '../common/commonStyles';
import GroupInput from '../common/GroupInput';
import DrawingTypeInput from '../common/DrawingTypeInput';
import LocationInput from '../common/LocationInput';
import TextInput from '../common/TextInput';
import { required } from '../../utils/UtilityFunctions';
import DrawingDisciplineInput from './components/DrawingDisciplineInput';

interface DrawingFormProps {
  bulkEditMode?: boolean;
}

const DrawingForm: React.FC<DrawingFormProps> = ({
  bulkEditMode,
}): ReactElement => {
  const requiredValidation = bulkEditMode ? [] : [required()];

  return (
    <Fragment>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="drawingName"
          validate={[...requiredValidation, maxLength(150)]}
          label="Drawing Name"
          sx={commonStyles.flexBoxLongItem}
          disabled={bulkEditMode}
        />
        <TextInput
          source="description"
          validate={[maxLength(150)]}
          label="Description"
          sx={commonStyles.flexBoxLongItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="sheet"
          validate={[maxLength(10)]}
          label="Sheet"
          sx={commonStyles.flexBoxItem}
        />
        <GroupInput
          category={['DRAWING_ENGINEERING_GRP']}
          sx={commonStyles.flexBoxItem}
        />
        <DrawingTypeInput sx={commonStyles.flexBoxItem} />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <DrawingDisciplineInput sx={commonStyles.flexBoxItem} />
        <LocationInput sx={commonStyles.flexBoxItem} />
      </Box>
    </Fragment>
  );
};

export default DrawingForm;
