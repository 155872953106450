import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import ColumnActions from '../common/ColumnActions';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import ListWithTitle from '../common/ListWithTitle';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import ListActions from '../common/ListActions';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import DrawingTypeShow from './DrawingTypeShow';
import DrawingTypeEdit from './DrawingTypeEdit';

const DrawingTypeList: React.FC = (): ReactElement => {
  const defaultFilter = useDefaultFilter('project');

  return (
    <ListWithTitle
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={<ListActions />}
      sort={{ field: 'drawingType', order: 'ASC' }}
    >
      <DatagridConfigurableRbac
        ShowComponent={<DrawingTypeShow withoutActions />}
        EditComponent={<DrawingTypeEdit syncWithLocation={false} />}
        defaultVisibleColumns={['id', 'drawingType', 'description']}
      >
        <TextField source="id" label="ID" />
        <TextField source="drawingType" label="Type" />
        <TextField source="description" label="Description" />
        {renderMetaDataFields()}
        <ColumnActions label="Actions" />
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default DrawingTypeList;
