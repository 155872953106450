import { AuthProvider } from 'react-admin';
import { environment } from '../api';
import {
  AuthProviderExt,
  CognitoAuthProvider,
} from './providers/cognitoAuthProvider';

export let authProvider: AuthProviderExt | AuthProvider = undefined;

export const getAuthProvider = (
  getForceCognitoProvider?: boolean
): AuthProviderExt | AuthProvider => {
  if (getForceCognitoProvider) {
    return getCognitoAuthProvider();
  }

  if (!authProvider) {
    authProvider = getCognitoAuthProvider();
  }

  return authProvider;
};

const getCognitoAuthProvider = (): AuthProviderExt | AuthProvider => {
  let cognitoAuthProvider: AuthProviderExt | AuthProvider = undefined;
  switch (environment) {
    case 'local':
      cognitoAuthProvider = CognitoAuthProvider({
        mode: 'oauth',
        clientId: '2136aqaav200lkes9lro9jlsnd',
        userPoolId: 'eu-west-1_RYSpkn9SP',
        hostedUIUrl: 'https://auth-v2.tracker-check-dev.trackersuite.co',
        cognitoDomain: 'https://rev1-dev-v2.auth.eu-west-1.amazoncognito.com',
        redirect_uri: 'http://localhost:3000/auth-callback',
        scope: ['openid', 'email', 'profile', 'aws.cognito.signin.user.admin'],
      });
      break;
    case 'dev':
      cognitoAuthProvider = CognitoAuthProvider({
        mode: 'oauth',
        clientId: '2136aqaav200lkes9lro9jlsnd',
        userPoolId: 'eu-west-1_RYSpkn9SP',
        hostedUIUrl: 'https://auth-v2.tracker-check-dev.trackersuite.co',
        cognitoDomain: 'https://rev1-dev-v2.auth.eu-west-1.amazoncognito.com',
        redirect_uri: 'https://tracker-check-dev.trackersuite.co/auth-callback',
        scope: ['openid', 'email', 'profile', 'aws.cognito.signin.user.admin'],
      });
      break;
    case 'prod':
      cognitoAuthProvider = CognitoAuthProvider({
        mode: 'oauth',
        clientId: '7gmdfh2ue1l1sjclk1610c0fi7',
        userPoolId: 'us-east-2_UUvS1i0cM',
        hostedUIUrl: 'https://auth-v2.tracker-check.trackersuite.co',
        cognitoDomain: 'https://rev1-v2.auth.us-east-2.amazoncognito.com',
        redirect_uri: 'https://tracker-check.trackersuite.co/auth-callback',
        scope: ['openid', 'email', 'profile', 'aws.cognito.signin.user.admin'],
      });
      break;
    default:
      throw `Unknown environment:${environment}`;
  }

  return cognitoAuthProvider;
};
