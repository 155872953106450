import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import ColumnActions from '../common/ColumnActions';
import ListWithTitle from '../common/ListWithTitle';
import { RESOURCE_LUCID_DIAGRAM } from '../constants';
import CustomBooleanField from '../common/CustomBooleanField';
import ListActions from '../common/ListActions';

const LucidDiagramList: React.FC = (): ReactElement => {
  const defaultFilter = useDefaultFilter('project');

  return (
    <ListWithTitle
      resource={RESOURCE_LUCID_DIAGRAM}
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={<ListActions showAction={{ import: false, export: false }} />}
      sort={{ field: 'id', order: 'ASC' }}
    >
      <DatagridConfigurableRbac
        defaultVisibleColumns={['id', 'title', 'isForDashboard']}
      >
        <TextField source="id" label="ID" />
        <TextField source="title" label="Title" />
        <CustomBooleanField
          source="isForDashboard"
          label="Display on Dashboard"
        />
        {renderMetaDataFields()}
        <ColumnActions label="Actions" />
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default LucidDiagramList;
