import { DateField, Labeled, SimpleShowLayout, TextField } from 'react-admin';
import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import commonStyles from '../common/commonStyles';
import renderShowMetaDataFields from '../common/helpers/renderShowMetaDataFields';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import ComponentField from '../common/ComponentField';
import TestField from '../common/TestField';
import LocationField from '../common/LocationField';
import { useDictionaryLabelsWithResource } from '../../utils/UtilityFunctions';

const PreservationItemShow: React.FC<ShowComponentProps> = (
  props
): ReactElement => {
  const { labels } = useDictionaryLabelsWithResource();

  return (
    <ShowComponent {...props}>
      <SimpleShowLayout sx={commonStyles.bottomMargin}>
        <Box>
          <Labeled
            label={labels['preservationItem.component.id'] || 'Tag'}
            sx={commonStyles.flexBoxItem}
          >
            <ComponentField
              source="preservationItem.component.id"
              textSource="preservationItem.component.tag"
            />
          </Labeled>
          <Labeled
            label={labels['preservationItem.test.id'] || 'Test'}
            sx={commonStyles.flexBoxItem}
          >
            <TestField
              source="preservationItem.test.id"
              textSource="preservationItem.test.testFormNo"
            />
          </Labeled>
          <Labeled
            label={labels['preservationItem.sequence'] || 'Sequence'}
            sx={commonStyles.flexBoxItem}
          >
            <TextField source="preservationItem.sequence"></TextField>
          </Labeled>
          <Labeled
            label={labels['preservationItem.location.id'] || 'Location'}
            sx={commonStyles.flexBoxItem}
          >
            <LocationField
              source="preservationItem.location.id"
              textSource="preservationItem.location.location"
            />
          </Labeled>
          <Labeled
            label={labels['dueDate'] || 'Due Date'}
            sx={commonStyles.flexBoxItem}
          >
            <DateField source="dueDate" />
          </Labeled>
          <Labeled
            label={labels['lateDate'] || 'Late Date'}
            sx={commonStyles.flexBoxItem}
          >
            <DateField source="lateDate" />
          </Labeled>
        </Box>
        <Box>{renderShowMetaDataFields()}</Box>
      </SimpleShowLayout>
    </ShowComponent>
  );
};

export default PreservationItemShow;
