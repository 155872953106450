import { Identifier } from 'react-admin';
import get from 'lodash/get';
import { getContext, UserContext } from '../../../provider/userContext';

const isProjectCurrentProject = (projectId: Identifier): boolean => {
  const ctx: UserContext = getContext();
  const userCurrentProjectId = get(ctx, 'projectId', null);

  return userCurrentProjectId === projectId;
};

export default isProjectCurrentProject;
