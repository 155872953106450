import React, { ReactElement, useMemo } from 'react';
import { RESOURCE_PRESERVATION_ITEM } from '../constants';
import { getContext, UserContext } from '../../provider/userContext';
import { required } from '../../utils/UtilityFunctions';
import AutocompleteReferenceInput from './AutocompleteReferenceInput';

interface PreservationItemInputProps {
  isRequired?: boolean;
  alwaysOn?: boolean;
  multiple?: boolean;
  sx?: object;
  disabled?: boolean;
  source?: string;
  label?: string;
}

const PreservationItemInput: React.FC<PreservationItemInputProps> = ({
  isRequired,
  label,
  ...rest
}): ReactElement => {
  const ctx: UserContext = getContext();
  const requestParams = useMemo(() => {
    return {
      filter: {
        projectId: ctx?.projectId,
      },
      sort: { field: 'id', order: 'ASC' as const },
    };
  }, [ctx?.projectId]);

  const optionRenderer = (choice) => `${choice.id}`;

  return (
    <AutocompleteReferenceInput
      {...rest}
      resource={RESOURCE_PRESERVATION_ITEM}
      requestParams={requestParams}
      label={label}
      name="id"
      optionText={optionRenderer}
      validate={isRequired ? [required()] : []}
      includeFields={['id']}
    />
  );
};

PreservationItemInput.defaultProps = {
  source: 'preservationItemId',
  label: 'Preservation Item',
};

export default PreservationItemInput;
