import { Card, CardContent } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import React, { ReactElement, useEffect } from 'react';
import { Button, Filter, NullableBooleanInput } from 'react-admin';
import CustomNumberInput from '../../common/CustomNumberInput';
import DateRangeFilter from '../../common/DateRangeFilter';
import useSidebarFilters from '../../common/hooks/useSidebarFilters';
import sideFilterStyles from '../../common/sideFilterStyles';
import TestEquipmentTypeInput from '../../common/TestEquipmentTypeInput';
import TextInput from '../../common/TextInput';
import { SideFilter } from '../../common/types';
import VendorInput from '../../common/VendorInput/VendorInput';
import { RESOURCE_TEST_EQUIPMENT } from '../../constants';

const TestEquipmentSidebarFilters: React.FC<SideFilter> = ({
  defaultFilter,
}): ReactElement => {
  const {
    data,
    isLoading,
    filterValues,
    setFilters,
    handleClearFilters,
    dateFrom,
    dateTo,
    handleDateFromChange,
    handleDateToChange,
  } = useSidebarFilters(RESOURCE_TEST_EQUIPMENT, defaultFilter);

  useEffect(() => {
    if (filterValues) {
      setFilters({ ...defaultFilter, ...filterValues }, []);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(filterValues), setFilters, JSON.stringify(defaultFilter)]);

  if (!data || (!data.length && isEmpty(filterValues)) || isLoading) {
    return null;
  }

  return (
    <Card sx={sideFilterStyles.sideFiltersCard} style={{ minWidth: 310 }}>
      <CardContent sx={sideFilterStyles.sideFiltersCardContainer}>
        <Filter>
          <TextInput
            source="testEquipment"
            label="Test Equipment"
            name="testEquipment_inc"
            alwaysOn
            fullWidth
          />
          <TextInput
            source="manufacturer"
            label="Manufacturer"
            name="manufacturer_inc"
            alwaysOn
            fullWidth
          />
          <TextInput
            source="model"
            label="Model"
            name="model_inc"
            alwaysOn
            fullWidth
          />
          <TextInput
            source="serialNumber"
            label="Serial Number"
            name="serialNumber_inc"
            alwaysOn
            fullWidth
          />
          <DateRangeFilter
            source="calibrationDate"
            label="Calibration Date"
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <DateRangeFilter
            source="calibrationExpirationDate"
            label="Calibration Expiration Date"
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <NullableBooleanInput
            source="rent"
            label="Rent"
            name="rent"
            trueLabel="True"
            falseLabel="False"
            nullLabel="None"
            alwaysOn
            fullWidth
          />
          <CustomNumberInput
            source="cost"
            label="Cost"
            alwaysOn
            fullWidth
            isPositive
          />
          <VendorInput source="vendorId" label="Vendor" alwaysOn multiple />
          <TestEquipmentTypeInput
            source="testEquipmentTypeId"
            alwaysOn
            multiple
          />
        </Filter>
        <Button label="Clear filters" onClick={handleClearFilters} />
      </CardContent>
    </Card>
  );
};

export default TestEquipmentSidebarFilters;
