import React, { ReactElement } from 'react';
import { Button, Filter, NullableBooleanInput } from 'react-admin';
import { Card, CardContent } from '@mui/material';
import { isEmpty } from 'lodash';
import useSidebarFilters from '../../common/hooks/useSidebarFilters';
import AssignedInput from '../../common/Assigned/AssignedInput';
import ContactInput from '../../common/contactInput/ContactInput';
import VendorInput from '../../common/VendorInput/VendorInput';
import { ContactCategories } from '../../common/contactInput/constants';
import TestFormNoInput from '../../common/TestFormNoInput';
import DisciplineTestInput from '../../common/DisciplineTestInput';
import SubsystemInput from '../../common/SubsystemInput';
import EquipmentTypeInput from '../../common/EquipmentTypeInput';
import WorkPackInput from '../../common/WorkPackInput';
import TestTypeInput from '../../common/TestTypeInput';
import ComponentInput from '../../common/ComponentInput';
import TextInput from '../../common/TextInput';
import LocationInput from '../../common/LocationInput';
import AutocompleteInput from '../../common/AutocompleteInput';
import CustomDateInput from '../../common/CustomDateInput';
import DisciplineInput from '../../common/DisciplineInput';
import DateRangeFilter from '../../common/DateRangeFilter';
import sideFilterStyles from '../../common/sideFilterStyles';
import { SideFilter } from '../../common/types';
import { RESOURCE_COMPONENT_TEST } from '../../constants';
import { mobileTestStatuses, statuses } from '../constants';
import MobileTestStatusInput from './MobileTestStatusInput';
import PhaseSearchInput from './PhaseSearchInput';

const ComponentTestSidebarFilters: React.FC<SideFilter> = ({
  defaultFilter,
}): ReactElement => {
  const {
    data,
    isLoading,
    filterValues,
    dateFrom,
    dateTo,
    handleDateFromChange,
    handleDateToChange,
    handleClearFilters,
  } = useSidebarFilters(RESOURCE_COMPONENT_TEST, defaultFilter);

  if (!data || (!data.length && isEmpty(filterValues)) || isLoading) {
    return null;
  }

  return (
    <Card sx={sideFilterStyles.sideFiltersCard}>
      <CardContent sx={sideFilterStyles.sideFiltersCardContainer}>
        <Filter>
          <ComponentInput
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <TestFormNoInput
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <LocationInput
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <EquipmentTypeInput
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
            source="component->equipTypeId"
          />
          <NullableBooleanInput
            label="Verified"
            name="isVerified"
            source="isVerified"
            trueLabel="True"
            falseLabel="False"
            nullLabel="None"
            fullWidth
            alwaysOn
          />
          <AutocompleteInput
            label="Status"
            source="status"
            fullWidth
            alwaysOn
            sx={sideFilterStyles.sideFiltersSelectInput}
            choices={statuses}
          />
          <ContactInput
            category={ContactCategories.itrEmpWorkGrp}
            label="Completed By"
            source="completedBy_inc"
            alwaysOn
            canCreate={false}
          />
          <CustomDateInput
            source="completedDateTime"
            label="Completed Test Date"
            name="completedDateTime_inc"
            alwaysOn
          />
          <NullableBooleanInput
            label="Retest"
            name="retest"
            source="retest"
            trueLabel="True"
            falseLabel="False"
            nullLabel="None"
            fullWidth
            alwaysOn
          />
          <TextInput
            source="retestReason"
            label="Retest Reason"
            name="retestReason_inc"
            alwaysOn
          />
          <TextInput
            source="reviewComments"
            label="Review Comments"
            name="reviewComments_inc"
            alwaysOn
          />
          <NullableBooleanInput
            label="Printed"
            name="isPrinted"
            source="isPrinted"
            trueLabel="True"
            falseLabel="False"
            nullLabel="None"
            fullWidth
            alwaysOn
          />
          <AssignedInput
            label="Responsible"
            category={ContactCategories.itrEmpWorkGrp}
            source="responsible"
            alwaysOn
          />
          <VendorInput source="responsibleVendorId" alwaysOn multiple />
          <CustomDateInput
            source="printedDate"
            label="Printed Date"
            name="printedDate_inc"
            alwaysOn
          />
          <NullableBooleanInput
            label="Mobile"
            name="isMobile"
            source="isMobile"
            trueLabel="True"
            falseLabel="False"
            nullLabel="None"
            fullWidth
            alwaysOn
          />
          <NullableBooleanInput
            source="hasAttachment"
            label="Has Attachment"
            name="hasAttachment"
            trueLabel="True"
            falseLabel="False"
            nullLabel="None"
            fullWidth
            alwaysOn
          />
          <MobileTestStatusInput
            label="Mobile Test Status"
            source="mobileTestStatus"
            fullWidth
            alwaysOn
            sx={sideFilterStyles.sideFiltersSelectInput}
            choices={mobileTestStatuses}
          />
          <DisciplineTestInput
            label="Test Discipline"
            source="test->disciplineTestId"
            sx={sideFilterStyles.sideFiltersSelectInput}
            alwaysOn
            multiple
          />
          <DisciplineInput
            label="Tag Discipline"
            source="component->disciplineId"
            sx={sideFilterStyles.sideFiltersSelectInput}
            alwaysOn
            multiple
          />
          <WorkPackInput
            source="component->workPackId"
            sx={sideFilterStyles.sideFiltersSelectInput}
            alwaysOn
            multiple
          />
          <TestTypeInput
            source="test->testTypeId"
            sx={sideFilterStyles.sideFiltersSelectInput}
            alwaysOn
            multiple
          />
          <SubsystemInput
            source="component->subsystemId"
            sx={sideFilterStyles.sideFiltersSelectInput}
            alwaysOn
            multiple
          />
          <PhaseSearchInput
            label="Subsystem Phase"
            source="component->subsystem->phase"
            sx={sideFilterStyles.sideFiltersSelectInput}
            alwaysOn
            multiple
          />
          <DateRangeFilter
            label="Updated"
            source="updatedAt"
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
          />
          <TextInput
            label="Updated By"
            name="updatedBy_inc"
            source="updatedBy"
            fullWidth
            alwaysOn
          />
          <TextInput
            label="Updated By Name"
            name="updatedByName_inc"
            source="updatedByName"
            fullWidth
            alwaysOn
          />
        </Filter>
        <Button
          label="Clear filters"
          onClick={handleClearFilters}
          id="clearFilterButton"
        />
      </CardContent>
    </Card>
  );
};

export default ComponentTestSidebarFilters;
