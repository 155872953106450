import React, { ReactElement, useState, useCallback } from 'react';
import { Edit, ReferenceManyField, useGetOne } from 'react-admin';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import PendingPunchCreateInDialog from '../pendingPunch/components/PendingPunchCreateInDialog';
import sideFilterStyles from '../common/sideFilterStyles';
import { EditPropsWithLocation } from '../common/types';
import { Originator } from '../submitPunch/constants';
import EditTitle from '../common/EditTitle';
import { RESOURCE_PENDING_PUNCH, RESOURCE_COMPONENT_TEST } from '../constants';
import SmartTagITREditTabs from './components/SmartTagITREditTabs';

interface SmartTagITREditProps extends EditPropsWithLocation {
  onCancel?: () => void;
}

const SmartTagITREdit: React.FC<SmartTagITREditProps> = (
  props
): ReactElement => {
  const { id } = useParams();
  const { onCancel } = props;
  const [pendingPunchTitle, setPendingPunchTitle] = useState(null);
  const [
    pendingPunchCreateDialogCloseDisabled,
    setPendingPunchCreateDialogCloseDisabled,
  ] = useState(false);
  // Use RESOURCE_COMPONENT_TEST for avoiding reloading on autosave
  const { data: record } = useGetOne(RESOURCE_COMPONENT_TEST, {
    id: props?.id ?? id,
  });

  const handleClose = useCallback(() => {
    setPendingPunchTitle(null);
  }, []);

  return (
    <Box sx={sideFilterStyles.sideEditCard} id="aside">
      <Edit
        {...props}
        mutationMode="pessimistic"
        actions={false}
        title={<EditTitle />}
      >
        <SmartTagITREditTabs
          onCancel={onCancel}
          setPendingPunchCreateDialogCloseDisabled={
            setPendingPunchCreateDialogCloseDisabled
          }
          setPendingPunchTitle={setPendingPunchTitle}
        />
      </Edit>
      <ReferenceManyField
        reference={RESOURCE_PENDING_PUNCH}
        target="componentTestId"
        perPage={100}
        fullWidth={true}
      >
        <PendingPunchCreateInDialog
          record={record}
          showButton={false}
          pendingPunchDescription={pendingPunchTitle}
          disableClose={pendingPunchCreateDialogCloseDisabled}
          onClose={handleClose}
          originator={Originator.ITR}
        />
      </ReferenceManyField>
    </Box>
  );
};

export default SmartTagITREdit;
