import { RowForm } from '@react-admin/ra-editable-datagrid';
import { NumberInput } from 'react-admin';
import { ReactElement } from 'react';
import commonStyles from '../../common/commonStyles';
import { SelectSertInput } from './SelectSertInput';

export const EditForm: React.FC = (): ReactElement => {
  return (
    <RowForm submitOnEnter={false}>
      <SelectSertInput />
      <NumberInput source="sortOrder" sx={commonStyles.flexBoxItem} />
    </RowForm>
  );
};
