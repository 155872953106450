import React, { Fragment, ReactElement } from 'react';
import { Box } from '@mui/material';
import { maxValue, minValue, NumberInput, required } from 'react-admin';
import commonStyles from '../../common/commonStyles';
import PresAutomationLegend from './PresAutomationLegend';
import PresAutomationInput from './PresAutomationInput';

const ProjectFormPresAutomationTab: React.FC = (): ReactElement => (
  <Fragment>
    <Box sx={commonStyles.flexBox}>
      <PresAutomationInput />
      <NumberInput
        source="presSchedulerIntervalDayBefore"
        label="Preservation Release - Days Before"
        validate={[required(), minValue(1), maxValue(999)]}
        sx={commonStyles.flexBoxItem}
      />
      <NumberInput
        source="presSchedulerIntervalDayAhead"
        label="Preservation Release - Days Ahead"
        validate={[required(), minValue(1), maxValue(999)]}
        sx={commonStyles.flexBoxItem}
      />
    </Box>
    <PresAutomationLegend />
  </Fragment>
);

export default ProjectFormPresAutomationTab;
