import React, { ReactElement } from 'react';
import AttachmentFormTab from '../../common/AttachmentFormTab';
import { EditPropsWithLocation } from '../../common/types';
import MetaDataInputs from '../../common/MetaDataInputs';
import TabbedEditForm from '../../common/TabbedEditForm';
import commonStyles from '../../common/commonStyles';
import LazyFormTab from '../../common/LazyFormTab';
import HistoryTab from '../../common/HistoryTab';
import { RESOURCE_MATERIAL_RELEASED } from '../../constants';
import MaterialReleasedForm from './components/MaterialReleasedForm';
import MRMaterialStoragesForm from './components/MRMaterialStoragesForm';
import { MaterialReleased } from './types';
import '../styles.css';

const MaterialReleasedEdit: React.FC<
  EditPropsWithLocation<MaterialReleased>
> = (props): ReactElement => (
  <TabbedEditForm {...props}>
    <LazyFormTab
      label="Released Materials"
      id="material_released_tab"
      sx={commonStyles.formTabHeader}
    >
      <MaterialReleasedForm />
      <MetaDataInputs />
    </LazyFormTab>
    <LazyFormTab
      label="Released Order Form"
      id="released_order_form-tab"
      contentClassName="materials-table"
      sx={commonStyles.formTabHeader}
    >
      <MRMaterialStoragesForm />
    </LazyFormTab>
    <LazyFormTab
      label="Attachment"
      id="attachments_tab"
      sx={commonStyles.formTabHeader}
    >
      <AttachmentFormTab
        targetId="materialReleasedId"
        resourceName={RESOURCE_MATERIAL_RELEASED}
      />
    </LazyFormTab>
    <HistoryTab tableName="tbl_material_released" />
  </TabbedEditForm>
);

export default MaterialReleasedEdit;
