import { RowForm } from '@react-admin/ra-editable-datagrid';
import React, { ReactElement } from 'react';
import { maxLength, TextField } from 'react-admin';
import { useDictionaryLabelsWithResource } from '../../../../../utils/UtilityFunctions';
import { ContactCategories } from '../../../../common/contactInput/constants';
import ContactInput from '../../../../common/contactInput/ContactInput';
import CustomDateInput from '../../../../common/CustomDateInput';
import commonStyles from '../../../../common/commonStyles';
import { validateStatusFields } from '../../../../common/validators';
import { FormRowProps } from '../../../types';
import FileNameField from '../components/FileNameField';
import VerifiedInput from './VerifiedInput';

const CertFormRow: React.FC<FormRowProps> = ({ data }): ReactElement => {
  const { labels } = useDictionaryLabelsWithResource();

  return (
    <RowForm>
      <FileNameField data={data} />
      <TextField
        source="certTemplate.certName"
        label={labels['certName'] || 'Certificate Name'}
      />
      <ContactInput
        category={ContactCategories.punchSignOff}
        source="acceptedBy"
        label={labels['acceptedBy'] || 'Accepted By'}
        validate={[validateStatusFields('accepted'), maxLength(75)]}
        sx={commonStyles.rowFormInput}
      />
      <CustomDateInput
        source="acceptedDate"
        label={labels['acceptedDate'] || 'Accepted Date'}
        validate={validateStatusFields('accepted')}
        sx={commonStyles.rowFormInput}
      />
      <VerifiedInput />
    </RowForm>
  );
};

export default CertFormRow;
