import React from 'react';
import { BooleanInput, SimpleForm } from 'react-admin';
import { EditInDialogButton } from '@react-admin/ra-form-layout';
import Box from '@mui/material/Box';
import SaveOnlyToolbar from '../common/SaveOnlyToolbar';
import commonStyles from '../common/commonStyles';
import { required } from '../../utils/UtilityFunctions';
import CustomMarkdownInput from '../common/CustomMarkdownInput';
import UserGroupsInput from './components/UserGroupsInput';
import UsersInput from './components/UsersInput';

const CommentEdit: React.FC = () => (
  <EditInDialogButton
    fullWidth
    maxWidth="md"
    label={''}
    mutationMode="pessimistic"
    ButtonProps={{ className: 'editButton' }}
  >
    <SimpleForm id="edit_comment_form" toolbar={<SaveOnlyToolbar />}>
      <CustomMarkdownInput
        label={false}
        source="comment"
        name="comment"
        validate={[required()]}
      />
      <Box sx={{ ...commonStyles.flexBox, ...commonStyles.fullWidth }}>
        <UserGroupsInput
          source="userGroupIds"
          label="Notify user groups"
          sx={commonStyles.halfWidth}
        />
        <UsersInput
          source="userIds"
          label="Notify users"
          sx={commonStyles.halfWidth}
        />
      </Box>
      <BooleanInput
        source="isIncludeInReport"
        label="Show in report?"
        name="isIncludeInReport"
      />
    </SimpleForm>
  </EditInDialogButton>
);

export default CommentEdit;
