import React, { ReactElement } from 'react';
import { RESOURCE_COMPONENT_ACTIVITY } from '../../constants';
import useCanAccess from '../../common/hooks/useCanAccess';
import BulkEditButton from '../../common/BulkEditButton';
import ComponentActivityForm from './ComponentActivityForm';

const BulkActionButtons: React.FC = (): ReactElement => {
  const canAccessList = useCanAccess();

  if (!canAccessList.edit) return;

  return (
    <BulkEditButton
      resource={RESOURCE_COMPONENT_ACTIVITY}
      form={<ComponentActivityForm bulkEditMode />}
    />
  );
};

export default BulkActionButtons;
