import { Box } from '@mui/material';
import React, { Fragment, ReactElement } from 'react';
import { maxLength } from 'react-admin';
import CustomDateInput from '../common/CustomDateInput';
import MetaDataInputs from '../common/MetaDataInputs';
import commonStyles from '../common/commonStyles';
import { validateStatusFields } from '../common/validators';
import TextInput from '../common/TextInput';
import { required } from '../../utils/UtilityFunctions';
import ParentMocInput from './components/ParentMocInput';
import MocTypeInput from './components/MocTypeInput';

interface MocFormProps {
  editMode?: boolean;
}

const MocForm: React.FC<MocFormProps> = ({ editMode }): ReactElement => (
  <Fragment>
    <Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="mocNo"
          label="MoC"
          validate={[required(), maxLength(50)]}
          sx={commonStyles.flexBoxItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <ParentMocInput sx={commonStyles.flexBoxSelectItem} />
        <MocTypeInput sx={commonStyles.flexBoxSelectItem} />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="title"
          label="Title"
          sx={commonStyles.flexBoxLongItem}
          validate={maxLength(255)}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="description"
          label="Description"
          sx={commonStyles.flexBoxLongItem}
          fullWidth
          multiline
        />
      </Box>
      <TextInput
        source="details"
        label="Details"
        sx={commonStyles.flexBoxLongItem}
        fullWidth
        multiline
      />
      <Box>
        <TextInput
          source="raisedBy"
          label="Raised By"
          sx={commonStyles.flexBoxItem}
          validate={[validateStatusFields('raised'), maxLength(75)]}
        />
        <CustomDateInput
          source="raisedDate"
          label="Raised Date"
          sx={commonStyles.flexBoxItem}
          validate={validateStatusFields('raised')}
        />
      </Box>
      <Box>
        <TextInput
          source="answeredBy"
          label="Answered By"
          sx={commonStyles.flexBoxItem}
          validate={[validateStatusFields('answered'), maxLength(75)]}
        />
        <CustomDateInput
          source="answeredDate"
          label="Answered Date"
          sx={commonStyles.flexBoxItem}
          validate={validateStatusFields('answered')}
        />
      </Box>
      <Box>
        <TextInput
          source="closedBy"
          label="Closed By"
          sx={commonStyles.flexBoxItem}
          validate={[validateStatusFields('closed'), maxLength(75)]}
        />
        <CustomDateInput
          source="closedDate"
          label="Closed Date"
          sx={commonStyles.flexBoxItem}
          validate={validateStatusFields('closed')}
        />
      </Box>
    </Box>
    {editMode && <MetaDataInputs />}
  </Fragment>
);

export default MocForm;
