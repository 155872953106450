import React, { ReactElement } from 'react';
import { ListProps, TextField, usePermissions } from 'react-admin';
import { canAccess } from '@react-admin/ra-rbac';
import ColumnActions from '../common/ColumnActions';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import DisciplineField from '../common/DisciplineField';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import ListActions from '../common/ListActions';
import ListWithTitle from '../common/ListWithTitle';
import { FieldPermissionActions } from '../common/constants';
import { RESOURCE_POSITION } from '../constants';
import CurrencyField from './components/CurrencyField';
import PositionEdit from './PositionEdit';
import PositionShow from './PositionShow';

const PositionList: React.FC<ListProps> = (): ReactElement => {
  const defaultFilter = useDefaultFilter('project', { isDeleted: false });
  const { permissions } = usePermissions();
  const combinedFieldPermissionActions = [
    FieldPermissionActions.show,
    FieldPermissionActions.edit,
  ];

  return (
    <ListWithTitle
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={<ListActions />}
      sort={{ field: 'position', order: 'ASC' }}
    >
      <DatagridConfigurableRbac
        ShowComponent={<PositionShow withoutActions />}
        EditComponent={<PositionEdit syncWithLocation={false} />}
        defaultVisibleColumns={['id', 'position', 'description']}
        checkFieldsAccess
      >
        <TextField source="id" label="ID" />
        <TextField source="position" label="Position" />
        <TextField source="description" label="Description" />
        <DisciplineField source="disciplineId" />
        {combinedFieldPermissionActions.some((action) =>
          canAccess({
            permissions,
            action,
            resource: `${RESOURCE_POSITION}.billRate`,
          })
        ) && <TextField source="billRate" label="Bill Rate" />}
        {combinedFieldPermissionActions.some((action) =>
          canAccess({
            permissions,
            action,
            resource: `${RESOURCE_POSITION}.currencyId`,
          })
        ) && <CurrencyField />}
        {renderMetaDataFields()}
        <ColumnActions label="Actions" />
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default PositionList;
