import { TextField } from 'react-admin';
import React, { ReactElement } from 'react';
import { RESOURCE_TEST } from '../constants';
import SmartLinkField from './SmartLinkField';

interface TestNameFieldProps {
  label?: string;
  source?: string;
  textSource?: string;
  sortBy?: string;
}

const TestNameField: React.FC<TestNameFieldProps> = ({
  label,
  source,
  textSource,
}): ReactElement => (
  <SmartLinkField label={label} source={source} resource={RESOURCE_TEST}>
    <TextField source={textSource} />
  </SmartLinkField>
);

TestNameField.defaultProps = {
  label: 'Test Name',
  source: 'testId',
  textSource: 'test.testName',
  sortBy: '`test.testName`',
};

export default TestNameField;
