import { Labeled, TabbedShowLayout, TextField } from 'react-admin';
import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import commonStyles from '../common/commonStyles';
import renderShowMetaDataFields from '../common/helpers/renderShowMetaDataFields';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import CustomBooleanField from '../common/CustomBooleanField';
import AuditListTab from '../audit/AuditListTab';

const ContactShow: React.FC<ShowComponentProps> = ({
  syncWithLocation,
  ...rest
}): ReactElement => (
  <ShowComponent {...rest}>
    <TabbedShowLayout
      sx={commonStyles.bottomMargin}
      syncWithLocation={syncWithLocation}
    >
      <TabbedShowLayout.Tab label="Contact">
        <Box>
          <Labeled>
            <TextField
              source="nickname"
              label="Nick Name"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled>
            <TextField
              source="firstName"
              label="First Name"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled>
            <TextField
              source="lastName"
              label="Last Name"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
        </Box>
        <Box>
          <Labeled>
            <TextField
              source="email"
              label="Email"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled>
            <TextField
              source="company"
              label="Company"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled>
            <TextField
              source="jobTitle"
              label="Job Title"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
        </Box>
        <Box>
          <Labeled>
            <CustomBooleanField
              source="isMobileUser"
              label="Mobile User"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled>
            <TextField
              source="description"
              label="Description"
              sx={commonStyles.createBoxHalfWidth}
            />
          </Labeled>
        </Box>
        <Box>{renderShowMetaDataFields()}</Box>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="History">
        <AuditListTab tableName="tbl_contact" />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </ShowComponent>
);

export default ContactShow;
