import React, { Fragment, ReactElement } from 'react';
import { maxLength } from 'react-admin';
import Box from '@mui/material/Box';
import { useWatch } from 'react-hook-form';
import commonStyles from '../../common/commonStyles';
import YesNoInput from '../../common/YesNoInput';
import { yesNoOptions } from '../../common/constants';
import TextInput from '../../../components/common/TextInput';
import { required } from '../../../utils/UtilityFunctions';
import CustomDateInput from '../../common/CustomDateInput';

interface ElectricalFormProps {
  bulkEditMode?: boolean;
}

const ElectricalForm: React.FC<ElectricalFormProps> = ({
  bulkEditMode,
}): ReactElement => {
  const requiredValidation = bulkEditMode ? [] : [required()];
  const isRequired = !bulkEditMode;
  const cablePulled = useWatch({
    name: 'electrical.cablePulled',
  });
  const cableTermFrom = useWatch({
    name: 'electrical.cableTermFrom',
  });
  const cableTermTo = useWatch({
    name: 'electrical.cableTermTo',
  });

  return (
    <Fragment>
      <Box sx={commonStyles.flexBox}>
        <YesNoInput
          source="electrical.cablePulled"
          label="Cable Pulled"
          sx={commonStyles.flexBoxItem}
          defaultValue={yesNoOptions.no}
          isRequired={isRequired}
        />
        <CustomDateInput
          source="electrical.cablePulledDate"
          label="Cable Pulled Date"
          validate={cablePulled === yesNoOptions.yes ? requiredValidation : []}
          sx={commonStyles.flexBoxItem}
        />
        <YesNoInput
          source="electrical.cableGlandedA"
          label="Cable Glanded A"
          sx={commonStyles.flexBoxItem}
          defaultValue={yesNoOptions.no}
          isRequired={isRequired}
        />
        <YesNoInput
          source="electrical.cableGlandedB"
          label="Cable Glanded B"
          sx={commonStyles.flexBoxItem}
          defaultValue={yesNoOptions.no}
          isRequired={isRequired}
        />
        <YesNoInput
          source="electrical.cableTested"
          label="Cable Tested"
          sx={commonStyles.flexBoxItem}
          defaultValue={yesNoOptions.no}
          isRequired={isRequired}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <YesNoInput
          source="electrical.cableTermFrom"
          label="Cable Term From"
          sx={commonStyles.flexBoxItem}
          defaultValue={yesNoOptions.no}
          isRequired={isRequired}
        />
        <CustomDateInput
          source="electrical.cableTermFromDate"
          label="Cable Term From Date"
          validate={
            cableTermFrom === yesNoOptions.yes ? requiredValidation : []
          }
          sx={commonStyles.flexBoxItem}
        />
        <YesNoInput
          label="Cable Term To"
          source="electrical.cableTermTo"
          sx={commonStyles.flexBoxItem}
          defaultValue={yesNoOptions.no}
          isRequired={isRequired}
        />
        <CustomDateInput
          source="electrical.cableTermToDate"
          label="Cable Term To Date"
          validate={cableTermTo === yesNoOptions.yes ? requiredValidation : []}
          sx={commonStyles.flexBoxItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="electrical.size"
          label="Size"
          validate={[maxLength(75)]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="electrical.motorRtg"
          label="Motor RTG"
          validate={[maxLength(75)]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="electrical.ratingUnits"
          label="Rating Units"
          validate={[maxLength(75)]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="electrical.rtdVoltage"
          label="RTD Voltage"
          validate={[maxLength(75)]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="electrical.skidNo"
          label="Skid No"
          validate={[maxLength(75)]}
          sx={commonStyles.flexBoxItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="electrical.currentPrevious"
          label="Current Previous"
          validate={[maxLength(75)]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="electrical.recordStatus"
          label="Record Status"
          validate={[maxLength(75)]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="electrical.fromPlanDwg"
          label="From Plan Dwg"
          validate={[maxLength(75)]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="electrical.toPlanDwg"
          label="To Plan Dwg"
          validate={[maxLength(75)]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="electrical.connectionDiagram"
          label="Connection Diagram"
          validate={[maxLength(75)]}
          sx={commonStyles.flexBoxItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="electrical.fromDescription"
          label="From Description"
          validate={[maxLength(255)]}
          sx={commonStyles.flexBoxLongItem}
        />
        <TextInput
          source="electrical.toDescription"
          label="To Description"
          validate={[maxLength(255)]}
          sx={commonStyles.flexBoxLongItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="electrical.cableDesc"
          label="Cable Description"
          validate={[maxLength(255)]}
          sx={commonStyles.flexBoxLongItem}
        />
        <TextInput
          source="electrical.cableType"
          label="Cable Type"
          validate={[maxLength(75)]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="electrical.route"
          label="Route"
          sx={commonStyles.flexBoxLongItem}
        />
      </Box>
    </Fragment>
  );
};

export default ElectricalForm;
