import React, { ReactElement, useEffect } from 'react';
import { Button, Filter, NullableBooleanInput } from 'react-admin';
import { Card, CardContent } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import { SideFilter } from '../../common/types';
import MobileTestStatusInput from '../../componentTest/components/MobileTestStatusInput';
import { ContactCategories } from '../../common/contactInput/constants';
import useSidebarFilters from '../../common/hooks/useSidebarFilters';
import ContactInput from '../../common/contactInput/ContactInput';
import EquipmentTypeInput from '../../common/EquipmentTypeInput';
import sideFilterStyles from '../../common/sideFilterStyles';
import CustomDateInput from '../../common/CustomDateInput';
import TestFormNoInput from '../../common/TestFormNoInput';
import { RESOURCE_COMPONENT_TEST } from '../../constants';
import ComponentInput from '../../common/ComponentInput';
import LocationInput from '../../common/LocationInput';
import { mobileTestStatusesFilter } from '../constants';
import TextInput from '../../common/TextInput';

const SmartTagITRReviewSidebarFilters: React.FC<SideFilter> = ({
  defaultFilter,
}): ReactElement => {
  const { data, isLoading, filterValues, setFilters, handleClearFilters } =
    useSidebarFilters(RESOURCE_COMPONENT_TEST, defaultFilter);

  useEffect(() => {
    if (filterValues) {
      setFilters({ ...defaultFilter, ...filterValues }, []);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(filterValues), setFilters, JSON.stringify(defaultFilter)]);

  if (!data || (!data.length && isEmpty(filterValues)) || isLoading) {
    return null;
  }

  return (
    <Card sx={sideFilterStyles.sideFiltersCard}>
      <CardContent sx={sideFilterStyles.sideFiltersCardContainer}>
        <Filter>
          <ComponentInput
            label="Tag"
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <TestFormNoInput
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <LocationInput
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <EquipmentTypeInput
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
            source="component->equipTypeId"
          />
          <NullableBooleanInput
            label="Verified"
            name="isVerified"
            source="isVerified"
            trueLabel="True"
            falseLabel="False"
            nullLabel="None"
            fullWidth
            alwaysOn
          />
          <ContactInput
            category={ContactCategories.itrEmpWorkGrp}
            label="Completed By"
            source="completedBy_inc"
            alwaysOn
            canCreate={false}
          />
          <CustomDateInput
            source="completedDateTime"
            label="Completed Test Date"
            name="completedDateTime_inc"
            alwaysOn
          />
          <NullableBooleanInput
            label="Retest"
            name="retest"
            source="retest"
            trueLabel="True"
            falseLabel="False"
            nullLabel="None"
            fullWidth
            alwaysOn
          />
          <TextInput
            source="retestReason"
            label="Retest Reason"
            name="retestReason_inc"
            alwaysOn
          />
          <NullableBooleanInput
            label="Printed"
            name="isPrinted"
            source="isPrinted"
            trueLabel="True"
            falseLabel="False"
            nullLabel="None"
            fullWidth
            alwaysOn
          />
          <CustomDateInput
            source="printedDate"
            label="Printed Date"
            name="printedDate_inc"
            alwaysOn
          />
          <MobileTestStatusInput
            label="Mobile Test Status"
            source="mobileTestStatus"
            fullWidth
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
            choices={mobileTestStatusesFilter}
          />
        </Filter>
        <Button label="Clear filters" onClick={handleClearFilters} />
      </CardContent>
    </Card>
  );
};

export default SmartTagITRReviewSidebarFilters;
