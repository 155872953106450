import React, { useCallback } from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import {
  useCreate,
  useCreateSuggestionContext,
  useNotify,
  SimpleForm,
} from 'react-admin';
import { getContext, UserContext } from '../../../provider/userContext';
import useInvalidateResourceQueryCache from '../hooks/useInvalidateResourceQueryCache';
import { RESOURCE_STORAGE } from '../../constants';
import { getErrorMessage, required } from '../../../utils/UtilityFunctions';
import commonStyles from '../commonStyles';
import TextInput from '../TextInput';
import SaveButton from '../SaveButton';

const StorageCreateDialog: React.FC = () => {
  const notify = useNotify();
  const ctx: UserContext = getContext();
  const invalidateQueries = useInvalidateResourceQueryCache(RESOURCE_STORAGE);
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [create] = useCreate();

  const handleSubmit = useCallback(
    async (formData) => {
      await create(
        RESOURCE_STORAGE,
        {
          data: {
            storage: formData?.storage.trim(),
            storageLocation: formData?.location.trim(),
            projectId: ctx?.projectId,
          },
        },
        {
          onSuccess: (data) => {
            onCreate(data);
            invalidateQueries();
          },
          onError: (error) => {
            notify(getErrorMessage(error), {
              type: 'error',
              undoable: false,
            });
          },
        }
      );
    },
    [create, ctx?.projectId, invalidateQueries, notify, onCreate]
  );

  return (
    <Dialog open onClose={onCancel}>
      <SimpleForm
        onSubmit={handleSubmit}
        defaultValues={{ storage: (filter || '').replace('Create', '').trim() }}
        toolbar={
          <DialogActions>
            <SaveButton />
            <Button onClick={onCancel}>Cancel</Button>
          </DialogActions>
        }
      >
        <DialogContent>
          <TextInput
            source="storage"
            label="New Storage"
            validate={required()}
            sx={commonStyles.fullWidth}
          />
          <TextInput
            source="location"
            label="Storage location"
            autoFocus
            validate={required()}
            sx={commonStyles.fullWidth}
          />
        </DialogContent>
      </SimpleForm>
    </Dialog>
  );
};

export default StorageCreateDialog;
