import React, { Fragment, ReactElement } from 'react';
import { BooleanInput, maxLength } from 'react-admin';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import TabbedEditForm from '../common/TabbedEditForm';
import LazyFormTab from '../common/LazyFormTab';
import HistoryTab from '../common/HistoryTab';
import MetaDataInputs from '../common/MetaDataInputs';
import TextInput from '../common/TextInput';
import { EditPropsWithLocation } from '../common/types';
import commonStyles from '../common/commonStyles';
import { required } from '../../utils/UtilityFunctions';
import { RESOURCE_BOOK, RESOURCE_BOOK_SECTION } from '../constants';
import LinkToToc from './components/LinkToToc';
import { SectionTypeInput } from './components/SectionTypeInput';
import { BookSectionTypeComponentEdit } from './components/BookSectionTypeComponentEdit';
import { BookSection } from './type';

const BookSectionEdit: React.FC<EditPropsWithLocation<BookSection>> = (
  props
): ReactElement => {
  const { bookId } = useParams();
  const { syncWithLocation } = props;

  return (
    <Fragment>
      {syncWithLocation && <LinkToToc bookId={bookId} />}
      <TabbedEditForm
        {...props}
        resource={RESOURCE_BOOK_SECTION}
        redirect={`/${RESOURCE_BOOK}/${bookId}/${RESOURCE_BOOK_SECTION}`}
        footerToolbarProps={{
          cancelButtonProps: {
            to: `/${RESOURCE_BOOK}/${bookId}/${RESOURCE_BOOK_SECTION}`,
          },
        }}
      >
        <LazyFormTab
          label="Book Section"
          id="book_section_tab"
          sx={commonStyles.formTabHeader}
        >
          <Box sx={commonStyles.flexBox}>
            <TextInput
              source="section"
              label="Section"
              disabled
              sx={commonStyles.flexBoxItem}
            />
            <SectionTypeInput />
            <TextInput
              source="title"
              label="Title"
              validate={[required(), maxLength(255)]}
              sx={commonStyles.flexBoxItem}
            />
          </Box>
          <Box sx={commonStyles.flexBox}>
            <TextInput
              fullWidth
              multiline
              source="description"
              label="Description"
              sx={commonStyles.flexBoxLongItem}
            />
          </Box>
          <Box sx={commonStyles.flexBox}>
            <BooleanInput
              label="Show section summary"
              source="showSectionSummary"
              sx={commonStyles.flexBoxSelectItem}
            />
          </Box>
          <Box>
            <BookSectionTypeComponentEdit />
          </Box>
          <MetaDataInputs />
        </LazyFormTab>
        <HistoryTab tableName="tbl_book_section" />
      </TabbedEditForm>
    </Fragment>
  );
};

export default BookSectionEdit;
