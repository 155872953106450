import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import ColumnActions from '../common/ColumnActions';
import ListWithTitle from '../common/ListWithTitle';
import ListActions from '../common/ListActions';
import AssetField from '../common/AssetField';
import RegionField from '../common/RegionField';
import StructureAssetGroupShow from './StructureAssetGroupShow';
import StructureAssetGroupEdit from './StructureAssetGroupEdit';

const StructureAssetGroupList: React.FC = (): ReactElement => {
  return (
    <ListWithTitle
      filters={<FreeTextSearchFilter />}
      actions={<ListActions />}
      sort={{ field: 'assetGroup', order: 'ASC' }}
    >
      <DatagridConfigurableRbac
        ShowComponent={<StructureAssetGroupShow withoutActions />}
        EditComponent={<StructureAssetGroupEdit syncWithLocation={false} />}
        defaultVisibleColumns={[
          'id',
          'assetGroup',
          'assetId',
          'description',
          'asset.regionId',
        ]}
      >
        <TextField source="id" label="ID" />
        <TextField source="assetGroup" label="Asset Group" />
        <TextField source="description" label="Description" />
        <RegionField
          source="asset.regionId"
          textSource="asset.region.region"
          sortBy="`asset.region.region`"
        />
        <AssetField label="Asset" source="assetId" />
        {renderMetaDataFields()}
        <ColumnActions label="Actions" />
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default StructureAssetGroupList;
