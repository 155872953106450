import React, { ReactElement, useCallback } from 'react';
import {
  TextField,
  useStore,
  useResourceContext,
  DateField,
} from 'react-admin';
import { RESOURCE_ACTIVITY_TYPE, RESOURCE_CERT_DETAIL } from '../constants';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import { getSideFiltersKey } from '../common/helpers/storeKeys';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import ActivityPhaseField from '../common/ActivityPhaseField';
import CustomBooleanField from '../common/CustomBooleanField';
import AssignedField from '../common/Assigned/AssignedField';
import CertTemplateField from '../common/CertTemplateField';
import DisciplineField from '../common/DisciplineField';
import ComponentField from '../common/ComponentField';
import DownloadButton from '../common/DownloadButton';
import PreviewButton from '../common/PreviewButton';
import ListWithTitle from '../common/ListWithTitle';
import ColumnActions from '../common/ColumnActions';
import LegendButton from '../common/LegendButton';
import ListActions from '../common/ListActions';
import SelectField from '../common/SelectField';
import { legends, getRowStyle } from './helpers';
import CertDetailsEdit from './CertDetailsEdit';
import CertDetailsShow from './CertDetailsShow';
import ActivityField from './components/ActivityField';
import BulkActionButtons from './components/BulkActionButtons';
import ActivityTextField from './components/ActivityTextField';
import CertDetailsSidebarFilters from './components/CertDetailsSidebarFilters';
import { statuses } from './constants';
import CertDetailsSystemField from './components/CertDetailsSystemField';
import CertDetailsSubsystemField from './components/CertDetailsSubsystemField';

const CertDetailsList: React.FC = (): ReactElement => {
  const sideFilter = getSideFiltersKey(RESOURCE_CERT_DETAIL);
  const [showSideFilter] = useStore(sideFilter, false);
  const defaultFilter = useDefaultFilter('project');
  const resource: string = useResourceContext();
  const [expanded] = useStore(`${resource}.datagrid.expanded`, []);

  const getRowStyles = useCallback(
    (record) => getRowStyle(record, expanded),
    [expanded]
  );

  return (
    <ListWithTitle
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={
        <ListActions
          showAction={{
            import: true,
            export: true,
            effective: false,
            create: false,
            select: true,
          }}
          actions={<LegendButton legends={legends} />}
          sideFilter={sideFilter}
          defaultFilter={defaultFilter}
        />
      }
      sort={{ field: 'id', order: 'ASC' }}
      aside={
        showSideFilter ? (
          <CertDetailsSidebarFilters defaultFilter={defaultFilter} />
        ) : null
      }
      queryOptions={{ meta: { excludeFields: ['formData'] } }}
    >
      <DatagridConfigurableRbac
        ShowComponent={<CertDetailsShow withoutActions />}
        EditComponent={<CertDetailsEdit syncWithLocation={false} />}
        bulkActionButtons={<BulkActionButtons />}
        defaultVisibleColumns={[
          'id',
          'certTemplateId',
          'certTemplate.category',
          'systemActivityId',
          'subsystemActivityId',
          'componentActivityId',
          'status',
          'activityPhaseId',
          'disciplineId',
          'acceptedBy',
          'acceptedDate',
        ]}
        rowStyle={getRowStyles}
      >
        <TextField source="id" label="ID" />
        <SelectField source="status" label="Status" choices={statuses} />
        <CertTemplateField />
        <TextField source="certTemplate.description" label="Description" />
        <TextField source="certTemplate.category" label="Category" />
        <ActivityField source="activity" label="Activity" sortable={false} />
        <ActivityTextField
          resource={RESOURCE_ACTIVITY_TYPE}
          source="id"
          label="Activity Type"
          sortBy="activityType"
          sortable={false}
        />
        <ActivityTextField
          source="plannedDate"
          label="Activity Planned Date"
          sortable={false}
        />
        <ActivityTextField
          source="actualDate"
          label="Activity Actual Date"
          sortable={false}
        />
        <ActivityTextField
          source="activityConstraint"
          label="Activity Constraint"
          sortable={false}
        />
        <ActivityTextField
          source="duration"
          label="Activity Duration"
          sortable={false}
        />
        <ActivityTextField
          source="actualDuration"
          label="Activity Actual Duration"
          sortable={false}
        />
        <ActivityTextField
          source="durationUnit"
          label="Activity Duration Unit"
          sortable={false}
        />
        <ActivityTextField
          source="sequence"
          label="Activity Sequence"
          sortable={false}
        />
        <ComponentField />
        <CertDetailsSystemField />
        <CertDetailsSubsystemField />
        <ActivityPhaseField />
        <DisciplineField />
        <CustomBooleanField source="isPrinted" label="Printed" />
        <DateField source="printedDate" label="Printed Date" />
        <TextField source="printedBy" label="Printed By" />
        <CustomBooleanField source="isSmart" label="Smart" />
        <TextField
          source="certTotalSignatures"
          label="Certificate Total Signatures"
        />
        <TextField source="certSigned" label="Certificate Signed" />
        <TextField source="certSignedBy" label="Certificate Signed By" />
        <AssignedField label="Responsible" sortable={false} />
        <CustomBooleanField source="isVerified" label="Verified" />
        <TextField source="acceptedBy" label="Accepted By" />
        <DateField source="acceptedDate" label="Accepted Date" />
        <TextField source="generatedBy" label="Generated By" />
        <DateField source="generatedDate" label="Generated Date" />
        {renderMetaDataFields()}
        <ColumnActions label="Actions" showActions={{ delete: false }}>
          <PreviewButton />
          <DownloadButton />
        </ColumnActions>
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default CertDetailsList;
