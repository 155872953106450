import React, { ReactElement, useCallback } from 'react';
import Box from '@mui/material/Box';
import DeleteWithConfirmIconButton from '../../common/DeleteWithConfirmIconButton';
import useCanAccess from '../../common/hooks/useCanAccess';
import commonStyles from '../../common/commonStyles';
import useInvalidateResourceQueryCache from '../../common/hooks/useInvalidateResourceQueryCache';
import { RESOURCE_EQUIPMENT_TYPE } from '../../constants';
import { FieldPropsWithOptionalSource } from '../../common/types';

const ColumnActions: React.FC<
  FieldPropsWithOptionalSource
> = (): ReactElement => {
  const canAccessList = useCanAccess();
  const invalidateQueries = useInvalidateResourceQueryCache(
    RESOURCE_EQUIPMENT_TYPE,
    true
  );

  const onDelete = useCallback(
    async () => await invalidateQueries(),
    [invalidateQueries]
  );

  return (
    <Box sx={commonStyles.columnActionsBox}>
      {canAccessList.delete && (
        <DeleteWithConfirmIconButton
          className="deleteButton"
          redirect={false}
          onDelete={onDelete}
        />
      )}
    </Box>
  );
};

export default ColumnActions;
