import { RowForm } from '@react-admin/ra-editable-datagrid';
import React, { ReactElement } from 'react';
import { Identifier, TextField } from 'react-admin';
import DrawingInput from './DrawingInput';
import commonStyles from './commonStyles';

interface DrawingRowFormProps {
  targetId: Identifier;
  targetField: 'componentId' | 'subsystemId';
}

const DrawingRowForm: React.FC<DrawingRowFormProps> = ({
  targetId,
  targetField,
}): ReactElement => (
  <RowForm
    transform={(data) => ({
      ...data,
      [targetField]: targetId,
    })}
  >
    <DrawingInput sx={commonStyles.rowFormInput} isRequired={true} />
    <TextField source="" sx={commonStyles.flexBoxLongItem} />
  </RowForm>
);

export default DrawingRowForm;
