import React, {
  ReactElement,
  useCallback,
  useState,
  useEffect,
  Fragment,
} from 'react';
import {
  TextField,
  useStore,
  useResourceContext,
  DateField,
  RowClickFunction,
} from 'react-admin';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from '@mui/material';
import CertDetailsSubsystemField from '../certDetails/components/CertDetailsSubsystemField';
import CertDetailsSystemField from '../certDetails/components/CertDetailsSystemField';
import ActivityTextField from '../certDetails/components/ActivityTextField';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import ActivityField from '../certDetails/components/ActivityField';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import { getSideFiltersKey } from '../common/helpers/storeKeys';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import { getRowStyle } from '../certDetails/helpers';
import ActivityPhaseField from '../common/ActivityPhaseField';
import CustomBooleanField from '../common/CustomBooleanField';
import AssignedField from '../common/Assigned/AssignedField';
import CertTemplateField from '../common/CertTemplateField';
import sideFilterStyles from '../common/sideFilterStyles';
import DisciplineField from '../common/DisciplineField';
import ComponentField from '../common/ComponentField';
import ListWithTitle from '../common/ListWithTitle';
import ColumnActions from '../common/ColumnActions';
import LegendButton from '../common/LegendButton';
import ListActions from '../common/ListActions';
import SelectField from '../common/SelectField';
import ListTitle from '../common/ListTitle';
import {
  RESOURCE_ACTIVITY_TYPE,
  RESOURCE_SMART_CERT_DETAIL,
} from '../constants';
import useSmartTabWideNarrow from '../common/SmartTabWidthButtons/useSmartTabWideNarrow';
import { legends, statuses } from './constants';
import SmartCertDetailsEdit from './SmartCertDetailsEdit';
import BulkActionButtons from './components/BulkActionButtons';
import FilterMyCertificatesButton from './components/FilterMyCertificatesButton';
import SmartCertDetailsSidebarFilters from './components/SmartCertDetailsSidebarFilters';

const defaultVisibleColumns = [
  'id',
  'certTemplateId',
  'activity',
  'assigned',
  'status',
  'certTotalSignatures',
  'certSigned',
  'certSignedBy',
  'actions',
];

const SmartCertDetailsList: React.FC = (): ReactElement => {
  const queryClient = useQueryClient();
  const resource: string = useResourceContext();
  const sideFilter = getSideFiltersKey(resource);
  const [showSideFilter] = useStore(sideFilter, false);
  const [selectedRecordId, setSelectedRecordId] = useState<number | null>(null);
  const [expanded, setExpanded] = useStore(`${resource}.datagrid.expanded`, []);
  const defaultFilter = useDefaultFilter('project', {
    isSmart: true,
    status: ['NONE', 'IN_PROGRESS'],
  });
  const isWideActive = useSmartTabWideNarrow(
    ['id', 'certTemplateId', 'activity'],
    defaultVisibleColumns
  );

  const rowClick: RowClickFunction = useCallback(
    async (id, resource): Promise<false> => {
      if (!expanded.length || !expanded.includes(id)) {
        await queryClient.invalidateQueries({
          queryKey: [resource, 'getOne', { id: String(id) }],
        });
        setExpanded([id]);
      } else {
        setExpanded([]);
      }

      return false;
    },
    [expanded, setExpanded, queryClient]
  );

  const onCancel = useCallback(() => setExpanded([]), [setExpanded]);

  useEffect(() => {
    setSelectedRecordId(!expanded?.length ? null : expanded[0]);
  }, [setSelectedRecordId, expanded]);

  const getRowStyles = useCallback(
    (record) => getRowStyle(record, expanded),
    [expanded]
  );

  return (
    <ListWithTitle
      title={<ListTitle />}
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={
        <ListActions
          sideFilter={isWideActive ? undefined : sideFilter}
          defaultFilter={defaultFilter}
          showAction={{
            import: false,
            export: false,
            effective: false,
            create: false,
            select: !isWideActive,
            topPagination: !isWideActive,
          }}
          actions={
            <Fragment>
              <FilterMyCertificatesButton defaultFilter={defaultFilter} />
              {!isWideActive && <LegendButton legends={legends} />}
            </Fragment>
          }
        />
      }
      sort={{ field: 'id', order: 'ASC' }}
      className="smartList"
      sx={sideFilterStyles.smartList}
      aside={
        <Fragment>
          {showSideFilter && !isWideActive && (
            <SmartCertDetailsSidebarFilters defaultFilter={defaultFilter} />
          )}
          {selectedRecordId && (
            <SmartCertDetailsEdit
              syncWithLocation={false}
              id={selectedRecordId}
              resource={RESOURCE_SMART_CERT_DETAIL}
              onCancel={onCancel}
            />
          )}
        </Fragment>
      }
    >
      <DatagridConfigurableRbac
        bulkActionButtons={<BulkActionButtons />}
        defaultVisibleColumns={defaultVisibleColumns}
        rowClick={rowClick}
        rowStyle={getRowStyles}
        hover
      >
        <TextField source="id" label="ID" />
        <SelectField source="status" label="Status" choices={statuses} />
        <CertTemplateField />
        <TextField source="certTemplate.description" label="Description" />
        <TextField source="certTemplate.category" label="Category" />
        <ActivityField source="activity" label="Activity" sortable={false} />
        <ActivityTextField
          resource={RESOURCE_ACTIVITY_TYPE}
          source="activityTypeId"
          sortBy="activityType.activityType"
          label="Activity Type"
          sortable={false}
        />
        <ComponentField />
        <CertDetailsSystemField />
        <CertDetailsSubsystemField />
        <ActivityPhaseField />
        <DisciplineField />
        <CustomBooleanField source="isPrinted" label="Printed" />
        <DateField source="printedDate" label="Printed Date" />
        <TextField source="printedBy" label="Printed By" />
        <CustomBooleanField source="isSmart" label="Smart" />
        <TextField
          source="certTotalSignatures"
          label="Certificate Total Signatures"
        />
        <TextField source="certSigned" label="Certificate Signed" />
        <TextField source="certSignedBy" label="Certificate Signed By" />
        <AssignedField label="Responsible" sortable={false} />
        <CustomBooleanField source="isVerified" label="Verified" />
        <TextField source="generatedBy" label="Generated By" />
        <DateField source="generatedDate" label="Generated Date" />
        {renderMetaDataFields()}
        <ColumnActions
          label="Actions"
          showActions={{ edit: false, delete: false, show: false }}
        >
          <Button>Show/Hide</Button>
        </ColumnActions>
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default SmartCertDetailsList;
