import React, { Fragment, ReactElement } from 'react';
import { Labeled, NumberField, TextField } from 'react-admin';
import Box from '@mui/material/Box';
import renderShowMetaDataFields from '../../common/helpers/renderShowMetaDataFields';
import SubsystemField from '../../common/SubsystemField';
import DisciplineField from '../../common/DisciplineField';
import GroupField from '../../common/GroupField';
import ModuleField from '../../common/ModuleField';
import WorkPackField from '../../common/WorkPackField';
import LocationField from '../../common/LocationField';
import EquipmentTypeField from '../../common/EquipmentTypeField';
import CustomBooleanField from '../../common/CustomBooleanField';
import VendorField from '../../common/VendorField';
import commonStyles from '../../common/commonStyles';
import ComponentDrawingField from './ComponentDrawingField';

const ComponentShowTab: React.FC = (): ReactElement => (
  <Fragment>
    <Box>
      <Labeled label="SubSystem" sx={commonStyles.flexBoxItem}>
        <SubsystemField source="subsystemId" />
      </Labeled>
      <Labeled label="Discipline" sx={commonStyles.flexBoxItem}>
        <DisciplineField source="disciplineId" />
      </Labeled>
      <Labeled label="Tag" sx={commonStyles.flexBoxItem}>
        <TextField source="tag" />
      </Labeled>
      <Labeled label="Tag Description" sx={commonStyles.flexBoxLongItem}>
        <TextField source="tagDescription" />
      </Labeled>
    </Box>
    <Box>
      <Labeled label="Group" sx={commonStyles.flexBoxItem}>
        <GroupField />
      </Labeled>
      <Labeled label="Module" sx={commonStyles.flexBoxItem}>
        <ModuleField />
      </Labeled>
      <Labeled label="Work Pack" sx={commonStyles.flexBoxItem}>
        <WorkPackField source="workPackId" />
      </Labeled>
      <Labeled label="Location" sx={commonStyles.flexBoxItem}>
        <LocationField source="locationId" />
      </Labeled>
      <Labeled label="Equipment Type" sx={commonStyles.flexBoxItem}>
        <EquipmentTypeField source="equipTypeId" />
      </Labeled>
    </Box>
    <Box sx={commonStyles.createBoxHalfWidth}>
      <Labeled label="Drawings">
        <ComponentDrawingField />
      </Labeled>
    </Box>
    <Box>
      <Labeled label="Skip ITR Automation" sx={commonStyles.flexBoxItem}>
        <CustomBooleanField source="skipItrAutomation" />
      </Labeled>
      <Labeled label="Maintenance" sx={commonStyles.flexBoxItem}>
        <CustomBooleanField source="isMaintenance" />
      </Labeled>
      <Labeled label="Regulatory Item" sx={commonStyles.flexBoxItem}>
        <CustomBooleanField source="isRegulatoryItem" />
      </Labeled>
      <Labeled label="Tag Scoped" sx={commonStyles.flexBoxItem}>
        <CustomBooleanField source="tagScoped" />
      </Labeled>
      <Labeled label="Tag Verified" sx={commonStyles.flexBoxItem}>
        <CustomBooleanField source="tagVerified" />
      </Labeled>
      <Labeled label="Tag Category" sx={commonStyles.flexBoxItem}>
        <TextField source="tagCategory" />
      </Labeled>
    </Box>
    <Box>
      <Labeled label="Parent Tag" sx={commonStyles.flexBoxItem}>
        <TextField source="parentTag" />
      </Labeled>
      <Labeled label="Data Sheet" sx={commonStyles.flexBoxItem}>
        <TextField source="dataSheet" />
      </Labeled>
      <Labeled label="Manufacturer" sx={commonStyles.flexBoxItem}>
        <TextField source="manufacturer" />
      </Labeled>
      <Labeled label="Model" sx={commonStyles.flexBoxItem}>
        <TextField source="model" />
      </Labeled>
      <Labeled label="Serial" sx={commonStyles.flexBoxItem}>
        <TextField source="serial" />
      </Labeled>
    </Box>
    <Box>
      <Labeled label="Batch" sx={commonStyles.flexBoxItem}>
        <TextField source="batch" />
      </Labeled>
      <Labeled label="Loop Name" sx={commonStyles.flexBoxItem}>
        <TextField source="loopName" />
      </Labeled>
      <Labeled label="Purchase Order" sx={commonStyles.flexBoxItem}>
        <TextField source="purchaseOrder" />
      </Labeled>
      <Labeled label="Vendor" sx={commonStyles.flexBoxItem}>
        <VendorField source="vendorId" />
      </Labeled>
      <Labeled label="Delivery Date" sx={commonStyles.flexBoxItem}>
        <TextField source="deliveryDate" />
      </Labeled>
    </Box>
    <Box>
      <Labeled label="Hard Soft" sx={commonStyles.flexBoxItem}>
        <TextField source="hardSoft" />
      </Labeled>
      <Labeled label="EngList" sx={commonStyles.flexBoxItem}>
        <TextField source="engList" />
      </Labeled>
      <Labeled label="Storage Location" sx={commonStyles.flexBoxLongItem}>
        <TextField source="storageLocation" />
      </Labeled>
    </Box>
    <Box>
      <Labeled label="Verified By" sx={commonStyles.flexBoxItem}>
        <TextField source="verifiedBy" />
      </Labeled>
      <Labeled label="Verified Date" sx={commonStyles.flexBoxItem}>
        <TextField source="verifiedDate" />
      </Labeled>
      <Labeled label="Verified" sx={commonStyles.flexBoxItem}>
        <CustomBooleanField source="verified" />
      </Labeled>
      <Labeled label="Cable From" sx={commonStyles.flexBoxItem}>
        <TextField source="cableFrom" />
      </Labeled>
      <Labeled label="Cable To" sx={commonStyles.flexBoxItem}>
        <TextField source="cableTo" />
      </Labeled>
    </Box>
    <Box>
      <Labeled label="Latitude" sx={commonStyles.flexBoxItem}>
        <NumberField source="latitude" />
      </Labeled>
      <Labeled label="Longitude" sx={commonStyles.flexBoxItem}>
        <NumberField source="longitude" />
      </Labeled>
      <Labeled label="IFC Quantity" sx={commonStyles.flexBoxItem}>
        <NumberField source="ifcQty" />
      </Labeled>
      <Labeled label="List Source" sx={commonStyles.flexBoxItem}>
        <TextField source="listSource" />
      </Labeled>
      <Labeled label="Track By Quantity" sx={commonStyles.flexBoxItem}>
        <NumberField source="trackByQty" />
      </Labeled>
    </Box>
    <Box>
      <Labeled label="Remarks" sx={commonStyles.flexBoxDescriptionItem}>
        <TextField source="remarks" />
      </Labeled>
    </Box>
    <Box>{renderShowMetaDataFields()}</Box>
  </Fragment>
);

export default ComponentShowTab;
