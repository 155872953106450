import { useCallback, useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { AxiosResponse } from 'axios';
import { getErrorMessage } from '../../../utils/UtilityFunctions';
import { RecordWithAnyData } from '../types';
import { FormDataWithFiles } from '../../attachment/types';
import { useUploadFile } from './useUploadFile';

type options = {
  onSuccess?: (result: AxiosResponse | AxiosResponse[]) => void;
  createRecord?: (
    resource: string,
    data: RecordWithAnyData
  ) => Promise<unknown>;
};

const useUpload = ({ onSuccess, createRecord }: options) => {
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const notify = useNotify();
  const { uploadFile } = useUploadFile();
  const dataProvider = useDataProvider();

  const uploadAttachment = useCallback(
    async (resource: string, data: RecordWithAnyData, file: File) => {
      const result = await uploadFile(resource, file, data);
      if (createRecord) {
        return createRecord(resource, { ...data, ...result });
      } else {
        return dataProvider.addAttachment(resource, {
          ...data,
          ...result,
        });
      }
    },
    [dataProvider, uploadFile, createRecord]
  );

  const upload = useCallback(
    async (resource: string, data: FormDataWithFiles) => {
      try {
        setUploadInProgress(true);
        let result;
        const { files, ...formValues } = data;
        if (files.length === 1) {
          result = await uploadAttachment(resource, formValues, files[0]);
        } else {
          result = [];
          for (const file of files) {
            result.push(await uploadAttachment(resource, formValues, file));
          }
        }

        onSuccess?.(result);

        return result;
      } catch (err) {
        notify(getErrorMessage(err), {
          type: 'error',
          undoable: false,
        });
      } finally {
        setUploadInProgress(false);
      }
    },
    [notify, onSuccess, uploadAttachment]
  );

  return { uploadInProgress, upload };
};

export default useUpload;
