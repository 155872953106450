import React, { ReactElement } from 'react';
import commonStyles from '../common/commonStyles';
import HistoryTab from '../common/HistoryTab';
import ImageGalleryFormTab from '../common/ImageGalleryFormTab';
import LazyFormTab from '../common/LazyFormTab';
import MetaDataInputs from '../common/MetaDataInputs';
import TabbedEditForm from '../common/TabbedEditForm';
import { EditPropsWithLocation } from '../common/types';
import { PendingPunch } from '../pendingPunch/types';
import useCanAccess from '../common/hooks/useCanAccess';
import SaveButtons from './components/SaveButtons';
import SubmitPunchForm from './SubmitPunchForm';

const SubmitPunchEdit: React.FC<EditPropsWithLocation<PendingPunch>> = (
  props
): ReactElement => {
  const canAccess = useCanAccess();

  return (
    <TabbedEditForm
      {...props}
      footerToolbarProps={{ SaveButtonComponent: <SaveButtons /> }}
    >
      <LazyFormTab
        label="Submit Punch"
        id="submit_punch_tab"
        sx={commonStyles.formTabHeader}
      >
        <SubmitPunchForm />
        <MetaDataInputs />
      </LazyFormTab>
      <LazyFormTab
        label="Image Gallery"
        id="gallery_tab"
        sx={commonStyles.formTabHeader}
      >
        <ImageGalleryFormTab
          targetId="pendingPunchId"
          onlyShow={!canAccess.edit}
        />
      </LazyFormTab>
      <HistoryTab tableName="tbl_pending_punch" />
    </TabbedEditForm>
  );
};

export default SubmitPunchEdit;
