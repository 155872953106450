import React, { ReactElement, useMemo } from 'react';
import { RESOURCE_WORK_PACK } from '../constants';
import { getContext, UserContext } from '../../provider/userContext';
import { required } from '../../utils/UtilityFunctions';
import AutocompleteReferenceInput from './AutocompleteReferenceInput';

interface WorkPackInputProps {
  isRequired?: boolean;
  alwaysOn?: boolean;
  multiple?: boolean;
  sx?: object;
  source?: string;
}

const WorkPackInput: React.FC<WorkPackInputProps> = ({
  isRequired,
  multiple,
  sx,
  ...rest
}): ReactElement => {
  const ctx: UserContext = getContext();
  const requestParams = useMemo(() => {
    return {
      filter: { projectId: ctx?.projectId },
      sort: { field: 'workPack', order: 'ASC' as const },
    };
  }, [ctx?.projectId]);

  return (
    <AutocompleteReferenceInput
      {...rest}
      resource={RESOURCE_WORK_PACK}
      requestParams={requestParams}
      label="Work Pack"
      name="workPack_inc"
      optionText="workPack"
      multiple={multiple}
      validate={isRequired ? [required()] : []}
      sx={sx}
      includeFields={['id', 'workPack']}
    />
  );
};

WorkPackInput.defaultProps = { source: 'workPackId' };

export default WorkPackInput;
