import React, { ReactElement } from 'react';
import { Datagrid, ReferenceManyField, TextField } from 'react-admin';
import renderMetaDataFields from '../../common/helpers/renderMetaDataFields';
import { RESOURCE_EQUIPMENT_TYPE_TEST_EQUIPMENT_TYPE } from '../../constants';
import TestEquipTypeField from './TestEquipTypeField';

const TestEquipmentTypeShowTab: React.FC = (): ReactElement => {
  return (
    <ReferenceManyField
      reference={RESOURCE_EQUIPMENT_TYPE_TEST_EQUIPMENT_TYPE}
      target="equipTypeId"
      sort={{ field: 'id', order: 'ASC' }}
    >
      <Datagrid
        bulkActionButtons={false}
        rowClick={false}
        sx={{ width: '100%' }}
      >
        <TextField source="id" label="ID" />
        <TestEquipTypeField />
        {renderMetaDataFields()}
      </Datagrid>
    </ReferenceManyField>
  );
};

export default TestEquipmentTypeShowTab;
