import React, { useEffect, useState } from 'react';
import { Button, Link, useDataProvider, useRecordContext } from 'react-admin';
import { RESOURCE_ATTACHMENT_REVISION } from '../../constants';
import { PullAndPushButtonProps } from '../types';

const DownloadMasterCopyButton: React.FC<
  PullAndPushButtonProps & { type: string }
> = ({ resource, resourceId, type }) => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const [downloadLink, setDownloadLink] = useState('');

  useEffect(() => {
    dataProvider
      .getMasterTemplateDownloadLink(RESOURCE_ATTACHMENT_REVISION, {
        resource,
        resourceId,
        type,
      })
      .then((response) => {
        setDownloadLink(response.data);
      });
  }, [dataProvider, record.id, resource, resourceId, type]);

  return (
    <Link to={downloadLink} download>
      <Button
        label="Download Master Copy"
        disabled={!downloadLink}
        size="medium"
        variant="contained"
        sx={{ marginTop: '10px' }}
      />
    </Link>
  );
};

export default DownloadMasterCopyButton;
