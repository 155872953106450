import React, { ReactElement, useCallback, useState } from 'react';
import {
  Identifier,
  useGetList,
  useRecordContext,
  useStore,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Box, Button } from '@mui/material';
import { MAX_RECORD_PER_PAGE } from '../../../provider/constants';
import { RESOURCE_BOOK_SECTION_CERT } from '../../constants';
import { BookSectionCert, CertificateTemplate } from '../type';
import commonStyles from '../../common/commonStyles';
import { bookSectionIdStore } from '../constants';
import TextInput from '../../common/TextInput';
import { required } from '../../../utils/UtilityFunctions';
import { SelectCertDialog } from './SelectCertDialog';

export const SelectSertInput: React.FC = (): ReactElement => {
  const [bookSectionId] = useStore<Identifier>(bookSectionIdStore, null);
  const record = useRecordContext<BookSectionCert>();
  const [isOpen, setIsOpen] = useState(false);
  const { setValue } = useFormContext<BookSectionCert>();
  const { data: certList } = useGetList<BookSectionCert>(
    RESOURCE_BOOK_SECTION_CERT,
    {
      pagination: {
        page: 1,
        perPage: MAX_RECORD_PER_PAGE,
      },
      sort: { field: 'sortOrder', order: 'ASC' },
      filter: {
        bookSectionId,
      },
    }
  );

  const validateDuplication = useCallback(
    (value: string) => {
      if (
        certList.some(
          (cert) => cert.id !== record?.id && cert.certName === value
        )
      ) {
        return `${value} is already in the list`;
      }
      return undefined;
    },
    [certList, record]
  );

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const onSelect = (certTemplate: CertificateTemplate) => {
    setValue('certTemplateId', certTemplate.id);
    setValue(
      'certName',
      `${certTemplate.certName} - ${certTemplate.description}`,
      { shouldTouch: true, shouldValidate: true }
    );
    onClose();
  };

  return (
    <Box sx={{ ...commonStyles.flexBox, alignItems: 'baseline' }}>
      <Button sx={commonStyles.flexBoxItem} onClick={onOpen}>
        Select Certificate
      </Button>
      <TextInput
        disabled
        source="certName"
        validate={[required(), validateDuplication]}
        sx={commonStyles.flexBoxItem}
      />
      <SelectCertDialog open={isOpen} onClose={onClose} onSelect={onSelect} />
    </Box>
  );
};
