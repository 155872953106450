import React, { ReactElement } from 'react';
import { maxLength } from 'react-admin';
import Box from '@mui/material/Box';
import CustomDateInput from '../common/CustomDateInput';
import HistoryTab from '../common/HistoryTab';
import LazyFormTab from '../common/LazyFormTab';
import TabbedEditForm from '../common/TabbedEditForm';
import commonStyles from '../common/commonStyles';
import { EditPropsWithLocation } from '../common/types';
import TextInput from '../common/TextInput';
import { required } from '../../utils/UtilityFunctions';
import { AttachmentType } from './types';

const AttachmentTypeEdit: React.FC<EditPropsWithLocation<AttachmentType>> = (
  props
): ReactElement => (
  <TabbedEditForm {...props}>
    <LazyFormTab
      label="Book Section"
      id="AttachmentType_tab"
      sx={commonStyles.formTabHeader}
    >
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="subtype"
          label="Book Section"
          validate={[required(), maxLength(75)]}
          sx={commonStyles.flexBoxItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <CustomDateInput
          source="updatedAt"
          label="Updated at"
          sx={commonStyles.flexBoxItem}
          disabled
        />
        <TextInput
          source="updatedBy"
          label="Updated by"
          sx={commonStyles.flexBoxItem}
          disabled
        />
        <TextInput
          source="updatedByName"
          label="Updated by name"
          sx={commonStyles.flexBoxItem}
          disabled
        />
      </Box>
    </LazyFormTab>
    <HistoryTab tableName="tbl_attachment_subtype" />
  </TabbedEditForm>
);

export default AttachmentTypeEdit;
