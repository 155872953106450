import React, { ReactElement } from 'react';
import EquipmentTypeTestListTab from '../equipmentTypeTest/EquipmentTypeTestListTab';
import { EditPropsWithLocation } from '../common/types';
import TabbedEditForm from '../common/TabbedEditForm';
import MetaDataInputs from '../common/MetaDataInputs';
import commonStyles from '../common/commonStyles';
import LazyFormTab from '../common/LazyFormTab';
import HistoryTab from '../common/HistoryTab';
import EquipmentTypeForm from './EquipmentTypeForm';
import TestEquipmentTypeTab from './components/TestEquipmentTypeTab';
import { EquipmentType } from './types';

const EquipmentTypeEdit: React.FC<EditPropsWithLocation<EquipmentType>> = (
  props
): ReactElement => (
  <TabbedEditForm {...props}>
    <LazyFormTab
      label="Equipment Type"
      id="equipment_type_tab"
      sx={commonStyles.formTabHeader}
    >
      <EquipmentTypeForm />
      <MetaDataInputs />
    </LazyFormTab>
    <LazyFormTab label="Tests" id="tests_tab" sx={commonStyles.formTabHeader}>
      <EquipmentTypeTestListTab />
    </LazyFormTab>
    <LazyFormTab
      label="Test Equipment Type"
      id="test_equipment_type_tab"
      sx={commonStyles.formTabHeader}
    >
      <TestEquipmentTypeTab />
    </LazyFormTab>
    <HistoryTab tableName="tbl_equipment_type" />
  </TabbedEditForm>
);

export default EquipmentTypeEdit;
