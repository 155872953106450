const notificationStyles = {
  notificationDismissAll: {
    display: 'block',
    width: 'fit-content',
    margin: '12px auto',
    padding: '0',
  },
  notificationMenuItem: {
    display: 'block',
    padding: 0,
    margin: '3px 5px',
  },
  notificationCard: {
    borderRadius: 0,
  },
  notificationCardContent: {
    padding: '24px 24px 24px 10px',
    maxWidth: '450px',
    whiteSpace: 'normal',
  },
  notificationDismiss: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    padding: 0,
  },
  notificationIframe: {
    visibility: 'hidden',
    width: 0,
    height: 0,
  },
};

export default notificationStyles;
