import React, { ReactElement, useEffect } from 'react';
import { maxLength, useGetOne } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import Box from '@mui/material/Box';
import {
  required,
  useDictionaryLabelsWithResource,
} from '../../utils/UtilityFunctions';
import AutocompleteInput from '../common/AutocompleteInput';
import ComponentInput from '../common/ComponentInput';
import CustomDateInput from '../common/CustomDateInput';
import DisciplineInput from '../common/DisciplineInput';
import LocationInput from '../common/LocationInput';
import PunchCategoryInput from '../common/PunchCategoryInput';
import PunchPriorityInput from '../common/PunchPriorityInput';
import SubsystemInput from '../common/SubsystemInput';
import TextInput from '../common/TextInput';
import commonStyles from '../common/commonStyles';
import { editStatuses } from '../pendingPunch/constants';
import { RESOURCE_COMPONENT } from '../constants';
import useAutoSetHighPunchPriorityForRegulatoryItemComponent from '../punch/useAutoSetHighPunchPriorityForRegulatoryItemComponent';
import { originators } from './constants';

interface PendingPunchFormProps {
  bulkEditMode?: boolean;
}

const SubmitPunchForm: React.FC<PendingPunchFormProps> = ({
  bulkEditMode,
}): ReactElement => {
  const { setValue, getValues } = useFormContext();
  const requiredValidation = bulkEditMode ? [] : [required()];
  const isRequired = !bulkEditMode;
  const { labels } = useDictionaryLabelsWithResource();
  const componentId = useWatch({ name: 'componentId' });
  useAutoSetHighPunchPriorityForRegulatoryItemComponent();

  const { data: component } = useGetOne(
    RESOURCE_COMPONENT,
    { id: componentId },
    { enabled: !!componentId }
  );

  useEffect(() => {
    if (!component) {
      return;
    }

    setValue('subsystemId', component.subsystemId);
  }, [component, setValue, getValues]);

  return (
    <Box sx={commonStyles.columnFlexBox}>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="description"
          label="Description"
          sx={{ ...commonStyles.flexBoxLongItem, flexGrow: 1 }}
          fullWidth
          multiline
          validate={[...requiredValidation, maxLength(200)]}
        />
        <SubsystemInput isRequired={isRequired} sx={commonStyles.flexBoxItem} />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <DisciplineInput isRequired={isRequired} />
        <PunchPriorityInput
          source="priorityId"
          sx={commonStyles.flexBoxItem}
          isRequired={isRequired}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <LocationInput sx={commonStyles.flexBoxItem} />
        <PunchCategoryInput
          source="punchCatId"
          sx={commonStyles.flexBoxItem}
          label={labels['punchCatId']}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <ComponentInput
          sx={commonStyles.flexBoxItem}
          source="componentId"
          label="Tag"
        />
        <AutocompleteInput
          source="originator"
          label="Originator"
          sx={commonStyles.flexBoxItem}
          choices={originators}
          disabled
        />
        <AutocompleteInput
          source="status"
          label="Status"
          sx={commonStyles.flexBoxItem}
          choices={editStatuses}
          disabled
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="comments"
          label="Review Comments"
          sx={{ ...commonStyles.flexBoxLongItem, flexGrow: 1 }}
          fullWidth
          multiline
          validate={[maxLength(255)]}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput source="raisedBy" label="Raised By" disabled />
        <CustomDateInput source="raisedDate" label="Raised Date" disabled />
      </Box>
    </Box>
  );
};

export default SubmitPunchForm;
