import React, { ReactElement, useEffect, useMemo } from 'react';
import { minValue, NumberInput, NumberInputProps } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Stack } from '@mui/material';
import { required, integer } from '../../../../utils/UtilityFunctions';
import { MaterialType } from '../../materialPage/types';
import POMaterialsSuggestedQtyInfo from './POMaterialsSuggestedQtyInfo';

export interface POMaterialsQtyOrderedInputProps
  extends Partial<NumberInputProps> {
  material?: MaterialType;
}

const POMaterialsQtyOrderedInput: React.FC<POMaterialsQtyOrderedInputProps> = ({
  material,
  ...rest
}): ReactElement => {
  const { setValue } = useFormContext();

  const suggestedQty = useMemo(() => {
    if (!material) return null;
    return material.maxLevel - material.totalQty;
  }, [material]);

  const defaultQty = useMemo(() => {
    if (!material) return null;
    if (material.defReorderQty) return material.defReorderQty;
  }, [material]);

  useEffect(() => {
    const value = defaultQty || suggestedQty;
    setValue('qtyOrdered', value > 0 ? value : 0);
  }, [setValue, defaultQty, suggestedQty]);

  return (
    <Stack spacing={0}>
      <NumberInput
        {...rest}
        source="qtyOrdered"
        validate={[required(), minValue(0), integer()]}
        min={0}
      />
      {suggestedQty >= 0 && (
        <POMaterialsSuggestedQtyInfo suggestedQty={suggestedQty} />
      )}
    </Stack>
  );
};

export default POMaterialsQtyOrderedInput;
