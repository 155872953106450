import React, { Fragment, ReactElement, useCallback, useState } from 'react';
import {
  useNotify,
  useGetList,
  useUpdateMany,
  useListContext,
  useUnselectAll,
  Button,
  Confirm,
} from 'react-admin';
import { useQueryClient } from '@tanstack/react-query';
import CheckIcon from '@mui/icons-material/Check';
import { CompletedType } from '../../preservationItemActivity/constants';
import { getErrorMessage } from '../../../utils/UtilityFunctions';
import EditTitle from '../../common/EditTitle';

interface BulkEditButtonProps {
  resource: string;
  onUpdate?: () => void;
}

const BulkCloseOutButton: React.FC<BulkEditButtonProps> = ({
  resource,
  onUpdate,
}): ReactElement => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  const [updateMany] = useUpdateMany();
  const { selectedIds } = useListContext();
  const unselectAll = useUnselectAll(resource);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const { data: records = [] } = useGetList(resource, {
    filter: { id: selectedIds },
  });

  const handleOpen = useCallback(() => {
    setIsConfirmOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsConfirmOpen(false);
  }, []);

  const onError = useCallback(
    (error) => {
      notify(getErrorMessage(error), {
        type: 'error',
        undoable: false,
      });
      setIsConfirmOpen(false);
    },
    [setIsConfirmOpen, notify]
  );

  const closeOut = useCallback(
    async () =>
      await updateMany(
        resource,
        { ids: selectedIds, data: { isClosed: true } },
        {
          onSuccess: async () => {
            queryClient.removeQueries({ queryKey: [resource], exact: false });
            notify(`${selectedIds.length} elements updated`, {
              type: 'success',
            });
            unselectAll();
            onUpdate();
          },
          onError,
        }
      ),
    [
      selectedIds,
      resource,
      queryClient,
      unselectAll,
      onError,
      notify,
      onUpdate,
      updateMany,
    ]
  );

  const handleCheck = useCallback(() => {
    setIsConfirmOpen(false);

    for (const record of records) {
      if (record?.['completed'] === CompletedType.ISSUED) {
        notify("Preservations with Status Issued can't be Close Out", {
          type: 'error',
        });
        return;
      }
      if (record?.['completed'] === CompletedType.REJECTED) {
        notify("Preservations with Status Rejected can't be Close Out", {
          type: 'error',
        });
        return;
      }
    }

    if (selectedIds.length > 0) {
      closeOut();
    }
  }, [records, selectedIds, closeOut, notify]);

  return (
    <Fragment>
      <Button label="Close Out Preservation(s)" onClick={handleOpen}>
        <CheckIcon />
      </Button>

      <Confirm
        isOpen={isConfirmOpen}
        title={
          <Fragment>
            Update <EditTitle />
          </Fragment>
        }
        content={
          <Fragment>
            Are you sure you want to update this <EditTitle />?
          </Fragment>
        }
        onConfirm={handleCheck}
        onClose={handleClose}
      />
    </Fragment>
  );
};

export default BulkCloseOutButton;
