import React, { Fragment, ReactElement } from 'react';
import { BooleanInput, maxLength } from 'react-admin';
import Box from '@mui/material/Box';
import CustomDateInput from '../../common/CustomDateInput';
import commonStyles from '../../common/commonStyles';
import RegionInput from '../../common/RegionInput';
import TextInput from '../../../components/common/TextInput';
import { required } from '../../../utils/UtilityFunctions';
import CustomImageInput from '../../common/CustomImageInput';
import AssetInput from './AssetInput';
import AssetGroupInput from './AssetGroupInput';

const ProjectFormProjectTab: React.FC = (): ReactElement => (
  <Fragment>
    <Box sx={commonStyles.bottomMargin}>
      <CustomImageInput label="Logo" source="attachment" />
    </Box>
    <Box sx={commonStyles.flexBox}>
      <TextInput
        source="project"
        label="Project"
        validate={[required(), maxLength(150)]}
        sx={commonStyles.flexBoxLongItem}
        fullWidth
        multiline
      />
    </Box>
    <Box sx={commonStyles.flexBox}>
      <TextInput
        source="description"
        label="Description"
        validate={[maxLength(255)]}
        sx={commonStyles.flexBoxLongItem}
        fullWidth
        multiline
      />
      <TextInput
        source="location"
        label="Location"
        validate={[maxLength(75)]}
        sx={commonStyles.flexBoxItem}
        fullWidth
      />
    </Box>
    <Box sx={commonStyles.flexBox}>
      <RegionInput />
      <AssetInput sx={commonStyles.flexBoxItem} />
      <AssetGroupInput sx={commonStyles.flexBoxItem} />
    </Box>
    <Box sx={commonStyles.flexBox}>
      <TextInput
        source="projectContractNo"
        label="Project Contract No"
        validate={[maxLength(25)]}
        sx={commonStyles.flexBoxItem}
      />
      <CustomDateInput
        isRequired
        source="startDate"
        label="Start Date"
        sx={commonStyles.flexBoxItem}
      />
      <CustomDateInput
        isRequired
        source="plannedStopDate"
        label="Planned completion date"
        sx={commonStyles.flexBoxItem}
      />
      <CustomDateInput
        source="stopDate"
        label="Actual completion date"
        sx={commonStyles.flexBoxItem}
      />
    </Box>
    <Box sx={commonStyles.flexBox}>
      <BooleanInput
        source="isItrMobile"
        label="Mobile ITRs"
        defaultValue={false}
        sx={commonStyles.flexBoxItem}
      />
      <BooleanInput
        source="isPresMobile"
        label="Mobile Preservations"
        defaultValue={false}
        sx={commonStyles.flexBoxItem}
      />
      <BooleanInput
        source="isFrequentlyUsed"
        label="Frequent"
        sx={commonStyles.flexBoxItem}
      />
    </Box>
    <Box sx={commonStyles.flexBox}>
      <BooleanInput
        source="isDuplicateItrEnabled"
        label="Duplicate ITR Enabled"
        helperText="It controls ITR Duplication feature for Mobile App users"
        defaultValue={true}
        sx={commonStyles.flexBoxItem}
      />
      <BooleanInput
        source="isItrPunchForcedCreationEnabled"
        label="ITR Punch Forced Creation Enabled"
        helperText="Allows to force user to create ITR Punch on 'punchlist' radio button click"
        defaultValue={false}
        sx={commonStyles.flexBoxItem}
      />
      <BooleanInput
        source="isPresPunchForcedCreationEnabled"
        label="Preservation Punch Forced Creation Enabled"
        helperText="Allows to force user to create Preservation Punch on 'punchlist' radio button click"
        defaultValue={false}
        sx={commonStyles.flexBoxItem}
      />
      <BooleanInput
        source="isPinCodeEntryEnabled"
        label="Pin Code Entry Enabled"
        helperText="It controls the use of Pin Code instead of Wet Signature in the Mobile App"
        defaultValue={false}
        sx={commonStyles.flexBoxItem}
      />
    </Box>
    <Box sx={commonStyles.flexBox}>
      <BooleanInput
        source="isArchived"
        label="Archived"
        sx={commonStyles.flexBoxItem}
      />
      <CustomDateInput
        source="archivedDate"
        label="Archived Date"
        sx={commonStyles.flexBoxItem}
      />
    </Box>
  </Fragment>
);

export default ProjectFormProjectTab;
