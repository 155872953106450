import { Identifier } from 'react-admin';
import get from 'lodash/get';
import { getContext, UserContext } from '../../../provider/userContext';

const canUserAccessProject = (projectId: Identifier): boolean => {
  const ctx: UserContext = getContext();
  const isCurrentUserProject = get(
    ctx,
    `companies.${ctx.company}.projects[${projectId}]`,
    false
  );

  return !!isCurrentUserProject;
};

export default canUserAccessProject;
