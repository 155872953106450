import React, { useCallback } from 'react';
import { Button, useGetRecordId, useRefresh, useRedirect } from 'react-admin';
import { useQueryClient } from '@tanstack/react-query';
import Box from '@mui/material/Box';
import { updateContext } from '../../../provider/userContext';
import { clearLocalStorageListParamsFilter } from '../../../utils/UtilityFunctions';
import useRefreshCompanyContext from '../../common/hooks/useRefreshCompanyContext';
import { TextFieldPropsWithOptionalSource } from '../../common/types';
import commonStyles from '../../common/commonStyles';

const ColumnActions: React.FC<
  TextFieldPropsWithOptionalSource & { handleClose: () => void }
> = ({ handleClose }) => {
  const queryClient = useQueryClient();
  const refreshCompanyContext = useRefreshCompanyContext();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const recordId = Number(useGetRecordId());

  const handleSelect = useCallback(async () => {
    updateContext({ projectId: recordId });
    queryClient.getQueryCache().clear();
    clearLocalStorageListParamsFilter();
    await refreshCompanyContext();
    handleClose();
    refresh();
    redirect('/');
  }, [
    recordId,
    queryClient,
    refreshCompanyContext,
    handleClose,
    refresh,
    redirect,
  ]);

  return (
    <Box sx={commonStyles.columnActionsBox}>
      <Button
        onClick={handleSelect}
        label="Select"
        className="projectSelectButton"
      />
    </Box>
  );
};

export default ColumnActions;
