import React, { ReactElement, useCallback, useMemo } from 'react';
import { required } from '../../utils/UtilityFunctions';
import { RESOURCE_MATERIAL_STORAGE } from '../constants';
import AutocompleteReferenceInput from './AutocompleteReferenceInput';

interface MaterialStorageInputProps
  extends Partial<React.ComponentProps<typeof AutocompleteReferenceInput>> {
  additionalFilter?: object;
}

const MaterialStorageInput: React.FC<MaterialStorageInputProps> = ({
  isRequired,
  onChange,
  additionalFilter,
  ...rest
}): ReactElement => {
  const requestParams = useMemo(() => {
    return {
      filter: {
        ...additionalFilter,
      },
      sort: { field: 'material.material', order: 'ASC' as const },
    };
  }, [additionalFilter]);

  const optionText = useCallback((o) => {
    return `${o.material.material} :: ${o.storage.storageLocation} :: ${o.qtyOnHand}`;
  }, []);

  return (
    <AutocompleteReferenceInput
      {...rest}
      resource={RESOURCE_MATERIAL_STORAGE}
      requestParams={requestParams}
      optionText={optionText}
      validate={isRequired ? [required()] : []}
      handleOnChange={onChange}
      includeFields={[
        'id',
        'material.material',
        'storage.storageLocation',
        'qtyOnHand',
        'materialId',
        'storageId',
      ]}
    />
  );
};

MaterialStorageInput.defaultProps = {
  source: 'materialStorageId',
  label: 'Material',
};

export default MaterialStorageInput;
