import React, { ReactElement } from 'react';
import { Card, CardContent } from '@mui/material';
import { Button, Filter } from 'react-admin';
import isEmpty from 'lodash/isEmpty';
import sideFilterStyles from '../common/sideFilterStyles';
import useSidebarFilters from '../common/hooks/useSidebarFilters';
import { SideFilter } from '../common/types';
import { RESOURCE_DRAWING } from '../constants';
import GroupInput from '../common/GroupInput';
import DrawingTypeInput from '../common/DrawingTypeInput';
import LocationInput from '../common/LocationInput';
import DateRangeFilter from '../common/DateRangeFilter';
import TextInput from '../common/TextInput';
import DrawingDisciplineInput from './components/DrawingDisciplineInput';

const DrawingSideFilters: React.FC<SideFilter> = ({
  defaultFilter,
}): ReactElement => {
  const {
    data,
    isLoading,
    filterValues,
    dateFrom,
    dateTo,
    handleDateFromChange,
    handleDateToChange,
    handleClearFilters,
  } = useSidebarFilters(RESOURCE_DRAWING, defaultFilter);

  if (!data || (!data.length && isEmpty(filterValues)) || isLoading) {
    return null;
  }

  return (
    <Card sx={sideFilterStyles.sideFiltersCard}>
      <CardContent sx={sideFilterStyles.sideFiltersCardContainer}>
        <Filter>
          <TextInput
            label="Drawing"
            name="drawingName_inc"
            source="drawingName"
            fullWidth
            alwaysOn
          />
          <TextInput
            label="Description"
            name="description_inc"
            source="description"
            fullWidth
            alwaysOn
          />
          <TextInput
            label="Sheet"
            name="sheet_inc"
            source="sheet"
            fullWidth
            alwaysOn
          />
          <GroupInput
            category={['DRAWING_ENGINEERING_GRP']}
            alwaysOn
            multiple
          />
          <DrawingTypeInput alwaysOn multiple />
          <DrawingDisciplineInput alwaysOn multiple />
          <LocationInput alwaysOn multiple />
          <DateRangeFilter
            label="Updated"
            source="updatedAt"
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
          />
          <TextInput
            label="Updated By"
            name="updatedBy_inc"
            source="updatedBy"
            fullWidth
            alwaysOn
          />
          <TextInput
            label="Updated By Name"
            name="updatedByName_inc"
            source="updatedByName"
            fullWidth
            alwaysOn
          />
        </Filter>
        <Button label="Clear filters" onClick={handleClearFilters} />
      </CardContent>
    </Card>
  );
};

export default DrawingSideFilters;
