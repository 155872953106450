import { SmartPreservationItemActivityType } from '../types';
import { RED, GREEN, GREY, ORANGE } from '../../../theme/colors';

export const legends = [
  {
    label: 'Not Completed',
    color: ORANGE,
  },
  {
    label: 'Completed',
    color: GREEN,
  },
  {
    label: 'Not Required',
    color: GREY,
  },
  {
    label: 'Rejected',
    color: RED,
  },
  {
    label: 'Issued',
    color: 'transparent',
  },
];

export const getRowBorderColor = (
  record: SmartPreservationItemActivityType
) => {
  switch (record.completed) {
    case 'NO':
      return ORANGE;
    case 'REJECTED':
      return RED;
    case 'YES':
      return GREEN;
    case 'NA':
      return GREY;
    default:
      return 'transparent';
  }
};

export const getRowStyle = (
  record: SmartPreservationItemActivityType,
  expanded = []
) => ({
  backgroundColor: expanded.includes(record.id)
    ? 'rgba(0, 0, 0, 0.04)'
    : 'initial',
  borderLeftColor: getRowBorderColor(record),
  borderLeftWidth: 8,
  borderLeftStyle: 'solid',
});
