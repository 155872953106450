import React, { ReactElement, useEffect, useState } from 'react';
import { useRecordContext } from 'ra-core';
import get from 'lodash/get';
import clsx from 'clsx';
import lucidDiagramDataProvider from '../../../provider/dataProvider/providers/lucidDiagramDataProvider';
import { lucidDocumentViewUrl } from '../constants';
import { FieldPropsWithOptionalSource } from '../../common/types';
import LucidDiagramUnavailableMessage from './LucidDiagramUnavailableMessage';
import './styles.css';

interface LucidEmbedViewProps extends FieldPropsWithOptionalSource {
  className?: string;
}

const LucidEmbedView: React.FC<LucidEmbedViewProps> = (props): ReactElement => {
  const { className } = props;
  const record = useRecordContext(props);
  const documentId = get(record, 'documentId');
  const [isLoading, setIsLoading] = useState(true);
  const [isAvailable, setIsAvailable] = useState(false);

  useEffect(() => {
    lucidDiagramDataProvider.isDocumentAvailable(documentId).then((result) => {
      setIsAvailable(result);
      setIsLoading(false);
    });
  }, [documentId, setIsLoading, setIsAvailable]);

  if (isLoading) return null;

  if (!isAvailable) {
    return <LucidDiagramUnavailableMessage documentId={documentId} />;
  }

  return (
    <iframe
      className={clsx('lucid-embed', className)}
      src={lucidDocumentViewUrl(documentId)}
    />
  );
};

export default LucidEmbedView;
