import React from 'react';
import { Datagrid, ReferenceManyField, TextField } from 'react-admin';
import { RESOURCE_AUDIT } from '../constants';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import { CustomPagination } from '../common/CustomPagination';
import DifferenceField from './components/DifferenceField';
import AuditActions from './components/AuditActions';

interface TableAuditListProps {
  tableName?: string;
}

const AuditListTab: React.FC<TableAuditListProps> = ({ tableName }) => (
  <ReferenceManyField
    reference={RESOURCE_AUDIT}
    target="recordId"
    filter={{ tableName }}
    sort={{ field: 'updatedAt', order: 'DESC' }}
    pagination={<CustomPagination sx={{ width: '100%' }} />}
  >
    <AuditActions />
    <Datagrid bulkActionButtons={false} rowClick={false} sx={{ width: '100%' }}>
      <TextField source="groupAction" label="Group Action" />
      <TextField source="action" label="Action" />
      <DifferenceField source="diff" label="Difference" sortable={false} />
      {renderMetaDataFields(true)}
    </Datagrid>
  </ReferenceManyField>
);

export default AuditListTab;
