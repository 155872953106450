import { ReactElement } from 'react';
import { SimpleForm } from 'react-admin';
import FooterToolbar from '../common/FooterToolbar';
import { getContext, UserContext } from '../../provider/userContext';
import CreateWithTitle from '../common/CreateWithTitle';
import { CertTemplate } from './types';
import CertTemplateForm from './components/CertTemplateForm';

const CertTemplateCreate: React.FC = (): ReactElement => {
  const ctx: UserContext = getContext();

  const transform = (data: CertTemplate): CertTemplate => ({
    ...data,
    divisionId: ctx?.defaultDivisionId,
  });

  return (
    <CreateWithTitle transform={transform} redirect="list">
      <SimpleForm toolbar={<FooterToolbar />}>
        <CertTemplateForm />
      </SimpleForm>
    </CreateWithTitle>
  );
};

export default CertTemplateCreate;
