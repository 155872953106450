import React, { Fragment, ReactElement } from 'react';
import { Datagrid, ReferenceManyField, TextField } from 'react-admin';
import renderMetaDataFields from '../../common/helpers/renderMetaDataFields';
import { RESOURCE_EQUIPMENT_TYPE_TEST_EQUIPMENT_TYPE } from '../../constants';
import ColumnActions from './ColumnActions';
import TestEquipmentTypeCreateDialog from './TestEquipmentTypeCreateDialog';
import TestEquipTypeField from './TestEquipTypeField';

interface TestEquipmentTypeTabProps {
  onlyShow?: boolean;
}

const TestEquipmentTypeTab: React.FC<TestEquipmentTypeTabProps> = ({
  onlyShow,
}): ReactElement => {
  return (
    <ReferenceManyField
      reference={RESOURCE_EQUIPMENT_TYPE_TEST_EQUIPMENT_TYPE}
      target="equipTypeId"
      sort={{ field: 'id', order: 'ASC' }}
    >
      <Fragment>
        <Datagrid
          bulkActionButtons={false}
          rowClick={false}
          sx={{ width: '100%' }}
        >
          <TextField source="id" label="ID" />
          <TestEquipTypeField />
          {renderMetaDataFields()}
          {!onlyShow && <ColumnActions label="Actions" />}
        </Datagrid>
        {!onlyShow && <TestEquipmentTypeCreateDialog />}
      </Fragment>
    </ReferenceManyField>
  );
};

export default TestEquipmentTypeTab;
