import React, { ReactElement } from 'react';
import { TextField, Labeled, TabbedShowLayout } from 'react-admin';
import Box from '@mui/material/Box';
import commonStyles from '../common/commonStyles';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import renderShowMetaDataFields from '../common/helpers/renderShowMetaDataFields';
import AuditListTab from '../audit/AuditListTab';
import { RESOURCE_VENDOR_CONTACT } from '../constants';
import ContactShowTab from '../common/ContactFormTab/ContactShowTab';

const VendorShow: React.FC<ShowComponentProps> = ({
  syncWithLocation,
  ...rest
}): ReactElement => (
  <ShowComponent {...rest}>
    <TabbedShowLayout
      sx={commonStyles.bottomMargin}
      syncWithLocation={syncWithLocation}
    >
      <TabbedShowLayout.Tab label="Vendor">
        <Box>
          <Labeled label="Vendor">
            <TextField source="vendor" sx={commonStyles.flexBoxItem} />
          </Labeled>
          <Labeled label="Responsibility">
            <TextField source="responsibility" sx={commonStyles.flexBoxItem} />
          </Labeled>
        </Box>
        <Labeled label="Description">
          <TextField
            source="description"
            fullWidth
            sx={commonStyles.flexBoxItem}
          />
        </Labeled>
        <Box>{renderShowMetaDataFields()}</Box>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Contact">
        <ContactShowTab
          target="vendor_id"
          reference={RESOURCE_VENDOR_CONTACT}
        />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="History">
        <AuditListTab tableName="tbl_vendor" />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </ShowComponent>
);

export default VendorShow;
