import { Labeled, TabbedShowLayout, TextField } from 'react-admin';
import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import commonStyles from '../common/commonStyles';
import renderShowMetaDataFields from '../common/helpers/renderShowMetaDataFields';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import AuditListTab from '../audit/AuditListTab';

const DisciplineTestShow: React.FC<ShowComponentProps> = ({
  syncWithLocation,
  ...rest
}): ReactElement => (
  <ShowComponent {...rest}>
    <TabbedShowLayout
      sx={commonStyles.bottomMargin}
      syncWithLocation={syncWithLocation}
    >
      <TabbedShowLayout.Tab label="Discipline Test">
        <Box>
          <Labeled label="Discipline Test">
            <TextField source="disciplineTest" sx={commonStyles.flexBoxItem} />
          </Labeled>
          <Labeled label="Discipline Test Code">
            <TextField
              source="disciplineTestCode"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled label="Resource">
            <TextField source="resource" sx={commonStyles.flexBoxItem} />
          </Labeled>
        </Box>
        <Box>{renderShowMetaDataFields()}</Box>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="History">
        <AuditListTab tableName="tbl_discipline_test" />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </ShowComponent>
);

export default DisciplineTestShow;
