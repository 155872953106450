import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import SmartLinkField from '../../common/SmartLinkField';
import { RESOURCE_TEST_EQUIPMENT_TYPE } from '../../constants';

interface TestEquipTypeFieldProps {
  label?: string;
  source?: string;
  sortBy?: string;
  sx?: object;
}

const TestEquipTypeField: React.FC<TestEquipTypeFieldProps> = ({
  label,
  source,
  sortBy,
  sx,
}): ReactElement => (
  <SmartLinkField
    label={label}
    source={source}
    resource={RESOURCE_TEST_EQUIPMENT_TYPE}
    sortBy={sortBy}
  >
    <TextField source="testEquipType.type" sx={sx} />
  </SmartLinkField>
);

TestEquipTypeField.defaultProps = {
  label: 'Test Equipment Type',
  source: 'testEquipType.id',
  sortBy: 'testEquipType.type',
};

export default TestEquipTypeField;
