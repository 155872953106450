import React, { ReactElement } from 'react';
import { BooleanField } from 'react-admin';
import { SxProps, Theme } from '@mui/material/styles';

type CustomBooleanFieldProps = {
  source: string;
  label?: string;
  sx?: SxProps<Theme>;
  sortBy?: string;
};

const CustomBooleanField: React.FC<CustomBooleanFieldProps> = ({
  source,
  label,
  sx,
  sortBy,
}): ReactElement => (
  <BooleanField
    source={source}
    label={label}
    FalseIcon={null}
    sx={sx}
    sortBy={sortBy}
  />
);

export default CustomBooleanField;
