import React, { ReactElement, useCallback } from 'react';
import { Identifier, useNotify } from 'react-admin';
import { Notification } from '../type';
import {
  downloadFileByLink,
  getErrorMessage,
} from '../../../utils/UtilityFunctions';
import NotificationItemCard from './NotificationItemCard';

interface ExportNotificationProps {
  notification: Notification;
  readNotification: (id: Identifier) => void;
}

const ExportNotification: React.FC<ExportNotificationProps> = ({
  notification,
  readNotification,
}): ReactElement => {
  const notify = useNotify();

  const handleOpenNotification = useCallback(() => {
    try {
      downloadFileByLink(notification?.details?.downloadUrl);
    } catch (error) {
      notify(getErrorMessage(error), {
        type: 'error',
        undoable: false,
      });
    }
  }, [notification?.details?.downloadUrl, notify]);

  return (
    <NotificationItemCard
      notification={notification}
      readNotification={readNotification}
      handleOpenNotification={handleOpenNotification}
    />
  );
};

export default ExportNotification;
