import React, { Fragment, ReactElement } from 'react';
import Box from '@mui/material/Box';
import FreeTextSearchFilter from '../../../common/FreeTextSearchFilter';
import { styles } from '../../styles';
import AttachmentAdditionalFilters from './AttachmentAdditionalFilters';
import RefreshAttachmentList from './RefreshAttachmentList';

const AttachmentSelectionFilters: React.FC = (): ReactElement => {
  return (
    <Fragment>
      <Box sx={styles.attachmentSearchFilter}>
        <FreeTextSearchFilter />
      </Box>
      <AttachmentAdditionalFilters />
      <RefreshAttachmentList />
    </Fragment>
  );
};

export default AttachmentSelectionFilters;
