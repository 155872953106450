import React, { ReactElement } from 'react';
import {
  Button,
  useDataProvider,
  useNotify,
  useRecordContext,
} from 'react-admin';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getErrorMessage } from '../../../utils/UtilityFunctions';
import { RESOURCE_ATTACHMENT_REVISION } from '../../constants';
import { PullAndPushButtonProps } from '../types';

const PullJSONFormFromMasterCopyButton: React.FC<PullAndPushButtonProps> = ({
  resource,
  resourceId,
}): ReactElement => {
  const record = useRecordContext();
  const queryClient = useQueryClient();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const { mutate: pullJSONFormFromMasterTemplate, isPending: pullInProgress } =
    useMutation({
      mutationFn: async () => {
        return await dataProvider.pullJSONFormFromMasterTemplate(
          RESOURCE_ATTACHMENT_REVISION,
          {
            id: record?.id,
            resource,
            resourceId,
          }
        );
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: [RESOURCE_ATTACHMENT_REVISION],
        });
        notify('Successfully Pulled JSON Form.', {
          type: 'success',
          undoable: false,
        });
      },
      onError: (error) => {
        notify('Pull JSON Form Error: ' + getErrorMessage(error), {
          type: 'error',
          undoable: false,
        });
      },
    });

  return (
    <Button
      label={pullInProgress ? 'Pulling...' : 'Pull From Master Copy'}
      onClick={() => {
        pullJSONFormFromMasterTemplate();
      }}
      size="medium"
      variant="contained"
      sx={{ marginTop: '10px' }}
    />
  );
};

export default PullJSONFormFromMasterCopyButton;
