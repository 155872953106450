import React, { ReactElement } from 'react';
import { Labeled, TabbedShowLayout, TextField, DateField } from 'react-admin';
import Box from '@mui/material/Box';
import renderShowMetaDataFields from '../common/helpers/renderShowMetaDataFields';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import PunchPriorityField from '../common/PunchPriorityField';
import PunchCategoryField from '../common/PunchCategoryField';
import DisciplineField from '../common/DisciplineField';
import ImageGallery from '../imageGallery/ImageGallery';
import SubsystemField from '../common/SubsystemField';
import LocationField from '../common/LocationField';
import commonStyles from '../common/commonStyles';
import SelectField from '../common/SelectField';
import AuditListTab from '../audit/AuditListTab';
import ComponentField from '../common/ComponentField';
import { useDictionaryLabelsWithResource } from '../../utils/UtilityFunctions';
import { statuses } from './constants';

const PendingPunchShow: React.FC<ShowComponentProps> = ({
  syncWithLocation,
  ...rest
}): ReactElement => {
  const { labels } = useDictionaryLabelsWithResource();

  return (
    <ShowComponent {...rest}>
      <TabbedShowLayout
        sx={commonStyles.bottomMargin}
        syncWithLocation={syncWithLocation}
      >
        <TabbedShowLayout.Tab
          label="Smart Punch Review"
          id="pending_punch_tab"
          sx={commonStyles.formTabHeader}
        >
          <Box sx={commonStyles.columnFlexBox}>
            <Box>
              <Labeled label="Description" sx={commonStyles.flexBoxItem}>
                <TextField source="description" fullWidth />
              </Labeled>
              <Labeled label="SybSystem" sx={commonStyles.flexBoxItem}>
                <SubsystemField />
              </Labeled>
              <Labeled label="Tag" sx={commonStyles.flexBoxItem}>
                <ComponentField />
              </Labeled>
            </Box>
            <Box>
              <Labeled label="Discipline" sx={commonStyles.flexBoxItem}>
                <DisciplineField />
              </Labeled>
              <Labeled label="Priority" sx={commonStyles.flexBoxItem}>
                <PunchPriorityField />
              </Labeled>
            </Box>
            <Box>
              <Labeled label="Location" sx={commonStyles.flexBoxItem}>
                <LocationField source="locationId" />
              </Labeled>
              <Labeled
                label={labels['punchCatId']}
                sx={commonStyles.flexBoxItem}
              >
                <PunchCategoryField source="punchCatId" />
              </Labeled>
            </Box>
            <Box>
              <Labeled label="Raised By" sx={commonStyles.flexBoxLongItem}>
                <TextField source="raisedBy" />
              </Labeled>
              <Labeled label="Raised Date" sx={commonStyles.flexBoxItem}>
                <DateField source="raisedDate" />
              </Labeled>
            </Box>
            <Box>
              <Labeled label="Status">
                <SelectField
                  source="status"
                  sx={commonStyles.flexBoxItem}
                  choices={statuses}
                />
              </Labeled>

              <Labeled label="Review Comments">
                <TextField
                  source="comments"
                  sx={commonStyles.flexBoxLongItem}
                  fullWidth
                />
              </Labeled>
            </Box>
            <Box>{renderShowMetaDataFields()}</Box>
          </Box>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab
          label="Image Gallery"
          id="gallery_tab"
          sx={commonStyles.formTabHeader}
        >
          <ImageGallery targetId="pendingPunchId" onlyShow />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="History">
          <AuditListTab tableName="tbl_pending_punch" />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </ShowComponent>
  );
};

export default PendingPunchShow;
