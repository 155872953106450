import React, { ReactElement } from 'react';
import {
  Labeled,
  TabbedShowLayout,
  TextField,
  usePermissions,
} from 'react-admin';
import Box from '@mui/material/Box';
import { canAccess } from '@react-admin/ra-rbac';
import renderShowMetaDataFields from '../common/helpers/renderShowMetaDataFields';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import DisciplineField from '../common/DisciplineField';
import commonStyles from '../common/commonStyles';
import AuditListTab from '../audit/AuditListTab';
import { RESOURCE_POSITION } from '../constants';
import { FieldPermissionActions } from '../common/constants';
import CurrencyField from './components/CurrencyField';

const PositionShow: React.FC<ShowComponentProps> = ({
  syncWithLocation,
  ...rest
}): ReactElement => {
  const { permissions } = usePermissions();

  return (
    <ShowComponent {...rest}>
      <TabbedShowLayout
        sx={commonStyles.bottomMargin}
        syncWithLocation={syncWithLocation}
      >
        <TabbedShowLayout.Tab label="Position">
          <Box>
            <Labeled>
              <TextField
                source="position"
                label="Position"
                sx={commonStyles.flexBoxItem}
              />
            </Labeled>
            <Labeled>
              <TextField
                source="description"
                label="Description"
                sx={commonStyles.flexBoxItem}
              />
            </Labeled>
            <Labeled sx={commonStyles.flexBoxItem}>
              <DisciplineField source="disciplineId" />
            </Labeled>
          </Box>
          <Box>
            {canAccess({
              permissions,
              action: FieldPermissionActions.show,
              resource: `${RESOURCE_POSITION}.billRate`,
            }) && (
              <Labeled sx={commonStyles.flexBoxItem}>
                <TextField source="billRate" />
              </Labeled>
            )}
            {canAccess({
              permissions,
              action: FieldPermissionActions.show,
              resource: `${RESOURCE_POSITION}.currencyId`,
            }) && (
              <Labeled sx={commonStyles.flexBoxItem}>
                <CurrencyField />
              </Labeled>
            )}
          </Box>

          <Box>{renderShowMetaDataFields()}</Box>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="History">
          <AuditListTab tableName="tbl_position" />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </ShowComponent>
  );
};

export default PositionShow;
