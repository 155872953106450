import React, { ReactElement, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { UISchemaElement, JsonSchema } from '@jsonforms/core';
import Box from '@mui/material/Box';
import useCurrentProjectSettings from '../../common/hooks/useCurrentProjectSettings';
import { getDiffObject, getStepActions } from '../../common/JSONForm/helpers';
import { getContext, UserContext } from '../../../provider/userContext';
import useCanAccess from '../../common/hooks/useCanAccess';
import JSONForm from '../../common/JSONForm/JSONForm';
import TextInput from '../../common/TextInput';
import { SmartTagItr } from '../types';

interface JSONFormTabProps {
  formSchema: JsonSchema;
  formUiSchema: UISchemaElement;
  formData: object;
  formDataKey?: string;
  setPendingPunchTitle: (title: string | null) => void;
  setPendingPunchCreateDialogCloseDisabled: (value: boolean) => void;
}

const JSONFormTab: React.FC<JSONFormTabProps> = ({
  formSchema,
  formUiSchema,
  formData,
  formDataKey = 'formData',
  setPendingPunchTitle,
  setPendingPunchCreateDialogCloseDisabled,
}): ReactElement => {
  const ctx: UserContext = getContext();
  const canAccessList = useCanAccess();
  const { getValues, setValue } = useFormContext<SmartTagItr>();
  const {
    currentProjectSettings: { isItrPunchForcedCreationEnabled },
  } = useCurrentProjectSettings();

  const onChange = useCallback(
    (errors: object[], data: object) => {
      // Remove Required function in JSON forms
      // https://trackertech.monday.com/boards/195709125/views/141874149/pulses/7315991016
      // if (data && errors.length === 0) {
      if (data) {
        const formDataValues = getValues(formDataKey) || {};
        const { diffObj, op, key } = getDiffObject(formDataValues, data);

        if (diffObj && key) {
          data['stepActions'] = {
            ...(data['stepActions'] || {}),
            ...getStepActions(key, op, ctx?.fullName),
          };

          // Open Create Pending Punch Dialog
          if (diffObj[0]?.value === 'punchlist') {
            let title = formSchema?.properties?.[key]?.title || null;
            // If it is an array component - open the punch create dialog with empty title
            const fieldKey = key.split('.')[0];
            if (formSchema?.properties?.[fieldKey]?.type === 'array') {
              title = '';
            }
            setPendingPunchTitle(title);
            if (isItrPunchForcedCreationEnabled) {
              setPendingPunchCreateDialogCloseDisabled(true);
            }
          }
        }

        setValue(formDataKey, data, { shouldDirty: true });
      }
    },
    [
      getValues,
      setValue,
      formDataKey,
      ctx?.fullName,
      setPendingPunchTitle,
      formSchema?.properties,
      isItrPunchForcedCreationEnabled,
      setPendingPunchCreateDialogCloseDisabled,
    ]
  );

  return (
    <Box>
      <TextInput name={formDataKey} source={formDataKey} hidden={true} />
      <TextInput
        name="attachmentRevisionId"
        source="attachmentRevisionId"
        hidden={true}
      />
      <JSONForm
        formSchema={formSchema}
        formUiSchema={formUiSchema}
        formData={formData}
        readonly={!canAccessList.edit}
        onChange={onChange}
      />
    </Box>
  );
};

export default JSONFormTab;
