import React, { ReactElement, useMemo } from 'react';
import { AutocompleteInput } from 'react-admin';
import { getContext, UserContext } from '../../provider/userContext';
import { RESOURCE_ACTIVITY_PHASE } from '../constants';
import { required } from '../../utils/UtilityFunctions';
import AutocompleteReferenceInput from './AutocompleteReferenceInput';

interface ActivityPhaseInputProps
  extends React.ComponentProps<typeof AutocompleteInput> {
  isRequired?: boolean;
}

const ActivityPhaseInput: React.FC<ActivityPhaseInputProps> = ({
  isRequired,
  ...rest
}): ReactElement => {
  const ctx: UserContext = getContext();
  const requestParams = useMemo(() => {
    return {
      filter: {
        projectId: ctx?.projectId,
      },
      sort: { field: 'phase', order: 'ASC' as const },
    };
  }, [ctx?.projectId]);

  return (
    <AutocompleteReferenceInput
      {...rest}
      resource={RESOURCE_ACTIVITY_PHASE}
      requestParams={requestParams}
      label="Phase"
      name="phase_inc"
      optionText="phase"
      validate={isRequired ? required() : undefined}
      includeFields={['id', 'phase']}
    />
  );
};

ActivityPhaseInput.defaultProps = {
  source: 'activityPhaseId',
};

export default ActivityPhaseInput;
