import React, { ReactElement } from 'react';
import { maxLength } from 'react-admin';
import Box from '@mui/material/Box';
import SelectInputWithFreeText from '../common/SelectInputWithFreeText';
import TabbedEditForm from '../common/TabbedEditForm';
import MetaDataInputs from '../common/MetaDataInputs';
import commonStyles from '../common/commonStyles';
import LazyFormTab from '../common/LazyFormTab';
import HistoryTab from '../common/HistoryTab';
import { RESOURCE_VENDOR_CONTACT } from '../constants';
import ContactFormTab from '../common/ContactFormTab/ContactFormTab';
import { EditPropsWithLocation } from '../common/types';
import TextInput from '../common/TextInput';
import { required } from '../../utils/UtilityFunctions';
import { Vendor } from './types';
import { responsibilityChoices } from './constants';

const VendorEdit: React.FC<EditPropsWithLocation<Vendor>> = (
  props
): ReactElement => (
  <TabbedEditForm {...props}>
    <LazyFormTab label="Vendor" id="vendor_tab" sx={commonStyles.formTabHeader}>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="vendor"
          label="Vendor"
          validate={[required(), maxLength(100)]}
          sx={commonStyles.flexBoxItem}
        />
        <SelectInputWithFreeText
          source="responsibility"
          label="Responsibility"
          options={responsibilityChoices}
          sx={commonStyles.flexBoxItem}
        />
      </Box>
      <Box sx={commonStyles.createBoxHalfWidth}>
        <TextInput
          source="description"
          label="Description"
          validate={maxLength(255)}
          sx={commonStyles.flexBoxItem}
          fullWidth
          multiline
        />
      </Box>
      <MetaDataInputs />
    </LazyFormTab>
    <LazyFormTab
      label="Contacts"
      id="contacts_tab"
      sx={commonStyles.formTabHeader}
    >
      <ContactFormTab
        target="vendor_id"
        reference={RESOURCE_VENDOR_CONTACT}
        field="vendorId"
      />
    </LazyFormTab>
    <HistoryTab tableName="tbl_vendor" />
  </TabbedEditForm>
);

export default VendorEdit;
