import { Labeled, TabbedShowLayout, TextField } from 'react-admin';
import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import commonStyles from '../common/commonStyles';
import renderShowMetaDataFields from '../common/helpers/renderShowMetaDataFields';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import GatingTestTypesField from './components/gatingFields/GatingTestTypesField';
import GatingPunchPrioritiesField from './components/gatingFields/GatingPunchPrioritiesField';
import GatingCertTemplatesField from './components/gatingFields/GatingCertTemplatesField';

const CertGatingShow: React.FC<ShowComponentProps> = ({
  syncWithLocation,
  ...rest
}): ReactElement => (
  <ShowComponent {...rest}>
    <TabbedShowLayout
      sx={commonStyles.bottomMargin}
      syncWithLocation={syncWithLocation}
    >
      <TabbedShowLayout.Tab label="Certificate">
        <Box>
          <Labeled sx={commonStyles.flexBoxItem}>
            <TextField source="certName" label="Certificate" />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxLongItem}>
            <TextField source="description" label="Description" />
          </Labeled>
        </Box>
        <Box>
          <Labeled sx={commonStyles.flexBoxItem}>
            <GatingTestTypesField />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <GatingPunchPrioritiesField />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <GatingCertTemplatesField label="Predecessor Certificates" />
          </Labeled>
        </Box>
        <Box>{renderShowMetaDataFields()}</Box>
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </ShowComponent>
);

export default CertGatingShow;
