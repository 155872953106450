import React, { ReactElement } from 'react';
import { ArrayInput, FormDataConsumer, SimpleFormIterator } from 'react-admin';
import { getContext, UserContext } from '../../../provider/userContext';
import { required } from '../../../utils/UtilityFunctions';
import ProjectEditRow from './ProjectEditRow';

const ProjectsInputArrayField: React.FC = (): ReactElement => {
  const ctx: UserContext = getContext();

  return (
    <ArrayInput
      label="Projects"
      source={`companies.${ctx?.company}.projects`}
      name={`companies.${ctx?.company}.projects`}
      validate={[required()]}
    >
      <SimpleFormIterator inline disableReordering>
        <FormDataConsumer>
          {({ scopedFormData }) => (
            <ProjectEditRow scopedFormData={scopedFormData} />
          )}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export default ProjectsInputArrayField;
