import { Button } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import lodash, { maxBy } from 'lodash';
import { Confirm } from 'ra-ui-materialui';
import React, { Fragment, ReactElement, useCallback, useMemo } from 'react';

import { useDataProvider, useNotify } from 'react-admin';
import {
  ensureArray,
  getErrorMessage,
  isEmpty,
} from '../../../utils/UtilityFunctions';
import useDialogState from '../../common/hooks/useDialogState';
import { RESOURCE_AUDIT } from '../../constants';
import { Audit } from '../types';
import RestoreButtonConfirmContent from './RestoreButtonConfirmContent';

interface RestoreButtonProps {
  audit: Audit | Audit[];
  onComplete?: () => void;
}

const RestoreButton: React.FC<RestoreButtonProps> = ({
  audit,
  onComplete,
}): ReactElement => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const {
    isVisible: isConfirmVisible,
    show: showConfirm,
    hide: closeConfirm,
  } = useDialogState(false);

  const originalAudit = useMemo(() => ensureArray(audit), [audit]);

  /*
    Filter audits with action != CREATE, with hard-deleted attachments, or with preservation tables
    For the case when different audits for the same entity record are selected,
    choose the recent on.
  */

  const auditData = useMemo(() => {
    return lodash(originalAudit)
      .filter(({ action, tableName, diff }) => {
        const isSystemChange =
          diff?.[0]?.path?.length === 1 &&
          ['hasAttachment'].includes(diff?.[0]?.path?.[0]);

        return !(
          isSystemChange ||
          action === 'CREATE' ||
          ['tbl_preservation_item', 'tbl_preservation_series'].includes(
            tableName
          ) ||
          (action === 'DELETE' &&
            tableName === 'tbl_preservation_item_activity') ||
          (action === 'DELETE' && tableName.startsWith('tbl_attachment'))
        );
      })
      .groupBy(({ tableName, recordId }) => [tableName, recordId])
      .values()
      .map((auditItems) => maxBy(auditItems, 'updatedAt'))
      .orderBy(['tableName', 'recordId'])
      .value();
  }, [originalAudit]);

  const restoreEntity = useCallback(
    (auditIds: number[]) => {
      dataProvider
        .restoreEntity(RESOURCE_AUDIT, auditIds)
        .then(() => {
          queryClient.invalidateQueries({
            refetchType: 'all',
          });
          closeConfirm();
          if (onComplete) {
            onComplete();
          }
          notify('Restore successfully completed.', { type: 'success' });
        })
        .catch((error) => {
          notify(getErrorMessage(error), { type: 'error' });
        });
    },
    [dataProvider, queryClient, closeConfirm, onComplete, notify]
  );

  const restoreSelected = useCallback(() => {
    const auditIds = auditData.map(({ id }) => id);
    restoreEntity(auditIds);
  }, [auditData, restoreEntity]);

  if (isEmpty(auditData)) return;

  return (
    <Fragment>
      <Button onClick={showConfirm}>Restore</Button>
      <Confirm
        title="Confirmation"
        content={
          <RestoreButtonConfirmContent
            audit={auditData}
            showClarification={originalAudit.length != auditData.length}
          />
        }
        onConfirm={restoreSelected}
        onClose={closeConfirm}
        isOpen={isConfirmVisible}
      />
    </Fragment>
  );
};

export default RestoreButton;
