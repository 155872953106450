import React, { ReactElement } from 'react';
import {
  NumberField,
  ReferenceManyField,
  TextField,
  Datagrid,
} from 'react-admin';
import { Box } from '@mui/material';
import { RESOURCE_BOOK_SECTION_CERT } from '../../constants';
import { MAX_RECORD_PER_PAGE } from '../../../provider/constants';
import { ListComponentTitle } from '../components/ListComponentTitle';
import commonStyles from '../../common/commonStyles';

export const BookSectionCertListShow: React.FC = (): ReactElement => {
  return (
    <Box sx={commonStyles.editableDataGrid}>
      <ListComponentTitle variant="caption" label="Certificates" />
      <ReferenceManyField
        reference={RESOURCE_BOOK_SECTION_CERT}
        target="bookSectionId"
        perPage={MAX_RECORD_PER_PAGE}
        sort={{ field: 'sortOrder', order: 'ASC' }}
      >
        <Datagrid bulkActionButtons={false} rowClick={false}>
          <TextField source="certName" label="Certificate" sortable={false} />
          <NumberField source="sortOrder" sortable={false} />
        </Datagrid>
      </ReferenceManyField>
    </Box>
  );
};
