export const ITR_AUTOMATION_CHOICES = [
  { id: 'MANUAL', name: 'Manual' },
  { id: 'SEMI_AUTO', name: 'Semi-Auto' },
  { id: 'AUTO', name: 'Auto' },
];

export const YES_NO_CHOICES = [
  { id: true, name: 'Yes' },
  { id: false, name: 'No' },
];

export const PRES_AUTOMATION_CHOICES = [
  { id: 'NO', name: 'No' },
  { id: 'YES', name: 'Yes' },
];

export enum Industry {
  POWER = 'POWER',
  OIL_GAS = 'OIL_GAS',
  RENEWABLES = 'RENEWABLES',
  LNG = 'LNG',
}

export enum SubIndustry {
  POWER_COAL = 'POWER_COAL',
  POWER_NUCLEAR = 'POWER_NUCLEAR',
  POWER_COMBINED_CYCLE = 'POWER_COMBINED_CYCLE',
  POWER_SIMPLE_CYCLE = 'POWER_SIMPLE_CYCLE',
  OIL_GAS_REFINERY = 'OIL_GAS_REFINERY',
  OIL_GAS_PLATFORM = 'OIL_GAS_PLATFORM',
  OIL_GAS_FPSO = 'OIL_GAS_FPSO',
  OIL_GAS_SUB_SEA = 'OIL_GAS_SUB_SEA',
  RENEWABLES_WIND = 'RENEWABLES_WIND',
  RENEWABLES_SOLAR = 'RENEWABLES_SOLAR',
  RENEWABLES_HYDRO = 'RENEWABLES_HYDRO',
  RENEWABLES_RNG = 'RENEWABLES_RNG',
  RENEWABLES_BESS = 'RENEWABLES_BESS',
  LNG_FUEL_BUNKERING = 'LNG_FUEL_BUNKERING',
  LNG_TRANSPORT_AND_SALES = 'LNG_TRANSPORT_AND_SALES',
  LNG_GAS_SALES = 'LNG_GAS_SALES',
}

export enum ProjectType {
  GREENFIELD = 'GREENFIELD',
  BROWNFIELD = 'BROWNFIELD',
  RESTART = 'RESTART',
}

export enum LocationCategory {
  ONSHORE = 'ONSHORE',
  OFFSHORE = 'OFFSHORE',
}

export const INDUSTRY_CHOICES = [
  { id: Industry.POWER, name: 'Power' },
  { id: Industry.OIL_GAS, name: 'Oil-gas' },
  { id: Industry.RENEWABLES, name: 'Renewables' },
  { id: Industry.LNG, name: 'LNG' },
];

export const SUB_INDUSTRY_CHOICES_POWER = [
  { id: SubIndustry.POWER_COAL, name: 'Coal' },
  { id: SubIndustry.POWER_NUCLEAR, name: 'Nuclear' },
  {
    id: SubIndustry.POWER_COMBINED_CYCLE,
    name: 'Combined Cycle',
  },
  { id: SubIndustry.POWER_SIMPLE_CYCLE, name: 'Simple Cycle' },
];

export const SUB_INDUSTRY_CHOICES_OILGAS = [
  { id: SubIndustry.OIL_GAS_REFINERY, name: 'Refinery' },
  { id: SubIndustry.OIL_GAS_PLATFORM, name: 'Platform' },
  { id: SubIndustry.OIL_GAS_FPSO, name: 'FPSO' },
  { id: SubIndustry.OIL_GAS_SUB_SEA, name: 'SubSea' },
];

export const SUB_INDUSTRY_CHOICES_RENEWABLES = [
  { id: SubIndustry.RENEWABLES_WIND, name: 'Wind' },
  { id: SubIndustry.RENEWABLES_SOLAR, name: 'Solar' },
  { id: SubIndustry.RENEWABLES_HYDRO, name: 'Hydro' },
  { id: SubIndustry.RENEWABLES_RNG, name: 'RNG' },
  { id: SubIndustry.RENEWABLES_BESS, name: 'BESS' },
];

export const SUB_INDUSTRY_CHOICES_LNG = [
  { id: SubIndustry.LNG_FUEL_BUNKERING, name: 'Bunkering' },
  {
    id: SubIndustry.LNG_TRANSPORT_AND_SALES,
    name: 'Transport and Sales',
  },
  { id: SubIndustry.LNG_GAS_SALES, name: 'Gas Sales' },
];

export const SUB_INDUSTRY_CHOICES = [].concat(
  SUB_INDUSTRY_CHOICES_POWER,
  SUB_INDUSTRY_CHOICES_OILGAS,
  SUB_INDUSTRY_CHOICES_RENEWABLES,
  SUB_INDUSTRY_CHOICES_LNG
);

export const PROJECT_TYPE_CHOICES = [
  { id: ProjectType.GREENFIELD, name: 'Greenfield' },
  { id: ProjectType.BROWNFIELD, name: 'Brownfield' },
  { id: ProjectType.RESTART, name: 'Restart' },
];

export const LOCATION_CATEGORY_CHOICES = [
  { id: LocationCategory.ONSHORE, name: 'Onshore' },
  { id: LocationCategory.OFFSHORE, name: 'Offshore' },
];
