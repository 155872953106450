import React, { ReactElement } from 'react';
import {
  Button,
  useDataProvider,
  useNotify,
  useRecordContext,
} from 'react-admin';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getErrorMessage } from '../../../utils/UtilityFunctions';
import useInvalidateResourceQueryCache from '../../common/hooks/useInvalidateResourceQueryCache';
import { RESOURCE_ATTACHMENT_REVISION } from '../../constants';
import { PullAndPushButtonProps } from '../types';

const PullRevisionFileFromMasterCopyButton: React.FC<
  PullAndPushButtonProps
> = ({ resource, resourceId }): ReactElement => {
  const record = useRecordContext();
  const queryClient = useQueryClient();
  const clearCache = useInvalidateResourceQueryCache(resource);
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const {
    mutate: pullRevisionFileFromMasterTemplate,
    isPending: pullInProgress,
  } = useMutation({
    mutationFn: async () => {
      return await dataProvider.pullRevisionFileFromMasterTemplate(
        RESOURCE_ATTACHMENT_REVISION,
        {
          id: record?.id,
          resource,
          resourceId,
        }
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [RESOURCE_ATTACHMENT_REVISION],
      });
      await clearCache();
      notify('Successfully Pulled Revision File.', {
        type: 'success',
        undoable: false,
      });
    },
    onError: (error) => {
      notify('Pull Revision File Error: ' + getErrorMessage(error), {
        type: 'error',
        undoable: false,
      });
    },
  });

  return (
    <Button
      label={pullInProgress ? 'Pulling...' : 'Pull From Master Copy'}
      onClick={() => {
        pullRevisionFileFromMasterTemplate();
      }}
      size="medium"
      variant="contained"
      sx={{ marginTop: '10px' }}
    />
  );
};

export default PullRevisionFileFromMasterCopyButton;
