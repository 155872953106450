import { InputAdornment, TextField } from '@mui/material';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import { FieldTitle } from 'ra-core';
import React, { ReactElement, useCallback } from 'react';
import { isRequired } from 'react-admin';
import { useController } from 'react-hook-form';
import { InputHelperText } from './InputHelperText';
import { FILTER_TYPES } from './constants';
import AdditionalSearchFilterButton from './AdditionalSearchFilterButton';
import { composeValidators } from './helpers';

interface TextFieldWithFilterOption {
  source: string;
  label: string | boolean;
  resource: string;
  validate;
  helperText: boolean;
  isInputFocused: boolean;
  menuOpen: boolean;
  handleMenuOpen: () => void;
  handleInputFocus;
  handleInputBlur;
  menuRef: React.RefObject<HTMLButtonElement>;
  handleMenuClose: (event: Event) => void;
  filterType: (typeof FILTER_TYPES)[keyof typeof FILTER_TYPES];
  handleChangeFilterType: (
    value: TextFieldWithFilterOption['filterType']
  ) => void;
  name: string;
  InputProps: Partial<StandardInputProps>;
}

const TextFieldWithFilterOption: React.FC<TextFieldWithFilterOption> = (
  props
): ReactElement => {
  const {
    source,
    label,
    resource,
    validate,
    helperText,
    isInputFocused,
    menuOpen,
    menuRef,
    handleMenuOpen,
    handleMenuClose,
    handleInputFocus,
    handleInputBlur,
    filterType,
    handleChangeFilterType,
    name,
    ...params
  } = props;
  const sanitizedValidate = Array.isArray(validate)
    ? composeValidators(validate)
    : validate;

  const { fieldState } = useController({
    name: source,
    rules: {
      validate: async (value, values) => {
        if (!sanitizedValidate) return true;
        const error = await sanitizedValidate(value, values, {
          ...props,
          source,
        });

        if (!error) return true;

        return `@@react-admin@@${JSON.stringify(error)}`;
      },
    },
  });

  const { error, invalid } = fieldState;

  const getEndAdornment = useCallback(
    (endAdornment) => {
      const additionalSearchFilterButton = (
        <AdditionalSearchFilterButton
          key={source}
          index={source}
          isInputFocused={isInputFocused}
          menuOpen={menuOpen}
          ref={menuRef}
          handleMenuOpen={handleMenuOpen}
          handleMenuClose={handleMenuClose}
          filterType={filterType}
          handleChangeFilterType={handleChangeFilterType}
        />
      );

      if (endAdornment) {
        const children = React.Children.toArray(endAdornment.props.children);
        children.unshift(additionalSearchFilterButton);

        return React.cloneElement(endAdornment, {}, children);
      } else {
        return (
          <InputAdornment position="end">
            {additionalSearchFilterButton}
          </InputAdornment>
        );
      }
    },
    [
      filterType,
      handleChangeFilterType,
      handleMenuClose,
      handleMenuOpen,
      isInputFocused,
      menuOpen,
      menuRef,
      source,
    ]
  );

  const renderHelperText = helperText !== false || invalid;

  return (
    <TextField
      {...params}
      error={invalid}
      label={
        label !== '' && label !== false ? (
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired(validate)}
          />
        ) : null
      }
      helperText={
        renderHelperText ? (
          <InputHelperText error={error?.message} helperText={helperText} />
        ) : null
      }
      InputProps={{
        ...params?.InputProps,
        style: {
          ...(params?.InputProps?.endAdornment && {
            paddingRight: isInputFocused || menuOpen ? '80px' : '56px',
          }),
        },
        endAdornment: getEndAdornment(params.InputProps.endAdornment),
        onFocus: handleInputFocus,
        onBlur: handleInputBlur,
        name: name,
        onMouseDown: (e) => {
          e.preventDefault();
        },
      }}
    />
  );
};

export default TextFieldWithFilterOption;
