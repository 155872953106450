import React, { ReactElement } from 'react';
import { DateField, Labeled, TabbedShowLayout, TextField } from 'react-admin';
import Box from '@mui/material/Box';
import renderShowMetaDataFields from '../../common/helpers/renderShowMetaDataFields';
import ShowComponent, { ShowComponentProps } from '../../common/ShowComponent';
import AttachmentList from '../../attachment/AttachmentList';
import commonStyles from '../../common/commonStyles';
import AuditListTab from '../../audit/AuditListTab';
import { RESOURCE_MATERIAL_RELEASED } from '../../constants';
import MRMaterialStoragesForm from './components/MRMaterialStoragesForm';
import StatusField from './components/StatusField';

const MaterialReleasedShow: React.FC<ShowComponentProps> = ({
  syncWithLocation,
  ...rest
}): ReactElement => (
  <ShowComponent {...rest}>
    <TabbedShowLayout
      sx={commonStyles.bottomMargin}
      syncWithLocation={syncWithLocation}
    >
      <TabbedShowLayout.Tab label="Released Materials">
        <Box>
          <Labeled sx={commonStyles.flexBoxItem}>
            <TextField
              source="orderNo"
              label="Released Order No"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <StatusField />
          </Labeled>
        </Box>
        <Box>
          <Labeled sx={commonStyles.flexBoxItem}>
            <DateField source="generateDate" label="Date Generated" />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <DateField source="releasedDate" label="Date Released" />
          </Labeled>
        </Box>
        <Box>
          <Labeled sx={commonStyles.flexBoxItem}>
            <TextField source="releasedTo" label="Released To" />
          </Labeled>
        </Box>
        <Box>{renderShowMetaDataFields()}</Box>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab
        label="Released Order Form"
        id="released_order_form-tab"
        contentClassName="materials-table"
      >
        <MRMaterialStoragesForm isShowMode />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Attachments" id="attachments_tab">
        <AttachmentList
          targetId="materialReleasedId"
          resourceName={RESOURCE_MATERIAL_RELEASED}
          onlyShow
        />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="History">
        <AuditListTab tableName="tbl_material_released" />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </ShowComponent>
);

export default MaterialReleasedShow;
