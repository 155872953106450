import React, { ReactElement } from 'react';
import { ListButton, TopToolbar, useGetOne } from 'react-admin';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { RESOURCE_REPORT } from '../constants';
import TelerikReportView from './components/TelerikReportView';
import PowerBIView from './components/PowerBIView';
import { Report } from './type';
import { reportTypeChoices } from './constants';

const ReportView: React.FC = (): ReactElement => {
  const { reportId } = useParams();
  const { data: report, isLoading } = useGetOne<Report>(RESOURCE_REPORT, {
    id: reportId,
  });

  return (
    <Box>
      <TopToolbar>
        <ListButton label="Back" icon={null} resource={RESOURCE_REPORT} />
      </TopToolbar>
      {!isLoading ? (
        report.reportType === reportTypeChoices[0].name ? (
          <TelerikReportView
            projectId={report.projectId}
            reportName={report.telerikReportFileName}
          />
        ) : (
          <PowerBIView report={report} />
        )
      ) : null}
    </Box>
  );
};

export default ReportView;
