import React, { ReactElement } from 'react';
import ChoicesLegend from '../../common/choicesLegend/ChoicesLegend';

const BulkEditLegend: React.FC = (): ReactElement => (
  <ChoicesLegend
    title="Warning"
    items={[
      {
        name: 'Frequency and Start Date',
        description: `Deleting the Frequency or Start Date fields may result in the removal of associated series and activities.`,
        isMarked: true,
      },
    ]}
  />
);

export default BulkEditLegend;
