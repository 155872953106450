import React, { ReactElement, useMemo } from 'react';
import { AutocompleteInput } from 'react-admin';
import { getContext, UserContext } from '../../provider/userContext';
import { RESOURCE_PUNCH_CATEGORY } from '../constants';
import { required } from '../../utils/UtilityFunctions';
import AutocompleteReferenceInput from './AutocompleteReferenceInput';

interface PunchCategoryInputProps
  extends React.ComponentProps<typeof AutocompleteInput> {
  label?: string;
  isRequired?: boolean;
  alwaysOn?: boolean;
  fullWidth?: boolean;
  multiple?: boolean;
  sx?: object;
  source?: string;
}

const PunchCategoryInput: React.FC<PunchCategoryInputProps> = ({
  label,
  isRequired,
  fullWidth,
  multiple,
  sx,
  ...rest
}): ReactElement => {
  const ctx: UserContext = getContext();
  const requestParams = useMemo(() => {
    return {
      filter: {
        projectId: ctx?.projectId,
      },
      sort: { field: 'category', order: 'ASC' as const },
    };
  }, [ctx?.projectId]);

  return (
    <AutocompleteReferenceInput
      {...rest}
      resource={RESOURCE_PUNCH_CATEGORY}
      requestParams={requestParams}
      label={label}
      name="category_inc"
      optionText="category"
      multiple={multiple}
      validate={isRequired ? [required()] : []}
      fullWidth={fullWidth}
      sx={sx}
      includeFields={['id', 'category']}
    />
  );
};

PunchCategoryInput.defaultProps = {
  source: 'punchCatId',
  label: 'Punch Category',
};

export default PunchCategoryInput;
