import React, { ReactElement, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { useGetIdentity } from 'ra-core';
import { Title, useDataProvider, useGetList, useNotify } from 'react-admin';
import { useMutation } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { getContext, UserContext } from '../../provider/userContext';
import { getErrorMessage } from '../../utils/UtilityFunctions';
import {
  CUSTOM_ROUTE_BOOK_GENERATION,
  RESOURCE_BOOK,
  RESOURCE_PRINTED_BOOK,
} from '../constants';
import { Book } from '../book/type';
import commonStyles from '../common/commonStyles';
import { setPageTitle } from '../common/helpers/setPageTitle';
import { BookType, GenerateType, SortOrder } from './constants';
import { styles } from './styles';
import SubsystemSelection from './components/subsystemSelection/SubsystemSelection';
import { AttachmentSelectionItem, GenerateForm, Subsystem } from './types';
import AttachmentSelection from './components/attachmentSelection/AttachmentSelection';
import { ViewSelectedItems } from './components/ViewSelectedItems';
import { BookQueueDialog } from './components/BookQueueDialog';

export const BookGeneration: React.FC = (): ReactElement => {
  const ctx: UserContext = getContext();
  const { data: books } = useGetList(RESOURCE_BOOK, {
    filter: { projectId: ctx?.projectId },
    sort: { field: 'title', order: 'ASC' },
  });
  const [bookId, setBookId] = useState('');
  const [selectedSubsystems, setSelectedSubsystems] = useState<Subsystem[]>([]);
  const [selectedAttachments, setSelectedAttachments] = useState<
    AttachmentSelectionItem[]
  >([]);
  const [isPrintQueueOpen, setIsPrintQueueOpen] = useState(false);
  const { control, watch } = useForm<GenerateForm>({
    defaultValues: {
      sortOrder: SortOrder.TAG,
      bookType: BookType.TABS,
      generateType: GenerateType.ZIP,
      sendEmail: true,
    },
  });
  const title = 'Generate Book(s)';
  setPageTitle(title);
  const { identity } = useGetIdentity();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const { mutate: startBookGeneration } = useMutation({
    mutationFn: () => {
      const { bookType, generateType, sendEmail, sortOrder } = watch();
      return dataProvider.startBookGeneration(CUSTOM_ROUTE_BOOK_GENERATION, {
        companyName: ctx?.company,
        userId: identity.id,
        userName: identity.fullName,
        projectId: ctx?.projectId,
        bookId,
        bookType,
        outputType: generateType,
        testSortOrder: sortOrder,
        sendEmail,
        subSystems: selectedSubsystems,
        bookAttachments: selectedAttachments,
      });
    },
    onSuccess: () => {
      notify('Book generation has started');
    },
    onError: (error) => {
      notify(getErrorMessage(error), {
        type: 'error',
        undoable: false,
      });
    },
  });

  const onSetSelectedSubsystems = (selectedItems: Subsystem[]) => {
    const newItems = [];
    selectedItems.forEach((selectedItem) => {
      if (
        !selectedSubsystems.find(
          (selectedSubsystem) => selectedSubsystem.id === selectedItem.id
        )
      ) {
        newItems.push(selectedItem);
      }
    });
    setSelectedSubsystems((prevItems) => [...prevItems, ...newItems]);
  };

  const onSetSelectedAttachments = (
    selectedItems: AttachmentSelectionItem[]
  ) => {
    const newItems = [];

    selectedItems.forEach((selectedItem) => {
      if (
        !selectedAttachments.find(
          (selectedAttachment) => selectedAttachment.id === selectedItem.id
        )
      ) {
        newItems.push(selectedItem);
      }
    });
    setSelectedAttachments((prevItems) => [...prevItems, ...newItems]);
  };

  return (
    <Box>
      <Title title={title} />
      <Box sx={styles.generateBookPage}>
        <Box sx={styles.generateBookPageLeftColumn}>
          <Stack>
            <FormControl>
              <FormLabel id="select-book-label">Select Book</FormLabel>
              <Select
                id="select-book"
                sx={styles.select}
                value={bookId}
                onChange={(event: SelectChangeEvent) =>
                  setBookId(event.target.value)
                }
              >
                {books &&
                  books.map((book: Book) => {
                    return (
                      <MenuItem key={book.id} value={'' + book.id}>
                        {book.title}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>

            <Typography
              variant={'body1'}
              color={'textSecondary'}
              sx={styles.selectListTitle}
            >
              Select Subsystems
            </Typography>

            <SubsystemSelection onSelectSubsystems={onSetSelectedSubsystems} />
            {bookId && (
              <AttachmentSelection
                onSelectAttachments={onSetSelectedAttachments}
                bookId={bookId}
              />
            )}
          </Stack>
        </Box>
        <Box sx={styles.generateBookPageRightColumn}>
          <Stack>
            <ViewSelectedItems
              selectedItems={selectedSubsystems}
              setSelectedItems={setSelectedSubsystems}
              header="Selected Subsystems"
              getItemLabel={(item: Subsystem) => item.subsystem}
            />

            <Divider style={styles.divider} />

            {selectedAttachments.length > 0 && (
              <>
                <ViewSelectedItems
                  selectedItems={selectedAttachments}
                  setSelectedItems={setSelectedAttachments}
                  header="Selected Attachments"
                  getItemLabel={(item: AttachmentSelectionItem) =>
                    item.fileName
                  }
                />
                <Divider style={styles.divider} />
              </>
            )}

            <FormControl>
              <FormLabel id="sort-order-radio-buttons-group">
                Sort Order
              </FormLabel>
              <Controller
                name="sortOrder"
                control={control}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    row
                    aria-labelledby="sort-order-radio-buttons-group"
                  >
                    <FormControlLabel
                      value={SortOrder.TAG}
                      control={<Radio sx={commonStyles.radioIcon} />}
                      label="Sort By Tag"
                    />
                    <FormControlLabel
                      value={SortOrder.ITR}
                      control={<Radio sx={commonStyles.radioIcon} />}
                      label="Sort By ITR"
                    />
                  </RadioGroup>
                )}
              />
            </FormControl>

            <FormControl>
              <FormLabel id="book-type-radio-buttons-group">
                Book Type
              </FormLabel>
              <Controller
                name="bookType"
                control={control}
                render={({ field }) => (
                  <RadioGroup
                    row
                    aria-labelledby="book-type-radio-buttons-group"
                    {...field}
                  >
                    <FormControlLabel
                      value={BookType.TABS}
                      control={<Radio sx={commonStyles.radioIcon} />}
                      label="Tabs"
                    />
                    <FormControlLabel
                      value={BookType.TAGS}
                      control={<Radio sx={commonStyles.radioIcon} />}
                      label="Tags"
                    />
                    <FormControlLabel
                      value={BookType.SCANS}
                      control={<Radio sx={commonStyles.radioIcon} />}
                      label="Scans"
                    />
                  </RadioGroup>
                )}
              />
            </FormControl>

            <FormControl>
              <FormLabel id="generate-radio-buttons-group">Generate</FormLabel>
              <Controller
                name="generateType"
                control={control}
                render={({ field }) => (
                  <RadioGroup
                    row
                    aria-labelledby="generate-radio-buttons-group"
                    {...field}
                  >
                    <FormControlLabel
                      value={GenerateType.ZIP}
                      control={<Radio sx={commonStyles.radioIcon} />}
                      label="Zip"
                    />
                    <FormControlLabel
                      value={GenerateType.PDF}
                      control={<Radio sx={commonStyles.radioIcon} />}
                      label="Combined Pdf"
                    />
                    <FormControlLabel
                      value={GenerateType.BOTH}
                      control={<Radio sx={commonStyles.radioIcon} />}
                      label="Both"
                    />
                  </RadioGroup>
                )}
              />
            </FormControl>

            <FormControl>
              <Controller
                name="sendEmail"
                control={control}
                render={({ field: { value, ...rest } }) => (
                  <FormControlLabel
                    {...rest}
                    checked={value}
                    control={<Checkbox />}
                    sx={{ ...commonStyles.radioIcon, marginTop: '10px' }}
                    label="Send me an email"
                  />
                )}
              />
            </FormControl>

            <Button
              variant="contained"
              type="button"
              sx={styles.printBookButton}
              disabled={selectedSubsystems.length === 0 || !bookId}
              onClick={() => startBookGeneration()}
            >
              Print Book(s)
            </Button>
            <Stack direction="row">
              <Button
                variant="outlined"
                type="button"
                sx={styles.printQueueButton}
                onClick={() => setIsPrintQueueOpen(true)}
              >
                Print Queue
              </Button>
              <BookQueueDialog
                isOpen={isPrintQueueOpen}
                onClose={() => setIsPrintQueueOpen(false)}
              />
              <Button
                component={Link}
                to={`/${RESOURCE_PRINTED_BOOK}`}
                target="_self"
                variant="outlined"
                sx={styles.printedBooksButton}
              >
                Printed Book(s)
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};
