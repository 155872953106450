import React, { Fragment, useCallback, useState, ReactElement } from 'react';
import { Button, useCreate, useNotify, useRecordContext } from 'react-admin';
import Add from '@mui/icons-material/Add';
import { CreateDialog } from '@react-admin/ra-form-layout';
import useInvalidateResourceQueryCache from '../../../../common/hooks/useInvalidateResourceQueryCache';
import {
  RESOURCE_MATERIAL,
  RESOURCE_MATERIAL_STORAGE,
} from '../../../../constants';
import { getErrorMessage } from '../../../../../utils/UtilityFunctions';
import StorageTabForm from './StorageTabForm';

const StorageTabAdd: React.FC = (): ReactElement => {
  const record = useRecordContext();
  const notify = useNotify();
  const [open, setOpen] = useState(false);
  const invalidateQueries = useInvalidateResourceQueryCache(RESOURCE_MATERIAL);
  const [create] = useCreate(
    RESOURCE_MATERIAL_STORAGE,
    {},
    {
      onSuccess: () => {
        invalidateQueries().then();
      },
    }
  );

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onSubmit = useCallback(
    async (data) => {
      await create(
        RESOURCE_MATERIAL_STORAGE,
        {
          data: {
            ...data,
            materialId: record.id,
          },
        },
        {
          onError: (error) => {
            notify('Error: ' + getErrorMessage(error), {
              type: 'error',
              undoable: false,
            });
          },
          onSuccess: () => {
            notify('Storage was added to material', {
              type: 'success',
              undoable: false,
            });
          },
        }
      );

      handleClose();
    },
    [create, notify, handleClose, record]
  );

  return (
    <Fragment>
      <Button label="Add Storage" onClick={handleOpen}>
        <Add />
      </Button>
      <CreateDialog
        title={<span>Add Storage</span>}
        fullWidth
        maxWidth="md"
        isOpen={open}
        open={handleOpen}
        close={handleClose}
      >
        <StorageTabForm onSubmit={onSubmit} />
      </CreateDialog>
    </Fragment>
  );
};

export default StorageTabAdd;
