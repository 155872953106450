import React from 'react';

import { Box } from '@mui/material';
import { maxLength } from 'react-admin';
import TabbedEditForm from '../common/TabbedEditForm';
import LazyFormTab from '../common/LazyFormTab';
import commonStyles from '../common/commonStyles';
import { EditPropsWithLocation } from '../common/types';
import TextInput from '../common/TextInput';
import { required } from '../../utils/UtilityFunctions';
import { DrawingPrefixType } from './type';

const DrawingPrefixEdit: React.FC<EditPropsWithLocation<DrawingPrefixType>> = (
  props
): React.ReactElement => {
  return (
    <TabbedEditForm {...props}>
      <LazyFormTab
        label="Drawing Prefix"
        id="drawing_prefix_tab"
        sx={commonStyles.formTabHeader}
      >
        <Box sx={commonStyles.flexBox}>
          <TextInput
            source="prefix"
            label="Prefix"
            validate={[required(), maxLength(50)]}
            sx={commonStyles.flexBoxItem}
          />
          <TextInput
            source="description"
            label="Description"
            validate={[maxLength(255)]}
            sx={commonStyles.flexBoxLongItem}
          />
        </Box>
      </LazyFormTab>
    </TabbedEditForm>
  );
};

export default DrawingPrefixEdit;
