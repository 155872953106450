import React, { ReactElement } from 'react';
import { Button, Filter, NullableBooleanInput, SelectInput } from 'react-admin';
import { Card, CardContent } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import { getContext, UserContext } from '../../../provider/userContext';
import VendorInput from '../../common/VendorInput/VendorInput';
import useSidebarFilters from '../../common/hooks/useSidebarFilters';
import AutoCompleteWithCreateOptionReferenceInput from '../../common/AutoCompleteWithCreateOptionReferenceInput';
import DrawingInput from '../../common/DrawingInput';
import ComponentInput from '../../common/ComponentInput';
import SubsystemInput from '../../common/SubsystemInput';
import DisciplineInput from '../../common/DisciplineInput';
import GroupInput from '../../common/GroupInput';
import ModuleInput from '../../common/ModuleInput';
import WorkPackInput from '../../common/WorkPackInput';
import LocationInput from '../../common/LocationInput';
import EquipmentTypeInput from '../../common/EquipmentTypeInput';
import TextInput from '../../common/TextInput';
import DateRangeFilter from '../../common/DateRangeFilter';
import { SideFilter } from '../../common/types';
import sideFilterStyles from '../../common/sideFilterStyles';
import { RESOURCE_COMPONENT, RESOURCE_LOCATION } from '../../constants';
import { tagCategory } from '../constants';

const ComponentSidebarFilters: React.FC<SideFilter> = ({
  defaultFilter,
}): ReactElement => {
  const ctx: UserContext = getContext();
  const {
    data,
    isLoading,
    filterValues,
    dateFrom,
    dateTo,
    handleDateFromChange,
    handleDateToChange,
    handleClearFilters,
  } = useSidebarFilters(RESOURCE_COMPONENT, defaultFilter);

  if (!data || (!data.length && isEmpty(filterValues)) || isLoading) {
    return null;
  }

  return (
    <Card sx={sideFilterStyles.sideFiltersCard} id="componentSidebarFilter">
      <CardContent sx={sideFilterStyles.sideFiltersCardContainer}>
        <Filter>
          <SubsystemInput
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <DisciplineInput alwaysOn multiple />
          <ComponentInput source="id" multiple fullWidth alwaysOn />
          <TextInput
            source="tagDescription"
            label="Tag Description"
            name="tagDescription_inc"
            alwaysOn
          />
          <GroupInput
            category={['COMPONENT']}
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <ModuleInput
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <WorkPackInput
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <LocationInput
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <EquipmentTypeInput
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <DrawingInput
            source="componentDrawings->drawingId"
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
          />

          <NullableBooleanInput
            label="Skip ITR Automation"
            name="skipItrAutomation"
            source="skipItrAutomation"
            trueLabel="True"
            falseLabel="False"
            nullLabel="None"
            fullWidth
            alwaysOn
          />
          <NullableBooleanInput
            label="Maintenance"
            name="isMaintenance"
            source="isMaintenance"
            trueLabel="True"
            falseLabel="False"
            nullLabel="None"
            fullWidth
            alwaysOn
          />
          <NullableBooleanInput
            label="Tag Scoped"
            name="tagScoped"
            source="tagScoped"
            trueLabel="True"
            falseLabel="False"
            nullLabel="None"
            fullWidth
            alwaysOn
          />
          <TextInput
            source="parentTag"
            label="Parent Tag"
            name="parentTag_inc"
            alwaysOn
          />
          <TextInput
            source="dataSheet"
            label="Data Sheet"
            name="dataSheet_inc"
            alwaysOn
          />
          <TextInput
            source="manufacturer"
            label="Manufacturer"
            name="manufacturer_inc"
            alwaysOn
          />
          <TextInput source="model" label="Model" name="model_inc" alwaysOn />
          <TextInput
            source="serial"
            label="Serial"
            name="serial_inc"
            alwaysOn
          />
          <TextInput source="batch" label="Batch" name="batch_inc" alwaysOn />
          <TextInput
            source="loopName"
            label="Loop Name"
            name="loopName_inc"
            alwaysOn
          />
          <TextInput
            source="purchaseOrder"
            label="Purchase Order"
            name="purchaseOrder_inc"
            alwaysOn
          />
          <VendorInput alwaysOn multiple />
          <TextInput
            source="hardSoft"
            label="Hard Soft"
            name="hardSoft_inc"
            alwaysOn
          />
          <AutoCompleteWithCreateOptionReferenceInput
            source="storageLocation_inc_any"
            label="Storage Location"
            reference={RESOURCE_LOCATION}
            keyValue="location"
            sx={sideFilterStyles.sideFiltersSelectInput}
            alwaysOn
            multiple
            defaultFilter={{
              projectId: ctx?.projectId,
            }}
          />
          <SelectInput
            source="tagCategory"
            label="Tag Category"
            choices={tagCategory}
            sx={sideFilterStyles.sideFiltersSelectInput}
            alwaysOn
          />
          <TextInput
            source="verifiedBy"
            label="Tag Verified By"
            name="verifiedBy_inc"
            alwaysOn
          />
          <NullableBooleanInput
            label="Tag Verified"
            name="verified"
            source="verified"
            trueLabel="True"
            falseLabel="False"
            nullLabel="None"
            fullWidth
            alwaysOn
          />
          <TextInput
            source="cableFrom"
            label="Cable From"
            name="cableFrom_inc"
            alwaysOn
          />
          <TextInput
            source="cableTo"
            label="Cable To"
            name="cableTo_inc"
            alwaysOn
          />
          <TextInput
            source="listSource"
            label="List Source"
            name="listSource_inc"
            alwaysOn
          />
          <NullableBooleanInput
            source="hasAttachment"
            label="Has Attachment"
            name="hasAttachment"
            trueLabel="True"
            falseLabel="False"
            nullLabel="None"
            fullWidth
            alwaysOn
          />
          <NullableBooleanInput
            source="hasItrAttachment"
            label="Has Tag-Test/ITR Attachment"
            name="hasItrAttachment"
            trueLabel="True"
            falseLabel="False"
            nullLabel="None"
            fullWidth
            alwaysOn
          />
          <NullableBooleanInput
            source="hasPreservationAttachment"
            label="Has Preservation Attachment"
            name="hasPreservationAttachment"
            trueLabel="True"
            falseLabel="False"
            nullLabel="None"
            fullWidth
            alwaysOn
          />
          <DateRangeFilter
            label="Updated"
            source="updatedAt"
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
          />
          <TextInput
            label="Updated By"
            name="updatedBy_inc"
            source="updatedBy"
            fullWidth
            alwaysOn
          />
          <TextInput
            label="Updated By Name"
            name="updatedByName_inc"
            source="updatedByName"
            fullWidth
            alwaysOn
          />
        </Filter>
        <Button label="Clear filters" onClick={handleClearFilters} />
      </CardContent>
    </Card>
  );
};

export default ComponentSidebarFilters;
