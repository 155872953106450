import { ReactElement } from 'react';
import { TextField } from 'react-admin';
import ColumnActions from '../common/ColumnActions';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import ListActions from '../common/ListActions';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import ListWithTitle from '../common/ListWithTitle';
import StructureRegionShow from './StructureRegionShow';
import StructureRegionEdit from './StructureRegionEdit';

const StructureRegionList: React.FC = (): ReactElement => (
  <ListWithTitle
    filters={<FreeTextSearchFilter />}
    actions={<ListActions />}
    sort={{ field: 'region', order: 'ASC' }}
  >
    <DatagridConfigurableRbac
      ShowComponent={<StructureRegionShow withoutActions />}
      EditComponent={<StructureRegionEdit syncWithLocation={false} />}
      defaultVisibleColumns={['id', 'region', 'description']}
    >
      <TextField source="id" label="ID" />
      <TextField source="region" label="Region" />
      <TextField source="description" label="Description" />
      {renderMetaDataFields()}
      <ColumnActions label="Actions" />
    </DatagridConfigurableRbac>
  </ListWithTitle>
);

export default StructureRegionList;
