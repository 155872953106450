import React, { ReactElement, useCallback } from 'react';
import { AutocompleteInput, SimpleForm } from 'react-admin';
import { CreateDialog } from '@react-admin/ra-form-layout';
import { required } from '../../../utils/UtilityFunctions';
import { getContext, UserContext } from '../../../provider/userContext';
import ImportToolbar from '../../common/ImportButton/components/ImportToolbar';
import commonStyles from '../../common/commonStyles';

type SelectOtherCompaniesDialogProps = {
  isOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
  submit: (data) => Promise<void>;
};

const SelectOtherCompaniesDialog: React.FC<SelectOtherCompaniesDialogProps> = ({
  isOpen,
  openModal,
  closeModal,
  submit,
}): ReactElement => {
  const ctx: UserContext = getContext();
  const companies = Object.keys(ctx?.companies || {})
    .map((company) => {
      if (
        !ctx?.companies?.[company]?.isCompanyAccessDisabled &&
        company !== ctx?.company
      ) {
        return {
          companyValue: {
            name: company,
            divisionId: ctx?.companies[company].defaultDivisionId,
          },
          companyName:
            ctx?.companies[company].longName || ctx?.companies[company].name,
        };
      } else {
        return null;
      }
    })
    .filter((company) => company)
    .sort((company1, company2) =>
      company1.companyValue.name.localeCompare(company2.companyValue.name)
    );

  const onSubmit = useCallback(
    async (data) => {
      const companiesData = data.companies
        .map(
          (companyName) =>
            companies.find((company) => company.companyName === companyName)
              ?.companyValue
        )
        .filter((value) => value);
      await submit(companiesData);
    },
    [companies, submit]
  );

  return (
    <CreateDialog
      title={<span>Select Companies</span>}
      fullWidth
      maxWidth="sm"
      isOpen={isOpen}
      open={openModal}
      close={closeModal}
    >
      <SimpleForm
        toolbar={<ImportToolbar onClose={closeModal} label="Push" />}
        onSubmit={onSubmit}
      >
        <AutocompleteInput
          label="Companies"
          source="companies"
          optionValue="companyName"
          optionText="companyName"
          choices={companies}
          validate={[required()]}
          multiple
          sx={commonStyles.fullWidth}
        />
      </SimpleForm>
    </CreateDialog>
  );
};

export default SelectOtherCompaniesDialog;
