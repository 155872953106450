import React, { ReactElement, useMemo } from 'react';
import { getContext, UserContext } from '../../provider/userContext';
import { required } from '../../utils/UtilityFunctions';
import { RESOURCE_MATERIAL } from '../constants';
import AutocompleteReferenceInput from './AutocompleteReferenceInput';

interface MaterialInputProps
  extends Partial<React.ComponentProps<typeof AutocompleteReferenceInput>> {
  additionalFilter?: object;
}

const MaterialInput: React.FC<MaterialInputProps> = ({
  isRequired,
  onChange,
  additionalFilter,
  ...rest
}): ReactElement => {
  const ctx: UserContext = getContext();

  const requestParams = useMemo(() => {
    return {
      filter: {
        projectId: ctx?.projectId,
        ...additionalFilter,
      },
      sort: { field: 'material', order: 'ASC' as const },
    };
  }, [ctx?.projectId, additionalFilter]);

  return (
    <AutocompleteReferenceInput
      {...rest}
      resource={RESOURCE_MATERIAL}
      requestParams={requestParams}
      name="material_inc"
      optionText="material"
      validate={isRequired ? [required()] : []}
      handleOnChange={onChange}
      includeFields={[
        'id',
        'material',
        'description',
        'materialType.type',
        'maxLevel',
        'totalQty',
        'defReorderQty',
        'price',
      ]}
    />
  );
};

MaterialInput.defaultProps = {
  source: 'materialId',
  label: 'Material',
};

export default MaterialInput;
