import React, { ReactElement, useMemo } from 'react';
import { AutocompleteInput } from 'react-admin';
import { getContext, UserContext } from '../../provider/userContext';
import { required } from '../../utils/UtilityFunctions';
import { RESOURCE_TEST_EQUIPMENT_TYPE } from '../constants';
import AutocompleteReferenceInput from './AutocompleteReferenceInput';

export interface TestEquipmentTypeInputProps
  extends React.ComponentProps<typeof AutocompleteInput> {
  isRequired?: boolean;
  alwaysOn?: boolean;
  multiple?: boolean;
  fullWidth?: boolean;
  sx?: object;
  source?: string;
}

const TestEquipmentTypeInput: React.FC<TestEquipmentTypeInputProps> = ({
  isRequired,
  ...rest
}): ReactElement => {
  const ctx: UserContext = getContext();
  const requestParams = useMemo(() => {
    return {
      filter: {
        projectId: ctx?.projectId,
      },
      sort: { field: 'type', order: 'ASC' as const },
    };
  }, [ctx?.projectId]);

  return (
    <AutocompleteReferenceInput
      {...rest}
      resource={RESOURCE_TEST_EQUIPMENT_TYPE}
      requestParams={requestParams}
      validate={isRequired ? [required()] : []}
      includeFields={['id', 'type']}
    />
  );
};

TestEquipmentTypeInput.defaultProps = {
  source: 'type',
  label: 'Test Equipment Type',
  optionText: 'type',
  name: 'type_inc',
};

export default TestEquipmentTypeInput;
