import React, { ReactElement } from 'react';
import { maxLength } from 'react-admin';
import Box from '@mui/material/Box';
import ActivityConstraintInput from '../../common/ActivityConstraintInput';
import ActivityDurationUnitInput from '../../common/ActivityDurationUnitInput';
import ActivityPhaseInput from '../../common/ActivityPhaseInput';
import ActivityTypeInput from '../../common/ActivityTypeInput';
import CertTemplateInput from '../../common/CertTemplateInput';
import commonStyles from '../../common/commonStyles';
import { activityCategory } from '../../common/constants';
import CustomDateInput from '../../common/CustomDateInput';
import CustomNumberInput from '../../common/CustomNumberInput';
import DisciplineInput from '../../common/DisciplineInput';
import SystemInput from '../../common/SystemInput';
import TextInput from '../../common/TextInput';

interface SystemActivityFormProps {
  bulkEditMode?: boolean;
}

const SystemActivityForm: React.FC<SystemActivityFormProps> = ({
  bulkEditMode,
}): ReactElement => {
  const isRequired = !bulkEditMode;
  return (
    <Box sx={commonStyles.columnFlexBox}>
      <Box sx={commonStyles.flexBoxWithWrap}>
        <TextInput
          source="systemActivity"
          label="Activity"
          sx={commonStyles.flexBoxItem}
          validate={maxLength(75)}
          disabled={bulkEditMode}
        />
        <SystemInput sx={commonStyles.flexBoxItem} isRequired={isRequired} />
        <TextInput
          source="sequence"
          label="Sequence"
          sx={commonStyles.flexBoxItem}
          validate={maxLength(20)}
          disabled={bulkEditMode}
        />
      </Box>
      <Box sx={commonStyles.flexBoxWithWrap}>
        <ActivityPhaseInput
          sx={commonStyles.flexBoxItem}
          isRequired={isRequired}
        />
        <DisciplineInput
          sx={commonStyles.flexBoxItem}
          withAllOption
          isRequired={isRequired}
        />
        <ActivityTypeInput
          sx={commonStyles.flexBoxItem}
          isRequired={isRequired}
        />
      </Box>
      <Box sx={commonStyles.flexBoxWithWrap}>
        <TextInput
          source="description"
          label="Description"
          sx={commonStyles.flexBoxLongItem}
          validate={maxLength(150)}
          multiline
        />
        <ActivityConstraintInput sx={commonStyles.flexBoxItem} />
      </Box>
      <Box sx={commonStyles.flexBoxWithWrap}>
        <CustomNumberInput
          source="duration"
          label="Duration"
          sx={commonStyles.flexBoxItem}
          isPositive
        />
        <CustomNumberInput
          source="actualDuration"
          label="Actual Duration"
          sx={commonStyles.flexBoxItem}
          isPositive
        />
        <ActivityDurationUnitInput sx={commonStyles.flexBoxItem} />
      </Box>
      <Box sx={commonStyles.flexBoxWithWrap}>
        <CustomDateInput
          source="plannedDate"
          label="Planned Date"
          sx={commonStyles.flexBoxItem}
        />
        <CustomDateInput
          source="actualDate"
          label="Actual Date"
          sx={commonStyles.flexBoxItem}
        />
        <CertTemplateInput
          category={activityCategory.system}
          sx={commonStyles.flexBoxItem}
        />
      </Box>
    </Box>
  );
};

export default SystemActivityForm;
