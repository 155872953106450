import React, { ReactElement } from 'react';
import {
  DateField,
  SelectField,
  TextField,
  useRecordContext,
} from 'react-admin';
import { useDictionaryLabelsWithResource } from '../../../../../utils/UtilityFunctions';
import commonStyles from '../../../../common/commonStyles';
import ComponentField from '../../../../common/ComponentField';
import { RESOURCE_PRESERVATION_ITEM_ACTIVITY } from '../../../../constants';
import { FormTabProps, QrScanList } from '../../../types';
import DataFormTab from '../DataFormTab';
import { completedChoices } from '../../../../preservationItemActivity/constants';
import PreservationFormRow from './PreservationFormRow';

const PreservationFormTab: React.FC<FormTabProps> = ({
  editMode,
}): ReactElement => {
  const { labels } = useDictionaryLabelsWithResource();
  const record = useRecordContext<QrScanList>();
  const data = record.qrs.preservations;

  return (
    <DataFormTab
      data={data}
      attachmentId={record.id}
      resource={RESOURCE_PRESERVATION_ITEM_ACTIVITY}
      editFormRow={<PreservationFormRow data={data} />}
      editMode={editMode}
    >
      <TextField
        source="inspector"
        label={labels['inspector'] || 'Inspector'}
        sx={commonStyles.rowFormField}
      />
      <DateField
        source="signOffDate"
        label={labels['signOffDate'] || 'Sign Off Date'}
        sx={commonStyles.rowFormField}
      />
      <SelectField
        source="completed"
        label={labels['completed'] || 'Status'}
        sx={commonStyles.rowFormField}
        choices={completedChoices}
      />
      <TextField
        source="storagePreservation"
        label={labels['storagePreservation'] || 'Storage Preservation'}
        sx={commonStyles.rowFormField}
      />
      <ComponentField
        source="component.id"
        label={labels['tagNo'] || 'Tag No'}
      />
      <DateField source="dueDate" label={labels['dueDate'] || 'Due Date'} />
      <DateField source="lateDate" label={labels['lateDate'] || 'Late Date'} />
    </DataFormTab>
  );
};

export default PreservationFormTab;
