import React, { ReactElement } from 'react';
import { RESOURCE_DRAWING_DISCIPLINE } from '../../constants';
import AutocompleteReferenceInput from '../../common/AutocompleteReferenceInput';
import { required } from '../../../utils/UtilityFunctions';

interface DrawingDisciplineInputProps {
  isRequired?: boolean;
  alwaysOn?: boolean;
  multiple?: boolean;
  sx?: object;
  source?: string;
}

const DrawingDisciplineInput: React.FC<DrawingDisciplineInputProps> = ({
  isRequired,
  multiple,
  sx,
  source,
}): ReactElement => {
  return (
    <AutocompleteReferenceInput
      resource={RESOURCE_DRAWING_DISCIPLINE}
      label="Drawing Discipline"
      name="drawingDiscipline_inc"
      optionText="drawingDiscipline"
      source={source}
      multiple={multiple}
      validate={isRequired ? [required()] : []}
      sx={sx}
      includeFields={['id', 'drawingDiscipline']}
    />
  );
};

DrawingDisciplineInput.defaultProps = { source: 'drawingDisciplineId' };

export default DrawingDisciplineInput;
