import React, { ReactElement } from 'react';
import { ListProps, TextField, useStore } from 'react-admin';
import FreeTextSearchFilter from '../../common/FreeTextSearchFilter';
import ListActions from '../../common/ListActions';
import DatagridConfigurableRbac from '../../common/DatagridConfigurableRbac';
import renderMetaDataFields from '../../common/helpers/renderMetaDataFields';
import ColumnActions from '../../common/ColumnActions';
import ListWithTitle from '../../common/ListWithTitle';
import useDefaultFilter from '../../common/hooks/useDefaultFilter';
import { useDictionaryLabelsWithResource } from '../../../utils/UtilityFunctions';
import { getSideFiltersKey } from '../../common/helpers/storeKeys';
import { RESOURCE_MATERIAL } from '../../constants';
import PriceField from '../common/PriceField';
import MaterialTypeField from '../../common/MaterialTypeField';
import {
  getListRowStyle,
  getMaterialListRowBorderColor,
  statusesLegend,
} from '../helpers';
import LegendButton from '../../common/LegendButton';
import CustomBooleanField from '../../common/CustomBooleanField';
import MaterialSidebarFilter from './MaterialSidebarFilter';
import MaterialShow from './MaterialShow';
import MaterialEdit from './MaterialEdit';
import ActivePoListField from './components/ActivePoListField';
import { MATERIAL_EXPORT_BUTTONS } from './constants';
import BulkActionButtons from './components/BulkActionButtons';

const MaterialList: React.FC<ListProps> = (): ReactElement => {
  const { preferenceKey } = useDictionaryLabelsWithResource();
  const sideFilter = getSideFiltersKey(RESOURCE_MATERIAL);
  const [showSideFilter] = useStore(sideFilter, false);
  const defaultFilter = useDefaultFilter('project');

  return (
    <ListWithTitle
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={
        <ListActions
          actions={<LegendButton legends={statusesLegend} />}
          sideFilter={sideFilter}
          preferenceKey={preferenceKey}
          defaultFilter={defaultFilter}
          multipleExportButtonsInfo={MATERIAL_EXPORT_BUTTONS}
        />
      }
      sort={{ field: 'id', order: 'ASC' }}
      aside={
        showSideFilter ? (
          <MaterialSidebarFilter defaultFilter={defaultFilter} />
        ) : null
      }
    >
      <DatagridConfigurableRbac
        ShowComponent={<MaterialShow withoutActions />}
        EditComponent={<MaterialEdit syncWithLocation={false} />}
        bulkActionButtons={<BulkActionButtons />}
        rowSx={getListRowStyle(getMaterialListRowBorderColor)}
        defaultVisibleColumns={[
          'id',
          'material',
          'description',
          'materialType',
          'totalQty',
          'orderPoint',
          'Actions',
        ]}
        preferenceKey={preferenceKey}
      >
        <TextField source="id" label="ID" />
        <TextField source="material" label="Material" />
        <TextField source="description" label="Description" />
        <MaterialTypeField label="Material Type" sortable={false} />
        <TextField source="totalQty" label="Total Qty on Hand" />
        <TextField source="orderPoint" label="Order Point" />
        <ActivePoListField
          source="materialPurchaseOrderMaterials"
          sortable={false}
        />
        <CustomBooleanField source="spare" label="Spare" />
        <PriceField />
        <TextField source="defReorderQty" label="Def Reorder Qty" />
        <TextField source="minLevel" label="Min Level" />
        <TextField source="maxLevel" label="Max Level" />
        {renderMetaDataFields()}
        <ColumnActions label="Actions" />
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default MaterialList;
