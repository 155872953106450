import React, { ReactElement, useMemo } from 'react';
import { AutocompleteInput } from 'react-admin';
import { getContext, UserContext } from '../../provider/userContext';
import { RESOURCE_DISCIPLINE_TEST } from '../constants';
import AutocompleteReferenceInput from './AutocompleteReferenceInput';

interface DisciplineTestInputProps
  extends React.ComponentProps<typeof AutocompleteInput> {
  alwaysOn?: boolean;
  multiple?: boolean;
  sx?: object;
  source?: string;
  label?: string;
}

const DisciplineTestInput: React.FC<DisciplineTestInputProps> = ({
  label,
  multiple,
  sx,
  ...rest
}): ReactElement => {
  const ctx: UserContext = getContext();
  const requestParams = useMemo(() => {
    return {
      filter: { divisionId: ctx?.defaultDivisionId },
      sort: { field: 'disciplineTest', order: 'ASC' as const },
    };
  }, [ctx?.defaultDivisionId]);

  return (
    <AutocompleteReferenceInput
      {...rest}
      resource={RESOURCE_DISCIPLINE_TEST}
      requestParams={requestParams}
      label={label}
      name="disciplineTest_inc"
      optionText="disciplineTest"
      multiple={multiple}
      sx={sx}
      includeFields={['id', 'disciplineTest']}
    />
  );
};

DisciplineTestInput.defaultProps = {
  label: 'Discipline Test',
  source: 'disciplineTestId',
};

export default DisciplineTestInput;
