import { useCallback, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { FILE_CHUNK_LIMIT } from '../constants';
import useDataProvider from '../../../provider/dataProvider/useDataProvider';
import { splitFileToСhunks } from '../../attachment/helpers';
import { RecordWithAnyData } from '../types';

interface FileChunkInfo {
  filename: string;
  partNumber: number;
  total: number;
  id: string;
  uploadId?: string;
  parts?: Omit<UploadPartResponse, 'uploadId'>[];
}

interface UploadPartResponse {
  uploadId: string;
  eTag: string;
  partNumber: number;
  size: number;
}

interface ExternalStorageObject {
  key: string;
  lastModified: Date;
  size: number;
  filename: string;
  excelRowsCount?: number;
}

export const useUploadFile = () => {
  const dataProvider = useDataProvider();
  const [uploadInProgress, setUploadInProgress] = useState(false);

  const uploadLargeFile = useCallback(
    async (resource: string, largeFile: File, data: RecordWithAnyData) => {
      const id: string = uuid();
      const filename = largeFile.name;
      const parts: Blob[] = splitFileToСhunks(largeFile, FILE_CHUNK_LIMIT);
      const total = parts.length;

      const resultParts: Omit<UploadPartResponse, 'uploadId'>[] = [];
      let uploadId: string;
      let partNumber = 1;
      for (const fileChunk of parts) {
        const chunkInfo: FileChunkInfo = {
          id,
          partNumber,
          filename,
          total,
          uploadId,
          parts: partNumber === total ? resultParts : undefined,
        };
        const result = await dataProvider.uploadFile(resource, {
          file: fileChunk,
          ...chunkInfo,
          ...data,
        });

        if (partNumber === total) {
          return result;
        } else {
          uploadId = result.data.uploadId;
          resultParts.push({
            eTag: result.data.eTag,
            partNumber,
            size: result.data.size,
          });
          partNumber++;
        }
      }
    },
    [dataProvider]
  );

  const uploadSmallFile = useCallback(
    (resource: string, file: File, data: RecordWithAnyData) =>
      dataProvider.uploadFile(resource, { file, ...data }),
    [dataProvider]
  );

  const uploadFile = useCallback(
    async (
      resource: string,
      file: File,
      data: RecordWithAnyData
    ): Promise<ExternalStorageObject> => {
      try {
        let result;
        if (file.size > FILE_CHUNK_LIMIT) {
          result = await uploadLargeFile(resource, file, data);
        } else {
          result = await uploadSmallFile(resource, file, data);
        }
        return result.data;
      } finally {
        setUploadInProgress(false);
      }
    },
    [uploadLargeFile, uploadSmallFile]
  );

  return { uploadInProgress, uploadFile };
};
