import Add from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import { CreateDialog } from '@react-admin/ra-form-layout';
import React, { Fragment, ReactElement, useCallback, useState } from 'react';
import {
  Button,
  CreateProps,
  SimpleForm,
  useCreate,
  useNotify,
  useRecordContext,
} from 'react-admin';
import { getContext, UserContext } from '../../../provider/userContext';
import { getErrorMessage, required } from '../../../utils/UtilityFunctions';
import AutocompleteReferenceInput from '../../common/AutocompleteReferenceInput';
import commonStyles from '../../common/commonStyles';
import useInvalidateResourceQueryCache from '../../common/hooks/useInvalidateResourceQueryCache';
import {
  RESOURCE_EQUIPMENT_TYPE,
  RESOURCE_TEST_EQUIPMENT_TYPE,
  RESOURCE_EQUIPMENT_TYPE_TEST_EQUIPMENT_TYPE,
} from '../../constants';

const TestEquipmentTypeCreateDialog: React.FC<CreateProps> = (
  props
): ReactElement => {
  const notify = useNotify();
  const record = useRecordContext();
  const [create] = useCreate();
  const [open, setOpen] = useState(false);
  const ctx: UserContext = getContext();
  const invalidateQueries = useInvalidateResourceQueryCache(
    RESOURCE_EQUIPMENT_TYPE,
    true
  );

  const handleOpen = useCallback(() => setOpen(true), []);

  const handleClose = useCallback(() => setOpen(false), []);

  const onSubmit = useCallback(async () => {
    setOpen(false);
    await invalidateQueries();
  }, [invalidateQueries]);

  const handleSubmit = useCallback(
    async (data) => {
      await create(
        RESOURCE_EQUIPMENT_TYPE_TEST_EQUIPMENT_TYPE,
        {
          data: {
            ...data,
            equipTypeId: Number(record.id),
          },
        },
        {
          onSuccess: onSubmit,
          onError: (error) => {
            notify(getErrorMessage(error), { type: 'error', undoable: false });
          },
        }
      );
    },
    [create, record, onSubmit, notify]
  );

  return (
    <Fragment>
      <Button label="Add test equipment type" onClick={handleOpen}>
        <Add />
      </Button>
      <CreateDialog
        {...props}
        title={<span>Add Test Equipment Type</span>}
        fullWidth
        maxWidth="md"
        isOpen={open}
        open={handleOpen}
        close={handleClose}
      >
        <SimpleForm
          id="create_equipment_type_test_equipment_type_form"
          onSubmit={handleSubmit}
        >
          <Box sx={commonStyles.createBoxHalfWidth}>
            <AutocompleteReferenceInput
              resource={RESOURCE_TEST_EQUIPMENT_TYPE}
              requestParams={{ filter: { projectId: ctx?.projectId } }}
              source="testEquipTypeId"
              label="Test Equipment Type"
              sx={commonStyles.flexBoxLongItem}
              optionText="type"
              validate={[required()]}
            />
          </Box>
        </SimpleForm>
      </CreateDialog>
    </Fragment>
  );
};

export default TestEquipmentTypeCreateDialog;
