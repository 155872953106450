import React, { Fragment, ReactElement, useCallback, useState } from 'react';
import { BooleanInput, maxLength, SimpleForm, useUpdate } from 'react-admin';

import { EditDialog } from '@react-admin/ra-form-layout';
import { Box, IconButton } from '@mui/material';
import { ReactComponent as EditIcon } from '../../../assets/icons/functionalIcons/Edit.svg';
import { RESOURCE_ATTACHMENT } from '../../constants';
import TextInput from '../../common/TextInput';
import AttachmentTypeInput from '../../common/AttachmentTypeInput';
import commonStyles from '../../common/commonStyles';
import { required } from '../../../utils/UtilityFunctions';
import FormToolBar from './FormToolBar';

type AttachmentEditType = {
  isAdmin?: boolean;
};

const transform = (data) => {
  return {
    ...data,
    fileName: data.fileName.split('.')[0],
  };
};

const AttachmentEditButton: React.FC<AttachmentEditType> = ({
  isAdmin = false,
}): ReactElement => {
  const [showDialog, setShowDialog] = useState(false);
  const [update] = useUpdate();
  const openDialog = useCallback(() => {
    setShowDialog(true);
  }, []);
  const closeDialog = useCallback(() => setShowDialog(false), []);
  const submit = useCallback(
    async (data) => {
      await update(RESOURCE_ATTACHMENT, {
        id: data.id,
        data: transform(data),
        previousData: data,
      });
      closeDialog();
    },
    [closeDialog, update]
  );

  return (
    <Fragment>
      <IconButton
        size="small"
        color="primary"
        onClick={openDialog}
        className="editButton"
        data-testid="attachmentEditButton"
      >
        <EditIcon />
      </IconButton>
      <EditDialog
        fullWidth
        maxWidth="md"
        mutationMode="pessimistic"
        close={closeDialog}
        onClick={(event) => event?.stopPropagation()}
        isOpen={showDialog}
      >
        <SimpleForm
          id="edit_attachment_form"
          toolbar={
            <FormToolBar
              deleteButtonProps={{ show: !isAdmin, redirect: false }}
              onCancel={closeDialog}
            />
          }
          onSubmit={submit}
        >
          <TextInput
            source="fileName"
            name="fileName"
            validate={[maxLength(150), required()]}
            fullWidth
            format={(value) => (value ? value.split('.')[0] : '')}
          />
          <Box sx={[commonStyles.flexBox, { width: '100%' }]}>
            <TextInput
              source="description"
              name="description"
              validate={[maxLength(255)]}
              sx={commonStyles.flexBoxLongItem}
              fullWidth
            />
            <AttachmentTypeInput
              sx={[commonStyles.flexBoxLongItem, { marginRight: '0px' }]}
            />
          </Box>
          {isAdmin && (
            <BooleanInput label="Deleted" source="isDeleted" name="isDeleted" />
          )}
        </SimpleForm>
      </EditDialog>
    </Fragment>
  );
};

export default AttachmentEditButton;
