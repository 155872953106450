import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import React, {
  Fragment,
  ReactElement,
  useCallback,
  useRef,
  useState,
} from 'react';
import { useListContext, useStore } from 'react-admin';
import { DEFAULT_MULTIPLE_EXPORT_BUTTONS } from './constants';
import getExportParams from './helpers/getExportParams';
import useColumnsToShow from './hooks/useColumnsToShow';
import useExport from './hooks/useExport';
import useGetLookupFilter from './hooks/useGetLookupFilter';
import { AvailableColumn, MultipleExportButtonInfo } from './types';

interface MultipleExportButtonProps {
  buttonsInfo?: MultipleExportButtonInfo[];
  preferenceKey?: string;
}

const META_COLUMN_SOURCES = ['updatedAt', 'updatedBy', 'updatedByName'];

/*                             Export ways
  Default export (first option):
    - selected columns
  Export All (buttonsInfo[selectedItem].includeAllColumns = true):
    - all data columns
    - columns from selected export option if present (buttonsInfo[selectedItem].column)
    - meta columns
  Export <Custom>:
    - columns from selected export option if present (buttonsInfo[selectedItem].column)
    - meta columns
 */

export const MultipleExportButton: React.FC<MultipleExportButtonProps> = ({
  buttonsInfo = DEFAULT_MULTIPLE_EXPORT_BUTTONS,
  preferenceKey,
}): ReactElement => {
  const { exportInProgress, exportFile } = useExport();
  const { resource, sort, filterValues } = useListContext();
  const resourceKey = preferenceKey ?? resource + '.datagrid';
  const [availableColumns] = useStore<AvailableColumn[]>(
    `preferences.${resourceKey}.availableColumns`,
    []
  );
  const [menuOpen, setMenuOpen] = useState(false);
  const menuAnchorRef = useRef<HTMLDivElement>(null);
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);
  const lookupFilter = useGetLookupFilter();
  const selectedColumns = useColumnsToShow(resourceKey);

  const handleExportButtonClick = useCallback(() => {
    const columnsToExport = [];
    let columnSources: string[];
    const selectedExportOption = buttonsInfo[selectedMenuIndex];
    // Check for export additional columns.
    // If selectedMenuIndex is zero, this means default export.
    if (buttonsInfo && selectedMenuIndex !== 0) {
      const metaDataColumns = availableColumns.filter((column) =>
        META_COLUMN_SOURCES.includes(column.source)
      );
      const relevantDataColumns = availableColumns.filter(
        (column) =>
          !META_COLUMN_SOURCES.includes(column.source) && column.source
      );

      if (selectedExportOption.includeAllColumns) {
        columnsToExport.push(...relevantDataColumns);
      }

      if (selectedExportOption.columns) {
        columnsToExport.push(...selectedExportOption.columns);
      }

      columnsToExport.push(...metaDataColumns);
      columnSources = columnsToExport.map((column) => column.source);
    } else {
      columnSources = selectedColumns;
    }

    const exportParams = getExportParams(
      columnSources,
      filterValues,
      sort,
      lookupFilter
    );
    exportFile(
      selectedExportOption?.resource || resource,
      exportParams,
      selectedExportOption.fileName
    );
  }, [
    buttonsInfo,
    selectedMenuIndex,
    exportFile,
    resource,
    availableColumns,
    selectedColumns,
    filterValues,
    sort,
    lookupFilter,
  ]);

  const handleExportMenuItemClick = useCallback(
    (_: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
      setSelectedMenuIndex(index);
      setMenuOpen(false);
    },
    []
  );

  const handleMenuToggle = useCallback(() => {
    setMenuOpen((prevOpen) => !prevOpen);
  }, []);

  const handleMenuClose = useCallback((event: Event) => {
    if (
      menuAnchorRef.current &&
      menuAnchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setMenuOpen(false);
  }, []);

  return (
    <Fragment>
      <ButtonGroup
        variant="outlined"
        ref={menuAnchorRef}
        aria-label="export buttons"
      >
        <Button
          style={{ borderWidth: 2 }}
          disabled={exportInProgress}
          onClick={handleExportButtonClick}
          className="exportButton"
        >
          {exportInProgress
            ? 'Exporting...'
            : `${buttonsInfo[selectedMenuIndex].label}`}
        </Button>
        {!buttonsInfo[selectedMenuIndex]?.singleButton && (
          <Button
            style={{ borderWidth: 2, marginLeft: -2 }}
            size="small"
            aria-controls={menuOpen ? 'export-button-menu' : undefined}
            aria-expanded={menuOpen ? 'true' : undefined}
            aria-label="select export method"
            aria-haspopup="menu"
            onClick={handleMenuToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        )}
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1000,
        }}
        open={menuOpen}
        anchorEl={menuAnchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleMenuClose}>
                <MenuList id="export-button-menu" autoFocusItem>
                  {buttonsInfo.map((option, index) => (
                    <MenuItem
                      key={option.label}
                      selected={index === selectedMenuIndex}
                      onClick={(event) =>
                        handleExportMenuItemClick(event, index)
                      }
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

export default MultipleExportButton;
