import { SxProps, Theme } from '@mui/material/styles';
import { Dayjs } from 'dayjs';
import React, { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import CustomDateInput from '../../common/CustomDateInput';
import { required } from '../../../utils/UtilityFunctions';

interface CompletedDateTimeInputProps {
  sx?: SxProps<Theme>;
  defaultDate?: Dayjs;
}

const CompletedDateTimeInput: React.FC<CompletedDateTimeInputProps> = ({
  sx,
  defaultDate,
}): ReactElement => {
  const { watch } = useFormContext();
  const isVerified = watch('isVerified');
  const completedBy = watch('completedBy');

  return (
    <CustomDateInput
      source="completedDateTime"
      label="Completed Test Date"
      validate={isVerified || completedBy ? [required()] : []}
      sx={sx}
      defaultValue={defaultDate}
    />
  );
};

export default CompletedDateTimeInput;
