import React, { ReactElement } from 'react';
import { ListProps, TextField } from 'react-admin';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import CustomBooleanField from '../common/CustomBooleanField';
import DisciplineField from '../common/DisciplineField';
import ListWithTitle from '../common/ListWithTitle';
import ColumnActions from '../common/ColumnActions';
import ListActions from '../common/ListActions';
import EquipmentTypeShow from './EquipmentTypeShow';
import EquipmentTypeEdit from './EquipmentTypeEdit';
import EquipmentTypeTest from './EquipmentTypeTest';
import BulkActionButtons from './BulkActionButtons';
import {
  EQUIPMENT_TYPE_EXPORT_BUTTONS,
  EQUIPMENT_TYPE_IMPORT_BUTTONS,
} from './constants';
import EquipmentTypeTestEquipmentType from './EquipmentTypeTestEquipmentType';

const EquipmentTypeList: React.FC<ListProps> = (): ReactElement => {
  const defaultFilter = useDefaultFilter('project');

  return (
    <ListWithTitle
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={
        <ListActions
          multipleImportButtonsInfo={EQUIPMENT_TYPE_IMPORT_BUTTONS}
          multipleExportButtonsInfo={EQUIPMENT_TYPE_EXPORT_BUTTONS}
        />
      }
      sort={{ field: 'equipType', order: 'ASC' }}
    >
      <DatagridConfigurableRbac
        ShowComponent={<EquipmentTypeShow withoutActions />}
        EditComponent={<EquipmentTypeEdit syncWithLocation={false} />}
        bulkActionButtons={<BulkActionButtons />}
        defaultVisibleColumns={[
          'id',
          'equipType',
          'description',
          'disciplineId',
        ]}
      >
        <TextField source="id" label="ID" />
        <TextField source="equipType" label="Type" />
        <TextField source="description" label="Description" />
        <EquipmentTypeTest label="Tests" sortable={false} />
        <EquipmentTypeTestEquipmentType
          label="Test Equipment Types"
          sortable={false}
        />
        <TextField source="hardSoft" label="Hard/Soft" />
        <CustomBooleanField source="metricsCount" label="Metrics Count" />
        <TextField source="grouping" label="Grouping" sortable={false} />
        <DisciplineField source="disciplineId" />
        {renderMetaDataFields()}
        <ColumnActions label="Actions" />
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default EquipmentTypeList;
