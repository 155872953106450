import React, { Fragment, ReactElement } from 'react';
import BulkEditButton from '../../common/BulkEditButton';
import useCanAccess from '../../common/hooks/useCanAccess';
import { RESOURCE_TEST_EQUIPMENT } from '../../constants';
import TestEquipmentForm from '../TestEquipmentForm';

const BulkActionButtons: React.FC = (): ReactElement => {
  const canAccessList = useCanAccess();

  if (!canAccessList.edit) return;

  return (
    <Fragment>
      <BulkEditButton
        resource={RESOURCE_TEST_EQUIPMENT}
        form={<TestEquipmentForm bulkEditMode editMode />}
      />
    </Fragment>
  );
};

export default BulkActionButtons;
