import React, { ReactElement } from 'react';
import { TabbedShowLayout, Labeled, TextField, SelectField } from 'react-admin';
import { Box } from '@mui/material';
import AuditListTab from '../../../audit/AuditListTab';
import commonStyles from '../../../common/commonStyles';
import renderShowMetaDataFields from '../../../common/helpers/renderShowMetaDataFields';
import { ShowComponentProps } from '../../../common/ShowComponent';
import { RESOURCE_GROUP_CONTACT } from '../../../constants';
import { categoryChoices } from '../../constants';
import { useIsShowContactsTab } from '../../hooks';
import ContactShowTab from '../../../common/ContactFormTab/ContactShowTab';

const GroupTabsShow: React.FC<ShowComponentProps> = ({
  syncWithLocation,
}): ReactElement => {
  const isShowContactsTab = useIsShowContactsTab();

  return (
    <TabbedShowLayout
      sx={commonStyles.bottomMargin}
      syncWithLocation={syncWithLocation}
    >
      <TabbedShowLayout.Tab label="Group">
        <Box>
          <Labeled>
            <TextField
              source="groupName"
              label="Group"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled>
            <TextField
              source="description"
              label="Description"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
        </Box>
        <SelectField
          source="category"
          choices={categoryChoices}
          label="Category"
          sx={commonStyles.flexBoxItem}
        />
        <Box>{renderShowMetaDataFields()}</Box>
      </TabbedShowLayout.Tab>
      {isShowContactsTab && (
        <TabbedShowLayout.Tab label="Contacts">
          <ContactShowTab
            target="group_id"
            reference={RESOURCE_GROUP_CONTACT}
          />
        </TabbedShowLayout.Tab>
      )}
      <TabbedShowLayout.Tab label="History">
        <AuditListTab tableName="tbl_group" />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  );
};

export default GroupTabsShow;
