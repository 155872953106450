export const MATERIAL_EXPORT_BUTTONS = [
  {
    label: 'Export',
    fileName: 'material',
  },
  {
    label: 'Export All',
    fileName: 'material_all',
    // Total Qty in Warehouses;	Storage Location;	Actual PO; 	Total Qty Ordered;	PO Status;	Vendor;	Tag
    columns: [
      { source: 'id', label: 'ID' },
      { source: 'totalQtyInWarehouse', label: 'Total Qty in Warehouses' },
      { source: 'storageLocation', label: 'Storage Location' },
      { source: 'totalQtyOrdered', label: 'Total Qty Ordered' },
      { source: 'actualPo', label: 'Actual PO' },
      { source: 'poStatus', label: 'PO Status' },
      { source: 'vendor', label: 'Vendor' },
      { source: 'tag', label: 'Tag' },
    ],
    includeAllColumns: true,
  },
];
