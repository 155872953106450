import React, { ReactElement } from 'react';
import { Labeled, TabbedShowLayout, TextField } from 'react-admin';
import Box from '@mui/material/Box';
import ActivitiesTab from '../common/ActivitiesTab';
import renderShowMetaDataFields from '../common/helpers/renderShowMetaDataFields';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import CustomBooleanField from '../common/CustomBooleanField';
import commonStyles from '../common/commonStyles';
import {
  RESOURCE_BOOK,
  RESOURCE_COMMENT,
  RESOURCE_SUBSYSTEM,
  RESOURCE_SUBSYSTEM_DRAWING,
} from '../constants';
import SystemField from '../common/SystemField';
import CommentList from '../comment/CommentList';
import AttachmentList from '../attachment/AttachmentList';
import { activityCategory, commentType } from '../common/constants';
import DrawingTab from '../common/DrawingTab';
import AuditListTab from '../audit/AuditListTab';

const SubSystemShow: React.FC<ShowComponentProps> = ({
  syncWithLocation,
  ...rest
}): ReactElement => (
  <ShowComponent {...rest}>
    <TabbedShowLayout
      sx={commonStyles.bottomMargin}
      syncWithLocation={syncWithLocation}
    >
      <TabbedShowLayout.Tab label="SubSystem">
        <Box>
          <Labeled sx={commonStyles.flexBoxItem}>
            <SystemField source="systemId" />
          </Labeled>
          <Labeled>
            <TextField
              source="subsystem"
              label="SubSystem"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled>
            <TextField
              source="description"
              label="Description"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
        </Box>
        <Box>
          <Labeled>
            <TextField
              source="phase"
              label="Phase"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled>
            <TextField
              source="sequence"
              label="Sequence"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled>
            <CustomBooleanField
              source="isScoped"
              label="Scoped"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled>
            <CustomBooleanField
              source="isVerified"
              label="Verified"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
        </Box>
        <Box>{renderShowMetaDataFields()}</Box>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Drawings" id="drawings_tab">
        <DrawingTab
          target="subsystem_id"
          reference={RESOURCE_SUBSYSTEM_DRAWING}
        />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Comments" id="comments_tab">
        <CommentList
          resource={RESOURCE_COMMENT}
          targetId="subsystem_id"
          commentType={commentType.subsystem}
        />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Attachments" id="attachments_tab">
        <AttachmentList
          targetId="subsystemId"
          resourceName={[RESOURCE_SUBSYSTEM, RESOURCE_BOOK]}
          onlyShow
        />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Activities" id="activity_tab">
        <ActivitiesTab category={activityCategory.subSystem} onlyShow />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="History">
        <AuditListTab tableName="tbl_subsystem" />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </ShowComponent>
);

export default SubSystemShow;
