import React, { ReactElement } from 'react';
import { maxLength } from 'react-admin';
import Box from '@mui/material/Box';
import { EditPropsWithLocation } from '../common/types';
import TabbedEditForm from '../common/TabbedEditForm';
import MetaDataInputs from '../common/MetaDataInputs';
import commonStyles from '../common/commonStyles';
import LazyFormTab from '../common/LazyFormTab';
import HistoryTab from '../common/HistoryTab';
import TextInput from '../common/TextInput';
import { required } from '../../utils/UtilityFunctions';
import { PunchCategory } from './types';

const PunchCategoryEdit: React.FC<EditPropsWithLocation<PunchCategory>> = (
  props
): ReactElement => (
  <TabbedEditForm {...props}>
    <LazyFormTab
      label="Punch Category"
      id="punch_category_tab"
      sx={commonStyles.formTabHeader}
    >
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="category"
          label="Punch Category"
          validate={[maxLength(75), required()]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="description"
          label="Description"
          validate={[maxLength(150)]}
          sx={commonStyles.flexBoxItem}
          fullWidth
        />
      </Box>
      <MetaDataInputs />
    </LazyFormTab>
    <HistoryTab tableName="tbl_punch_category" />
  </TabbedEditForm>
);

export default PunchCategoryEdit;
