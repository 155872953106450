import moment from 'moment';
import React, { ReactElement } from 'react';
import { DateField, TextField } from 'react-admin';
import { RED, YELLOW } from '../../../theme/colors';
import ColumnActions from '../../common/ColumnActions';
import CustomBooleanField from '../../common/CustomBooleanField';
import DatagridConfigurableRbac from '../../common/DatagridConfigurableRbac';
import renderMetaDataFields from '../../common/helpers/renderMetaDataFields';
import TestEquipmentTypeField from '../../common/TestEquipmentTypeField';
import VendorField from '../../common/VendorField';
import TestEquipmentEdit from '../TestEquipmentEdit';
import TestEquipmentShow from '../TestEquipmentShow';
import { TestEquipment } from '../types';
import BulkActionButtons from './BulkActionButtons';

const TestEquipmentListDatagrid: React.FC = (): ReactElement => {
  const getRowStyle = (record: TestEquipment) => ({
    borderLeftColor: getRowBorderColor(record),
    borderLeftWidth: 8,
    borderLeftStyle: 'solid',
  });

  const getRowBorderColor = (record: TestEquipment) => {
    const dateDifference = moment().diff(
      record.calibrationExpirationDate,
      'month',
      true
    );
    if (dateDifference <= -1) {
      return 'transparent';
    } else if (dateDifference > -1 && dateDifference <= 0) {
      return YELLOW;
    } else {
      return RED;
    }
  };

  return (
    <DatagridConfigurableRbac
      ShowComponent={<TestEquipmentShow withoutActions />}
      EditComponent={<TestEquipmentEdit syncWithLocation={false} />}
      bulkActionButtons={<BulkActionButtons />}
      defaultVisibleColumns={[
        'id',
        'testEquipment',
        'manufacturer',
        'model',
        'serialNumber',
        'rent',
        // TODO uncomment when "Test Equipment Checkout Page" is ready
        // 'available',
        'calibrationDate',
        'calibrationExpirationDate',
        'updatedAt',
      ]}
      rowStyle={getRowStyle}
    >
      <TextField source="id" label="ID" />
      <TextField
        source="testEquipment"
        label="Test Equipment name"
        sortBy="testEquipment"
      />
      <TextField
        source="manufacturer"
        label="Manufacturer"
        sortBy="manufacturer"
      />
      <TextField source="model" label="Model" sortBy="model" />
      <TextField
        source="serialNumber"
        label="Serial Number"
        sortBy="serialNumber"
      />
      <CustomBooleanField source="rent" label="Rent" />
      <TextField source="cost" label="Cost" />
      <DateField source="calibrationDate" label="Calibration Date" />
      <DateField
        source="calibrationExpirationDate"
        label="Calibration Expiration Date"
      />
      {/* TODO uncomment when "Test Equipment Checkout Page" is ready */}
      {/*<CustomBooleanField source="available" label="Available" />*/}
      <VendorField label="Vendor" />
      <TestEquipmentTypeField />
      {renderMetaDataFields()}
      <ColumnActions label="Actions" />
    </DatagridConfigurableRbac>
  );
};

export default TestEquipmentListDatagrid;
