import React, { ReactElement } from 'react';
import { SimpleForm } from 'react-admin';
import { getContext, UserContext } from '../../provider/userContext';
import CreateWithTitle from '../common/CreateWithTitle';
import FooterToolbar from '../common/FooterToolbar';
import { editStatuses } from '../pendingPunch/constants';
import { PendingPunch } from '../pendingPunch/types';
import SaveButtons from './components/SaveButtons';
import { Originator } from './constants';
import SubmitPunchForm from './SubmitPunchForm';

const SubmitPunchCreate: React.FC = (): ReactElement => {
  const ctx: UserContext = getContext();

  const transform = (data: PendingPunch): PendingPunch => ({
    ...data,
    projectId: ctx?.projectId,
  });

  return (
    <CreateWithTitle redirect="list" transform={transform}>
      <SimpleForm
        defaultValues={{
          raisedBy: ctx?.fullName,
          raisedDate: new Date().toISOString(),
          originator: Originator.StandaloneWeb,
          status: editStatuses[0].id,
        }}
        toolbar={<FooterToolbar SaveButtonComponent={<SaveButtons />} />}
      >
        <SubmitPunchForm />
      </SimpleForm>
    </CreateWithTitle>
  );
};

export default SubmitPunchCreate;
