import React, { ReactElement } from 'react';
import { ReferenceField, TextField } from 'react-admin';
import { RESOURCE_CURRENCY } from '../../../components/constants';

interface CurrencyFieldProps {
  label?: string;
  source?: string;
  textSource?: string;
}

const CurrencyField: React.FC<CurrencyFieldProps> = ({
  label,
  source,
  textSource,
}): ReactElement => {
  return (
    <ReferenceField
      label={label}
      reference={RESOURCE_CURRENCY}
      link={false}
      source={source}
    >
      <TextField source={textSource} />
    </ReferenceField>
  );
};

CurrencyField.defaultProps = {
  label: 'Currency',
  source: 'currencyId',
  textSource: 'code',
};

export default CurrencyField;
