import React, { ReactElement } from 'react';
import { maxLength } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { SxProps, Theme } from '@mui/material/styles';
import { ContactCategories } from '../../common/contactInput/constants';
import ContactInput from '../../common/contactInput/ContactInput';
import { required } from '../../../utils/UtilityFunctions';

interface CompletedByInputProps {
  sx: SxProps<Theme>;
}

const CompletedByInput: React.FC<CompletedByInputProps> = ({
  sx,
}): ReactElement => {
  const { watch } = useFormContext();
  const isVerified = watch('isVerified');
  const completedDateTime = watch('completedDateTime');

  return (
    <ContactInput
      category={ContactCategories.itrEmpWorkGrp}
      source="completedBy"
      label="Completed By"
      validate={
        isVerified || completedDateTime
          ? [maxLength(75), required()]
          : [maxLength(75)]
      }
      sx={sx}
    />
  );
};

export default CompletedByInput;
