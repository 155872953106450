import React, { ReactElement } from 'react';
import { NotificationType } from '../common/enums';
import ImportNotification from './components/ImportNotification';
import ErrorNotification from './components/ErrorNotification';
import NotificationItemCard from './components/NotificationItemCard';
import { Notification } from './type';
import ExportNotification from './components/ExportNotification';
import ConvertToPdfNotification from './components/ConvertToPdfNotification';

interface NotificationTitleProps {
  notification: Notification;
  readNotification: (id: number) => void;
}

const NotificationItem: React.FC<NotificationTitleProps> = ({
  notification,
  readNotification,
}): ReactElement => {
  switch (notification.notificationType) {
    case NotificationType.IMPORT:
      return (
        <ImportNotification
          notification={notification}
          readNotification={readNotification}
        />
      );
    case NotificationType.ERROR:
      return (
        <ErrorNotification
          notification={notification}
          readNotification={readNotification}
        />
      );
    case NotificationType.EXPORT:
      return (
        <ExportNotification
          notification={notification}
          readNotification={readNotification}
        />
      );
    case NotificationType.EXPORT_REPORT:
      return (
        <ExportNotification
          notification={notification}
          readNotification={readNotification}
        />
      );
    case NotificationType.CONVERT_TO_PDF_AND_SAVE_ATTACHMENTS:
      return (
        <ConvertToPdfNotification
          notification={notification}
          readNotification={readNotification}
        />
      );
    default:
      return (
        <NotificationItemCard
          notification={notification}
          readNotification={readNotification}
        />
      );
  }
};

export default NotificationItem;
