import React, { ReactElement, useState, useCallback } from 'react';
import { Edit, ReferenceManyField, useGetOne, useNotify } from 'react-admin';
import { useParams } from 'react-router-dom';
import { Box, Tabs, Tab } from '@mui/material';
import { AxiosResponse } from 'axios';
import useCanAccess from '../common/hooks/useCanAccess';
import AttachmentFormTab from '../common/AttachmentFormTab';
import CustomTabPanel from '../common/CustomTabPanel';
import EditTitle from '../common/EditTitle';
import { EditPropsWithLocation } from '../common/types';
import { ATTACHMENT_TYPE_ENUMS } from '../common/enums';
import sideFilterStyles from '../common/sideFilterStyles';
import commonStyles from '../common/commonStyles';
import PendingPunchCreateInDialog from '../pendingPunch/components/PendingPunchCreateInDialog';
import PendingPunchFormTab from '../pendingPunch/components/PendingPunchFormTab';
import { Originator } from '../submitPunch/constants';
import {
  RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY,
  RESOURCE_PRESERVATION_ITEM_ACTIVITY,
  RESOURCE_PENDING_PUNCH,
} from '../constants';
import SmartPreservationItemActivityForm from './components/SmartPreservationItemActivityForm';
import { SmartPreservationItemActivityType } from './types';

interface SmartPreservationItemActivityEditProps
  extends EditPropsWithLocation<SmartPreservationItemActivityType> {
  onCancel?: () => void;
}

const SmartPreservationItemActivityEdit: React.FC<
  SmartPreservationItemActivityEditProps
> = (props): ReactElement => {
  const { id } = useParams();
  const { onCancel } = props;
  const [value, setValue] = useState(0);
  const [pendingPunchTitle, setPendingPunchTitle] = useState(null);
  const [
    pendingPunchCreateDialogCloseDisabled,
    setPendingPunchCreateDialogCloseDisabled,
  ] = useState(false);
  const notify = useNotify();
  const canAccessList = useCanAccess();
  // Use RESOURCE_PRESERVATION_ITEM_ACTIVITY for avoiding reloading on autosave
  const { data: record } = useGetOne(RESOURCE_PRESERVATION_ITEM_ACTIVITY, {
    id: props?.id ?? id,
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleClose = useCallback(() => {
    setPendingPunchTitle(null);
  }, []);

  const handleUpload = (result: AxiosResponse | AxiosResponse[]) => {
    if (result?.['status'] === 204) {
      notify(
        `File you uploaded is big and it will take some time to convert it to pdf. 
        Once it's converted you will be notified and the pdf file will appear in the list of attachments.`,
        { type: 'success' }
      );
    }
  };

  return (
    <Box sx={sideFilterStyles.sideEditCard} id="aside">
      <Edit
        {...props}
        mutationMode="pessimistic"
        actions={false}
        title={<EditTitle />}
      >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab
              label="Form"
              id="form_tab"
              sx={{ ...commonStyles.formTabHeader, overflowX: 'auto' }}
            />
            <Tab
              label="Punch List"
              id="pending_punch_common_tab"
              sx={{ ...commonStyles.formTabHeader, overflowX: 'auto' }}
            />
            <Tab
              label="Attachment"
              id="attachment_tab"
              sx={commonStyles.formTabHeader}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0} id="form_tab">
          <SmartPreservationItemActivityForm
            onCancel={onCancel}
            setPendingPunchTitle={setPendingPunchTitle}
            setPendingPunchCreateDialogCloseDisabled={
              setPendingPunchCreateDialogCloseDisabled
            }
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1} id="pending_punch_common_tab">
          <PendingPunchFormTab
            targetId="preservationItemActivityId"
            readonly={!canAccessList.edit}
            outerResource={RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}
            originator={Originator.Preservation}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2} id="attachment_tab">
          <AttachmentFormTab
            targetId="preservationItemActivityId"
            attachmentType={[ATTACHMENT_TYPE_ENUMS.GENERAL]}
            resourceName={RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}
            simpleView={true}
            onUpload={handleUpload}
          />
        </CustomTabPanel>
      </Edit>

      <ReferenceManyField
        reference={RESOURCE_PENDING_PUNCH}
        target="preservationItemActivityId"
        perPage={100}
        fullWidth={true}
      >
        <PendingPunchCreateInDialog
          record={record}
          showButton={false}
          pendingPunchDescription={pendingPunchTitle}
          disableClose={pendingPunchCreateDialogCloseDisabled}
          onClose={handleClose}
          targetId="preservationItemActivityId"
          originator={Originator.Preservation}
        />
      </ReferenceManyField>
    </Box>
  );
};

export default SmartPreservationItemActivityEdit;
