import React, { ReactElement } from 'react';
import { useStore, TextField, DateField, WrapperField } from 'react-admin';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import ListWithTitle from '../common/ListWithTitle';
import MobileTestStatusField from '../componentTest/components/MobileTestStatusField';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import { getSideFiltersKey } from '../common/helpers/storeKeys';
import CustomBooleanField from '../common/CustomBooleanField';
import AssignedField from '../common/Assigned/AssignedField';
import { RESOURCE_COMPONENT_TEST } from '../constants';
import ComponentField from '../common/ComponentField';
import DownloadButton from '../common/DownloadButton';
import ColumnActions from '../common/ColumnActions';
import LocationField from '../common/LocationField';
import ListActions from '../common/ListActions';
import TestField from '../common/TestField';
import useCheckCurrentProjectResourceAccessSettings from '../common/hooks/useCheckCurrentProjectResourceAccessSettings';
import SmartTagITRReviewEdit from './SmartTagITRReviewEdit';
import MobilePunchField from './components/MobilePunchField';
import BulkActionButtons from './components/BulkActionButtons';
import SmartTagITRReviewSidebarFilters from './components/SmartTagITRReviewSidebarFilters';

const SmartTagITRReviewList: React.FC = (): ReactElement => {
  useCheckCurrentProjectResourceAccessSettings();

  const sideFilter = getSideFiltersKey(RESOURCE_COMPONENT_TEST);
  const [showSideFilter] = useStore(sideFilter, false);
  const defaultFilter = useDefaultFilter('project', {
    isDeleted: false,
    isMobile: true,
    mobileTestStatus: ['SUBMITTED', 'APPROVED'],
  });

  return (
    <ListWithTitle
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={
        <ListActions
          sideFilter={sideFilter}
          defaultFilter={defaultFilter}
          showAction={{
            import: false,
            export: false,
            effective: false,
            create: false,
            select: true,
            expand: true,
            collapse: true,
          }}
        />
      }
      sort={{ field: 'id', order: 'ASC' }}
      aside={
        showSideFilter ? (
          <SmartTagITRReviewSidebarFilters defaultFilter={defaultFilter} />
        ) : null
      }
    >
      <DatagridConfigurableRbac
        EditComponent={<SmartTagITRReviewEdit syncWithLocation={false} />}
        bulkActionButtons={<BulkActionButtons />}
        expandSingle={false}
        defaultVisibleColumns={[
          'id',
          'componentId',
          'testId',
          'completedDateTime',
          'completedBy',
          'isVerified',
          'mobileTestStatus',
          'notProcessedPunchCount',
          'reviewComments',
        ]}
      >
        <TextField source="id" label="ID" />
        <ComponentField source="componentId" label="Tag" />
        <TestField
          source="testId"
          textSource="test.testFormNo"
          sortBy="test.test_form_no"
          label="Test No"
        />
        <LocationField source="locationId" />
        <AssignedField label="Mobile Responsible" sortable={false} />
        <TextField source="completedBy" label="Completed By" />
        <DateField source="completedDateTime" label="Completed Test Date" />
        <CustomBooleanField source="retest" label="Retest" />
        <TextField source="retestReason" label="Retest Reason" />
        <CustomBooleanField source="isPrinted" label="Printed" />
        <DateField source="printedDate" label="Printed Date" />
        <TextField source="printedBy" label="Printed By" />
        <CustomBooleanField source="isVerified" label="Verified" />
        <MobileTestStatusField source="mobileTestStatus" label="Status" />
        <WrapperField
          label="Mobile Punch"
          source="notProcessedPunchCount"
          sortable={false}
        >
          <MobilePunchField />
        </WrapperField>
        <TextField source="reviewComments" label="Review Comments" />
        {renderMetaDataFields()}
        <ColumnActions
          label="Actions"
          showActions={{ show: false, edit: false, delete: false }}
        >
          <DownloadButton />
        </ColumnActions>
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default SmartTagITRReviewList;
