import React, { ReactElement } from 'react';
import { SelectInput } from 'react-admin';
import { sectionTypeChoices } from '../constants';
import commonStyles from '../../common/commonStyles';

export const SectionTypeInput: React.FC = (): ReactElement => {
  return (
    <SelectInput
      source="sectionType"
      label="Section Type"
      choices={sectionTypeChoices}
      sx={commonStyles.flexBoxItem}
    />
  );
};

export default SectionTypeInput;
