import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import ColumnActions from '../common/ColumnActions';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import ListWithTitle from '../common/ListWithTitle';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import ListActions from '../common/ListActions';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import PunchCategoryField from '../common/PunchCategoryField';
import PreviewButton from '../common/PreviewButton';
import DownloadButton from '../common/DownloadButton';
import CopyTemplatesButton from '../common/CopyTemplatesButton';
import { templateType } from '../common/constants';
import { useDictionaryLabelsWithResource } from '../../utils/UtilityFunctions';
import PunchTemplateShow from './PunchTemplateShow';
import PunchTemplateEdit from './PunchTemplateEdit';

const PunchTemplateList: React.FC = (): ReactElement => {
  const defaultFilter = useDefaultFilter('division');
  const { labels } = useDictionaryLabelsWithResource();

  return (
    <ListWithTitle
      filterDefaultValues={defaultFilter}
      filters={<FreeTextSearchFilter />}
      actions={
        <ListActions
          showAction={{ create: false, import: false }}
          actions={<CopyTemplatesButton templateType={templateType.punch} />}
        />
      }
      sort={{ field: 'templateName', order: 'ASC' }}
    >
      <DatagridConfigurableRbac
        ShowComponent={<PunchTemplateShow withoutActions />}
        EditComponent={<PunchTemplateEdit syncWithLocation={false} />}
        defaultVisibleColumns={[
          'id',
          'templateName',
          'description',
          'punchTemplateCategory.punchCategoryId',
        ]}
      >
        <TextField source="id" label="ID" />
        <TextField source="templateName" label="Template Name" />
        <TextField source="description" label="Description" />
        <PunchCategoryField
          source="punchTemplateCategory.punchCategoryId"
          label={labels['punchCatId']}
          textFieldSource="punchTemplateCategory.punchCat.category"
          sortBy={'`punchTemplateCategory.punchCat.category`'}
        />
        <TextField source="externalFormId" label="External Form ID" />
        {renderMetaDataFields()}
        <ColumnActions label="Actions">
          <PreviewButton />
          <DownloadButton />
        </ColumnActions>
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default PunchTemplateList;
