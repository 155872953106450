import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import { ImportResult, ImportResultStatusToText } from '../types';
import commonStyles from '../../commonStyles';
import ImportResultsErrors from './ImportResultsErrors';
import ImportResultsRow from './ImportResultsRow';
import DownloadErrorFileLink from './DownloadErrorFileLink';

interface ImportResultsProps {
  importResult?: ImportResult;
}

const ImportResults: React.FC<ImportResultsProps> = ({
  importResult,
}): ReactElement => {
  if (!importResult) {
    return null;
  }

  return (
    <Box sx={commonStyles.columnFlexBox}>
      <ImportResultsRow
        label="Import Result:"
        value={ImportResultStatusToText[importResult.status]}
      />
      <ImportResultsRow
        label="Unchanged Records:"
        value={importResult.unchangedRecords}
      />
      <ImportResultsRow
        label="Updated Records:"
        value={importResult.updatedRecords}
      />
      <ImportResultsRow
        label="Added Records:"
        value={importResult.insertedRecords}
      />
      <ImportResultsRow
        label="Deleted Records:"
        value={importResult.deletedRecords}
      />
      <br />
      <DownloadErrorFileLink importResult={importResult} />
      <ImportResultsErrors importValidation={importResult.validations} />
    </Box>
  );
};

export default ImportResults;
