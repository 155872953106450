import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import { CreateDialog } from '@react-admin/ra-form-layout';
import React, {
  Fragment,
  ReactElement,
  useCallback,
  useRef,
  useState,
} from 'react';
import {
  FileField,
  SimpleForm,
  useNotify,
  useResourceContext,
} from 'react-admin';
import { getContext, UserContext } from '../../provider/userContext';
import { getErrorMessage, getTimeZone } from '../../utils/UtilityFunctions';
import { DEFAULT_MULTIPLE_IMPORT_BUTTONS } from './constants';
import CustomFileInput from './CustomFileInput';
import useImport from './hooks/useImport';
import useResourceTitle from './hooks/useResourceTitle';
import ImportToolbar from './ImportButton/components/ImportToolbar';
import { MultipleImportButtonInfo } from './types';

interface MultipleImportButtonProps {
  buttonsInfo?: MultipleImportButtonInfo[];
}

export const MultipleImportButton: React.FC<MultipleImportButtonProps> = ({
  buttonsInfo = DEFAULT_MULTIPLE_IMPORT_BUTTONS,
}): ReactElement => {
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const menuAnchorRef = useRef<HTMLDivElement>(null);

  const context: UserContext = getContext();
  const title = useResourceTitle(true);
  const { importFile, importInProgress } = useImport();
  const resourceFromContext = useResourceContext();
  const notify = useNotify();

  const openModal = useCallback(() => setModalOpen(true), []);
  const closeModal = useCallback(() => setModalOpen(false), []);

  const handleMenuToggle = useCallback(
    () => setMenuOpen((prevOpen) => !prevOpen),
    []
  );

  const handleMenuClose = useCallback((event: Event) => {
    if (
      menuAnchorRef.current &&
      menuAnchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setMenuOpen(false);
  }, []);

  const handleMenuItemClick = useCallback((index: number) => {
    setSelectedMenuIndex(index);
    setMenuOpen(false);
  }, []);

  const onSubmit = useCallback(
    async (data) => {
      const source = buttonsInfo[selectedMenuIndex].source;
      const resource = source ? source : resourceFromContext;
      try {
        await importFile(resource, {
          projectId: context.projectId,
          divisionId: context.defaultDivisionId,
          timeZone: getTimeZone(),
          file: data.file.rawFile,
        });

        notify(
          'The import file has been uploaded. You will receive a notification once the import process is complete.',
          {
            type: 'success',
            undoable: false,
          }
        );

        closeModal();
      } catch (error) {
        notify('Import error: ' + getErrorMessage(error), {
          type: 'error',
          undoable: false,
        });
      }
    },
    [
      buttonsInfo,
      selectedMenuIndex,
      resourceFromContext,
      importFile,
      context.projectId,
      context.defaultDivisionId,
      notify,
      closeModal,
    ]
  );

  return (
    <Fragment>
      <ButtonGroup variant="outlined" ref={menuAnchorRef}>
        <Button
          disabled={importInProgress}
          onClick={openModal}
          style={{ borderWidth: 2 }}
        >
          {importInProgress
            ? 'Importing...'
            : buttonsInfo[selectedMenuIndex].label}
        </Button>
        <Button
          size="small"
          onClick={handleMenuToggle}
          style={{ borderWidth: 2, marginLeft: -2 }}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{ zIndex: 1000 }}
        open={menuOpen}
        anchorEl={menuAnchorRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleMenuClose}>
                <MenuList autoFocusItem>
                  {buttonsInfo.map((option, index) => (
                    <MenuItem
                      key={option.label}
                      selected={index === selectedMenuIndex}
                      onClick={() => handleMenuItemClick(index)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <CreateDialog
        title={<span>Import {title}</span>}
        fullWidth
        maxWidth="md"
        isOpen={modalOpen}
        open={openModal}
        close={closeModal}
      >
        <SimpleForm
          title=""
          toolbar={<ImportToolbar onClose={closeModal} />}
          onSubmit={onSubmit}
        >
          <CustomFileInput source="import" name="file" label={false}>
            <FileField source="src" title="title" target="_blank" />
          </CustomFileInput>
        </SimpleForm>
      </CreateDialog>
    </Fragment>
  );
};

export default MultipleImportButton;
