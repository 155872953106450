import { ReactElement } from 'react';
import { NumberField, ReferenceManyField, TextField } from 'react-admin';
import { EditableDatagrid } from '@react-admin/ra-editable-datagrid';
import { Box } from '@mui/material';
import { ListComponentTitle } from '../components/ListComponentTitle';
import { MAX_RECORD_PER_PAGE } from '../../../provider/constants';
import { RESOURCE_BOOK_SECTION_CERT } from '../../constants';
import commonStyles from '../../common/commonStyles';
import { EditableDatagridCreateButton } from '../components/EditableDatagridCreateButton';
import { EditForm } from './EditForm';
import { CreateForm } from './CreateForm';

export const BookSectionCertListEdit: React.FC = (): ReactElement => {
  return (
    <Box sx={commonStyles.editableDataGrid}>
      <ListComponentTitle label="Certificates" />
      <ReferenceManyField
        reference={RESOURCE_BOOK_SECTION_CERT}
        target="bookSectionId"
        perPage={MAX_RECORD_PER_PAGE}
        sort={{ field: 'sortOrder', order: 'ASC' }}
      >
        <EditableDatagrid
          disableAutofocus
          bulkActionButtons={false}
          editForm={<EditForm />}
          createForm={<CreateForm />}
          empty={
            <EditableDatagridCreateButton label="Create Book Section Cert" />
          }
        >
          <TextField source="certName" label="Certificate" sortable={false} />
          <NumberField source="sortOrder" sortable={false} />
        </EditableDatagrid>
      </ReferenceManyField>
    </Box>
  );
};
