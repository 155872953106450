import Box from '@mui/material/Box';
import React, { ReactElement } from 'react';
import {
  DateField,
  Labeled,
  TabbedShowLayout,
  TextField,
  UpdateButton,
} from 'react-admin';
import commonStyles from '../common/commonStyles';
import ComponentField from '../common/ComponentField';
import DisciplineField from '../common/DisciplineField';
import renderShowMetaDataFields from '../common/helpers/renderShowMetaDataFields';
import LocationField from '../common/LocationField';
import PunchCategoryField from '../common/PunchCategoryField';
import PunchPriorityField from '../common/PunchPriorityField';
import StatusField from '../common/SelectField';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import SubsystemField from '../common/SubsystemField';
import ImageGallery from '../imageGallery/ImageGallery';
import { statuses } from '../pendingPunch/constants';
import AuditListTab from '../audit/AuditListTab';
import useCanAccess from '../common/hooks/useCanAccess';
import { useDictionaryLabelsWithResource } from '../../utils/UtilityFunctions';
import { originators } from './constants';

const SubmitPunchShow: React.FC<ShowComponentProps> = ({
  showActionsProps,
  syncWithLocation,
  ...rest
}): ReactElement => {
  const canAccess = useCanAccess();
  const { labels } = useDictionaryLabelsWithResource();

  return (
    <ShowComponent
      {...rest}
      showActionsProps={{
        ...showActionsProps,
        Component: canAccess.edit && (
          <UpdateButton label="Submit" data={{ status: 'SUBMITTED' }} />
        ),
      }}
    >
      <TabbedShowLayout
        sx={commonStyles.bottomMargin}
        syncWithLocation={syncWithLocation}
      >
        <TabbedShowLayout.Tab
          label="Submit Punch"
          id="submit_punch_tab"
          sx={commonStyles.formTabHeader}
        >
          <Box sx={commonStyles.columnFlexBox}>
            <Box>
              <Labeled label="Description" sx={commonStyles.flexBoxItem}>
                <TextField source="description" fullWidth />
              </Labeled>
              <Labeled label="SybSystem" sx={commonStyles.flexBoxItem}>
                <SubsystemField />
              </Labeled>
            </Box>
            <Box>
              <Labeled label="Discipline" sx={commonStyles.flexBoxItem}>
                <DisciplineField />
              </Labeled>
              <Labeled label="Priority" sx={commonStyles.flexBoxItem}>
                <PunchPriorityField />
              </Labeled>
            </Box>
            <Box>
              <Labeled label="Location" sx={commonStyles.flexBoxItem}>
                <LocationField source="locationId" />
              </Labeled>
              <Labeled
                label={labels['punchCatId']}
                sx={commonStyles.flexBoxItem}
              >
                <PunchCategoryField source="punchCatId" />
              </Labeled>
            </Box>
            <Box>
              <Labeled sx={commonStyles.flexBoxItem} label="Tag">
                <ComponentField
                  source="componentId"
                  textSource="component.tag"
                  sortBy="`component.tag`"
                />
              </Labeled>
              <Labeled label="Originator">
                <StatusField
                  source="originator"
                  sx={commonStyles.flexBoxItem}
                  choices={originators}
                />
              </Labeled>
              <Labeled label="Status">
                <StatusField
                  source="status"
                  sx={commonStyles.flexBoxItem}
                  choices={statuses}
                />
              </Labeled>
            </Box>
            <Labeled label="Review Comments">
              <TextField source="comments" />
            </Labeled>
            <Box>
              <Labeled label="Raised By" sx={commonStyles.flexBoxLongItem}>
                <TextField source="raisedBy" />
              </Labeled>
              <Labeled label="Raised Date" sx={commonStyles.flexBoxItem}>
                <DateField source="raisedDate" />
              </Labeled>
            </Box>
            <Box>{renderShowMetaDataFields()}</Box>
          </Box>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab
          label="Image Gallery"
          id="gallery_tab"
          sx={commonStyles.formTabHeader}
        >
          <ImageGallery targetId="pendingPunchId" onlyShow />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="History">
          <AuditListTab tableName="tbl_pending_punch" />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </ShowComponent>
  );
};

export default SubmitPunchShow;
