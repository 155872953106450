import { Labeled, TabbedShowLayout, TextField } from 'react-admin';
import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import commonStyles from '../common/commonStyles';
import renderShowMetaDataFields from '../common/helpers/renderShowMetaDataFields';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import AuditListTab from '../audit/AuditListTab';

const ActivityTypeShow: React.FC<ShowComponentProps> = ({
  syncWithLocation,
  ...rest
}): ReactElement => (
  <ShowComponent {...rest}>
    <TabbedShowLayout
      sx={commonStyles.bottomMargin}
      syncWithLocation={syncWithLocation}
    >
      <TabbedShowLayout.Tab label="Type">
        <Box>
          <Labeled label="Activity Type" sx={commonStyles.flexBoxItem}>
            <TextField source="activityType" />
          </Labeled>
        </Box>
        <Box>{renderShowMetaDataFields()}</Box>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="History">
        <AuditListTab tableName="tbl_activity_type" />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </ShowComponent>
);

export default ActivityTypeShow;
