import React, { ReactElement } from 'react';
import { maxLength } from 'react-admin';
import Box from '@mui/material/Box';
import HistoryTab from '../common/HistoryTab';
import LazyFormTab from '../common/LazyFormTab';
import MetaDataInputs from '../common/MetaDataInputs';
import TabbedEditForm from '../common/TabbedEditForm';
import commonStyles from '../common/commonStyles';
import { EditPropsWithLocation } from '../common/types';
import TextInput from '../common/TextInput';
import { required } from '../../utils/UtilityFunctions';
import ResourceInput from './ResourceInput';
import FieldInput from './FieldInput';
import { Dictionary } from './types';

const DictionaryEdit: React.FC<EditPropsWithLocation<Dictionary>> = (
  props
): ReactElement => (
  <TabbedEditForm {...props}>
    <LazyFormTab
      label="Dictionary"
      id="dictionary_tab"
      sx={commonStyles.formTabHeader}
    >
      <Box sx={commonStyles.flexBox}>
        <ResourceInput
          validate={[required(), maxLength(75)]}
          sx={commonStyles.flexBoxItem}
        />
        <FieldInput
          validate={[required(), maxLength(75)]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="label"
          label="Label"
          validate={[required(), maxLength(75)]}
          sx={commonStyles.flexBoxItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="helperText"
          label="Helper Text"
          validate={[maxLength(255)]}
          sx={commonStyles.flexBoxLongItem}
          fullWidth
          multiline
        />
      </Box>
      <MetaDataInputs />
    </LazyFormTab>
    <HistoryTab tableName="tbl_dictionary" />
  </TabbedEditForm>
);

export default DictionaryEdit;
