import { useCallback, useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { useQueryClient } from '@tanstack/react-query';
import { RESOURCE_BOOK_SECTION_TEST } from '../../constants';
import { BookSectionTestCreate } from '../type';
import { useGetMaxSortOrder } from '../useGetMaxSortOrder';
import { CustomDataProvider } from '../../../provider/dataProvider/types';

export const useBulkUploadTests = () => {
  const [isLoading, setIsLoading] = useState(false);
  const notify = useNotify();
  const queryClient = useQueryClient();
  const dataProvider = useDataProvider<CustomDataProvider>();
  const maxSortOrder = useGetMaxSortOrder(RESOURCE_BOOK_SECTION_TEST);

  const create = useCallback(
    async (tests: BookSectionTestCreate[]) => {
      setIsLoading(true);
      try {
        await dataProvider.createMany<BookSectionTestCreate>(
          RESOURCE_BOOK_SECTION_TEST,
          {
            data: tests.map((test, i) => ({
              ...test,
              sortOrder: maxSortOrder + i + 1,
            })),
          }
        );
        notify('Tests were successfully added!', {
          type: 'success',
          undoable: false,
        });
        queryClient.invalidateQueries({
          queryKey: [RESOURCE_BOOK_SECTION_TEST],
        });
      } catch (error) {
        notify(
          'Some tests are duplicated, please select tests that are not added yet',
          {
            type: 'error',
            undoable: false,
          }
        );
      } finally {
        setIsLoading(false);
      }
    },
    [dataProvider, maxSortOrder, notify, queryClient]
  );

  return { create, isLoading };
};
