import { SxProps } from '@mui/material';
import { GREEN, GREY, RED, WHITE, YELLOW } from '../../theme/colors';
import { materialReleasedStatusLabels } from './materialReleased/constants';
import {
  MaterialReleased,
  MaterialReleasedStatus,
} from './materialReleased/types';

export const getListRowStyle =
  (checkStatusFn) =>
  (record: MaterialReleased): SxProps => ({
    borderLeftColor: checkStatusFn(record),
    borderLeftWidth: 8,
    borderLeftStyle: 'solid',
  });

export const getMaterialListRowBorderColor = ({
  totalQty,
  orderPoint,
}): string => {
  switch (true) {
    case totalQty === orderPoint:
      return YELLOW;
    case totalQty < orderPoint:
      return RED;
    default:
      return 'transparent';
  }
};

export const statusesLegend = [
  {
    label: 'the "Total Qty on Hand" is equal to the "Order Point"',
    color: YELLOW,
  },
  {
    label: 'the "Total Qty on Hand" is less than the "Order Point"',
    color: RED,
  },
];

export const getMaterialReleasedListRowBorderColor = ({ status }): string => {
  switch (status) {
    case MaterialReleasedStatus.GENERATED:
      return WHITE;
    case MaterialReleasedStatus.RELEASED:
      return GREEN;
    case MaterialReleasedStatus.CANCELLED:
      return GREY;
    default:
      return 'transparent';
  }
};

export const getStatusValue = (record: MaterialReleased): string =>
  !!record.status ? materialReleasedStatusLabels[record.status] : '';
