import Box from '@mui/material/Box';
import React, { ReactElement } from 'react';
import LazyFormTab from '../common/LazyFormTab';
import TabbedEditForm from '../common/TabbedEditForm';
import commonStyles from '../common/commonStyles';
import MetaDataInputs from '../common/MetaDataInputs';
import { EditPropsWithLocation } from '../common/types';
import HistoryTab from '../common/HistoryTab';
import TextInput from '../common/TextInput';
import { required } from '../../utils/UtilityFunctions';
import { Book } from './type';

const BookEdit: React.FC<EditPropsWithLocation<Book>> = (
  props
): ReactElement => {
  return (
    <TabbedEditForm {...props}>
      <LazyFormTab label="Book" id="book_tab" sx={commonStyles.formTabHeader}>
        <Box sx={commonStyles.flexBox}>
          <TextInput
            source="title"
            label="Title"
            validate={[required()]}
            sx={commonStyles.flexBoxItem}
          />
          <TextInput
            fullWidth
            multiline
            source="description"
            label="Description"
            sx={commonStyles.flexBoxLongItem}
          />
        </Box>
        <MetaDataInputs />
      </LazyFormTab>
      <HistoryTab tableName="tbl_book" />
    </TabbedEditForm>
  );
};

export default BookEdit;
