import { AxiosError } from 'axios';
import React, { Fragment, useCallback, useState } from 'react';
import {
  Button,
  useDataProvider,
  useRecordContext,
  Confirm,
  useNotify,
} from 'react-admin';
import { useMutation } from '@tanstack/react-query';
import { Box } from '@mui/material';
import { getErrorMessage } from '../../utils/UtilityFunctions';
import useInvalidateResourceQueryCache from '../common/hooks/useInvalidateResourceQueryCache';
import {
  RESOURCE_PRESERVATION_ITEM_ACTIVITY,
  RESOURCE_PRESERVATION_SERIES,
} from '../constants';
import { TextFieldPropsWithOptionalSource } from '../common/types';

const IssueButton: React.FC<TextFieldPropsWithOptionalSource> = () => {
  const dataProvider = useDataProvider();
  const record = useRecordContext();
  const notify = useNotify();
  const preservationSeriesInvalidateQueries = useInvalidateResourceQueryCache(
    RESOURCE_PRESERVATION_SERIES,
    true
  );
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const { mutate, isPending } = useMutation({
    mutationFn: () =>
      dataProvider.createPreservationItemActivity(
        RESOURCE_PRESERVATION_ITEM_ACTIVITY,
        {
          dueDate: record?.dueDate,
          lateDate: record?.lateDate,
          preservationItemId: record?.preservationItemId,
          preservationSeriesId: record?.id,
          projectId: record?.projectId,
        }
      ),
    onSuccess: async () => {
      notify('Preservation Item Activity created', {
        type: 'success',
      });
      await preservationSeriesInvalidateQueries();
    },
    onError: async (error: AxiosError) => {
      notify(getErrorMessage(error), {
        type: 'error',
      });
    },
  });

  const handleDialogOpen = useCallback(() => {
    setIsOpenDialog(true);
  }, []);

  const handleDialogClose = useCallback(() => {
    setIsOpenDialog(false);
  }, []);

  const handleConfirmIssue = useCallback(() => {
    mutate();
    setIsOpenDialog(false);
  }, [mutate]);

  return (
    // Box with minimum width is used to display empty space if the "Issue" button is not displayed
    <Box style={{ minWidth: '44px' }}>
      {!record?.isIssued && (
        <Fragment>
          <Button
            onClick={handleDialogOpen}
            disabled={isPending}
            label="Issue"
          />
          <Confirm
            isOpen={isOpenDialog}
            title="Confirm Activity Release"
            content="Are you sure to release the activity?"
            onConfirm={handleConfirmIssue}
            onClose={handleDialogClose}
          />
        </Fragment>
      )}
    </Box>
  );
};

export default IssueButton;
