import React, {
  Fragment,
  MutableRefObject,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { List, ListSubheader, Typography } from '@mui/material';
import {
  FileField,
  SimpleForm,
  useNotify,
  Identifier,
  useStore,
} from 'react-admin';
import Box from '@mui/material/Box';
import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment/moment';
import { QrUploadData } from '../../../../provider/dataProvider/providers/qrScannerDataProvider';
import { getErrorMessage, required } from '../../../../utils/UtilityFunctions';
import { UserContext, getContext } from '../../../../provider/userContext';
import {
  ACCEPTED_FORMATS_PDF_ALL,
  FILE_PART_SIZE,
  QR_FILE_SIZE_LIMIT,
} from '../../../common/constants';
import getUISettings from '../../../common/helpers/getUISettings';
import { useTimeout } from '../../../common/hooks/useTimeout';
import { RESOURCE_QR_SCANNER, RESOURCE_USER } from '../../../constants';
import commonStyles from '../../../common/commonStyles';
import {
  FILE_UPLOAD_STATUSES,
  SCAN_STATUS_CHECKING_DELAY,
} from '../../constants';
import CircularProgress from '../../../common/CircularProgress';
import { ScanFileProgressListItem } from '../../types';
import CustomFileInput from '../../../common/CustomFileInput';
import useDataProvider from '../../../../provider/dataProvider/useDataProvider';
import Actions from './Actions';
import ScanFileProgress from './ScanFileProgress';

interface QrUploadFormProps {
  abortController: MutableRefObject<AbortController>;
}

const UTC_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const QrUploadForm: React.FC<QrUploadFormProps> = ({
  abortController,
}): ReactElement => {
  const ctx: UserContext = getContext();
  const [statusCheckingDelay, setStatusCheckingDelay] = useState(null);
  const [scanFileProgressList, setScanFileProgressList] = useState<
    ScanFileProgressListItem[]
  >([]);
  const notify = useNotify();
  const [lastClearTime, setLastClearTime] = useStore<string>(
    `${RESOURCE_QR_SCANNER}.lastClearTime`,
    moment().utc().format(UTC_TIME_FORMAT)
  );
  const dataProvider = useDataProvider();

  const updateUploadStatus = (
    data: QrUploadData,
    partsUploadStatus: string,
    uploadId?: number
  ) => {
    if (
      partsUploadStatus === FILE_UPLOAD_STATUSES.UPLOADING &&
      data.partIndex === 0
    ) {
      setScanFileProgressList((prevValue) => [
        ...prevValue,
        {
          fileName: data.fileName,
          numOfParts: data.numOfParts,
          partIndex: data.partIndex,
          partsUploadStatus,
        },
      ]);
    } else {
      setScanFileProgressList((prevValue) => {
        const newValue = prevValue.slice(0);
        const lastItem = newValue.pop();
        lastItem.partsUploadStatus = partsUploadStatus;
        lastItem.partIndex = data.partIndex;
        lastItem.uploadId = data?.uploadId || uploadId;

        return [...newValue, lastItem];
      });
    }
  };

  const updateProcessingStatus = (processingData) => {
    setScanFileProgressList((prevScanFilesList) => {
      const newScanFilesList = prevScanFilesList.slice(0);
      processingData.forEach((data) => {
        const scanFileIndex = newScanFilesList.findIndex(
          (scanFile) => scanFile.uploadId === data.id
        );
        if (scanFileIndex > -1) {
          newScanFilesList[scanFileIndex].processingDetails = {
            processingStatus: data.status,
            s3FileName: data.details.s3FileName,
            errorMessages: data.details.errorMessages,
          };
        }
      });

      return newScanFilesList;
    });
  };

  const initializeFileProgressList = useCallback(
    (data) => {
      const fileProgressArray = data
        .filter((item) => {
          return moment(lastClearTime, UTC_TIME_FORMAT).isBefore(
            item.updatedAt
          );
        })
        .map((item) => ({
          uploadId: item.id,
          fileName: item.details.s3FileName,
          processingDetails: {
            processingStatus: item.status,
            s3FileName: item.details.s3FileName,
            errorMessages: item.details.errorMessages,
          },
        }));
      setScanFileProgressList(fileProgressArray);
    },
    [lastClearTime]
  );

  const { mutateAsync: uploadFiles, isPending: isFilesLoading } = useMutation({
    mutationFn: async (dataToUpload: QrUploadData) => {
      updateUploadStatus(dataToUpload, FILE_UPLOAD_STATUSES.UPLOADING);
      const response = await dataProvider.uploadQrPdfFiles(
        RESOURCE_QR_SCANNER,
        { qrData: dataToUpload, signal: abortController.current.signal }
      );

      return { response, dataToUpload };
    },
    onSuccess: ({ dataToUpload, response }) => {
      updateUploadStatus(
        dataToUpload,
        FILE_UPLOAD_STATUSES.SUCCESS,
        response?.data?.uploadId
      );
      if (dataToUpload.numOfParts === dataToUpload.partIndex + 1) {
        setStatusCheckingDelay(SCAN_STATUS_CHECKING_DELAY);
        notify(`${dataToUpload.fileName} scan has started`, {
          type: 'success',
        });
      }
    },
    onError: (error, dataToUpload) => {
      const errorMessage = getErrorMessage(error);
      updateUploadStatus(
        dataToUpload,
        `${FILE_UPLOAD_STATUSES.ERROR}: ${errorMessage}`
      );
      notify(errorMessage, {
        type: 'error',
        undoable: false,
      });
    },
  });

  const { mutate: getStatusByIds } = useMutation({
    mutationFn: (jobIds: Identifier[]) => {
      return dataProvider.getFilesScanStatusByIds(RESOURCE_QR_SCANNER, jobIds);
    },
    onSuccess: ({ data }) => {
      updateProcessingStatus(data);
    },
    onError: (error) => {
      notify(getErrorMessage(error), {
        type: 'error',
        undoable: false,
      });
    },
  });

  const {
    isFetching: isStatusChecking,
    data,
    isSuccess,
    isError,
    error,
  } = useQuery({
    queryKey: [],
    queryFn: () => {
      return dataProvider.getFilesScanStatus(RESOURCE_QR_SCANNER);
    },
    refetchOnMount: 'always',
  });

  useEffect(() => {
    if (isSuccess) {
      initializeFileProgressList(data.data);
      setStatusCheckingDelay(SCAN_STATUS_CHECKING_DELAY);
    }
    if (isError && error) {
      notify(getErrorMessage(error), {
        type: 'error',
        undoable: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, isError, isSuccess, notify]);

  const stopStatusChecking = useTimeout(async () => {
    const jobIds = scanFileProgressList.reduce((ids, scanFile) => {
      if (
        (scanFile.numOfParts === scanFile.partIndex + 1 &&
          scanFile.partsUploadStatus === FILE_UPLOAD_STATUSES.SUCCESS &&
          scanFile.uploadId) ||
        (!scanFile.numOfParts &&
          !scanFile.partsUploadStatus &&
          scanFile.uploadId)
      ) {
        ids.push(scanFile.uploadId);
      }

      return ids;
    }, []);

    if (jobIds?.length > 0) {
      await getStatusByIds(jobIds);
    }
  }, statusCheckingDelay);

  useEffect(() => {
    if (scanFileProgressList.length > 0) {
      const completedScans = scanFileProgressList.filter(
        (item) =>
          item?.processingDetails?.processingStatus === 'FINISHED_FAILED' ||
          item?.processingDetails?.processingStatus === 'FINISHED_SUCCESS'
      );
      if (completedScans.length === scanFileProgressList.length) {
        stopStatusChecking();
      }
    }
  }, [scanFileProgressList, stopStatusChecking]);

  const isAnyProcess = useMemo(
    () =>
      scanFileProgressList.findIndex(
        (scanFileProgress) =>
          scanFileProgress?.partsUploadStatus === FILE_UPLOAD_STATUSES.UPLOADING
      ) >= 0,
    [scanFileProgressList]
  );

  async function* filePartIndexGenerator(numOfParts) {
    let i = 0;
    while (i < numOfParts) {
      yield i++;
    }
  }

  const handleSubmit = async ({ files }) => {
    if (Array.isArray(files)) {
      for await (const file of files) {
        let response;
        let prevFilePartUploaded = true;
        const numOfParts = Math.ceil(file.rawFile.size / FILE_PART_SIZE);
        for await (const partIndex of filePartIndexGenerator(numOfParts)) {
          const uploadId =
            partIndex > 0 ? response?.response?.data?.uploadId : undefined;
          const startIndex = partIndex * FILE_PART_SIZE;
          const endIndex =
            startIndex +
            Math.min(
              file.rawFile.size - partIndex * FILE_PART_SIZE,
              FILE_PART_SIZE
            );
          if (prevFilePartUploaded) {
            response = await uploadFiles({
              file: file.rawFile.slice(startIndex, endIndex),
              fileName: file.title,
              projectId: String(ctx.projectId),
              numOfParts,
              partIndex,
              uploadId,
            });
          }
          prevFilePartUploaded =
            response?.response?.data?.uploadId &&
            response?.response?.request?.status === 200;
        }
      }
    }
  };

  const handleClear = useCallback(async () => {
    const lastClearTime = moment().utc().format(UTC_TIME_FORMAT);
    setStatusCheckingDelay(null);
    setScanFileProgressList([]);
    setLastClearTime(lastClearTime);

    try {
      const uiSettings = getUISettings();
      await dataProvider.update(RESOURCE_USER, {
        id: ctx.id,
        previousData: {},
        data: {
          uiSettings: JSON.stringify({
            ...uiSettings,
            [`RaStore.${RESOURCE_QR_SCANNER}.lastClearTime`]: lastClearTime,
          }),
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(getErrorMessage(error, 'Upload Datagrid settings error'));
    }
  }, [ctx.id, dataProvider, setLastClearTime]);

  return (
    <SimpleForm
      id="qr_upload_form"
      toolbar={
        <Actions
          filesInProgress={scanFileProgressList.length}
          handleClear={handleClear}
          isLoading={isAnyProcess || isFilesLoading || isStatusChecking}
        />
      }
      onSubmit={handleSubmit}
    >
      {isStatusChecking ? (
        <CircularProgress />
      ) : scanFileProgressList.length > 0 ? (
        <List
          sx={commonStyles.fullWidth}
          subheader={<ListSubheader>Status</ListSubheader>}
        >
          {scanFileProgressList.map((scanFileProgress, index) => (
            <ScanFileProgress key={index} scanFileProgress={scanFileProgress} />
          ))}
        </List>
      ) : (
        <Fragment>
          <Typography>To increase the processing speed</Typography>
          <Typography>
            The optimum scan resolution is 300 dpi and black/white when
            possible.
          </Typography>
          <Typography sx={{ mb: 2 }}>
            Alpha-numeric ordering to match how it will show up in the grid
            after imported
          </Typography>
          <CustomFileInput
            source="files"
            accept={ACCEPTED_FORMATS_PDF_ALL}
            label={false}
            labelMultiple={
              'Drag and drop PDF files here, or click to select files'
            }
            multiple={true}
            maxSize={QR_FILE_SIZE_LIMIT}
            sx={commonStyles.uploadField}
            validate={[required()]}
          >
            <Fragment>
              <FileField source="src" title="title" target="_blank" />
              <Box sx={commonStyles.uploadFieldBox}></Box>
            </Fragment>
          </CustomFileInput>
        </Fragment>
      )}
    </SimpleForm>
  );
};

export default QrUploadForm;
