import React, { ReactElement } from 'react';
import { maxLength, SimpleForm } from 'react-admin';
import Box from '@mui/material/Box';
import commonStyles from '../common/commonStyles';
import FooterToolbar from '../common/FooterToolbar';
import { getContext, UserContext } from '../../provider/userContext';
import CreateWithTitle from '../common/CreateWithTitle';
import TextInput from '../common/TextInput';
import { required } from '../../utils/UtilityFunctions';
import { ActivityPhase } from './types';

const ActivityPhaseCreate: React.FC = (): ReactElement => {
  const ctx: UserContext = getContext();

  const transform = (data): ActivityPhase => ({
    ...data,
    projectId: ctx?.projectId,
  });

  return (
    <CreateWithTitle redirect="list" transform={transform}>
      <SimpleForm toolbar={<FooterToolbar />}>
        <Box sx={commonStyles.createBoxHalfWidth}>
          <TextInput
            source="phase"
            label="Phase"
            validate={[required(), maxLength(30)]}
            sx={commonStyles.flexBoxItem}
          />
          <TextInput
            source="sortOrder"
            label="Sort Order"
            validate={[maxLength(35)]}
            sx={commonStyles.flexBoxItem}
          />
        </Box>
      </SimpleForm>
    </CreateWithTitle>
  );
};

export default ActivityPhaseCreate;
