import React, { ReactElement } from 'react';
import { useWatch } from 'react-hook-form';
import { useRecordContext } from 'react-admin';
import MetaDataInputs from '../../common/MetaDataInputs';
import LazyFormTab from '../../common/LazyFormTab';
import commonStyles from '../../common/commonStyles';
import InstrumentationForm from './InstrumentationForm';

const InstrumentationFormTab: React.FC = (props): ReactElement => {
  const record = useRecordContext();
  const hasInstrumentation = useWatch({ name: 'hasInstrumentation' });

  return (
    <LazyFormTab
      {...props}
      label="Instrumentation"
      id="instrumentation_tab"
      sx={
        hasInstrumentation
          ? commonStyles.formTabHeader
          : commonStyles.displayNone
      }
    >
      <InstrumentationForm />
      {record && <MetaDataInputs source="instrumentation" />}
    </LazyFormTab>
  );
};

export default InstrumentationFormTab;
