import React, { ReactElement } from 'react';
import { SimpleForm } from 'react-admin';
import FooterToolbar from '../../common/FooterToolbar';
import { EditPropsWithLocation } from '../../common/types';
import { getContext, UserContext } from '../../../provider/userContext';
import CreateWithTitle from '../../common/CreateWithTitle';
import { MaterialType } from './types';
import MaterialForm from './MaterialForm';

const MaterialCreate: React.FC<
  EditPropsWithLocation<MaterialType>
> = (): ReactElement => {
  const ctx: UserContext = getContext();
  const transform = (data: MaterialType): MaterialType => ({
    ...data,
    projectId: ctx?.projectId,
    totalQty: 0,
  });

  return (
    <CreateWithTitle redirect="list" transform={transform}>
      <SimpleForm toolbar={<FooterToolbar />}>
        <MaterialForm />
      </SimpleForm>
    </CreateWithTitle>
  );
};

export default MaterialCreate;
