import React, { ReactElement } from 'react';
import { TextField, Button } from 'react-admin';
import SyncIcon from '@mui/icons-material/Sync';
import { updateDictionaryContext } from '../../utils/UtilityFunctions';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import ColumnActions from '../common/ColumnActions';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import ListActions from '../common/ListActions';
import ListWithTitle from '../common/ListWithTitle';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import DictionaryEdit from './DictionaryEdit';
import DictionaryShow from './DictionaryShow';
import DictionaryFilters from './DictionaryFilters';

const DictionaryList: React.FC = (): ReactElement => {
  const defaultFilter = useDefaultFilter('division');

  const handleButtonClick = async () => await updateDictionaryContext();
  const updateLabelsButton = (
    <Button
      startIcon={<SyncIcon />}
      label="Update My Labels"
      onClick={handleButtonClick}
    />
  );

  return (
    <ListWithTitle
      filters={<DictionaryFilters />}
      filterDefaultValues={defaultFilter}
      actions={<ListActions actions={updateLabelsButton} />}
      sort={{ field: 'resourceName', order: 'ASC' }}
    >
      <DatagridConfigurableRbac
        ShowComponent={<DictionaryShow withoutActions />}
        EditComponent={<DictionaryEdit syncWithLocation={false} />}
      >
        <TextField source="id" label="ID" />
        <TextField source="resourceName" label="Resource" />
        <TextField source="field" label="Field" />
        <TextField source="label" label="Label" />
        <TextField source="helperText" label="Helper Text" />
        {renderMetaDataFields()}
        <ColumnActions label="Actions" />
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default DictionaryList;
