import { RowForm, useRowContext } from '@react-admin/ra-editable-datagrid';
import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { Identifier, maxLength, useRecordContext, minValue } from 'react-admin';
import CustomNumberInput from '../../../common/CustomNumberInput';
import { integer } from '../../../../utils/UtilityFunctions';
import { MaterialType } from '../../materialPage/types';
import TextInput from '../../../common/TextInput';
import POMaterialsPriceInput from './POMaterialsPriceInput';
import POMaterialsMaterialInput from './POMaterialsMaterialInput';
import POMaterialsQtyOrderedInput from './POMaterialsQtyOrderedInput';
import SubTotalInput from './SubTotalInput';

export interface POMaterialsRowFormProps {
  recordId: Identifier;
  onSuccess: () => Promise<void>;
}

const POMaterialsRowForm: React.FC<POMaterialsRowFormProps> = ({
  recordId,
  onSuccess,
}): ReactElement => {
  const { close } = useRowContext();
  const record = useRecordContext();
  const [material, setMaterial] = useState<MaterialType>(record?.material);

  const mutationOptions = useMemo(
    () => ({
      onSuccess: async () => {
        await onSuccess();
        close();
      },
    }),
    [close, onSuccess]
  );

  const onMaterialSelect = useCallback(
    (id, material: MaterialType) => {
      setMaterial(material);
    },
    [setMaterial]
  );

  return (
    <RowForm
      mutationOptions={mutationOptions}
      defaultValues={{ materialPurchaseOrderId: recordId }}
    >
      <POMaterialsMaterialInput
        disabled={!!record?.id}
        onMaterialSelect={onMaterialSelect}
      />
      <TextInput
        source={record ? 'material.description' : 'materialDescription'}
        disabled
        label="Description"
      />
      <TextInput
        source={record ? 'material.materialType.type' : 'materialType'}
        disabled
        label="Type"
      />
      <POMaterialsQtyOrderedInput
        material={material}
        label="Qty Ordered"
        className="fixed"
      />
      <CustomNumberInput
        source="qtyReceived"
        isPositive
        defaultNull
        label="Qty Received"
        className="fixed"
        validate={[minValue(0), integer()]}
      />
      <POMaterialsPriceInput
        material={material}
        label="Price"
        className="fixed"
        readOnly
      />
      <SubTotalInput className="fixed" />
      <TextInput
        source="comment"
        validate={[maxLength(255)]}
        label="Comment"
        className="fixed2x"
      />
    </RowForm>
  );
};

export default POMaterialsRowForm;
