import React, { ReactElement } from 'react';
import { AutocompleteInput } from 'react-admin';
import { required } from '../../utils/UtilityFunctions';
import { ACTIVITY_CONSTRAINT_OPTIONS } from './constants';
import { SelectInputMultipleProps } from './types';

const ActivityConstraintInput: React.FC<SelectInputMultipleProps> = ({
  source,
  label,
  isRequired,
  ...rest
}): ReactElement => (
  <AutocompleteInput
    {...rest}
    source={source}
    label={label}
    choices={ACTIVITY_CONSTRAINT_OPTIONS}
    validate={isRequired ? required() : undefined}
  />
);

ActivityConstraintInput.defaultProps = {
  label: 'Constraint',
  source: 'activityConstraint',
};

export default ActivityConstraintInput;
