import React, { ReactElement } from 'react';
import { ArrayField, ChipField } from 'react-admin';
import SmartSingleFieldList from '../common/SmartSingleFieldList';

interface EquipmentTypeTestEquipmentTypeProps {
  label?: string;
  source?: string;
  sortable?: boolean;
}

const EquipmentTypeTestEquipmentType: React.FC<
  EquipmentTypeTestEquipmentTypeProps
> = ({ label, source, sortable }): ReactElement => (
  <ArrayField source={source} label={label} sortable={sortable}>
    <SmartSingleFieldList linkType={false}>
      <ChipField source="testEquipType.type" clickable={false} />
    </SmartSingleFieldList>
  </ArrayField>
);

EquipmentTypeTestEquipmentType.defaultProps = {
  source: 'equipTypeTestEquipTypes',
};

export default EquipmentTypeTestEquipmentType;
