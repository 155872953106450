import React, { ReactElement } from 'react';
import AttachmentFormTab from '../../common/AttachmentFormTab';
import { EditPropsWithLocation } from '../../common/types';
import TabbedEditForm from '../../common/TabbedEditForm';
import MetaDataInputs from '../../common/MetaDataInputs';
import commonStyles from '../../common/commonStyles';
import LazyFormTab from '../../common/LazyFormTab';
import HistoryTab from '../../common/HistoryTab';
import { RESOURCE_MATERIAL_PURCHASE_ORDER } from '../../constants';
import POMaterialsForm from './components/POMaterialsForm';
import PurchaseOrderRequestForm from './components/PurchaseOrderRequestForm';
import '../styles.css';

const PurchaseOrderRequestEdit: React.FC<EditPropsWithLocation> = (
  props
): ReactElement => (
  <TabbedEditForm {...props}>
    <LazyFormTab
      label="Purchase Order"
      id="purchase_order_tab"
      sx={commonStyles.formTabHeader}
    >
      <PurchaseOrderRequestForm />
      <MetaDataInputs />
    </LazyFormTab>
    <LazyFormTab
      label="PO Form"
      id="po_form_tab"
      contentClassName="materials-table"
      sx={commonStyles.formTabHeader}
    >
      <POMaterialsForm />
    </LazyFormTab>
    <LazyFormTab
      label="Attachment"
      id="attachment_tab"
      sx={commonStyles.formTabHeader}
    >
      <AttachmentFormTab
        targetId="materialPurchaseOrderId"
        resourceName={RESOURCE_MATERIAL_PURCHASE_ORDER}
      />
    </LazyFormTab>
    <HistoryTab tableName="tbl_material_purchase_order" />
  </TabbedEditForm>
);

export default PurchaseOrderRequestEdit;
