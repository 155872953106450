import React from 'react';
import { Link } from 'react-admin';
import useCanAccess from '../../common/hooks/useCanAccess';
import { RESOURCE_COMPANY_SUBSCRIPTION } from '../../constants';
import SendEmailButton from '../SendEmailButton';
import ContactSupportLink from '../../common/ContactSupportLink';

const Error402: React.FC = () => {
  const canAccess = useCanAccess(RESOURCE_COMPANY_SUBSCRIPTION);

  return (
    <div>
      <p>
        Your current subscription does not include the <b>Mobile App</b> add-on.
      </p>
      {canAccess.list ? (
        <p>
          You can manage your subscriptions at the{' '}
          <Link to={`/${RESOURCE_COMPANY_SUBSCRIPTION}`}>
            Subscriptions Portal
          </Link>
        </p>
      ) : (
        <div>
          <p>
            Please contact your TrackerCheck Administrator for further
            assistance.
          </p>
          <p>
            Alternatively, you may reach our support team at{' '}
            <ContactSupportLink />.
          </p>

          <p>
            Or you can send email to Subscription Administrator
            <SendEmailButton />
          </p>
        </div>
      )}
    </div>
  );
};

export default Error402;
