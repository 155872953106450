import React, { ReactElement, Fragment } from 'react';
import useInvalidateResourceQueryCache from '../../common/hooks/useInvalidateResourceQueryCache';
import PreservationItemActivityForm from '../PreservationItemActivityForm';
import CustomBulkUpdateButton from '../../common/CustomBulkUpdateButton';
import BulkGeneratePdfButton from '../../common/BulkGeneratePdfButton';
import { RESOURCE_PRESERVATION_ITEM_ACTIVITY } from '../../constants';
import useCanAccess from '../../common/hooks/useCanAccess';
import BulkEditButton from '../../common/BulkEditButton';
import RejectButton from './RejectButton';

const BulkActionButtons: React.FC = (): ReactElement => {
  const invalidateCache = useInvalidateResourceQueryCache();
  const canAccessList = useCanAccess();

  if (!canAccessList.edit) return;

  return (
    <Fragment>
      <RejectButton />
      <CustomBulkUpdateButton
        data={{ isMobile: true }}
        label="Send to mobile"
        icon={null}
        resource={RESOURCE_PRESERVATION_ITEM_ACTIVITY}
      />
      <BulkEditButton
        resource={RESOURCE_PRESERVATION_ITEM_ACTIVITY}
        form={<PreservationItemActivityForm />}
      />
      <BulkGeneratePdfButton onSuccess={invalidateCache} />
    </Fragment>
  );
};

export default BulkActionButtons;
