import '@progress/kendo-theme-material/dist/all.css';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { QueryClient } from '@tanstack/react-query';
import { DataProviderErrorsProvider } from './components/DataProviderErrors/DataProviderErrorsContext';
import AppContent from './AppContent';
import { DATA_VALIDITY_DURATION } from './provider/constants';
import HeaderMeta from './HeaderMeta';
import './App.css';

const disableCache = process.env.DISABLE_REACT_QUERY_CACHE;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: disableCache ? 0 : DATA_VALIDITY_DURATION,
      gcTime: disableCache ? 0 : undefined,
    },
  },
});

function App() {
  return (
    <DataProviderErrorsProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <BrowserRouter>
          <HeaderMeta />
          <AppContent queryClient={queryClient} />
        </BrowserRouter>
      </LocalizationProvider>
    </DataProviderErrorsProvider>
  );
}

export default App;
