import { Identifier, RaRecord } from 'react-admin';
import { NotificationType } from '../common/enums';
import { ImportResult } from '../common/ImportButton/types';
import { GeneratePdfMessage } from './components/NotificationTitle';

type BaseNotificationDetail = {
  resourceName: string;
  message?: string;
};

type ExportNotificationDetail = {
  downloadUrl?: string;
};

type ErrorNotificationDetail = {
  error?: string;
};

type ConvertToPdfAndAddAttachmentResult = {
  fileName: string;
  recordResource: string;
  recordId: Identifier;
};

type NotificationDetails = BaseNotificationDetail &
  ExportNotificationDetail &
  ImportResult &
  GeneratePdfMessage &
  ErrorNotificationDetail &
  ConvertToPdfAndAddAttachmentResult;

export interface Notification extends RaRecord {
  id: Identifier;
  email: string;
  notificationType: NotificationType;
  seen: boolean;
  notificationChannel: string;
  projectId: number;
  details: NotificationDetails;
  createdAt: Date;
}

export enum IMPORT_RESULT_STATUS {
  PARTIAL_FAIL = 'PARTIAL_FAIL',
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
}
