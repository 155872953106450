import React, { ReactElement } from 'react';
import { Link, useRecordContext } from 'react-admin';
import { Button as ButtonMaterial } from '@mui/material';
import ArticleIcon from '@mui/icons-material/Article';
import { RESOURCE_BOOK_SECTION } from '../constants';

export const BookSectionsLink: React.FC = (): ReactElement => {
  const record = useRecordContext();

  return (
    <Link to={`${record.id}/${RESOURCE_BOOK_SECTION}`}>
      <ButtonMaterial startIcon={<ArticleIcon />}>TOC</ButtonMaterial>
    </Link>
  );
};

export default BookSectionsLink;
