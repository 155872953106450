import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import ColumnActions from '../common/ColumnActions';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import ListWithTitle from '../common/ListWithTitle';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import ListActions from '../common/ListActions';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import ContactShow from './ContactShow';
import ContactEdit from './ContactEdit';

const ContactList: React.FC = (): ReactElement => {
  const defaultFilter = useDefaultFilter('project');

  return (
    <ListWithTitle
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={<ListActions />}
      sort={{ field: 'nickname', order: 'ASC' }}
    >
      <DatagridConfigurableRbac
        ShowComponent={<ContactShow withoutActions />}
        EditComponent={<ContactEdit syncWithLocation={false} />}
        defaultVisibleColumns={[
          'id',
          'nickname',
          'firstName',
          'lastName',
          'email',
          'isMobileUser',
        ]}
      >
        <TextField source="id" label="ID" />
        <TextField source="nickname" label="Nick Name" />
        <TextField source="firstName" label="First Name" />
        <TextField source="lastName" label="Last Name" />
        <TextField source="email" label="Email" />
        <TextField source="company" label="Company" />
        <TextField source="jobTitle" label="Job Title" />
        <TextField source="description" label="Description" />
        <TextField source="address" label="Address" />
        <TextField source="phone" label="Phone" />
        <TextField source="isMobileUser" label="Mobile User" />
        {renderMetaDataFields()}
        <ColumnActions label="Actions" />
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default ContactList;
