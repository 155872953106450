import React, { useCallback, Fragment, ReactElement } from 'react';
import {
  ReferenceOneField,
  useRecordContext,
  useResourceContext,
} from 'react-admin';
import { useQueryClient } from '@tanstack/react-query';
import { getContext, UserContext } from '../../provider/userContext';
import AttachmentRevisionList from '../attachmentRevision/AttachmentRevisionList';
import AttachmentCreateInDialog from '../attachment/AttachmentCreateInDialog';
import { ATTACHMENT_TYPE_ENUMS } from '../common/enums';
import {
  RESOURCE_ATTACHMENT,
  RESOURCE_ATTACHMENT_REVISION,
} from '../constants';
import AttachmentRevisionCreateInDialog from './AttachmentRevisionCreateInDialog';

type AttachmentRevisionFormTabType = {
  targetId: string;
  attachmentType?: string;
  onlyShow?: boolean;
  onUpload?: () => Promise<void>;
  withoutProjectId?: boolean;
};

const AttachmentRevisionFormTab: React.FC<AttachmentRevisionFormTabType> = ({
  targetId,
  attachmentType = [ATTACHMENT_TYPE_ENUMS.GENERAL],
  onlyShow,
  onUpload: onUploadFromProps,
  withoutProjectId,
}): ReactElement => {
  const ctx: UserContext = getContext();
  const queryClient = useQueryClient();
  const record = useRecordContext();
  const resource = useResourceContext();

  const onUpload = useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: [RESOURCE_ATTACHMENT_REVISION],
    });
    await queryClient.invalidateQueries({ queryKey: [resource, 'getList'] });
    await queryClient.invalidateQueries({
      queryKey: [resource, 'getOne', { id: String(record.id) }],
    });

    if (onUploadFromProps) {
      await onUploadFromProps();
    }
  }, [onUploadFromProps, queryClient, record.id, resource]);

  return (
    <Fragment>
      <ReferenceOneField
        reference={RESOURCE_ATTACHMENT}
        target={targetId}
        fullWidth={true}
        filter={{
          isDeleted: false,
          attachmentType,
          ...(!withoutProjectId ? { projectId: ctx?.projectId } : {}),
        }}
      >
        <Fragment>
          <AttachmentRevisionList
            onUpload={onUpload}
            onlyShow={onlyShow}
            outerResource={resource}
            outerResourceId={record?.id}
            externalFormId={record?.externalFormId ?? ''}
          />
          {!onlyShow && (
            <AttachmentRevisionCreateInDialog onUpload={onUpload} />
          )}
        </Fragment>
      </ReferenceOneField>
      {record && !record?.downloadUrl && !onlyShow && (
        <AttachmentCreateInDialog
          title="Add revision"
          onUpload={onUpload}
          targetId={targetId}
          withoutProjectId={withoutProjectId}
        />
      )}
    </Fragment>
  );
};

export default AttachmentRevisionFormTab;
