import React, { ReactElement } from 'react';
import { ChipField, ReferenceArrayField } from 'react-admin';
import { RESOURCE_SECURITY_ROLE } from '../constants';
import SmartSingleFieldList from './SmartSingleFieldList';

type PropsType = Omit<
  React.ComponentProps<typeof ReferenceArrayField>,
  'reference'
>;

const UserProjectRoleField: React.FC<PropsType> = ({
  label,
  ...rest
}): ReactElement => (
  <ReferenceArrayField
    label={label ?? ''}
    reference={RESOURCE_SECURITY_ROLE}
    {...rest}
  >
    <SmartSingleFieldList>
      <ChipField source="role" />
    </SmartSingleFieldList>
  </ReferenceArrayField>
);

export default UserProjectRoleField;
