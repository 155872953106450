import React, { Fragment, ReactElement, useCallback } from 'react';
import { Edit, SimpleForm, useRecordContext } from 'react-admin';
import { PDFViewer } from '@progress/kendo-react-pdf-viewer';
import Box from '@mui/material/Box';
import useInvalidateRelatedResourceQueries from '../common/hooks/useInvalidateRelatedResourceQueries';
import { getAttachmentFileType } from '../attachment/helpers';
import { getFileName } from '../common/helpers/getFileName';
import { AttachmentFileType } from '../attachment/types';
import { EditPropsWithLocation } from '../common/types';
import SaveOnlyToolbar from '../common/SaveOnlyToolbar';
import { ComponentTest } from '../componentTest/types';
import commonStyles from '../common/commonStyles';
import SmartTagITRReviewForm from './components/SmartTagITRReviewForm';

const SmartTagITRReviewEdit: React.FC<EditPropsWithLocation<ComponentTest>> = (
  props
): ReactElement => {
  const record = useRecordContext();
  const fileName = getFileName(record.downloadUrl);
  const attachmentFileType = getAttachmentFileType(fileName);
  const invalidateQueries = useInvalidateRelatedResourceQueries();

  const clearCache = useCallback(async () => {
    await invalidateQueries();
  }, [invalidateQueries]);

  return (
    <Edit
      {...props}
      mutationOptions={{
        ...props.mutationOptions,
        onSettled: clearCache,
      }}
      mutationMode="pessimistic"
      actions={false}
      title={<></>}
    >
      <SimpleForm sx={commonStyles.rowFlexBox} toolbar={<SaveOnlyToolbar />}>
        <SmartTagITRReviewForm />
        <Box sx={commonStyles.pdfViewBox}>
          {record.downloadUrl ? (
            attachmentFileType === AttachmentFileType.pdf ? (
              <PDFViewer
                url={record.downloadUrl ? record.downloadUrl : ''}
                tools={[
                  'pager',
                  'spacer',
                  'zoomInOut',
                  'zoom',
                  'selection',
                  'spacer',
                  'search',
                ]}
                defaultZoom={1}
                style={{ width: 750, height: '75vh' }}
              />
            ) : (
              <Fragment>Attachment is not PDF.</Fragment>
            )
          ) : (
            <Fragment>No Attachment.</Fragment>
          )}
        </Box>
      </SimpleForm>
    </Edit>
  );
};

export default SmartTagITRReviewEdit;
