import React, { Fragment, ReactElement, useState, useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  Button,
  SimpleForm,
  useResourceContext,
  useUnselectAll,
  useListContext,
  useUpdateMany,
  useNotify,
} from 'react-admin';
import commonStyles from '../../common/commonStyles';
import ModalDialog from '../../common/ModalDialog';
import TextInput from '../../common/TextInput';
import { CompletedType } from '../constants';

interface RejectButtonProps {
  label?: string;
}

const RejectButton: React.FC<RejectButtonProps> = ({ label }): ReactElement => {
  const queryClient = useQueryClient();
  const notify = useNotify();
  const resource = useResourceContext();
  const [updateMany] = useUpdateMany();
  const unselectAll = useUnselectAll(resource);
  const { selectedIds } = useListContext();
  const [open, setOpen] = useState(false);
  const openDialog = useCallback(() => setOpen(true), []);
  const closeDialog = useCallback(() => setOpen(false), []);

  const submit = useCallback(
    async (data) => {
      await updateMany(
        resource,
        {
          ids: selectedIds,
          data: { ...data, completed: CompletedType.REJECTED },
        },
        {
          onSuccess: async () => {
            queryClient.invalidateQueries({ queryKey: [resource] });
            notify(`${selectedIds.length} elements updated`, {
              type: 'success',
            });
            unselectAll();
          },
        }
      );
      closeDialog();
    },
    [
      closeDialog,
      unselectAll,
      selectedIds,
      notify,
      updateMany,
      queryClient,
      resource,
    ]
  );

  return (
    <Fragment>
      <Button label={label} onClick={openDialog} />

      <ModalDialog
        title="Update Review Comments"
        open={open}
        onClose={closeDialog}
      >
        <SimpleForm id="edit_review_comments_form" onSubmit={submit}>
          <TextInput
            source="reviewComments"
            label="Review Comments"
            sx={commonStyles.flexBoxLongItem}
            fullWidth
            multiline
          />
        </SimpleForm>
      </ModalDialog>
    </Fragment>
  );
};

RejectButton.defaultProps = { label: 'Reject' };

export default RejectButton;
