import React, { ReactElement } from 'react';
import CertDetailsEditTab from '../certDetails/CertDetailsEditTab';
import commonStyles from '../common/commonStyles';
import HistoryTab from '../common/HistoryTab';
import MetaDataInputs from '../common/MetaDataInputs';
import TabbedEditForm from '../common/TabbedEditForm';
import LazyFormTab from '../common/LazyFormTab';
import { EditPropsWithLocation } from '../common/types';
import { RESOURCE_COMPONENT_ACTIVITY } from '../constants';
import ComponentActivityForm from './components/ComponentActivityForm';

const ComponentActivityEdit: React.FC<EditPropsWithLocation> = (
  props
): ReactElement => {
  return (
    <TabbedEditForm {...props}>
      <LazyFormTab
        label="Component Activity"
        id="component_activity_tab"
        sx={commonStyles.formTabHeader}
      >
        <ComponentActivityForm />
        <MetaDataInputs />
      </LazyFormTab>
      <LazyFormTab
        label="Certificates"
        id="certificates_tab"
        sx={commonStyles.formTabHeader}
      >
        <CertDetailsEditTab
          target="componentActivityId"
          targetReference={RESOURCE_COMPONENT_ACTIVITY}
        />
      </LazyFormTab>
      <HistoryTab tableName="tbl_component_activity" />
    </TabbedEditForm>
  );
};

export default ComponentActivityEdit;
