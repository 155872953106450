import grey from '@mui/material/colors/grey';
import { GREY } from '../../theme/colors';

const commonStyles = {
  columnActionsBox: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    '& .MuiButtonBase-root': {
      minWidth: '34px',
      height: '34px',
      '& > span': {
        margin: 0,
      },
    },
    '& .MuiSvgIcon-root, svg': {
      width: '18px',
      height: '18px',
      color: GREY[900],
    },
  },
  editableDataGrid: {
    marginBottom: '20px',
    width: '700px',
  },
  footerActionsBox: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    gap: '10px',
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '15px',
  },
  flexBoxWithWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    gap: '15px',
  },
  formTabHeader: {
    display: 'flex',
  },
  flexBoxItem: {
    width: '225px',
    marginRight: '10px',
    marginTop: '8px',
  },
  flexBoxLongItem: {
    width: '475px',
    marginRight: '10px',
    marginTop: '8px',
  },
  flexBoxFullWidthItem: {
    width: '100%',
    marginRight: '10px',
    marginTop: '8px',
  },
  flexBoxDescriptionItem: {
    width: '725px',
    marginRight: '10px',
    marginTop: '8px',
  },
  flexBoxSelectItem: {
    width: '300px',
    marginRight: '10px',
    marginTop: '8px',
  },
  markdownItem: {
    minWidth: '360px',
    maxWidth: '600px',
    maxHeight: '72px',
    overflow: 'hidden',
    marginBottom: '18px',
  },
  chipMargin: {
    marginRight: '10px',
    marginBottom: '3px',
  },
  bottomMargin: {
    marginBottom: '18px',
  },
  createBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  createBoxHalfWidth: {
    width: '50%',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  tabbedFormBoldHeader: {
    '& .Mui-selected.form-tab': {
      fontWeight: 600,
    },
  },
  noPadding: {
    padding: 0,
  },
  fullWidth: {
    width: '100%',
  },
  halfWidth: {
    width: '50%',
  },
  filterSidebar: {
    order: -1,
    mr: 1,
    mt: 1.25,
    mb: 6.5,
    width: '255px',
    '& .RaFilterFormInput-spacer': {
      display: 'none',
    },
  },
  uploadFieldBox: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10px',
  },
  uploadFieldRowBox: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '10px',
    gap: '15px',
  },
  uploadFieldRowBoxItem: {
    flex: 1,
  },
  uploadTextField: {
    margin: 0,
    marginLeft: '10px',
  },
  uploadField: {
    '& .RaFileInput-dropZone': {
      background: '#e1e5e9',
      border: '2px dashed',
    },
  },
  displayNone: {
    display: 'none',
  },
  previewImageField: {
    '& .RaImageField-image': {
      height: 'auto',
      width: 'auto',
      minWidth: 100,
      minHeight: 100,
      maxWidth: 600,
      maxHeight: 600,
      objectFit: 'contain',
    },
  },
  emptyButton: { height: 34, width: 51 },
  emptyIconButtonPlaceholder: {
    height: 34,
    width: 34,
  },
  columnFlexBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  rowFlexBox: {
    '.MuiStack-root': {
      flexDirection: 'row',
    },
  },
  formTabDatagrid: {
    minWidth: '360px',
    '& .MuiTableRow-root td:last-of-type': {
      minWidth: '130px',
      '& > *': {
        visibility: 'visible',
      },
    },
  },
  formTabInput: {
    display: 'block',
    width: '225px',
  },
  statusColorDot: {
    display: 'inline-block',
    width: '12px',
    height: '12px',
    border: `1px solid ${GREY}`,
    borderRadius: '50%',
  },
  headerWithBorder: {
    borderBottom: `1px solid ${GREY}`,
  },
  radioIcon: {
    '& .MuiSvgIcon-root': {
      fontSize: 18,
    },
  },
  rowFormField: {
    display: 'flex',
    width: '225px',
    height: '40px',
    alignItems: 'center',
  },
  rowFormShortField: {
    display: 'flex',
    width: '125px',
    height: '40px',
    alignItems: 'center',
  },
  rowFormInput: {
    width: '225px',
    height: '40px',
    margin: 0,
    '& > .MuiFormControl-root': {
      margin: 0,
    },
  },
  rowFormShortInput: {
    width: '125px',
    height: '40px',
    margin: 0,
    '& > .MuiFormControl-root': {
      margin: 0,
    },
  },
  logo: {
    height: '52px',
    padding: '12px',
  },
  customChip: {
    color: 'red',
    background: 'transparent',
  },
  pdfViewBox: {
    marginLeft: '18px',
    width: '750px',
    display: 'flex',
    justifyContent: 'center',
    fontSize: 24,
  },
  pendingPunchEditForm: {
    '& > div > div': {
      width: '100%',
      margin: 0,
    },
  },
  pendingPunchCreateForm: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > div': {
      width: '100%',
    },
    '& > div > div': {
      flexGrow: 1,
    },
  },
  smResponsiveButton: {
    '& .MuiButton-startIcon': {
      display: {
        sm: 'none',
      },
    },
  },
  mdResponsiveButton: {
    '& .MuiButton-startIcon': {
      display: {
        md: 'none',
      },
    },
  },
  blackColor: {
    color: 'black',
  },
  telerikContainer: {
    width: '100%',
    height: '85vh',
    padding: '5px',
  },
  telerikReportContainer: {
    '& .k-menu:not(.k-context-menu)>.k-item': {
      color: '#00435e',
    },

    '& .k-disabled, .k-widget[disabled]': {
      opacity: '0.3',
    },
  },
  emptyBox: {
    flex: 1,

    '& .empty-message': {
      textAlign: 'center',
      opacity: 0.5,
      margin: '0 1em',
      color: 'inherit',
    },

    '& .empty-icon': {
      width: '9em',
      height: '9em',
    },
  },
  sideMenu: {
    paddingBottom: '30px',
  },
  sideMenuMultiLevelItem: {
    '& .RaMenuItem-icon': {
      right: '8px',
    },
  },
  paginationButton: {
    border: 'none',
    minWidth: 0,
    padding: 0,
    color: 'black',
  },
  textInputDisabled: {
    '& .MuiInputLabel-root': {
      color: 'rgba(0, 0, 0, 0.38)',
    },
  },
  imageListItemBar: {
    backgroundColor: GREY,
    justifyContent: 'space-between',
    '& .MuiImageListItemBar-titleWrap': {
      padding: '6px 0 6px 6px',
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiImageListItemBar-actionIcon': {
      display: 'flex',
    },
    '& .MuiImageListItemBar-subtitle': {
      color: 'white',
    },
  },
  imageGalleryItemImageField: {
    backgroundColor: grey[100],
    display: 'flex',
    flex: '1 1 auto',
    justifyContent: 'center',
    alignContent: 'center',
    flexWrap: 'wrap',
    cursor: 'pointer',
    '& .RaImageField-image': {
      width: '300px',
      height: 'auto',
      aspectRatio: 1,
    },
  },
};

export default commonStyles;
