import moment from 'moment/moment';
import { diff } from 'just-diff';
import { union } from 'lodash';
import { ErrorObject } from 'ajv';
import { JsonFormsStateContext } from '@jsonforms/react';
import {
  errorsAt,
  formatErrorMessage,
  JsonSchema,
  JsonFormsRendererRegistryEntry,
  JsonFormsCellRendererRegistryEntry,
} from '@jsonforms/core';

export const getCurrentDate = (format?: string): string =>
  moment(new Date()).format(format || 'YYYY-MM-DD');

export const getLocaleFormatDate = (
  date: moment.Moment,
  localeDateFormat: string
): string =>
  moment(date.format(localeDateFormat), localeDateFormat).format('YYYY-MM-DD');

export const isValidDate = (date: string, format?: string) => {
  return date && moment(date, format || 'YYYY-MM-DD').isValid();
};

export const getOptions = (schema) => {
  let options = [];
  if (schema?.oneOf) {
    options = schema.oneOf.map((item) => ({
      label: item.title,
      value: item.const,
    }));
  }
  if (schema?.enum) {
    options = schema.enum.map((item) => ({ label: item, value: item }));
  }
  return options;
};

export const getDiffObject = (formData, data) => {
  const diffObj = diff(formData, data);
  return { diffObj, op: diffObj?.[0]?.op, key: diffObj?.[0]?.path?.join('.') };
};

export const getStepActions = (key, op, fullName) => {
  return {
    [key]: {
      [op === 'remove' ? 'clearedBy' : 'updatedBy']: fullName,
      [op === 'remove' ? 'clearedAt' : 'updatedAt']: new Date().toISOString(),
    },
  };
};

export const getFormUiSchemaFieldOrder = (ui, res) => {
  for (const el of ui.elements || []) {
    if (el?.scope) {
      res.push(el.scope.replace('#/properties/', ''));
    } else if (el?.elements) {
      getFormUiSchemaFieldOrder(el, res);
    }
  }
};

export interface FieldProps extends OwnPropsOfField {
  rootSchema: JsonSchema;
  errors: string;
  path: string;
  enabled: boolean;
}

export interface OwnPropsOfField {
  rowPath: string;
  propName?: string;
  schema: JsonSchema;
  enabled: boolean;
  renderers?: JsonFormsRendererRegistryEntry[];
  cells?: JsonFormsCellRendererRegistryEntry[];
}

export const ctxToFieldProps = (
  ctx: JsonFormsStateContext,
  ownProps: OwnPropsOfField
): FieldProps => {
  const path =
    ownProps.rowPath +
    (ownProps.schema.type === 'object' ? '.' + ownProps.propName : '');
  const errors = formatErrorMessage(
    union(
      errorsAt(
        path,
        ownProps.schema,
        (p) => p === path
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      )(ctx.core.errors).map((error: ErrorObject) => error.message)
    )
  );
  return {
    rowPath: ownProps.rowPath,
    propName: ownProps.propName,
    schema: ownProps.schema,
    rootSchema: ctx.core.schema,
    errors,
    path,
    enabled: ownProps.enabled,
    cells: ownProps.cells || ctx.cells,
    renderers: ownProps.renderers || ctx.renderers,
  };
};
