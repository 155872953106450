import React, { ReactElement } from 'react';
import { BooleanInput, maxLength } from 'react-admin';
import Box from '@mui/material/Box';
import HistoryTab from '../common/HistoryTab';
import LazyFormTab from '../common/LazyFormTab';
import TabbedEditForm from '../common/TabbedEditForm';
import commonStyles from '../common/commonStyles';
import MetaDataInputs from '../common/MetaDataInputs';
import CustomDateInput from '../common/CustomDateInput';
import { EditPropsWithLocation } from '../common/types';
import TextInput from '../common/TextInput';
import { required } from '../../utils/UtilityFunctions';
import { Division } from './type';

const DivisionEdit: React.FC<EditPropsWithLocation<Division>> = (
  props
): ReactElement => (
  <TabbedEditForm {...props}>
    <LazyFormTab
      label="Division"
      id="division_tab"
      sx={commonStyles.formTabHeader}
    >
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="divisionNumber"
          label="Division Number"
          validate={[required(), maxLength(50)]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="divisionName"
          label="Division Name"
          validate={[required(), maxLength(200)]}
          sx={commonStyles.flexBoxItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <CustomDateInput
          source="startDate"
          label="Start Date"
          sx={commonStyles.flexBoxItem}
        />
        <CustomDateInput
          source="stopDate"
          label="Stop Date"
          sx={commonStyles.flexBoxItem}
        />
        <BooleanInput source="isActive" label="Active" />
      </Box>
      <MetaDataInputs />
    </LazyFormTab>
    <HistoryTab tableName="tbl_division" />
  </TabbedEditForm>
);

export default DivisionEdit;
