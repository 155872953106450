import React, { ReactElement } from 'react';
import CertDetailsEditTab from '../certDetails/CertDetailsEditTab';
import commonStyles from '../common/commonStyles';
import HistoryTab from '../common/HistoryTab';
import MetaDataInputs from '../common/MetaDataInputs';
import TabbedEditForm from '../common/TabbedEditForm';
import LazyFormTab from '../common/LazyFormTab';
import { EditPropsWithLocation } from '../common/types';
import { RESOURCE_ACTIVITY } from '../constants';
import ActivityForm from './components/ActivityForm';

const ActivityEdit: React.FC<EditPropsWithLocation> = (props): ReactElement => {
  return (
    <TabbedEditForm {...props}>
      <LazyFormTab
        label="Activity"
        id="activity_tab"
        sx={commonStyles.formTabHeader}
      >
        <ActivityForm />
        <MetaDataInputs />
      </LazyFormTab>
      <LazyFormTab
        label="Certificates"
        id="certificates_tab"
        sx={commonStyles.formTabHeader}
      >
        <CertDetailsEditTab
          target="activityId"
          targetReference={RESOURCE_ACTIVITY}
        />
      </LazyFormTab>
      <HistoryTab tableName="tbl_activity" />
    </TabbedEditForm>
  );
};

export default ActivityEdit;
