import React, { ReactElement } from 'react';
import { Button, Filter } from 'react-admin';
import { Card, CardContent } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import TextInput from '../common/TextInput';
import DateRangeFilter from '../common/DateRangeFilter';
import sideFilterStyles from '../common/sideFilterStyles';
import useSidebarFilters from '../common/hooks/useSidebarFilters';
import { RESOURCE_SECURITY_ROLE_PERMISSION } from '../constants';
import SecurityRoleInput from './SecurityRoleInput';
import ResourceInput from './ResourceInput';
import PermissionTypeInput from './PermissionTypeInput';
import ActionInput from './ActionInput';

const SecurityRolePermissionSidebarFilters: React.FC = (): ReactElement => {
  const {
    data,
    isLoading,
    filterValues,
    dateFrom,
    dateTo,
    handleDateFromChange,
    handleDateToChange,
    handleClearFilters,
  } = useSidebarFilters(RESOURCE_SECURITY_ROLE_PERMISSION, {});

  if (!data || (!data.length && isEmpty(filterValues)) || isLoading) {
    return null;
  }

  return (
    <Card sx={sideFilterStyles.sideFiltersCard}>
      <CardContent sx={sideFilterStyles.sideFiltersCardContainer}>
        <Filter>
          <SecurityRoleInput
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <PermissionTypeInput
            emptyText="All"
            alwaysOn
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <ResourceInput
            source="resourceName_inc"
            alwaysOn
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <ActionInput alwaysOn isSidebarFilter label="Role Action" />
          <DateRangeFilter
            label="Updated"
            source="updatedAt"
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
          />
          <TextInput
            label="Updated By"
            name="updatedBy_inc"
            source="updatedBy"
            fullWidth
            alwaysOn
          />
          <TextInput
            label="Updated By Name"
            name="updatedByName_inc"
            source="updatedByName"
            fullWidth
            alwaysOn
          />
        </Filter>
        <Button label="Clear filters" onClick={handleClearFilters} />
      </CardContent>
    </Card>
  );
};

export default SecurityRolePermissionSidebarFilters;
