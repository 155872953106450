import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import { Labeled, TabbedShowLayout, TextField, DateField } from 'react-admin';
import renderShowMetaDataFields from '../common/helpers/renderShowMetaDataFields';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import DisciplineTestField from '../common/DisciplineTestField';
import CustomBooleanField from '../common/CustomBooleanField';
import AssignedField from '../common/Assigned/AssignedField';
import AttachmentList from '../attachment/AttachmentList';
import DisciplineField from '../common/DisciplineField';
import { ATTACHMENT_TYPE_ENUMS } from '../common/enums';
import ComponentField from '../common/ComponentField';
import LocationField from '../common/LocationField';
import commonStyles from '../common/commonStyles';
import AuditListTab from '../audit/AuditListTab';
import SelectField from '../common/SelectField';
import VendorField from '../common/VendorField';
import TestField from '../common/TestField';
import { RESOURCE_COMPONENT_TEST } from '../constants';
import MobileTestStatusField from './components/MobileTestStatusField';
import { statuses } from './constants';

const ComponentTestShow: React.FC<ShowComponentProps> = ({
  syncWithLocation,
  ...rest
}): ReactElement => (
  <ShowComponent {...rest}>
    <TabbedShowLayout
      sx={commonStyles.bottomMargin}
      syncWithLocation={syncWithLocation}
    >
      <TabbedShowLayout.Tab
        label="Component Test"
        id="component_test_tab"
        sx={commonStyles.formTabHeader}
      >
        <Box sx={commonStyles.columnFlexBox}>
          <Box>
            <Labeled label="Tag" sx={commonStyles.flexBoxItem}>
              <ComponentField source="componentId" />
            </Labeled>
            <Labeled label="Test" sx={commonStyles.flexBoxItem}>
              <TestField source="testId" textSource="test.testFormNo" />
            </Labeled>
            <Labeled label="Status">
              <SelectField
                source="status"
                sx={commonStyles.flexBoxItem}
                choices={statuses}
              />
            </Labeled>
            <Labeled label="Location">
              <LocationField source="locationId" />
            </Labeled>
          </Box>
          <Box>
            <Labeled label="Verified">
              <CustomBooleanField
                source="isVerified"
                sx={commonStyles.flexBoxItem}
              />
            </Labeled>
            <Labeled label="Completed Test Date" sx={commonStyles.flexBoxItem}>
              <DateField source="completedDateTime" />
            </Labeled>
            <Labeled label="Completed By">
              <TextField
                source="completedBy"
                sx={commonStyles.flexBoxLongItem}
              />
            </Labeled>
          </Box>
          <Box>
            <Labeled label="Retest">
              <CustomBooleanField
                source="retest"
                sx={commonStyles.flexBoxItem}
              />
            </Labeled>
            <Labeled label="Retest Reason">
              <TextField source="retestReason" sx={commonStyles.flexBoxItem} />
            </Labeled>
          </Box>
          <Box>
            <Labeled label="Review Comments">
              <TextField
                source="reviewComments"
                sx={commonStyles.flexBoxLongItem}
              />
            </Labeled>
          </Box>
          <Box>
            <Labeled label="Mobile">
              <CustomBooleanField
                source="isMobile"
                sx={commonStyles.flexBoxItem}
              />
            </Labeled>
            <Labeled label="Mobile Test Status">
              <MobileTestStatusField
                source="mobileTestStatus"
                sx={commonStyles.flexBoxItem}
              />
            </Labeled>
            <Labeled label="Responsible" sx={commonStyles.flexBoxItem}>
              <AssignedField />
            </Labeled>
          </Box>
          <Box>
            <Labeled label="ITR Total Signatures" sx={commonStyles.flexBoxItem}>
              <TextField source="itrTotalSignatures" />
            </Labeled>
            <Labeled label="ITR Signed" sx={commonStyles.flexBoxItem}>
              <TextField source="itrSigned" />
            </Labeled>
            <Labeled label="ITR Signed By" sx={commonStyles.flexBoxItem}>
              <TextField source="itrSignedBy" />
            </Labeled>
            <Labeled label="Responsible Vendor" sx={commonStyles.flexBoxItem}>
              <VendorField
                source="responsibleVendorId"
                textSource="responsibleVendor.vendor"
              />
            </Labeled>
          </Box>
          <Box>
            <Labeled label="Printed">
              <CustomBooleanField
                source="isPrinted"
                sx={commonStyles.flexBoxItem}
              />
            </Labeled>
            <Labeled label="Printed By">
              <TextField source="printedBy" sx={commonStyles.flexBoxLongItem} />
            </Labeled>
            <Labeled label="Printed Date" sx={commonStyles.flexBoxItem}>
              <DateField source="printedDate" />
            </Labeled>
          </Box>
          <Box>
            <Labeled label="Test Discipline" sx={commonStyles.flexBoxItem}>
              <DisciplineTestField
                source="test.disciplineTestId"
                textSource="test.disciplineTest.disciplineTest"
              />
            </Labeled>
            <Labeled label="Tag Discipline" sx={commonStyles.flexBoxItem}>
              <DisciplineField
                source="component.disciplineId"
                textSource="component.discipline.discipline"
              />
            </Labeled>
          </Box>
          <Box>{renderShowMetaDataFields()}</Box>
        </Box>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Attachment" id="attachment_tab">
        <AttachmentList
          targetId="componentTestId"
          resourceName={RESOURCE_COMPONENT_TEST}
          onlyShow={true}
          attachmentType={[
            ATTACHMENT_TYPE_ENUMS.GENERAL,
            ATTACHMENT_TYPE_ENUMS.SMART_ITR,
          ]}
        />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="History">
        <AuditListTab tableName="tbl_component_test" />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </ShowComponent>
);

export default ComponentTestShow;
