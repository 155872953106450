import React, { ReactElement } from 'react';
import { SimpleForm } from 'react-admin';
import { getContext, UserContext } from '../../provider/userContext';
import CreateWithTitle from '../common/CreateWithTitle';
import FooterToolbar from '../common/FooterToolbar';
import TestEquipmentForm from './TestEquipmentForm';
import { TestEquipment } from './types';

const TestEquipmentCreate: React.FC = (): ReactElement => {
  const ctx: UserContext = getContext();

  const transform = (data: TestEquipment): TestEquipment => ({
    ...data,
    projectId: ctx?.projectId,
  });

  return (
    <CreateWithTitle redirect="list" transform={transform}>
      <SimpleForm toolbar={<FooterToolbar />}>
        <TestEquipmentForm />
      </SimpleForm>
    </CreateWithTitle>
  );
};

export default TestEquipmentCreate;
