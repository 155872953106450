import { useState } from 'react';
import { useDataProvider } from 'react-admin';
import { ATTACHMENT_TYPE_ENUMS } from '../enums';
import { useUploadFile } from './useUploadFile';

const useImport = () => {
  const [importInProgress, setImportInProgress] = useState(false);
  const dataProvider = useDataProvider();
  const { uploadFile } = useUploadFile();
  const importFile = async (
    resource,
    { projectId, divisionId, timeZone, file }
  ) => {
    try {
      setImportInProgress(true);
      const response = await uploadFile(resource, file, {
        projectId,
        divisionId,
        timeZone,
        attachmentType: ATTACHMENT_TYPE_ENUMS.IMPORT,
      });
      return dataProvider.import(resource, {
        projectId,
        divisionId,
        timeZone,
        ...response,
      });
    } catch (error) {
      let requestError = error;
      if (error?.response?.data instanceof Blob) {
        requestError = await error?.response?.data.text();
      }
      throw requestError;
    } finally {
      setImportInProgress(false);
    }
  };
  return { importInProgress, importFile };
};

export default useImport;
